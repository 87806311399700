// Libs
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, SingleSelect } from '~/components';
// Others
import { IFilterOption, ISingleSelectOption } from '~/utils/interface/common';
import { ButtonTypeEnum, FieldFilterTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './FilterOptions.module.scss';
import { icons } from '~/assets';

type Props = {
  fields?: IFilterOption[];
  onChange?: (value: string, name: string) => void;
  onApply?: () => void;
};

const cx = classNames.bind(styles);

const FilterOptions = (props: Props) => {
  //#region Destructuring Props
  const { fields, onChange, onApply } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const filterRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [showFilter, setShowFilter] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSelectChange = (value: string, name: string) => {
    onChange && onChange(value, name);
  };

  const handleRenderOption = (field: IFilterOption) => {
    switch (field.type) {
      case FieldFilterTypeEnum.SELECT:
        return (
          <li key={field.label} className={cx('option')}>
            <span className={cx('label')}>{t(field.label)}</span>

            <div className={cx('selectWrap')}>
              <SingleSelect
                width={174}
                height={27}
                name={field.name}
                options={field.options}
                onChange={handleSelectChange}
                value={field.options[0].value}
              />
            </div>
          </li>
        );
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
      setShowFilter(false);
    }
  };

  const handleApplyFilter = () => {
    onApply && onApply();
    setShowFilter(false);
  };
  //#endregion Handle Function

  return (
    <div id='filterOptionsComponent' className={cx('container')} ref={filterRef}>
      <BaseButton
        width={117}
        height={31}
        label={t('common_btn_filter')}
        icon={icons.commonIconFilter}
        onClick={handleFilter}
      />

      {showFilter && (
        <div className={cx('optionsWrap')}>
          <ul className={cx('options')}>{fields?.map((item) => handleRenderOption(item))}</ul>

          <div className={cx('actions')}>
            <BaseButton
              width={117}
              height={31}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              label={t('common_btn_apply')}
              onClick={handleApplyFilter}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterOptions;
