// Libs
import classNames from 'classnames/bind';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { IProposal } from '~/utils/interface/proposal';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldUnassignedProposal.module.scss';

type Props = {
  lineItemIndex: number;
  fieldIndex: number;
  removeField?: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldUnassignedProposal = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex, fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldUnassignedProposalComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className={cx('colItem')}>
          <Controller
            control={control}
            name={`lineItems.${lineItemIndex}.subcontractor.unassignedWorks.${fieldIndex}.name`}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('admin_manage_proposal_subcontractors_item')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className={cx('colValue')}>
          <Controller
            control={control}
            name={`lineItems.${lineItemIndex}.subcontractor.unassignedWorks.${fieldIndex}.value`}
            render={({ field: { value, onChange } }) => (
              <FormInput label={t('Value')} value={value || EMPTY_STRING} onChange={onChange} />
            )}
          />
        </div>
      </div>

      <button
        className={cx('buttonClose')}
        type='button'
        onClick={() => removeField && removeField(fieldIndex)}
      >
        <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
      </button>
    </div>
  );
};

export default FieldUnassignedProposal;
