// Libs
import {
  Description,
  Field,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import classNames from 'classnames/bind';
import { ChangeEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import {
  ASTERISK_SYMBOL,
  DEFAULT_INDEX_OF_NULL,
  DEFAULT_SLICE_ONE_ITEM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IBaseOption, ISingleSelectOption } from '~/utils/interface/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './MultipleSelection.module.scss';

type Props = {
  name?: string;
  label?: string;
  value?: string[];
  placeholder?: string;
  options: IBaseOption[];
  disabled?: boolean;
  width?: number | string;
  height?: number | string;
  errorMessage?: string;
  isRequired?: boolean;
  onChange?: (optionsSelected: IBaseOption[], name: string) => void;
};

const cx = classNames.bind(styles);

const MultipleSelection = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    label,
    value,
    placeholder,
    options,
    disabled = false,
    width,
    height,
    errorMessage,
    isRequired,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [optionsSelected, setOptionsSelected] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value || value.length === 0) return;

    const optionMatchWithValue = options.filter((item) => value.includes(item.value));
    setOptionsSelected(optionMatchWithValue);
  }, [value, options]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleOptionChange = (selectedValues: IBaseOption[]) => {
    onChange && onChange(selectedValues, name ?? '');
    setOptionsSelected(selectedValues);
  };
  //#endregion Handle Function

  return (
    <Field
      id='multipleSelectionComponent'
      className={cx('container', { disabled })}
      style={{ width }}
      disabled={disabled}
    >
      {label && (
        <label className={cx('label')}>
          {label} {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </label>
      )}

      <Listbox value={optionsSelected} multiple onChange={handleOptionChange}>
        <ListboxButton className={cx('btnSelect')} style={{ height }}>
          {({ open }) => (
            <>
              {placeholder && optionsSelected.length === 0 ? (
                <span className={cx('btnPlaceholder')}>{placeholder}</span>
              ) : (
                <span className={cx('btnText')}>
                  {optionsSelected.map((option) => option.label).join(', ')}
                </span>
              )}
              <img
                src={icons.commonIconArrowBottom}
                className={cx(open ? 'iconActive' : '')}
                alt={t('common_img_text_alt')}
              />
            </>
          )}
        </ListboxButton>

        <ListboxOptions
          className={cx('optionList')}
          transition
          anchor={{ to: 'bottom', gap: '4px' }}
        >
          {options.length > 0 ? (
            <>
              {options.map((option) => (
                <ListboxOption
                  key={option.value}
                  value={option}
                  className={({ selected }) => cx('optionItem', selected && 'optionActive')}
                >
                  {option.label}
                </ListboxOption>
              ))}
            </>
          ) : (
            <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
          )}
        </ListboxOptions>
      </Listbox>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default MultipleSelection;
