// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  CircleAvatar,
  Toolbar,
  AdminTimeClockDetailsModal,
  ConfirmModal,
  AdminAddTimeClockModal,
  ThreeDotOptions,
  ModalUnderDevelopment,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { deleteTimeClock, getListTimeClock } from '~/thunks/timeClock/timeClockThunk';
import {
  AccountRoleCodesEnum,
  BaseTableEnum,
  CircleAvatarEnum,
  StorageEnum,
  TimeFormatEnum,
} from '~/utils/enum';
import {
  convertTime,
  formattedTime,
  formatTotalTimeToHour,
  getAvatarWithName,
  getFullName,
} from '~/utils/helper';
import { ColumnType, IPagination, IQueryBase, ITableParams } from '~/utils/interface/common';
import { ITimeClock } from '~/utils/interface/timeClock';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { timeClockActions } from '~/thunks/timeClock/timeClockSlice';
import { RootState } from '~/redux/store';
import useDebounce from '~/utils/hooks/useDebounce';
// Styles, images, icons
import styles from './TimeClock.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleViewDetail: (id: string) => void,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  handleDelete: (id: string) => void,
  handleEdit: () => void
): ColumnType<ITimeClock>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={handleSelectAll}
    //     />
    //   ),
    //   render(_, record: ITimeClock) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: 55,
    // },
    {
      key: 'resource',
      title: t('admin_employee_time_clock_table_title_resource_name'),
      dataIndex: 'assignee',
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName({
            firstName: record.assignee?.firstName,
            lastName: record.assignee?.lastName,
          }) ? (
            <>
              <CircleAvatar
                type={
                  record.assignee?.thumbnail || record.assignee?.avatar
                    ? CircleAvatarEnum.IMAGE
                    : CircleAvatarEnum.TEXT
                }
                imageUrl={record.assignee?.thumbnail || record.assignee?.avatar}
                firstName={record.assignee?.firstName}
                lastName={record.assignee?.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record.assignee?.firstName,
                lastName: record.assignee?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'startDate',
      title: t('admin_employee_time_clock_table_title_date'),
      dataIndex: 'startTime',
      render: (_, record) => (
        <div className={cx('textBase')}>
          {record?.startTime ? (
            <div className={cx('date')}>
              <img
                src={icons.commonIconCalender}
                alt={t('common_img_text_alt')}
                className={cx('icon')}
              />
              <span>{formattedTime(record.startTime)}</span>
            </div>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'job',
      title: t('admin_employee_time_clock_table_title_job'),
      dataIndex: 'job',
      render: (_, record) => <span>{record?.job?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'client',
      title: t('admin_employee_time_clock_table_title_client'),
      dataIndex: 'client',
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName({
            firstName: record.client?.firstName,
            lastName: record.client?.lastName,
          }) ? (
            <>
              <CircleAvatar
                type={
                  record.client?.thumbnail || record.client?.avatar
                    ? CircleAvatarEnum.IMAGE
                    : CircleAvatarEnum.TEXT
                }
                imageUrl={record.client?.thumbnail || record.client?.avatar}
                firstName={record.client?.firstName}
                lastName={record.client?.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record.client?.firstName,
                lastName: record.client?.lastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'startTime',
      title: t('admin_employee_time_clock_table_title_check_in'),
      dataIndex: 'startTime',
      render: (_, record) => (
        <span>
          {record?.startTime
            ? convertTime(record?.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
            : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'endTime',
      title: t('admin_employee_time_clock_table_title_check_out'),
      dataIndex: 'endTime',
      render: (_, record) => (
        <span>
          {record?.endTime
            ? convertTime(record?.endTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
            : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'hours',
      title: t('admin_employee_time_clock_table_title_total_time'),
      dataIndex: 'hours',
      render: (_, record) => (
        <span>
          {typeof record?.hours === 'number'
            ? formatTotalTimeToHour(Number(record.hours))
            : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_employee_table_action_title'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onView={() => handleViewDetail(record?.id)}
          // onEdit={() => handleEdit()}
          // onDelete={() => handleDelete(record.id)}
        />
      ),
    },
  ];
};

const EmployeeTimeClock = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshTimeClock } = useAppSelector((state: RootState) => state.timeClock);
  //#endregion Selector

  //#region Declare State
  const [timeClockList, setTimeClockList] = useState<ITimeClock[]>([]);
  const [isShowTimeClockDetails, setIsShowTimeClockDetails] = useState<boolean>(false);
  const [timeClockSelected, setTimeClockSelected] = useState<string>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [isShowAddTimeClock, setIsShowAddTimeClock] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);

  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListTimeClock(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshTimeClock) return;

    handleGetListTimeClock(paramObject);
    dispatch(timeClockActions.setRefreshTimeClockList(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshTimeClock]);

  useEffect(() => {
    if (timeClockList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = timeClockList.every((timeClock: ITimeClock) => timeClock.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = timeClockList
      .filter((timeClock: ITimeClock) => timeClock.isSelected)
      .map((timeClock: ITimeClock) => timeClock.id);

    //@Todo: Perform interactions with the time clock id list
  }, [timeClockList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListTimeClock = (payload: ITableParams) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListTimeClock(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { pagination, responses } = res?.data;

        setTimeClockList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handlePaginationChange = (page: number) => {
    if (!page) return;

    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCloseTimeClockDetails = () => {
    setIsShowTimeClockDetails(false);
  };

  const handleViewTimeClockDetails = (id: string) => {
    setTimeClockSelected(id);
    setIsShowTimeClockDetails(true);
  };

  const handleShowDeleteTimeClockModal = (id: string) => {
    setTimeClockSelected(id);
    setIsShowConfirmDelete(true);
  };

  const handleCancelDeleteTimeClock = () => {
    setIsShowConfirmDelete(false);
  };

  const handleDeleteTimeClock = () => {
    if (!timeClockSelected) return;

    loadingData?.show();

    dispatch(deleteTimeClock(timeClockSelected))
      .unwrap()
      .then((res) => {})
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleAddTimeClock = () => {
    setIsShowAddTimeClock(true);
  };

  const handleCloseAddTimeClock = () => {
    setIsShowAddTimeClock(false);
  };

  const handleAddTimeClockSuccess = () => {
    // TODO: Handle logic
  };

  const handleTableClick = (data: ITimeClock) => {
    // TODO: Handle logic
  };

  const handleSelectItem = (id: string) => {
    setTimeClockList((prevList: ITimeClock[]) =>
      prevList.map((timeClock: ITimeClock) =>
        timeClock.id === id ? { ...timeClock, isSelected: !timeClock.isSelected } : timeClock
      )
    );
  };

  const handleSelectAll = () => {
    setTimeClockList((prevList) =>
      prevList.map((timeClock: ITimeClock) => ({
        ...timeClock,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleEdit = () => {
    //TODO - HuyPahmGRF-ADB: Handle Edit
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleSearchTimeClock = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='employeeTimeClockPage' className={cx('employeeTimeClockContainer')}>
      <Toolbar
        title={t('admin_sidebar_label_employee_time_clock')}
        {...(currentRole !== AccountRoleCodesEnum.ADMIN && {
          primaryBtn: {
            label: t('admin_employee_time_clock_btn_add'),
            action: handleAddTimeClock,
          },
        })}
        onSearch={handleSearchTimeClock}
      />

      <section className={cx('documentContent')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            columns={columns(
              t,
              handleViewTimeClockDetails,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleShowDeleteTimeClockModal,
              handleEdit
            )}
            dataSource={timeClockList || []}
            typeStyle={BaseTableEnum.COLOR_TABLE}
            onClickRow={handleTableClick}
          />
        </div>

        <div className={cx('pagePagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </section>

      {isShowTimeClockDetails && timeClockSelected && (
        <AdminTimeClockDetailsModal
          timeClockId={timeClockSelected}
          onClose={handleCloseTimeClockDetails}
        />
      )}

      <AdminAddTimeClockModal
        isOpen={isShowAddTimeClock}
        onClose={handleCloseAddTimeClock}
        onSuccess={handleAddTimeClockSuccess}
      />

      {isShowConfirmDelete && timeClockSelected && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleCancelDeleteTimeClock}
          onAction={handleDeleteTimeClock}
        />
      )}

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default EmployeeTimeClock;
