// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getAccountList } from './accountantThunk';

export interface AccountantState {
  isRefreshAccountList: boolean;
}

const initialState: AccountantState = {
  isRefreshAccountList: false,
};

const accountantSlice = createSlice({
  name: 'accountant',
  initialState,
  reducers: {
    setRefreshAccountList(state, action) {
      state.isRefreshAccountList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get account list
    builder
      .addCase(getAccountList.pending, (state) => {})
      .addCase(getAccountList.fulfilled, (state, action) => {})
      .addCase(getAccountList.rejected, (state) => {});
  },
});

export const accountantActions = accountantSlice.actions;

const accountantReducer = accountantSlice.reducer;

export default accountantReducer;
