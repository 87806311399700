// Libs
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useWatch, useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import {
  BaseCheckbox,
  FieldAssignedProposal,
  FieldBudgetLaborProposal,
  FieldBudgetProductProposal,
  FieldUnassignedProposal,
  FormInput,
} from '~/components';
// Others
import { RootState } from '~/redux/store';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { IBudgetProposal, IProposal } from '~/utils/interface/proposal';
import { CurrencyEnum, ProposalContentEnum, TypeProductEnum } from '~/utils/enum';
import {
  DEFAULT_BUDGET_LABOR_BREAKDOWN,
  DEFAULT_ASSIGNED_PROPOSAL,
  DEFAULT_BUDGET_CATEGORY_PROPOSAL,
  DEFAULT_ENABLE_BUDGET_PROPOSAL,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_UNASSIGNED_PROPOSAL,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  UNDERSCORE_CHARACTER,
} from '~/utils/constants/common';
import { IQueryListProduct } from '~/utils/interface/product';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import { TLineItemBudgetDetail } from '~/utils/type/common';
import { formatCurrency } from '~/utils/helper';
import {
  getOptionFixtureLocations,
  getOptionProductByBudget,
} from '~/thunks/proposal/proposalThunk';
import { getListLabor } from '~/thunks/labor/LaborThunk';
// Styles, images, icons
import styles from './FormBreakDownContainer.module.scss';
import { icons } from '~/assets';

type Props = {
  lineItemIndex: number;
};

const cx = classNames.bind(styles);

const FormBreakDownContainer = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { control, watch, getValues, setValue } = useFormContext<IProposal>();

  // Calc Material
  const materialBudget = watch(`lineItems.${lineItemIndex}.materials`)?.reduce(
    (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );

  // Calc Labor
  const laborBudget = watch(`lineItems.${lineItemIndex}.labors`)?.reduce(
    (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );

  // Calc Subcontractor
  const assignedSubcontractors = watch(
    `lineItems.${lineItemIndex}.subcontractor.assignedSubcontractors`
  )?.reduce(
    (total, item) => total + (Number(item.value) ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );
  const unassignedWorks = watch(`lineItems.${lineItemIndex}.subcontractor.unassignedWorks`)?.reduce(
    (total, item) => total + (Number(item.value) ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );
  const subcontractors = Number(assignedSubcontractors) + Number(unassignedWorks);

  // Calc Equipment
  const equipment = watch(`lineItems.${lineItemIndex}.equipments`)?.reduce(
    (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );

  // Calc Miscellanea
  const miscellanea = watch(`lineItems.${lineItemIndex}.miscellanea`)?.reduce(
    (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
    DEFAULT_NUMBER_ZERO
  );

  // Materials useFieldArray
  const {
    fields: materialFields,
    append: appendMaterial,
    remove: removeMaterial,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.materials`,
  });

  // Labor useFieldArray
  const {
    fields: laborFields,
    append: appendLabor,
    remove: removeLabor,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.labors`,
  });

  // Unassigned useFieldArray
  const {
    fields: unassignedFields,
    append: appendUnassigned,
    remove: removeUnassigned,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.subcontractor.unassignedWorks`,
  });

  // Assigned useFieldArray
  const {
    fields: assignedFields,
    append: appendAssigned,
    remove: removeAssigned,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.subcontractor.assignedSubcontractors`,
  });

  // Equipment useFieldArray
  const {
    fields: equipmentFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.equipments`,
  });

  // Miscellanea useFieldArray
  const {
    fields: miscellaneaFields,
    append: appendMiscellanea,
    remove: removeMiscellanea,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.miscellanea`,
  });
  //#endregion Declare Hook

  //#region Selector
  const {
    productMaterials,
    productEquipment,
    fixtureLocations,
    productMiscellanea,
    laborProposalList,
  } = useAppSelector((state: RootState) => state.proposal);
  //#endregion Selector

  //#region Declare Form Watch
  const watchedLaborFields = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.labors`,
  });
  //#endregion Declare Form Watch

  //#region Declare State
  const [enableBudgetProposal, setEnableBudgetProposal] = useState<IBudgetProposal>(
    DEFAULT_ENABLE_BUDGET_PROPOSAL
  );
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const commonParams: IQueryListProduct = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    if (enableBudgetProposal.materials && !productMaterials) {
      const equipmentParams: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.MATERIAL_SUPPLIER,
      };
      fetchProductByBudget(equipmentParams);
    }

    if (enableBudgetProposal.equipments && !productEquipment) {
      const equipmentParams: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.EQUIPMENT_SUPPLIER,
      };
      fetchProductByBudget(equipmentParams);
    }

    if (enableBudgetProposal.miscellanea && !productMiscellanea) {
      const miscellaneaParams: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.MISCELLANEOUS_EXPENSES,
      };
      fetchProductByBudget(miscellaneaParams);
    }

    if (enableBudgetProposal.labors && !laborProposalList) {
      fetchListLabor(commonParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableBudgetProposal]);

  useEffect(() => {
    if (fixtureLocations && fixtureLocations.length >= DEFAULT_NUMBER_ZERO) return;

    if (
      enableBudgetProposal.materials ||
      enableBudgetProposal.equipments ||
      enableBudgetProposal.miscellanea
    ) {
      const params: IQueryListFixtureLocations = {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      };

      fetchFixtureLocation(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixtureLocations, enableBudgetProposal]);

  useEffect(() => {
    const lineItem = getValues().lineItems[lineItemIndex];
    const fields: TLineItemBudgetDetail[] = [
      'materials',
      'equipments',
      'labors',
      'miscellanea',
      'subcontractor',
    ];

    fields.forEach((field) => {
      const value = lineItem[field];
      const isObject = value && typeof value === 'object' && !Array.isArray(value);

      if (field === ProposalContentEnum.SUBCONTRACTORS && isObject) {
        const assignedWorks = value.assignedSubcontractors || [];
        const unassignedWorks = value.unassignedWorks || [];

        if (
          assignedWorks.length > DEFAULT_NUMBER_ZERO ||
          unassignedWorks.length > DEFAULT_NUMBER_ZERO
        ) {
          setEnableBudgetProposal((prevState) => ({ ...prevState, subcontractor: true }));
        }
      }

      if (Array.isArray(value) && value.length > DEFAULT_NUMBER_ZERO) {
        setEnableBudgetProposal((prevState) => ({ ...prevState, [field]: true }));
      }
    });
  }, []);

  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.totalMaterials`, materialBudget);
  }, [materialBudget]);

  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.totalSubcontractors`, subcontractors);
  }, [subcontractors]);

  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.totalLabors`, laborBudget);
  }, [laborBudget]);

  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.totalEquipments`, equipment);
  }, [equipment]);

  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.totalMiscellanea`, miscellanea);
  }, [miscellanea]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchProductByBudget = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getOptionProductByBudget(params));
  };

  const fetchFixtureLocation = (params: IQueryListFixtureLocations) => {
    if (!params) return;
    dispatch(getOptionFixtureLocations(params));
  };

  const fetchListLabor = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getListLabor(params));
  };

  const handleCheckboxChange = (checked: boolean, name: string) => {
    const convertName = name.split(UNDERSCORE_CHARACTER)[DEFAULT_NUMBER_ZERO];
    setEnableBudgetProposal((prevState) => ({ ...prevState, [convertName]: checked }));

    switch (convertName) {
      case ProposalContentEnum.MATERIALS:
        checked ? appendMaterial(DEFAULT_BUDGET_CATEGORY_PROPOSAL) : removeMaterial();
        break;

      case ProposalContentEnum.LABORS:
        checked ? appendLabor(DEFAULT_BUDGET_LABOR_BREAKDOWN) : removeLabor();
        break;

      case ProposalContentEnum.SUBCONTRACTORS:
        if (checked) {
          appendAssigned(DEFAULT_ASSIGNED_PROPOSAL);
          appendUnassigned(DEFAULT_UNASSIGNED_PROPOSAL);
        } else {
          removeAssigned();
          removeUnassigned();
        }
        break;

      case ProposalContentEnum.EQUIPMENTS:
        checked ? appendEquipment(DEFAULT_BUDGET_CATEGORY_PROPOSAL) : removeEquipment();
        break;

      case ProposalContentEnum.MISCELLANEA:
        checked ? appendMiscellanea(DEFAULT_BUDGET_CATEGORY_PROPOSAL) : removeMiscellanea();
        break;
    }
  };
  //#endregion Handle Function

  return (
    <div id='formPhaseContainerComponent' className={cx('container')} key={lineItemIndex}>
      <div className={cx('fiveColumn')}>
        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`${ProposalContentEnum.MATERIALS}${UNDERSCORE_CHARACTER}${lineItemIndex}`}
            value={enableBudgetProposal.materials}
            label={t('template_form_phase_content_field_category_material')}
            onChange={handleCheckboxChange}
          />
          <Controller
            name={`lineItems.${lineItemIndex}.totalMaterials`}
            render={({ field: { value } }) => (
              <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, value)} />
            )}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`${ProposalContentEnum.LABORS}${UNDERSCORE_CHARACTER}${lineItemIndex}`}
            value={enableBudgetProposal.labors}
            label={t('template_form_phase_content_field_category_labor')}
            onChange={handleCheckboxChange}
          />
          <Controller
            name={`lineItems.${lineItemIndex}.totalLabors`}
            render={({ field: { value } }) => (
              <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, value)} />
            )}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`${ProposalContentEnum.SUBCONTRACTORS}${UNDERSCORE_CHARACTER}${lineItemIndex}`}
            value={enableBudgetProposal.subcontractor}
            label={t('template_form_phase_content_field_category_subcontractor')}
            onChange={handleCheckboxChange}
          />
          <Controller
            name={`lineItems.${lineItemIndex}.totalSubcontractors`}
            render={({ field: { value } }) => (
              <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, value)} />
            )}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`${ProposalContentEnum.EQUIPMENTS}${UNDERSCORE_CHARACTER}${lineItemIndex}`}
            value={enableBudgetProposal.equipments}
            onChange={handleCheckboxChange}
            label={t('template_form_phase_content_field_category_equipment')}
          />
          <Controller
            name={`lineItems.${lineItemIndex}.totalEquipments`}
            render={({ field: { value } }) => (
              <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, value)} />
            )}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`${ProposalContentEnum.MISCELLANEA}${UNDERSCORE_CHARACTER}${lineItemIndex}`}
            value={enableBudgetProposal.miscellanea}
            onChange={handleCheckboxChange}
            label={t('template_form_phase_content_field_category_miscellanea')}
          />
          <Controller
            name={`lineItems.${lineItemIndex}.totalMiscellanea`}
            render={({ field: { value } }) => (
              <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, value)} />
            )}
          />
        </div>
      </div>

      {/* Materials */}
      {enableBudgetProposal.materials && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>{t('admin_manage_proposal_material_budget')}</div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {materialFields.map((row, index) => (
              <FieldBudgetProductProposal
                key={row.id}
                type='materials'
                lineItemIndex={lineItemIndex}
                fieldIndex={index}
                removeField={removeMaterial}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                type='button'
                onClick={() => appendMaterial(DEFAULT_BUDGET_CATEGORY_PROPOSAL)}
                className={cx('btnAddField', 'group')}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>

              <label htmlFor='exFile' className={cx('btnAddField', 'group')}>
                <img
                  src={icons.commonIconJobEstimateImportFile}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddFile')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_import_file')}
                </span>
                <input id='exFile' type='file' accept='.xlsx, .xls' style={{ display: 'none' }} />
              </label>
            </div>
          </div>
        </section>
      )}

      {/* Labor*/}
      {enableBudgetProposal.labors && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('template_form_phase_content_label_category_labor')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {laborFields.map((row, index) => (
              <FieldBudgetLaborProposal
                key={row.id}
                lineItemIndex={lineItemIndex}
                fieldIndex={index}
                removeField={removeLabor}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                type='button'
                onClick={() => appendLabor(DEFAULT_BUDGET_LABOR_BREAKDOWN)}
                className={cx('btnAddField', 'group')}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}

      {/* Subcontractors */}
      {enableBudgetProposal.subcontractor && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('admin_manage_proposal_subcontractors_budget')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListSubtitle')}>
            {t('admin_manage_proposal_subcontractors_unassigned_work')}
          </div>
          <div className={cx('formListGroupSubcontractor')}>
            {unassignedFields.map((row, index) => (
              <FieldUnassignedProposal
                key={row.id}
                lineItemIndex={lineItemIndex}
                fieldIndex={index}
                removeField={removeUnassigned}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendUnassigned(DEFAULT_UNASSIGNED_PROPOSAL)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>

          <div className={cx('formListSubtitle', 'assigned')}>
            {t('admin_manage_proposal_subcontractors_assigned')}
          </div>
          <div className={cx('formListGroupSubcontractor')}>
            {assignedFields.map((row, index) => (
              <FieldAssignedProposal
                key={row.id}
                lineItemIndex={lineItemIndex}
                fieldIndex={index}
                removeField={removeAssigned}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendAssigned(DEFAULT_ASSIGNED_PROPOSAL)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}

      {/* Equipment */}
      {enableBudgetProposal.equipments && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>{t('admin_manage_proposal_equipment_budget')}</div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {equipmentFields.map((row, index) => (
              <FieldBudgetProductProposal
                key={row.id}
                type='equipments'
                lineItemIndex={lineItemIndex}
                fieldIndex={index}
                removeField={removeEquipment}
              />
            ))}

            <button
              type='button'
              onClick={() => appendEquipment(DEFAULT_BUDGET_CATEGORY_PROPOSAL)}
              className={cx('btnAddField', 'group')}
            >
              <img
                src={icons.commonIconAddField}
                alt={t('common_img_text_alt')}
                className={cx('iconAddField')}
              />
              <span className={cx('btnTextAdd')}>
                {t('template_form_phase_content_btn_add_field')}
              </span>
            </button>
          </div>
        </section>
      )}

      {/* Miscellanea */}
      {enableBudgetProposal.miscellanea && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>{t('admin_manage_proposal_miscellanea_budget')}</div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {miscellaneaFields.length > DEFAULT_NUMBER_ZERO && (
              <>
                {miscellaneaFields.map((row, index) => (
                  <FieldBudgetProductProposal
                    key={row.id}
                    type='miscellanea'
                    lineItemIndex={lineItemIndex}
                    fieldIndex={index}
                    removeField={removeMiscellanea}
                  />
                ))}
              </>
            )}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendMiscellanea(DEFAULT_BUDGET_CATEGORY_PROPOSAL)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default FormBreakDownContainer;
