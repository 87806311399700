// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { MAX_PERCENT_VALUE, MIN_PERCENT_VALUE, PERCENT } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ProgressBar.module.scss';

type BaseProgressBarProps = {
  percentage?: number;
  value?: number | string;
  width?: number | string;
  height?: number | string;
  loss?: boolean;
  sales?: boolean;
};

const cx = classNames.bind(styles);

const ProgressBar = (props: BaseProgressBarProps) => {
  //#region Destructuring Props
  const { percentage, value, width, height, loss, sales } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [progress, setProgress] = useState<number>(0);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (percentage && percentage > MAX_PERCENT_VALUE) {
      setProgress(MAX_PERCENT_VALUE);
      return;
    }

    if (percentage && percentage < MIN_PERCENT_VALUE) {
      setProgress(MIN_PERCENT_VALUE);
      return;
    }

    percentage && setProgress(percentage);
  }, [percentage]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='baseProgressBarComponent' className={cx('container')} style={{ width }}>
      <div className={cx('progressGroup')}>
        <div className={cx('group', loss && 'loss', sales && 'sales')}>
          <div className={cx('progress')}>
            <div className={cx('progressBg')} style={{ height }}></div>
            <div
              className={cx('progressBar', percentage && percentage >= MAX_PERCENT_VALUE && 'max')}
              style={{ width: `${progress}%` }}
            ></div>
            <span className={cx('progressPercent')}>{`${percentage}${PERCENT}`}</span>
          </div>

          <span className={cx('value')}>{value && value}</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
