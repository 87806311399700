// Libs
import imageCompression from 'browser-image-compression';
import moment from 'moment';
import { t } from 'i18next';
import { toast } from 'react-toastify';
// Others
import type { AppDispatch } from '~/redux/store';
import {
  DEFAULT_BYTE_VALUE,
  DEFAULT_MIN_MINUS,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_TO_FIXED,
  EMPTY_STRING,
  EMPTY_VALUE,
  FILE_SIZE_UNITS,
  MINUTES_IN_HOUR,
  MONTHS_IN_YEAR,
  RANDOM_RANGE,
  REGEX,
  REGEX_FIND_FIRST_CHARACTER,
  REGEX_FIND_WHITESPACE_OR_UNDERSCORES,
  TIME_CLOSE_TOAST,
  monthsThreeCharacter,
} from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_TIMELINE_VIEWS, SCHEDULE_MODE_FULL } from '~/utils/constants/component';
import {
  CurrencyEnum,
  ProposalEnum,
  InventoryStatusEnum,
  MaterialUsedRemarkEnum,
  TimeFormatEnum,
  VendorTypeEnum,
  VendorLabelTypeEnum,
  AccountRoleCodesEnum,
  StorageEnum,
  ToastTypeEnum,
  DateFormatEnum,
  ContactJobTypeEnum,
  ContactJobGarageHandEnum,
  LocaleEnum,
} from '~/utils/enum';
import { IFormatAddress, IFullAddress, IFullName, IRouteModel } from '~/utils/interface/common';
import { IAssignee } from '~/utils/interface/job';
import { IToastDispatch } from './interface/toast';
import { toastActions } from '~/thunks/toast/toastSlice';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Checks if a route has an active child route based on the provided location.pathname.
 * @param route The route object to check.
 * @param locationPathname The pathname of the current location.
 * @returns True if the route has an active child route, false otherwise.
 */
export const hasActiveChild = (route: IRouteModel, locationPathname: string): boolean => {
  return (
    route.children?.some(
      (child) =>
        child.path === locationPathname ||
        isNestedRoute(child.path, locationPathname) ||
        hasActiveChild(child, locationPathname)
    ) || route.path === locationPathname
  );
};

/**
 * Determines if the current path is a nested route of the parent path.
 * @param parentPath The parent route path to check against.
 * @param currentPath The current route path to be evaluated.
 * @returns True if the current path is a nested route of the parent path and not exactly the same as the parent path, false otherwise.
 */
export const isNestedRoute = (parentPath: string, currentPath: string): boolean => {
  return currentPath.startsWith(parentPath) && currentPath !== parentPath;
};

export const convertToTitleCase = (str: string): string => {
  const newStr = str
    .toLowerCase()
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return newStr;
};

export const convertCodeToRole = (role: string) => {
  if (!role) return EMPTY_STRING;

  switch (role) {
    case AccountRoleCodesEnum.ADMIN:
      return 'common_admin_title';
    case AccountRoleCodesEnum.STAFF:
      return 'common_staff_title';
    case AccountRoleCodesEnum.TECHNICIAN:
      return 'common_technician_title';
    default:
      return EMPTY_STRING;
  }
};

export const getInitialsName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) return EMPTY_STRING;

  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  if (firstName && !lastName) {
    return `${firstInitial}`;
  }

  if (lastName && !firstName) {
    return `${lastInitial}`;
  }

  return `${firstInitial}${lastInitial}`;
};

/**
 * Displays the combined first and last name.
 *
 * @param firstName The first name.
 * @param lastName The last name.
 * @returns The combined name or a default message if no names are provided.
 */
export const getFullName = ({ firstName, lastName }: IFullName): string => {
  if (!firstName && !lastName) {
    return EMPTY_STRING;
  }

  if (firstName && !lastName) {
    return firstName;
  }

  if (!firstName && lastName) {
    return lastName;
  }

  return `${firstName} ${lastName}`;
};

export const getAvatarWithName = (
  { firstName, lastName }: IFullName,
  avatarUrl?: string
): boolean => {
  if (!avatarUrl && (firstName || lastName)) {
    return true;
  }

  if (avatarUrl && !firstName && !lastName) {
    return false;
  }

  if (!avatarUrl && !firstName && !lastName) {
    return false;
  }

  return true;
};

export const formattedTime = (inputTime?: string) => {
  if (!inputTime) return EMPTY_VALUE;

  const dateObj = new Date(inputTime);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = String(dateObj.getFullYear());

  const month = monthsThreeCharacter[monthIndex];

  return `${day} ${month} ${year}`;
};

/**
 * Get the image from assignees.
 *
 * @param assignees Assignees array includes assignee objects
 * @returns String array of image paths.
 */
export const handleArrayAvatar = (assignees: IAssignee[]): string[] => {
  const arrayAvatar = assignees.reduce((accAssignee, currentAssignee) => {
    currentAssignee.thumbnailUrl
      ? accAssignee.push(currentAssignee.thumbnailUrl)
      : accAssignee.push(currentAssignee.avatarUrl);
    return accAssignee;
  }, [] as string[]);

  return arrayAvatar;
};

/**
 * format numbers into currency.
 *
 * @param currency Currency unit that in CurrencyEnum
 * @param value The number value needs to be formatted
 * @returns The string value has been formatted as currency
 */
export const formatCurrency = (currency: CurrencyEnum, value: number) => {
  if (!value) return 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(value);
};

/**
 * Time format and conversion function.
 * @param time Time string or Date object.
 * @param format Time format (optional). Default is HOUR_MINUTE_AND_AM_PM.
 * @returns Time formatted as string.
 */
export const convertTime = (time: string | Date, format?: TimeFormatEnum): string => {
  const formatType = format ? format : TimeFormatEnum.HOUR_MINUTE_AM_PM;
  const formattedTime = moment(time).format(formatType);
  return formattedTime;
};

export const getColorByStatus = (status: string) => {
  switch (status) {
    case ProposalEnum.ACCEPTED:
      return 'green600';

    case ProposalEnum.IN_PROGRESS:
      return 'sky500';

    case ProposalEnum.ON_HOLD:
      return 'orange400';

    case ProposalEnum.DECLINED:
      return 'red600';

    case InventoryStatusEnum.ACTIVE:
      return 'green600';

    case InventoryStatusEnum.OUT_OF_STOCK:
      return 'orange400';

    case MaterialUsedRemarkEnum.IN_PROGRESS:
      return 'sky500';

    case MaterialUsedRemarkEnum.ON_HOLD:
      return 'orange400';

    case MaterialUsedRemarkEnum.CLOSED:
      return 'green600';
  }
};

/**
 * Get location values to return full address.
 * @param location is a object constants: address, city, state, postalCoded, country.
 * @returns full address.
 */
export const getFullAddress = ({ address, city, state, postalCode, country }: IFullAddress) => {
  const parts = [address, city, state, postalCode, country].filter(Boolean);

  if (parts.length === 0) return EMPTY_STRING;

  const formattedAddress = `${address || EMPTY_STRING}${city ? ` ${city}, ` : EMPTY_STRING} ${
    state || EMPTY_STRING
  }${postalCode ? ` ${postalCode}, ` : EMPTY_STRING}${country ? ` ${country}` : EMPTY_STRING}`;

  return formattedAddress.trim();
};

/**
 * Convert minutes to hours and minutes in hh:mm format.
 * @param totalMinutes is the number of minutes.
 * @returns hours and minutes in hh:mm format.
 */
export const convertMinsToHoursAndMins = (totalMinutes: number | string): string => {
  const duration = moment.duration(Number(totalMinutes), 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();
  return `${hours}:${
    minutes < DEFAULT_MIN_MINUS ? DEFAULT_NUMBER_ZERO.toString() : EMPTY_STRING
  }${minutes}`;
};

export const calculateDurationTime = (
  firstTime: string | undefined,
  lastTime: string | undefined
): string => {
  if (!firstTime || !lastTime) return EMPTY_STRING;
  const [firstTimeHours, firstTimeMinutes] = firstTime.split(':').map(Number);
  const [lastTimeHours, lastTimeMinutes] = lastTime.split(':').map(Number);
  const firstTimeTotalMinutes = firstTimeHours * 60 + firstTimeMinutes;
  const lastTimeTotalMinutes = lastTimeHours * 60 + lastTimeMinutes;
  const diffMinutes = lastTimeTotalMinutes - firstTimeTotalMinutes;
  const hours = Math.floor(diffMinutes / 60);
  const minutes = (diffMinutes % 60).toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

/**
 * Convert type vendors.
 * @param type is the string of type vendors.
 * @returns string type.
 */
export const formatVendorType = (type: string): string => {
  switch (type) {
    case VendorTypeEnum.MATERIAL_SUPPLIER: {
      return t(VendorLabelTypeEnum.MATERIAL_SUPPLIER);
    }
    case VendorTypeEnum.SUBCONTRACTOR: {
      return t(VendorLabelTypeEnum.SUBCONTRACTOR);
    }
    case VendorTypeEnum.EQUIPMENT_SUPPLIER: {
      return t(VendorLabelTypeEnum.EQUIPMENT_SUPPLIER);
    }
    case VendorTypeEnum.MISCELLANEOUS_EXPENSES: {
      return t(VendorLabelTypeEnum.MISCELLANEOUS_EXPENSES);
    }
    default:
      return EMPTY_STRING;
  }
};

/**
 * Convert type file image.
 * @param file is the File of type image.
 * @returns file image.
 */
export const compressImage = async (file: File) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1024,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.log('Error while compressing the image:', error);
    return;
  }
};

/**
 *
 * @param email is your email in string form.
 * @returns boolean value to check if email is in correct format.
 */
export const validateEmailFormat = (email: string) => {
  return REGEX.EMAIL.test(email);
};

/**
 * The function validates password to be greater than or equal to 8 characters.
 * @param password is the password to be checked.
 * @returns true if the password is greater than or equal to 8 characters and false otherwise.
 */
export const validatePassword = (password: string) => {
  return REGEX.PASSWORD.test(password);
};

/**
 * Generates a unique identifier string.
 * @param randomRange The range within which the random number is generated. Default is 1000.
 * @returns A unique identifier string composed of the current timestamp and a random number.
 */
export const generateUniqueId = (randomRange: number = RANDOM_RANGE): string => {
  return `${Date.now()}-${Math.floor(Math.random() * randomRange)}`;
};

/**
 * Checks if the current calendar view is 'week' or 'day' and the schedule mode is 'full'.
 * @param calendarView The current calendar view ('week', 'day', 'month').
 * @param scheduleMode The current schedule mode ('full', 'my').
 * @returns True if the conditions are met, false otherwise.
 */
export const checkTimelineMode = (calendarView: string, scheduleMode: string): boolean => {
  return (
    (calendarView === DEFAULT_SCHEDULE_TIMELINE_VIEWS.week ||
      calendarView === DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) &&
    scheduleMode === SCHEDULE_MODE_FULL
  );
};

export const showToast = (dispatch: AppDispatch, toast: IToastDispatch) => {
  dispatch(toastActions.addToast(toast));
  setTimeout(() => {
    dispatch(toastActions.removeToast());
  }, TIME_CLOSE_TOAST);
};

/**
 * The function format job success rate
 * @param rate is the string vale need format
 * @returns string value rate formatted
 */
export const formatRate = (rate?: string): string => {
  if (!rate) return EMPTY_STRING;

  const parsedRate = parseFloat(rate);
  if (Number.isInteger(parsedRate)) {
    return `${parsedRate}%`;
  } else {
    return `${rate}%`;
  }
};

/**
 * Validate value with regex.
 * @param value is the validate value.
 * @param regex is the regex used to check.
 * @returns true if regex is satisfied and vice versa.
 */
export const validateWithRegex = (value: string, regex: RegExp): boolean => {
  return regex.test(value);
};

/**
 * calculate total time based on inTime and outTime
 * @param inTime string data type start time value
 * @param outTime string data type end time value
 * @returns The total time value as a string
 */
export const calculateTotalTime = (inTime?: string, outTime?: string): string => {
  if (!inTime || !outTime) return EMPTY_STRING;

  const startTime = moment(inTime, 'h:mm A');
  const endTime = moment(outTime, 'h:mm A');

  const differenceInMinutes = endTime.diff(startTime, 'minutes');

  const hours = Math.floor(differenceInMinutes / 60);
  const minutes = differenceInMinutes % 60;

  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * Format total time in minutes to a string in the format "hh:mm"
 * @param totalTime number data type representing total time in minutes
 * @returns The formatted time value as a string "hh:mm"
 */
export const formatTotalTimeToHour = (totalTime: number) => {
  if (isNaN(totalTime)) return EMPTY_STRING;

  const hours = Math.floor(totalTime / MINUTES_IN_HOUR)
    .toString()
    .padStart(2, '0');
  const minutes = (totalTime % MINUTES_IN_HOUR).toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

/**
 * Check if the current role is Admin
 * @returns boolean value indicating whether the current role is Admin.
 */
export const isAdminRole = () => {
  return localStorage.getItem(StorageEnum.ROLE) === AccountRoleCodesEnum.ADMIN;
};

/**
 * Handle show toast message.
 * @param type is option of reducer.
 * @param message is option of reducer.
 * @returns modal toast by status
 */
export const customToast = (type: ToastTypeEnum, message: string) => {
  switch (type) {
    case ToastTypeEnum.ERROR:
      toast.error(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
    case ToastTypeEnum.SUCCESS:
      toast.success(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
    case ToastTypeEnum.WARNING:
      toast.warning(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
    default:
      toast(message, {
        autoClose: TIME_CLOSE_TOAST,
      });
      break;
  }
};

/**
 * Date format.
 * @param date is the time series that needs formatting.
 * @param format the date type you want to format.
 * @returns string after formatting.
 */
export const formatDate = (date: string, format: DateFormatEnum): string => {
  return moment(date).format(format);
};

/**
 * Converts a string to camelCase format.
 * @param input The string that needs to be converted to camelCase.
 * @returns The input string transformed into camelCase format, with the first letter in lowercase and special characters removed.
 */
export const toCamelCase = (input: string) => {
  const camelCaseFistCharacters = input.replace(REGEX_FIND_FIRST_CHARACTER, (match, index) =>
    index === 0 ? match.toLowerCase() : match.toUpperCase()
  );
  const removeSpecialCharacter = camelCaseFistCharacters.replace(
    REGEX_FIND_WHITESPACE_OR_UNDERSCORES,
    ''
  );
  return removeSpecialCharacter;
};

/**
 * Converts project type of contact job
 * @param type The string that needs to be converted of contact job type.
 * @returns The string has been converted.
 */
export const convertProjectTypeOfJob = (type: string) => {
  if (!type) return EMPTY_STRING;

  switch (type) {
    case ContactJobTypeEnum.FIXED_PRICE:
      return 'admin_manage_jobs_fixed_price_label';
    case ContactJobTypeEnum.FIXED_PRICE_WITH_AIA_BILLING:
      return 'admin_manage_jobs_fixed_price_with_aia_billing_label';
    default:
      return EMPTY_STRING;
  }
};

/**
 * Create a monthly array of months in a certain year in the format of "month".
 * @param year Optional year to generate the months for. Defaults to the current year
 * @returns An array of strings in the format "Jan 2024", "Feb 2024", etc.
 */
export const getMonthsWithYear = (year = new Date().getFullYear()) => {
  const months = [];
  for (let i = DEFAULT_NUMBER_ZERO; i < MONTHS_IN_YEAR; i++) {
    const date = new Date(year, i);
    const monthYear = date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
    months.push(monthYear);
  }
  return months;
};

/**
 * Converts project garage hand of contact job
 * @param type The string that needs to be converted of contact garage hand.
 * @returns The string has been converted.
 */
export const convertGarageHandOfJob = (type: string) => {
  if (!type) return EMPTY_STRING;

  switch (type) {
    case ContactJobGarageHandEnum.RIGHT:
      return 'admin_add_contract_job_garage_hand_right';
    case ContactJobGarageHandEnum.LEFT:
      return 'admin_add_contract_job_garage_hand_left';
    default:
      return EMPTY_STRING;
  }
};

/**
 * Checks if the provided value is an array with more than 0 elements.
 * @param input The array that needs to be checked.
 * @returns A boolean indicating whether the input is an array and contains more than 0 elements.
 */
export const isNonEmptyArray = (input: unknown): boolean => {
  return Array.isArray(input) && input.length > 0;
};

/**
 * Format numeric values ​​according to selected locale.
 * @param num is the numeric value to be formatted.
 * @param locale format locale.
 * @returns values ​​are formatted according to locale
 */
export const formatNumber = (
  num: number | string = DEFAULT_NUMBER_ZERO,
  locale: string = LocaleEnum.EN_US
): string => {
  return Number(num).toLocaleString(locale);
};

/**
 * Perform string cutting to get file name
 * @param url File path as string
 * @returns file name
 */
export const getFileNameFromUrl = (url: string): string | undefined => {
  const fullFileName = url.split('/').pop() || EMPTY_STRING;
  const fileName = fullFileName.split('_').pop();
  return fileName;
};

/**
 * Compares two objects and returns a partial object containing the updated values.
 * @param originalData - The original data object.
 * @param editedData - The edited data object.
 * @returns An object containing keys from editedData with values that differ from originalData.
 */
export const compareDataUpdate = (
  originalData: { [key: string]: any },
  editedData: { [key: string]: any }
): { [key: string]: any } => {
  const result: { [key: string]: any } = {};

  Object.entries(editedData).forEach(([key, value]) => {
    const originalValue = originalData[key];

    if (value instanceof File || originalValue instanceof File) {
      const originalFile = originalValue as File | undefined;
      const editedFile = value as File | undefined;

      if (originalFile?.name !== editedFile?.name || originalFile?.size !== editedFile?.size) {
        result[key] = editedFile;
      }
    } else if (JSON.stringify(originalValue) !== JSON.stringify(value)) {
      result[key] = value;
    }
  });

  return result;
};

export const generateGuid = (): string => {
  const generateSegment = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };

  const guid =
    generateSegment() +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    '-' +
    generateSegment() +
    generateSegment() +
    generateSegment();

  return guid;
};

/**
 * Converts Object to new object with all string value
 * @param obj a Object is passed
 * @returns value of key in Object is string
 */
export const convertValueToString = (obj: object) => {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, String(value)]));
};

/**
 * Convert uri to name file.
 * @param uri is uri of file.
 * @returns name file.
 */
export const getFileNameFromUri = (uri: string): string => {
  return uri.split('/').pop()?.replace(/^\d+_/, '') || EMPTY_STRING;
};

/**
 * Converts the keys of each object in an array to camelCase format.
 *
 * This function takes an array of objects and converts all the keys
 * in each object to camelCase. If the input is not an array, the function
 * returns the original input. If an item in the array is not an object,
 * it skips conversion for that item.
 *
 * @param data - An array of objects with keys to be converted to camelCase.
 * @returns A new array of objects with camelCase keys, or the original data if not an array.
 */
export const convertKeyToCamelCase = (data: unknown[]) => {
  if (!Array.isArray(data)) return data;

  return data.map((item) => {
    if (typeof item !== 'object' || item === null) {
      // eslint-disable-next-line array-callback-return
      return;
    }

    const newObj: Record<string, any> = {};
    Object.keys(item).forEach((key) => {
      const camelKey = toCamelCase(key);
      newObj[camelKey] = (item as Record<string, any>)[key];
    });

    return newObj;
  });
};

/**
 * Convert file size with specific unit.
 * @param size is the file size in bytes.
 * @returns file size with specific units.
 */
export const formatFileSize = (size: number): string => {
  let unitIndex = 0;
  let formattedSize = size;

  while (formattedSize > DEFAULT_BYTE_VALUE && unitIndex < FILE_SIZE_UNITS.length - 1) {
    formattedSize /= DEFAULT_BYTE_VALUE;
    unitIndex++;
  }
  return `${formattedSize.toFixed(DEFAULT_TO_FIXED)} ${FILE_SIZE_UNITS[unitIndex]}`;
};

/**
 * Format address based on provided details.
 * @param addressInfo is an object containing address, city, state zipCode and country.
 * @returns formatted address in the form "<address>, <city>, <state> <zipcode>, <country>".
 */
export const formatAddress = ({
  address,
  city,
  state,
  zipCode,
  country,
}: IFormatAddress): string => {
  const parts = [address, city, `${state || EMPTY_STRING} ${zipCode || EMPTY_STRING}`, country]
    .map((part) => part?.trim())
    .filter(Boolean);
  if (parts.length === DEFAULT_NUMBER_ZERO) return EMPTY_STRING;

  return parts.join(', ');
};

/**
 * Gets the user's current time zone identifier.
 * @returns {string} The time zone identifier in the IANA Time Zone Database format.
 */
export const getTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
