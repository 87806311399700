// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { parseDocument } from 'htmlparser2';
// Components, Layouts, Pages
import { Divider } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { renderNodes } from '~/components/specific/proposalInvoicePdf/components/utils';
// Styles, images, icons

type Props = {
  description?: string;
};

const InvoiceDescription = (props: Props) => {
  //#region Destructuring Props
  const { description } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderHtmlToPdf = (htmlString: string) => {
    if (!htmlString) return;
    const doms = parseDocument(htmlString);

    return renderNodes(doms.children);
  };

  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{`Description`}</Text>
      <Divider />

      <View style={styles.mainContent}>{description && <>{renderHtmlToPdf(description)}</>}</View>
    </View>
  );
};

export default InvoiceDescription;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  title: {
    fontWeight: 700,
  },
  mainContent: {
    width: '100%',
    fontSize: 10,
    fontFamily: 'Inter',
  },
});
