// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { convertToTitleCase } from '~/utils/helper';
import { ColorType } from '~/utils/type/common';
// Styles, images, icons
import styles from './ButtonStatus.module.scss';

type Props = {
  label?: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  color?: ColorType;
  borderRadius?: number;
};

const cx = classNames.bind(styles);

const ButtonStatus = (props: Props) => {
  //#region Destructuring Props
  const { width = '100%', height, label = 'BUTTON', onClick, color, borderRadius } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onClick && onClick();
  };
  //#endregion Handle Function
  return (
    <button
      id='buttonStatus'
      className={cx('originStyle', color)}
      style={{ width, height, borderRadius }}
      onClick={handleClick}
    >
      {convertToTitleCase(label)}
    </button>
  );
};

export default ButtonStatus;
