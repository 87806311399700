// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AddTermsAndConditionsModal,
  AdminFormInclusionAndExclusion,
  ModalUnderDevelopment,
  NavTab,
  Toolbar,
} from '~/components';
import { InclusionAndExclusionItemsTabTemplate, MainContentTabTemplate } from '~/pages';
// Others
import {
  AccountRoleCodesEnum,
  ManageTermsAndConditionsTabEnum,
  SearchParamsEnum,
  StorageEnum,
} from '~/utils/enum';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { manageTermsAndConditionsTabs } from '~/utils/constants/tab';
// Styles, images, icons
import styles from './TermsAndConditions.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const TermsAndConditions = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const currentTab = searchParams.get(SearchParamsEnum.TAB);
  const [isShowDevelopment, setIsShowDevelopment] = useState<boolean>(false);
  const [isShowFormAdd, setIsShowFormAdd] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!location.search) {
      searchParams.set(SearchParamsEnum.TAB, ManageTermsAndConditionsTabEnum.MAIN_CONTENT);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  }, [location.search]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleAdd = () => {
    if (!currentTab) return;

    switch (currentTab) {
      case ManageTermsAndConditionsTabEnum.MAIN_CONTENT:
        const role = localStorage.getItem(StorageEnum.ROLE) as AccountRoleCodesEnum;

        const routes = {
          [AccountRoleCodesEnum.ADMIN]: adminRouteAbsolute.createMainContent,
          [AccountRoleCodesEnum.STAFF]: staffRouteAbsolute.createMainContent,
        };

        if (role && routes[role as keyof typeof routes]) {
          navigate(routes[role as keyof typeof routes]);
        }
        break;

      case ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS:
        setIsShowFormAdd(!isShowFormAdd);
        break;
    }
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowDevelopment(!isShowDevelopment);
  };

  const renderTabContent = () => {
    if (!currentTab) return;

    switch (currentTab) {
      case ManageTermsAndConditionsTabEnum.MAIN_CONTENT:
        return <MainContentTabTemplate />;

      case ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS:
        return <InclusionAndExclusionItemsTabTemplate />;

      default:
        return <div className=''>{t('common_empty_data')}</div>;
    }
  };

  const handleShowModalFormAdd = () => {
    setIsShowFormAdd(!isShowFormAdd);
  };
  //#endregion Handle Function

  return (
    <div id='termsAndConditionsPage' className={cx('container')}>
      <Toolbar
        title={t('terms_and_conditions_title')}
        primaryBtn={{ action: handleAdd, label: t('terms_and_conditions_btn_add') }}
      />

      <div className={cx('body')}>
        <NavTab items={manageTermsAndConditionsTabs} searchParams={SearchParamsEnum.TAB} />

        <div className={cx('tabContent')}>{renderTabContent()}</div>
      </div>

      {isShowFormAdd && currentTab === ManageTermsAndConditionsTabEnum.MAIN_CONTENT && (
        <AddTermsAndConditionsModal onClose={handleShowModalFormAdd} />
      )}

      <AdminFormInclusionAndExclusion
        onClose={handleShowModalFormAdd}
        isOpen={
          isShowFormAdd &&
          currentTab === ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS
        }
      />

      {isShowDevelopment && <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />}
    </div>
  );
};

export default TermsAndConditions;
