// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createTechnician, getListTechnician } from './technicianThunk';
import { ITechnicianResp } from '~/utils/interface/technician';

export interface TechnicianState {
  isRefreshTableTechnician?: boolean;
  technicianResp: ITechnicianResp;
}

const initialState: TechnicianState = {
  technicianResp: {} as ITechnicianResp,
};

const technicianSlice = createSlice({
  name: 'technicianState',
  initialState,
  reducers: {
    setRefreshTableTechnician(state, action) {
      state.isRefreshTableTechnician = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list technician
    builder
      .addCase(getListTechnician.pending, (state) => {})
      .addCase(getListTechnician.fulfilled, (state, action) => {})
      .addCase(getListTechnician.rejected, (state) => {});

    builder
      .addCase(createTechnician.pending, (state) => {})
      .addCase(createTechnician.fulfilled, (state) => {})
      .addCase(createTechnician.rejected, (state) => {});
  },
});

export const technicianActions = technicianSlice.actions;

// Get list technician
export const selectTechnicianResp = (state: RootState) => state.technician.technicianResp;
export const setRefreshTableTechnician = (state: RootState) =>
  state.technician.isRefreshTableTechnician;

const technicianReducer = technicianSlice.reducer;
export default technicianReducer;
