import commonOffRangeBackground from '~/assets/images/common_off_range_background.svg';

const images = {
  adminBackgroundImageLogin: require('./admin_background_image_login.png'),
  adminHeaderImageAvatar: require('./admin_header_image_avatar.png'),
  commonImageLogoTextBlack: require('./common_image_logo_text_black.png'),
  commonImageLogoTextWhite: require('./common_image_logo_text_white.png'),
  commonImageLogoPlumbing: require('./common_image_logo_plumbing.png'),
  commonOffRangeBackground,
};

export default images;
