// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
import {
  AdminAddAssigneeModal,
  BaseButton,
  BaseDatePicker,
  BaseSelect,
  BaseTextarea,
  BaseTimePicker,
  Loading,
} from '~/components';
// Others
import { getDateTimeInUTC } from '~/components/specific/scheduleModules/helper';
import { getListJob } from '~/thunks/job/jobThunk';
import { createSchedule } from '~/thunks/schedule/scheduleThunk';
import { getAccountList } from '~/thunks/accountant/accountantThunk';
import { IAddAssignee, IBaseOption } from '~/utils/interface/common';
import { IGetListJobReq, IJob } from '~/utils/interface/job';
import { IScheduleBody } from '~/utils/interface/schedule';
import { ITechnicianResp } from '~/utils/interface/technician';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_LIST,
  EMPTY_STRING,
  LENGTH_LIST_SELECTION,
} from '~/utils/constants/common';
import { AccountTypeEnum, ButtonTypeEnum, StatusEnum } from '~/utils/enum';
import { IQueryAccount } from '~/utils/interface/account';
// Styles, images, icons
import styles from './AdminAddScheduleModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const AdminAddScheduleModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const initialFormState: IScheduleBody = {
    jobId: EMPTY_STRING,
    startDate: EMPTY_STRING,
    endDate: EMPTY_STRING,
    startTime: EMPTY_STRING,
    endTime: EMPTY_STRING,
    assigneeIds: EMPTY_LIST,
  };

  const [scheduleFormData, setScheduleFormData] = useState<IScheduleBody>(initialFormState);
  const [technicianResp, setTechnicianResp] = useState<ITechnicianResp>();
  const [dataJobList, setDataJobList] = useState<IBaseOption[]>([]);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListJob({ page: DEFAULT_CURRENT_PAGE, limit: LENGTH_LIST_SELECTION });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisableSave(!validateScheduleForm(scheduleFormData));
  }, [scheduleFormData]);

  useEffect(() => {
    const payload: IQueryAccount = {
      page: DEFAULT_CURRENT_PAGE,
      limit: LENGTH_LIST_SELECTION,
      type: AccountTypeEnum.TECHNICIAN,
      status: StatusEnum.ACTIVE,
    };

    handleGetListAssignee(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListAssignee = (payload: IQueryAccount) => {
    if (!payload) return;

    dispatch(getAccountList(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        setTechnicianResp(res.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListJob = (payload: IGetListJobReq) => {
    setIsLoading(true);

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const newListJob: IBaseOption[] = res?.data.responses?.map((prevJob: IJob) => ({
          value: prevJob.id,
          label: prevJob.jobName ?? EMPTY_STRING,
        }));

        setDataJobList(newListJob);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSelectChange = (option: IBaseOption, name?: string) => {
    if (!name) return;
    const { value } = option;

    setScheduleFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    setScheduleFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddAssignee = (assignee: IAddAssignee) => {
    if (!assignee) return;

    setScheduleFormData((prevState) => ({
      ...prevState,
      assigneeIds: [...prevState.assigneeIds, assignee.id],
    }));
  };

  const handleCloseAssignee = (assignee: IAddAssignee) => {
    if (!assignee) return;

    setScheduleFormData((prevState) => ({
      ...prevState,
      assigneeIds: prevState.assigneeIds.filter((idAssignee) => idAssignee !== assignee.id),
    }));
  };

  const handleSelectDate = (date: string, name?: string) => {
    if (!name) return;
    setScheduleFormData({ ...scheduleFormData, [name]: date });
  };

  const handleSelectTime = (date: Date, name?: string) => {
    if (!name) return;
    setScheduleFormData({ ...scheduleFormData, [name]: date });
  };

  const validateScheduleForm = (form: IScheduleBody): boolean => {
    const keys = Object.keys(form) as Array<keyof IScheduleBody>;

    return keys.every((key) => {
      if (key === 'note') return true;

      if (typeof form[key] === 'string') {
        return form[key] !== EMPTY_STRING;
      }

      if (key === 'assigneeIds') {
        return form[key].length > DEFAULT_NUMBER_ZERO;
      }

      return true;
    });
  };

  const handleAddSchedule = () => {
    setIsLoading(true);

    const updatedFormData: IScheduleBody = {
      ...scheduleFormData,
      ...(scheduleFormData.note ? { note: scheduleFormData?.note } : {}),
      startTime: getDateTimeInUTC(scheduleFormData.startDate, scheduleFormData.startTime),
      endTime: getDateTimeInUTC(scheduleFormData.endDate, scheduleFormData.endTime),
    };

    dispatch(createSchedule(updatedFormData))
      .unwrap()
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminAddScheduleModal' className={cx('modalContainer')}>
      <>
        {isOpen && (
          <div className={cx('modalContent')}>
            <div className={cx('modalHead')}>
              {/* <img
                src={icons.commonIconCloseModal}
                className={cx('closeIcon')}
                alt={t('common_img_text_alt')}
                onClick={onClose}
              /> */}
              <div className={cx('modalTitle')}>{t('admin_manage_schedule_title_modal')}</div>
            </div>

            <div className={cx('modalBody')}>
              <div className={cx('jobDetailSection')}>
                <BaseSelect
                  label={t('admin_manage_schedule_select_job_label')}
                  options={dataJobList}
                  placeholder={t('common_placeholder_select')}
                  name='jobId'
                  value={scheduleFormData?.jobId || EMPTY_STRING}
                  onChange={handleSelectChange}
                  isRequired
                />

                <AdminAddAssigneeModal
                  isOpen
                  technicianList={technicianResp?.responses ?? []}
                  onAdd={handleAddAssignee}
                  onCloseAssignee={handleCloseAssignee}
                  isRequired
                />

                <BaseDatePicker
                  label={t('admin_manage_schedule_start_date_label')}
                  placeholderText={t('common_placeholder_select_date')}
                  name='startDate'
                  onDateSelected={handleSelectDate}
                  isRequired
                />

                <BaseDatePicker
                  label={t('admin_manage_schedule_end_date_label')}
                  placeholderText={t('common_placeholder_select_date')}
                  name='endDate'
                  onDateSelected={handleSelectDate}
                  isRequired
                />

                <div className={cx('timePicker')}>
                  <BaseTimePicker
                    label={t('admin_manage_schedule_start_time_label')}
                    name='startTime'
                    onTimeChange={handleSelectTime}
                    isRequired
                  />
                </div>

                <div className={cx('timePicker')}>
                  <BaseTimePicker
                    label={t('admin_manage_schedule_end_time_label')}
                    name='endTime'
                    onTimeChange={handleSelectTime}
                    isRequired
                  />
                </div>
              </div>

              <div className={cx('tagNote')}>
                <BaseTextarea
                  label={t('admin_manage_schedule_add_note_title')}
                  height={120}
                  name='note'
                  value={scheduleFormData?.note || EMPTY_STRING}
                  onTextareaChange={handleTextareaChange}
                />
              </div>
            </div>

            <div className={cx('modalFooter')}>
              <div className={cx('buttonCancel')}>
                <BaseButton
                  label={t('common_btn_cancel')}
                  width={117}
                  onClick={handleCancel}
                  typeStyle={ButtonTypeEnum.CANCEL}
                />
              </div>

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                onClick={handleAddSchedule}
                isDisable={isDisableSave}
              />
            </div>

            {isLoading && <Loading />}
          </div>
        )}
      </>
    </div>
  );
};

export default AdminAddScheduleModal;
