// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import {
  LaborBudget,
  LinkedPhase,
  ProductBudget,
  Space,
  SubcontractorBudget,
} from '~/components/specific/proposalInvoicePdf/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ILineItems } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  index?: number;
  data: ILineItems;
};

const InvoiceLineItems = (props: Props) => {
  //#region Destructuring Props
  const { index, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    data && (
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={styles.numberLine}>{index}</Text>
          <Text style={styles.nameLine}>{data?.name ?? EMPTY_STRING}</Text>
          <Text style={styles.valueLine}>
            {formatCurrency(CurrencyEnum.USD, +data?.price) ?? EMPTY_STRING}
          </Text>
        </View>
        <Space height={10} />

        {/* Material */}
        {data?.materials && data.materials?.length > 0 && (
          <>
            <View style={styles.row}>
              <View style={styles.leftSpace} />
              <View style={{ flex: 1 }}>
                <ProductBudget type='Material' data={data.materials} />
              </View>
            </View>
            <Space height={10} />
          </>
        )}

        {/* Labor */}
        {data?.labors && data.labors?.length > 0 && (
          <>
            <View style={styles.row}>
              <View style={styles.leftSpace} />
              <View style={{ flex: 1 }}>
                <LaborBudget data={data.labors} />
              </View>
            </View>
            <Space height={10} />
          </>
        )}

        {/* Subcontractors */}
        {data?.subcontractor &&
          (data?.subcontractor?.assignedSubcontractors?.length ||
            data?.subcontractor?.unassignedWorks?.length) && (
            <>
              <View style={styles.row}>
                <View style={styles.leftSpace} />
                <View style={{ flex: 1 }}>
                  <SubcontractorBudget data={data.subcontractor} />
                </View>
              </View>
              <Space height={10} />
            </>
          )}

        {/* Equipment */}
        {data?.equipments && data.equipments?.length > 0 && (
          <>
            <View style={styles.row}>
              <View style={styles.leftSpace} />
              <View style={{ flex: 1 }}>
                <ProductBudget type='Equipment' data={data.equipments} />
              </View>
            </View>
            <Space height={10} />
          </>
        )}

        {/* Miscellanea */}
        {data?.miscellanea && data.miscellanea?.length > 0 && (
          <>
            <View style={styles.row}>
              <View style={styles.leftSpace} />
              <View style={{ flex: 1 }}>
                <ProductBudget type='Miscellanea' data={data.miscellanea} />
              </View>
            </View>
            <Space height={10} />
          </>
        )}

        {/* Linked Phases */}
        {data?.linkPhases && data.linkPhases?.length > 0 && (
          <>
            <View style={styles.row}>
              <View style={styles.leftSpace} />
              <View style={{ flex: 1 }}>
                <LinkedPhase data={data.linkPhases} />
              </View>
            </View>
            <Space height={10} />
          </>
        )}
      </View>
    )
  );
};

export default InvoiceLineItems;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
  },
  numberLine: {
    width: 40,
    fontWeight: 700,
  },
  nameLine: {
    flex: 1,
    fontWeight: 700,
  },
  valueLine: {
    fontWeight: 700,
  },
  leftSpace: {
    width: 40,
  },
});
