// Libs
import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { Views } from 'react-big-calendar';
// Components, Layouts, Pages
import { EventContainer, ScheduleAssignee } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import { IScheduleEvent, ITimelineEvent } from '~/utils/interface/schedule';
// Styles, images, icons
import './EventTimeLine.scss';

type Props = Omit<ReactCalendarItemRendererProps, 'item'> & {
  eventData: ITimelineEvent;
  viewType: string;
  onSelectEvent?: (data: IScheduleEvent) => void;
};

const CollapseEvent = (props: { resourceData: IScheduleEvent }) => {
  const { resourceData } = props;
  const { jobTitle } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = jobTitle || EMPTY_STRING;

  return (
    <div
      className='eventGroup collapseEventContainer'
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className='eventName'>{eventName}</div>
    </div>
  );
};

const ExpandEvent = (props: { resourceData: IScheduleEvent; viewType: string }) => {
  const { resourceData, viewType } = props;
  const { jobTitle, assignees } = resourceData;
  const eventColor = DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = jobTitle || EMPTY_STRING;

  return (
    <div
      className={`eventGroup expandEventContainer ${viewType === Views.DAY && 'expandEventDay'}`}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className='eventName'>{eventName}</div>
      <div className='eventTime'>{resourceData?.startTime + ' - ' + resourceData?.endTime}</div>

      <div className={viewType === Views.DAY ? 'assigneeDayContainer' : 'assigneeContainer'}>
        {assignees && <ScheduleAssignee assignees={assignees} eventColor={eventColor} />}
      </div>
    </div>
  );
};

const EventTimeLine = (props: Props) => {
  //#region Destructuring Props
  const { eventData, viewType, getItemProps, onSelectEvent } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initStyle, setInitStyle] = useState<CSSProperties>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!eventData) return;

    const newStyle: CSSProperties = {};
    if (eventData?.resource?.length && eventData.resource.length > 1) {
      newStyle.overflowY = 'auto';
    }

    setInitStyle(newStyle);
  }, [eventData]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div
      {...getItemProps({
        style: { ...initStyle },
        className: 'eventContainer',
      })}
      key={eventData.id}
    >
      {eventData?.resource?.map((data: IScheduleEvent, index: number) => (
        <EventContainer
          key={index}
          data={data}
          onClick={() => onSelectEvent && onSelectEvent(data)}
        >
          {eventData?.resource?.length && eventData.resource.length > 1 ? (
            <CollapseEvent resourceData={data} />
          ) : (
            <ExpandEvent resourceData={data} viewType={viewType} />
          )}
        </EventContainer>
      ))}
    </div>
  );
};

export default EventTimeLine;
