// Libs
// Components, Layouts, Pages
// Others
import { TORCH_RED } from '~/utils/constants/color';
import { IconSvgProps } from '~/utils/interface/common';
// Styles, images, icons

const CommonIconClose = (props: IconSvgProps) => {
  //#region Destructuring Props
  const { strokePath = TORCH_RED, width, height, fill = 'none' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 1.44267L15.0573 0.5L8 7.55733L0.942667 0.5L0 1.44267L7.05733 8.5L0 15.5573L0.942667 16.5L8 9.44267L15.0573 16.5L16 15.5573L8.94267 8.5L16 1.44267Z'
        fill={strokePath}
      />
    </svg>
  );
};

export default CommonIconClose;
