// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
  BaseSelect,
  BaseTextarea,
  BaseUploadDocument,
  CommonConfirmModal,
  ProductPurchaseOrder,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { LoadingData } from '~/context';
import {
  createPurchaseOrder,
  getDetailPurchaseOrder,
} from '~/thunks/purchaseOrder/purchaseOrderThunk';
import { getListVendors } from '~/thunks/vendors/vendorsThunk';
import { getListProducts } from '~/thunks/product/productThunk';
import { getListJob } from '~/thunks/job/jobThunk';
import { IGetListJobReq, IJob } from '~/utils/interface/job';
import { IProduct } from '~/utils/interface/product';
import { IDocumentPurchaseOrder, IFormCreatePurchaseOrder } from '~/utils/interface/purchaseOrder';
import { IBaseOption, IQueryBase } from '~/utils/interface/common';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  NAVIGATE_GO_BACK,
  purchaseTypeOptions,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, ButtonTypeEnum, CurrencyEnum, StorageEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { schema } from '~/components/specific/formPurchaseOrder/helper';
import { defaultProducts } from '~/utils/constants/common';
// Styles, images, icons
import styles from './FormPurchaseOrder.module.scss';
import { icons } from '~/assets';

type Props = {
  purchaseId?: string;
};

const cx = classNames.bind(styles);

const FormPurchaseOrder = (props: Props) => {
  //#region Destructuring Props
  const { purchaseId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);

  const defaultValues = useMemo(() => {
    return {
      products: defaultProducts,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormCreatePurchaseOrder>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultValues,
  });

  const { fields, append, remove } = useFieldArray({ control, name: 'products' });
  const watchProduct = watch('products');
  const watchTotal = watch('total');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowPurchaseDate, setIsShowPurchaseDate] = useState<boolean>(false);
  // const [isShowOrderNumber, setIsShowOrderNumber] = useState<boolean>(false);
  const [isShowUploadDocuments, setIsShowUploadDocuments] = useState<boolean>(false);
  const [isShowConfirmItem, setIsShowConfirmItem] = useState<boolean>(false);
  const [vendorOptions, setVendorOptions] = useState<IBaseOption[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [jobOptions, setJobOptions] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!purchaseId) return;

    handleGetDetailPurchaseOrder(purchaseId);
  }, [purchaseId]);

  useEffect(() => {
    const total = watchProduct?.reduce(
      (total, currentTotal) => total + (currentTotal?.subTotal || 0),
      0
    );

    setValue('total', total);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watchProduct?.map((item) => item.subTotal))]);

  useEffect(() => {
    const payload: IQueryBase = {
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      page: DEFAULT_CURRENT_PAGE,
    };

    handleGetListVendor(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload: IGetListJobReq = {
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      page: DEFAULT_CURRENT_PAGE,
    };

    handleGetListJob(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload: IQueryBase = {
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      page: DEFAULT_CURRENT_PAGE,
    };

    handleGetListProduct(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBackToPurchaseOrder = () => {
    navigate(NAVIGATE_GO_BACK);

    reset(defaultValues);

    const role = localStorage.getItem(StorageEnum.ROLE);
    if (role === AccountRoleCodesEnum.ADMIN) {
      navigate(adminRouteAbsolute.purchaseOrder);
      return;
    }

    navigate(staffRouteAbsolute.purchaseOrder);
  };

  const handleChangeCheckboxPurChaseDate = (checked: boolean, name: string) => {
    setIsShowPurchaseDate(checked);

    if (!checked) {
      setValue('date', EMPTY_STRING);
    }
  };

  // const handleChangeCheckboxOrderNumber = (checked: boolean, name: string) => {
  //   setIsShowOrderNumber(checked);

  //   if (!checked) {
  //     setValue('orderNumber', DEFAULT_NUMBER_ZERO);
  //   }
  // };

  const handleGetDetailPurchaseOrder = (id: string) => {
    loadingData?.show();

    dispatch(getDetailPurchaseOrder(id))
      .unwrap()
      .then((res) => {
        const { data } = res;
        if (!data) return;

        const newData: IFormCreatePurchaseOrder = {
          type: data.type || EMPTY_STRING,
          vendorId: data.vendor?.id?.toString() || EMPTY_STRING,
          products: data.products
            ? data.products.map((product) => {
                return {
                  ...product,
                  productId: product?.productId?.toString(),
                  jobId: product?.jobId?.toString(),
                };
              })
            : [],
          total: Number(data.total) ?? DEFAULT_NUMBER_ZERO,
          notes: data.notes || EMPTY_STRING,
          date: data.date || EMPTY_STRING,
          documents: data?.documents,
        };

        if (newData.date) {
          setIsShowPurchaseDate(true);
        }

        if (newData.documents && newData.documents?.length > 0) {
          setIsShowUploadDocuments(true);
        }

        reset(newData);
      })
      .catch((err) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeCheckboxUploadDocuments = (checked: boolean, name: string) => {
    setIsShowUploadDocuments(checked);

    if (!checked) {
      setValue('documents', null);
    }
  };

  const handleGetListVendor = (payload: IQueryBase) => {
    dispatch(getListVendors(payload))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res?.data;

        const options = responses?.map((option) => ({
          label: option?.name || EMPTY_STRING,
          value: option?.id,
        }));
        setVendorOptions(options);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListProduct = (payload: IQueryBase) => {
    dispatch(getListProducts(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res?.data;
        setProducts(responses);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleGetListJob = (payload: IQueryBase) => {
    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res?.data;

        const options = responses?.map((option: IJob) => ({
          label: option?.jobName || EMPTY_STRING,
          value: option?.id,
        }));
        setJobOptions(options);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleSubmitPurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    if (purchaseId) {
      handleUpdatePurchaseOrder(data);
    } else {
      handleCreatePurchaseOrder(data);
    }
  };

  const handleCreatePurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    loadingData?.show();

    if (!data?.products || data?.products?.length <= 0) {
      loadingData?.hide();
      setIsShowConfirmItem(true);
      return;
    }

    const formData = new FormData();

    const formDataFields = {
      type: data?.type,
      vendorId: data?.vendorId,
      products: data?.products ? JSON.stringify(data?.products) : EMPTY_STRING,
      date: data?.date,
      orderNumber: data?.orderNumber ? JSON.stringify(data?.orderNumber) : EMPTY_STRING,
      notes: data?.notes,
      total: data?.total ? JSON.stringify(data?.total) : EMPTY_STRING,
    };

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    if (data?.documents) {
      data.documents.forEach((document) => {
        const { file } = document;
        file && formData.append('documents', file);
      });
    }

    if (!formData) return;

    dispatch(createPurchaseOrder(formData))
      .unwrap()
      .then((res) => {
        handleBackToPurchaseOrder();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleUpdatePurchaseOrder = (data: IFormCreatePurchaseOrder) => {
    // TODO: Handle Logic Later
  };

  const handleAddItem = () => {
    append(defaultProducts);
  };

  const handleRemoveItem = (index: number) => {
    remove(index);
  };

  const handleCloseConfirmItem = () => {
    setIsShowConfirmItem(false);
  };
  //#endregion Handle Function

  return (
    <div id='formPurchaseOrderComponent' className={cx('container')}>
      <FormProvider
        {...({
          control,
          watch,
          setValue,
          formState: { errors },
        } as UseFormReturn<IFormCreatePurchaseOrder>)}
      >
        <form className={cx('formContainer')} onSubmit={handleSubmit(handleSubmitPurchaseOrder)}>
          <div className={cx('formContent')}>
            <div className={cx('twoCol')}>
              <Controller
                name='type'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('create_purchase_label_purchase_type')}
                    placeholder={t('common_placeholder_select')}
                    value={value}
                    options={purchaseTypeOptions || []}
                    isRequired
                    onChange={(optionSelected: IBaseOption) => onChange(optionSelected?.value)}
                    errorMessage={errors.type?.message}
                  />
                )}
              />

              <Controller
                name='vendorId'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('create_purchase_label_vendor')}
                    placeholder={t('common_placeholder_select')}
                    value={value}
                    options={vendorOptions || []}
                    isRequired
                    onChange={(optionSelected: IBaseOption) => onChange(optionSelected?.value)}
                    errorMessage={errors.vendorId?.message}
                  />
                )}
              />
            </div>

            <div className={cx('items')}>
              <p className={cx('itemsLabel')}>{t('create_purchase_label_items')}</p>
              {fields && fields?.length > 0 && (
                <div className={cx('listItem')}>
                  <div className={cx('listItemGroup')}>
                    {fields?.map((item, index) => (
                      <ProductPurchaseOrder
                        index={index}
                        key={item?.id}
                        products={products}
                        jobs={jobOptions}
                        onRemoveItem={handleRemoveItem}
                      />
                    ))}
                  </div>

                  <p className={cx('errorMessage')}>{errors.products?.message}</p>
                </div>
              )}

              <div className={cx('itemsActions')}>
                <div className={cx('addItem', 'group')} onClick={handleAddItem}>
                  <img
                    src={icons.commonIconAddField}
                    alt={t('common_img_text_alt')}
                    className={cx('addItemIcon')}
                  />
                  <span className={cx('addItemLabel')}>{t('Add Item')}</span>
                </div>

                <span className={cx('totalGroup')}>
                  {t('create_purchase_order_total', {
                    total: formatCurrency(CurrencyEnum.USD, watchTotal ?? 0),
                  })}
                </span>
              </div>
            </div>

            <div className={cx('twoCol')}>
              <BaseCheckbox
                name='purchaseDate'
                label={t('create_purchase_label_purchase_date')}
                value={isShowPurchaseDate}
                onChange={handleChangeCheckboxPurChaseDate}
              />

              {isShowPurchaseDate && (
                <Controller
                  name='date'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseDatePicker
                      value={value}
                      onDateSelected={onChange}
                      errorMessage={errors.date?.message}
                    />
                  )}
                />
              )}
            </div>

            {/* <div className={cx('twoCol')}>
              <BaseCheckbox
                name='orderNumber'
                label={t('create_purchase_label_purchase_order')}
                value={isShowOrderNumber}
                onChange={handleChangeCheckboxOrderNumber}
              />

              {isShowOrderNumber && (
                <Controller
                  name='orderNumber'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.orderNumber?.message}
                    />
                  )}
                />
              )}
            </div> */}

            <div className={cx('twoCol')}>
              <BaseCheckbox
                name='documents'
                label={t('create_purchase_label_upload_documents')}
                value={isShowUploadDocuments}
                onChange={handleChangeCheckboxUploadDocuments}
              />

              <div></div>
            </div>

            {isShowUploadDocuments && (
              <Controller
                name='documents'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseUploadDocument
                    multiple
                    values={value ?? []}
                    onFilesChange={(data: IDocumentPurchaseOrder[]) => {
                      onChange(data);
                    }}
                    errorMessage={errors.documents?.message}
                  />
                )}
              />
            )}

            <Controller
              name='notes'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  label={t('create_purchase_label_notes')}
                  value={value || EMPTY_STRING}
                  height={200}
                  onTextareaChange={onChange}
                />
              )}
            />
          </div>

          <div className={cx('formActions')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleBackToPurchaseOrder}
            />

            {!purchaseId && (
              <BaseButton
                label={t('common_btn_save')}
                width={117}
                isDisable={!isDirty}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                type='submit'
              />
            )}
          </div>
        </form>
      </FormProvider>

      <CommonConfirmModal
        isOpen={isShowConfirmItem}
        onClose={handleCloseConfirmItem}
        title={t('create_purchase_no_item')}
      />
    </div>
  );
};

export default FormPurchaseOrder;
