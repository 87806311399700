// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
// Components, Layouts, Pages
import BaseButton from '~/components/common/button/BaseButton';
import FormInput from '~/components/common/formInput/FormInput';
// Others
import { IAddTermsAndConditions } from '~/utils/interface/termsAndConditions';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './AddTermsAndConditionsModal.module.scss';
import { icons } from '~/assets';

type Props = {
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('common_validate_require_template_name')),
      upper: yup.string().optional(),
      lower: yup.string().optional(),
    })
    .required();
};

const AddTermsAndConditionsModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const defaultValues: IAddTermsAndConditions = useMemo(() => {
    return {
      name: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<IAddTermsAndConditions>({
    resolver: yupResolver(schema(t)),
    defaultValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();

    reset(defaultValues);
  };

  const handleAddTermsAndConditions = (data: IAddTermsAndConditions) => {
    // TODO: Handle logic add terms and conditions

    handleCancelModal();
  };
  //#endregion Handle Function

  return (
    <div id='addTermsAndConditionsModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}

        <form className={cx('modalContent')} onSubmit={handleSubmit(handleAddTermsAndConditions)}>
          <div className={cx('modalHeader')}>{t('add_terms_and_conditions_title')}</div>

          <div className={cx('line')} />

          <div className={cx('modalBody')}>
            <Controller
              name='name'
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  label={t('add_terms_and_conditions_label_name')}
                  value={value}
                  onChange={onChange}
                  required
                  errorMessage={errors.name?.message}
                />
              )}
            />

            <div className={cx('formItemTitle')}>{t('add_terms_and_conditions_label_upper')}</div>

            <Controller
              name='upper'
              control={control}
              render={({ field: { value, onChange } }) => <>{/* Text editor here */}</>}
            />

            <div className={cx('formItemTitle')}>{t('add_terms_and_conditions_label_lower')}</div>

            <Controller
              name='lower'
              control={control}
              render={({ field: { value, onChange } }) => <>{/* Text editor here */}</>}
            />
          </div>

          <div className={cx('modalFooter')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleCancelModal}
            />

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
              isDisable={!isValid}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTermsAndConditionsModal;
