// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './NotificationSettings.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const NotificationSettings = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='notificationSettings' className={cx('container')}>
      <Toolbar title={t('template_sidebar_label_notification_settings')} />

      <div className={cx('bodyContent')}></div>
    </div>
  );
};

export default NotificationSettings;
