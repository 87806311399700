// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { createService, deleteService, getListServices, updateService } from './serviceThunk';
import { RootState } from '~/redux/store';

export interface ServiceState {
  isRefreshServiceList: boolean;
}

const initialState: ServiceState = {
  isRefreshServiceList: false,
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshServiceList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list service
    builder
      .addCase(getListServices.pending, (state) => {})
      .addCase(getListServices.fulfilled, (state) => {})
      .addCase(getListServices.rejected, (state) => {});

    // Create service
    builder
      .addCase(createService.pending, (state) => {})
      .addCase(createService.fulfilled, (state) => {})
      .addCase(createService.rejected, (state) => {});

    // Delete service
    builder
      .addCase(deleteService.pending, (state) => {})
      .addCase(deleteService.fulfilled, (state) => {})
      .addCase(deleteService.rejected, (state) => {});

    // Update service
    builder
      .addCase(updateService.pending, (state) => {})
      .addCase(updateService.fulfilled, (state) => {})
      .addCase(updateService.rejected, (state) => {});
  },
});

// Action
export const serviceActions = serviceSlice.actions;

// Selectors
export const selectIsRefreshServiceList = (state: RootState) => state.service.isRefreshServiceList;

// Reducer
const serviceReducer = serviceSlice.reducer;
export default serviceReducer;
