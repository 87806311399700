// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { FormPurchaseOrder, Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './CreatePo.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CreatePo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { purchaseId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleGoBack = () => {
    navigate(-1);
  };
  //#endregion Handle Function

  return (
    <div id='createPoTemplate' className={cx('container')}>
      <Toolbar
        title={
          purchaseId
            ? t('purchase_order_detail_toolbar_title')
            : t('create_purchase_order_toolbar_title')
        }
      />

      <div className={cx('body')}>
        <FormPurchaseOrder purchaseId={purchaseId} />
      </div>
    </div>
  );
};

export default CreatePo;
