import axiosClient from '~/apis/axiosClient';
import { urlApiProfile } from '~/utils/constants/common';
import { BaseResponse } from '~/utils/interface/common';
import { IUserProfile } from '~/utils/interface/profile';

const profileApi = {
  getDetailProfile: () => {
    const url = `${urlApiProfile.getProfile}`;
    return axiosClient.get<BaseResponse<IUserProfile>>(url);
  },
};

export default profileApi;
