// Libs
import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { read, utils } from 'xlsx';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseCheckbox,
  FieldAssignedEst,
  FieldBudgetLaborEst,
  FieldBudgetProductEst,
  FieldUnassignedEst,
  FormInput,
  FormModalWorkOder,
  SetContractItemModal,
  CommonConfirmModal,
} from '~/components';
// Others
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import {
  getOptionFixtureLocations,
  getOptionProductByBudget,
} from '~/thunks/estimate/estimateThunk';
import { getListLabor } from '~/thunks/labor/LaborThunk';
import { getListVendorsSubcontractors } from '~/thunks/vendors/vendorsThunk';
import { getLineItemList } from '~/thunks/proposal/proposalThunk';
import {
  DEFAULT_BUDGET_ASSIGNED,
  DEFAULT_BUDGET_CATEGORY,
  DEFAULT_BUDGET_LABOR,
  DEFAULT_BUDGET_UNASSIGNED,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_ENABLE_BUDGET_CATEGORY,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { DEFAULT_INPUT_HEIGHT } from '~/utils/constants/component';
import { TypeProductEnum, CurrencyEnum } from '~/utils/enum';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import {
  IBudgetCategory,
  IEnableBudgetCategory,
  IEstimateContent,
  IWorkOrder,
} from '~/utils/interface/estimate';
import { IQueryListProduct } from '~/utils/interface/product';
import { IBaseOption } from '~/utils/interface/common';
import { convertKeyToCamelCase, formatCurrency } from '~/utils/helper';
import { IQueryGetListLineItem } from '~/utils/interface/proposal';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormPhaseContainer.module.scss';

type Props = {
  phaseIndex: number;
  isShowSetContractItemTemplate?: boolean;
};

const cx = classNames.bind(styles);

const FormPhaseContainer = (props: Props) => {
  //#region Destructuring Props
  const { phaseIndex, isShowSetContractItemTemplate = true } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<IEstimateContent>();

  // Materials useFieldArray
  const {
    fields: materialFields,
    append: appendMaterial,
    remove: removeMaterial,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.materials`,
  });

  // Unassigned useFieldArray
  const {
    fields: unassignedFields,
    append: appendUnassigned,
    remove: removeUnassigned,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.subcontractor.unassignedWorks`,
  });

  // Assigned useFieldArray
  const {
    fields: assignedFields,
    append: appendAssigned,
    remove: removeAssigned,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.subcontractor.assignedSubcontractors`,
  });

  // Equipment useFieldArray
  const {
    fields: equipmentFields,
    append: appendEquipment,
    remove: removeEquipment,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.equipments`,
  });

  // Labor useFieldArray
  const {
    fields: laborFields,
    append: appendLabor,
    remove: removeLabor,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.labors`,
  });

  // Miscellanea useFieldArray
  const {
    fields: miscellaneaFields,
    append: appendMiscellanea,
    remove: removeMiscellanea,
  } = useFieldArray({
    control,
    name: `phases.${phaseIndex}.miscellanea`,
  });
  const phaseNameValue = useWatch({ name: `phases.${phaseIndex}.name`, control });
  const materialBudget = useWatch({ name: `phases.${phaseIndex}.materials`, control });
  const laborBudget = useWatch({ name: `phases.${phaseIndex}.labors`, control });
  const subcontractor = useWatch({ name: `phases.${phaseIndex}.subcontractor`, control });
  const equipment = useWatch({ name: `phases.${phaseIndex}.equipments`, control });
  const miscellanea = useWatch({ name: `phases.${phaseIndex}.miscellanea`, control });

  const budgetedCost = useWatch({ name: `phases.${phaseIndex}.total`, control });
  const materialTotal = useWatch({ name: `phases.${phaseIndex}.budget.material`, control });
  const laborTotal = useWatch({ name: `phases.${phaseIndex}.budget.labor`, control });
  const subcontractorTotal = useWatch({
    name: `phases.${phaseIndex}.budget.subcontractor`,
    control,
  });
  const equipmentTotal = useWatch({ name: `phases.${phaseIndex}.budget.equipment`, control });
  const miscellaneaTotal = useWatch({ name: `phases.${phaseIndex}.budget.miscellanea`, control });

  const workOrder = useWatch({ name: `phases.${phaseIndex}.workOrder`, control });
  const lineItemSelected = useWatch({ name: `phases.${phaseIndex}.proposalItemId`, control });
  //#endregion Declare Hook

  //#region Selector
  const { productMaterials, productEquipment, fixtureLocations, productMiscellanea } =
    useAppSelector((state: RootState) => state.estimate);
  const { listVendorTypeSubcontractors } = useAppSelector((state: RootState) => state.vendors);
  const { listLabor } = useAppSelector((state: RootState) => state.labor);
  //#endregion Selector

  //#region Declare State
  const [enableBudgetCategory, setEnableBudgetCategory] = useState<IEnableBudgetCategory>(
    DEFAULT_ENABLE_BUDGET_CATEGORY
  );
  const [isShowSetContractItem, setIsShowSetContractItem] = useState<boolean>(false);
  const [isShowModalWorkOrder, setIsShowModalWorkOrder] = useState<boolean>(false);
  const [itemDataWorkOrder, setItemDataWorkOrder] = useState<IWorkOrder>({});
  const [contractItemOptions, setContractItemOptions] = useState<IBaseOption[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (laborTotal) {
      setEnableBudgetCategory((prevState) => ({ ...prevState, laborBudget: true }));
    }

    if (subcontractorTotal) {
      setEnableBudgetCategory((prevState) => ({ ...prevState, subcontractorsBudget: true }));
    }

    if (equipmentTotal) {
      setEnableBudgetCategory((prevState) => ({ ...prevState, equipmentBudget: true }));
    }

    if (miscellaneaTotal) {
      setEnableBudgetCategory((prevState) => ({ ...prevState, miscellaneaBudget: true }));
    }
  }, [laborTotal, subcontractorTotal, equipmentTotal, miscellaneaTotal]);

  useEffect(() => {
    const materialParams: IQueryListProduct = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      type: TypeProductEnum.MATERIAL_SUPPLIER,
    };
    fetchProductByBudget(materialParams);

    const params: IQueryListFixtureLocations = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };
    fetchFixtureLocation(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const commonParams: IQueryListProduct = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    if (enableBudgetCategory.equipmentBudget && !productEquipment) {
      const equipmentParams: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.EQUIPMENT_SUPPLIER,
      };
      fetchProductByBudget(equipmentParams);
    }

    if (enableBudgetCategory.miscellaneaBudget && !productMiscellanea) {
      const miscellaneaParams: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.MISCELLANEOUS_EXPENSES,
      };
      fetchProductByBudget(miscellaneaParams);
    }

    if (enableBudgetCategory.subcontractorsBudget && !listVendorTypeSubcontractors) {
      const newParam: IQueryListProduct = {
        ...commonParams,
        type: TypeProductEnum.SUBCONTRACTOR,
      };
      getListVendorSubcontractor(newParam);
    }

    if (enableBudgetCategory.laborBudget && !listLabor) {
      handleGetListLabor(commonParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableBudgetCategory]);

  useEffect(() => {
    if (!jobId) return;
    const lineItemParams: IQueryGetListLineItem = {
      jobId: jobId,
      params: {
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      },
    };
    handleGetListLineItem(lineItemParams);
  }, [jobId]);

  useEffect(() => {
    if (workOrder) {
      setItemDataWorkOrder({ description: workOrder });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder]);

  useEffect(() => {
    const materialTotal = materialBudget?.reduce(
      (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    setValue(`phases.${phaseIndex}.budget.material`, materialTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialBudget]);

  useEffect(() => {
    const laborBudget = watch(`phases.${phaseIndex}.labors`)?.reduce(
      (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    setValue(`phases.${phaseIndex}.budget.labor`, laborBudget);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laborBudget]);

  useEffect(() => {
    const assignedSubcontractors = watch(
      `phases.${phaseIndex}.subcontractor.assignedSubcontractors`
    )?.reduce(
      (total, item) => total + (Number(item.value) ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    const unassignedWorks = watch(`phases.${phaseIndex}.subcontractor.unassignedWorks`)?.reduce(
      (total, item) => total + (Number(item.value) ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );
    const subcontractors = Number(assignedSubcontractors) + Number(unassignedWorks);

    setValue(`phases.${phaseIndex}.budget.subcontractor`, subcontractors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcontractor]);

  useEffect(() => {
    const equipment = watch(`phases.${phaseIndex}.equipments`)?.reduce(
      (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    setValue(`phases.${phaseIndex}.budget.equipment`, equipment);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipment]);

  useEffect(() => {
    const miscellanea = watch(`phases.${phaseIndex}.miscellanea`)?.reduce(
      (total, item) => total + (item.subTotal ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    setValue(`phases.${phaseIndex}.budget.miscellanea`, miscellanea);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [miscellanea]);

  useEffect(() => {
    const materialArr = watch(`phases.${phaseIndex}.budget.material`) || 0;
    const laborArr = watch(`phases.${phaseIndex}.budget.labor`) || 0;
    const subcontractorArr = watch(`phases.${phaseIndex}.budget.subcontractor`) || 0;
    const equipmentArr = watch(`phases.${phaseIndex}.budget.equipment`) || 0;
    const miscellaneaArr = watch(`phases.${phaseIndex}.budget.miscellanea`) || 0;

    const valuesArr = [materialArr, laborArr, subcontractorArr, equipmentArr, miscellaneaArr];
    const budgetedCost = valuesArr.reduce(
      (total, value) => (total ?? DEFAULT_NUMBER_ZERO) + (value ?? DEFAULT_NUMBER_ZERO),
      DEFAULT_NUMBER_ZERO
    );

    setValue(`phases.${phaseIndex}.total`, budgetedCost);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materialBudget, subcontractor, laborBudget, equipment, miscellanea]);
  //#endregion Implement Hook

  //#region Handle Function
  const getListVendorSubcontractor = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getListVendorsSubcontractors(params));
  };

  const handleGetListLabor = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getListLabor(params));
  };

  const handleGetListLineItem = (params: IQueryGetListLineItem) => {
    if (!params) return;
    dispatch(getLineItemList(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses } = res.data;
        const newData: IBaseOption[] = responses.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setContractItemOptions(newData);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const fetchProductByBudget = (params: IQueryListProduct) => {
    if (!params) return;
    dispatch(getOptionProductByBudget(params));
  };

  const fetchFixtureLocation = (params: IQueryListFixtureLocations) => {
    if (!params) return;
    dispatch(getOptionFixtureLocations(params));
  };

  const handleCheckboxChange = (checked: boolean, name: string) => {
    switch (name) {
      case `phases.${phaseIndex}.isShowLabor`:
        setEnableBudgetCategory((prevState) => ({ ...prevState, laborBudget: checked }));
        checked ? appendLabor(DEFAULT_BUDGET_LABOR) : removeLabor();
        break;

      case `phases.${phaseIndex}.isShowSubcontractor`:
        setEnableBudgetCategory((prevState) => ({ ...prevState, subcontractorsBudget: checked }));
        if (!checked) {
          removeUnassigned();
          removeAssigned();
        }
        break;

      case `phases.${phaseIndex}.isShowEquipment`:
        setEnableBudgetCategory((prevState) => ({ ...prevState, equipmentBudget: checked }));
        checked ? appendEquipment(DEFAULT_BUDGET_CATEGORY) : removeEquipment();
        break;

      case `phases.${phaseIndex}.isShowMiscellanea`:
        setEnableBudgetCategory((prevState) => ({ ...prevState, miscellaneaBudget: checked }));
        checked ? appendMiscellanea(DEFAULT_BUDGET_CATEGORY) : removeMiscellanea();
        break;
    }
  };

  const handleShowSetContractItemModal = () => {
    setIsShowSetContractItem(true);
  };

  const handleContractItemChange = (value: string) => {
    setValue(`phases.${phaseIndex}.proposalItemId`, value);
  };

  const handleCloseSetContractItemModal = () => {
    setIsShowSetContractItem(false);
  };

  const handleShowWorkOrder = () => {
    setIsShowModalWorkOrder(!isShowModalWorkOrder);
  };

  const handleDiscardWorkOrder = () => {
    setIsShowModalWorkOrder(false);
    setItemDataWorkOrder(itemDataWorkOrder);
  };

  const handleModalWorkOrderSuccess = (item: IWorkOrder) => {
    setItemDataWorkOrder(item);
    setIsShowModalWorkOrder(false);
    setValue(`phases.${phaseIndex}.workOrder`, item.description, { shouldDirty: true });
  };

  const formatExcelDataForMaterials = (excelData: unknown[]): IBudgetCategory[] => {
    const dataExcelCamel = convertKeyToCamelCase(excelData) as IBudgetCategory[];

    return dataExcelCamel.map((dataItem: IBudgetCategory) => {
      const productMatch = productMaterials?.find((item) => item.label === dataItem.productName);
      const fixtureMatch = fixtureLocations?.find(
        (item) => item.label === dataItem.fixtureLocationName
      );

      const unitCost = Number(productMatch?.unitCost) || 0;
      const productName = productMatch?.label ?? EMPTY_STRING;
      const fixtureLocationName = fixtureMatch?.label ?? EMPTY_STRING;
      const productId = productMatch?.value ?? null;
      const fixtureLocationId = fixtureMatch?.value ?? null;

      return {
        ...dataItem,
        productName,
        fixtureLocationName,
        unitCost,
        subTotal: unitCost * (dataItem.quantity ?? 0),
        ...(productMatch?.value ? { productId } : {}),
        ...(fixtureMatch?.value ? { fixtureLocationId } : {}),
      };
    });
  };

  // Process the Excel file and format the data
  const handleProcessExcelFile = (file: File) => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      const fileData = event.target?.result;
      if (fileData) {
        const workbook = read(fileData); // Parse the array buffer
        const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]]; // Get the first worksheet
        const jsonData = utils.sheet_to_json(firstWorksheet); // Convert to JSON

        // Format data for MATERIALS
        const formattedData = formatExcelDataForMaterials(jsonData);
        console.log('formattedData (MATERIALS)', JSON.stringify(formattedData, null, 2));

        formattedData.forEach((item: IBudgetCategory) => {
          appendMaterial(item);
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const handleUploadExFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) return;
    handleProcessExcelFile(file);
    event.target.value = EMPTY_STRING; // Reset DOM when user select the same file in react
  };
  //#endregion Handle Function

  return (
    <div id='formPhaseContainerComponent' className={cx('container')}>
      <div className={cx('twoColumn')}>
        <Controller
          name={`phases.${phaseIndex}.name`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormInput
              required
              label={t('template_form_phase_content_field_name')}
              value={value || EMPTY_STRING}
              errorMessage={errors?.phases?.[phaseIndex]?.name?.message}
              onChange={onChange}
            />
          )}
        />

        <div className={cx('twoColumn')}>
          <div className={'readOnlyField'}>
            <div className={cx('label')}>{t('template_form_phase_content_field_cost')}</div>
            <div className={cx('value')} style={{ height: DEFAULT_INPUT_HEIGHT }}>
              {formatCurrency(CurrencyEnum.USD, Number(budgetedCost))}
            </div>
          </div>
          <Controller
            name={`phases.${phaseIndex}.percentage`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('template_form_phase_content_field_percentage')}
                value={value ?? EMPTY_STRING}
                onChange={onChange}
                // errorMessage={errors?.phases?.[phaseIndex]?.percentage?.message}
              />
            )}
          />
        </div>
      </div>

      <div className={cx('fiveColumn')}>
        <FormInput
          disabled={true}
          label={t('template_form_phase_content_field_category_material')}
          value={formatCurrency(CurrencyEnum.USD, Number(materialTotal))}
        />

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`phases.${phaseIndex}.isShowLabor`}
            value={enableBudgetCategory.laborBudget}
            label={t('template_form_phase_content_field_category_labor')}
            onChange={handleCheckboxChange}
          />
          <FormInput disabled={true} value={formatCurrency(CurrencyEnum.USD, Number(laborTotal))} />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            label={t('template_form_phase_content_field_category_subcontractor')}
            name={`phases.${phaseIndex}.isShowSubcontractor`}
            value={enableBudgetCategory.subcontractorsBudget}
            onChange={handleCheckboxChange}
          />
          <FormInput
            disabled={true}
            value={formatCurrency(CurrencyEnum.USD, Number(subcontractorTotal))}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`phases.${phaseIndex}.isShowEquipment`}
            value={enableBudgetCategory.equipmentBudget}
            label={t('template_form_phase_content_field_category_equipment')}
            onChange={handleCheckboxChange}
          />
          <FormInput
            disabled={true}
            value={formatCurrency(CurrencyEnum.USD, Number(equipmentTotal))}
          />
        </div>

        <div className={cx('budgetContainer')}>
          <BaseCheckbox
            name={`phases.${phaseIndex}.isShowMiscellanea`}
            label={t('template_form_phase_content_field_category_miscellanea')}
            value={enableBudgetCategory.miscellaneaBudget}
            onChange={handleCheckboxChange}
          />
          <FormInput
            disabled={true}
            value={formatCurrency(CurrencyEnum.USD, Number(miscellaneaTotal))}
          />
        </div>
      </div>

      <div className={cx('fiveColumn')}>
        <button type='button' className={cx('workOrder')} onClick={handleShowWorkOrder}>
          <div className={cx('iconWorkOrder')}>
            {Object.values(itemDataWorkOrder).length == 0 || !itemDataWorkOrder.description ? (
              <img src={icons.adminIconAddWorkOrder} alt={t('common_img_text_alt')} />
            ) : (
              <img src={icons.adminIconEditWorkOrder} alt={t('common_img_common_img_text_alt')} />
            )}
          </div>
          <div className={cx('labelWorkOrder')}>
            {t('template_form_phase_content_field_edit_wo')}
          </div>
        </button>

        {isShowSetContractItemTemplate && (
          <span onClick={handleShowSetContractItemModal} className={cx('formSetContractItem')}>
            {t('template_form_phase_content_field_set_contract')}
          </span>
        )}
      </div>

      <section className={cx('budgetSection')}>
        <div className={cx('titleBudget')}>
          {t('template_form_phase_content_label_category_material')}
        </div>
        <div className={cx('line')} />

        <div className={cx('formListGroup')}>
          {materialFields.map((row, index) => (
            <FieldBudgetProductEst
              key={row.id}
              type='materials'
              phaseIndex={phaseIndex}
              fieldIndex={index}
              removeField={removeMaterial}
            />
          ))}

          <div className={cx('btnAddWrap')}>
            <button
              type='button'
              onClick={() => appendMaterial(DEFAULT_BUDGET_CATEGORY)}
              className={cx('btnAddField', 'group')}
            >
              <img
                src={icons.commonIconAddField}
                alt={t('common_img_text_alt')}
                className={cx('iconAddField')}
              />
              <span className={cx('btnTextAdd')}>
                {t('template_form_phase_content_btn_add_field')}
              </span>
            </button>

            <label htmlFor='exFile' className={cx('btnAddField', 'group')}>
              <img
                src={icons.commonIconJobEstimateImportFile}
                alt={t('common_img_text_alt')}
                className={cx('iconAddFile')}
              />
              <span className={cx('btnTextAdd')}>
                {t('template_form_phase_content_btn_import_file')}
              </span>
              <input
                id='exFile'
                type='file'
                accept='.xlsx, .xls'
                style={{ display: 'none' }}
                onChange={handleUploadExFile}
              />
            </label>
          </div>
        </div>
      </section>

      {/* Labors */}
      {enableBudgetCategory.laborBudget && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('template_form_phase_content_label_category_labor')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {laborFields.map((row, index) => (
              <FieldBudgetLaborEst
                key={row.id}
                phaseIndex={phaseIndex}
                fieldIndex={index}
                removeField={removeLabor}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                type='button'
                onClick={() => appendLabor(DEFAULT_BUDGET_LABOR)}
                className={cx('btnAddField', 'group')}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}

      {/* Subcontractors */}
      {enableBudgetCategory.subcontractorsBudget && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('template_form_phase_content_label_category_subcontractor')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListSubtitle')}>
            {t('template_form_phase_content_title_unassigned_work')}
          </div>
          <div className={cx('formListGroupSubcontractor')}>
            {unassignedFields.map((row, index) => (
              <FieldUnassignedEst
                key={row.id}
                phaseIndex={phaseIndex}
                fieldIndex={index}
                removeField={removeUnassigned}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendUnassigned(DEFAULT_BUDGET_UNASSIGNED)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>

          <div className={cx('formListSubtitle', 'assigned')}>
            {t('template_form_phase_content_title_assigned_subcontractors')}
          </div>
          <div className={cx('formListGroupSubcontractor')}>
            {assignedFields.map((row, index) => (
              <FieldAssignedEst
                key={row.id}
                phaseIndex={phaseIndex}
                fieldIndex={index}
                removeField={removeAssigned}
              />
            ))}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendAssigned(DEFAULT_BUDGET_ASSIGNED)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}

      {/* Equipment */}
      {enableBudgetCategory.equipmentBudget && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('template_form_phase_content_label_category_equipment')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {equipmentFields.map((row, index) => (
              <FieldBudgetProductEst
                key={row.id}
                type='equipments'
                phaseIndex={phaseIndex}
                fieldIndex={index}
                removeField={removeEquipment}
              />
            ))}

            <button
              type='button'
              onClick={() => appendEquipment(DEFAULT_BUDGET_CATEGORY)}
              className={cx('btnAddField', 'group')}
            >
              <img
                src={icons.commonIconAddField}
                alt={t('common_img_text_alt')}
                className={cx('iconAddField')}
              />
              <span className={cx('btnTextAdd')}>
                {t('template_form_phase_content_btn_add_field')}
              </span>
            </button>
          </div>
        </section>
      )}

      {/* Miscellanea */}
      {enableBudgetCategory.miscellaneaBudget && (
        <section className={cx('budgetSection')}>
          <div className={cx('titleBudget')}>
            {t('template_form_phase_content_label_category_miscellanea')}
          </div>
          <div className={cx('line')} />

          <div className={cx('formListGroup')}>
            {miscellaneaFields.length > DEFAULT_NUMBER_ZERO && (
              <>
                {miscellaneaFields.map((row, index) => (
                  <FieldBudgetProductEst
                    key={row.id}
                    type='miscellanea'
                    phaseIndex={phaseIndex}
                    fieldIndex={index}
                    removeField={removeMiscellanea}
                  />
                ))}
              </>
            )}

            <div className={cx('btnAddWrap')}>
              <button
                className={cx('btnAddField', 'group')}
                type='button'
                onClick={() => appendMiscellanea(DEFAULT_BUDGET_CATEGORY)}
              >
                <img
                  src={icons.commonIconAddField}
                  alt={t('common_img_text_alt')}
                  className={cx('iconAddField')}
                />
                <span className={cx('btnTextAdd')}>
                  {t('template_form_phase_content_btn_add_field')}
                </span>
              </button>
            </div>
          </div>
        </section>
      )}

      {contractItemOptions.length > 0 && (
        <SetContractItemModal
          listLineItem={contractItemOptions}
          lineSelected={lineItemSelected}
          description={t('set_contract_item_label_description', {
            phaseName: getValues(`phases.${phaseIndex}.name`),
          })}
          isOpen={isShowSetContractItem}
          onChange={handleContractItemChange}
          onClose={handleCloseSetContractItemModal}
        />
      )}

      {contractItemOptions.length <= 0 && (
        <CommonConfirmModal
          isOpen={isShowSetContractItem}
          onClose={handleCloseSetContractItemModal}
          title={t('no_line_item_modal_desc')}
        />
      )}

      <FormModalWorkOder
        isOpen={isShowModalWorkOrder}
        onClose={handleDiscardWorkOrder}
        onSave={handleModalWorkOrderSuccess}
        phaseName={phaseNameValue || EMPTY_STRING}
        itemDataWorkOrder={itemDataWorkOrder}
      />
    </div>
  );
};

export default FormPhaseContainer;
