import { createSlice } from '@reduxjs/toolkit';
import { getStatisticChart } from './statisticThunk';

export interface StatisticState {}

const initialState: StatisticState = {};

const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list statistic
    builder
      .addCase(getStatisticChart.pending, (state) => {})
      .addCase(getStatisticChart.fulfilled, (state) => {})
      .addCase(getStatisticChart.rejected, (state) => {});
  },
});

// Action
export const statisticActions = statisticSlice.actions;

// Reducer
const statisticReducer = statisticSlice.reducer;
export default statisticReducer;
