// Libs
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { TButtonType } from '~/utils/type/common';
// Styles, images, icons
import styles from './BaseButton.module.scss';

type Props = {
  label?: string;
  typeStyle?: ButtonTypeEnum;
  icon?: string;
  width?: number | string;
  height?: number | string;
  isBorder?: boolean;
  borderRadius?: number | string;
  minWidth?: number | string;
  isDisable?: boolean;
  type?: TButtonType;
  onClick?: () => void;
};

const cx = classNames.bind(styles);

const BaseButton = (props: Props) => {
  //#region Destructuring Props
  const {
    type = 'button',
    width = '100%',
    height = 31,
    label = 'Button',
    typeStyle,
    icon,
    isBorder = true,
    borderRadius,
    minWidth = 117,
    isDisable = false,
    onClick,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const buttonClass = useMemo(() => {
    return cx(['originStyle', typeStyle, isDisable && 'bgrOpacityDisable']);
  }, [typeStyle, isDisable]);
  //#endregion Declare State

  //#region Handle Function
  const handleClick = () => {
    onClick && onClick();
  };
  //#endregion Handle Function

  return (
    <button
      id='baseButtonComponent'
      style={{ width, height, borderRadius, minWidth }}
      className={buttonClass}
      onClick={handleClick}
      disabled={isDisable}
      type={type}
    >
      {icon && (
        <img src={icon} alt={t('common_img_text_alt')} className={cx('iconButton', typeStyle)} />
      )}
      {label}
    </button>
  );
};

export default BaseButton;
