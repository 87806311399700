// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import clientApi from '~/apis/client';
// Others
import {
  CREATE_NEW_CLIENT,
  DELETE_CLIENT,
  GET_CLIENT_DETAILS,
  GET_LIST_CLIENT,
  SEND_TO_QUICK_BOCKS_CLIENT,
  UPDATE_CLIENT,
} from '~/utils/constants/actionType';
import { IUpdateClientBody } from '~/utils/interface/client';
import { ITableParams } from '~/utils/interface/common';

export const getListClient = createAsyncThunk(
  GET_LIST_CLIENT,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await clientApi.getListClient(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getClientDetails = createAsyncThunk(
  GET_CLIENT_DETAILS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await clientApi.getClientDetails(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteClient = createAsyncThunk(
  DELETE_CLIENT,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await clientApi.deleteClient(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewClient = createAsyncThunk(
  CREATE_NEW_CLIENT,
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const res = await clientApi.createNewClient(formData);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateClient = createAsyncThunk(
  UPDATE_CLIENT,
  async (dataUpdate: IUpdateClientBody, { rejectWithValue }) => {
    try {
      const res = await clientApi.updateClient(dataUpdate.id, dataUpdate.body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendToQuickBocksClient = createAsyncThunk(
  SEND_TO_QUICK_BOCKS_CLIENT,
  async (clientId: string, { rejectWithValue }) => {
    try {
      const res = await clientApi.sendToQBClient(clientId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
