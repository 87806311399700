// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';

// Others
import authApi from '~/apis/auth';
import {
  FORGOT_PASSWORD,
  CREATE_NEW_PASSWORD,
  LOGIN,
  VERIFY_CODE,
  CHANGE_PASSWORD,
} from '~/utils/constants/actionType';
import {
  IForgotPasswordPayload,
  ICreateNewPasswordPayload,
  ILoginPayload,
  IVerifyCode,
  IChangePasswordPayload,
} from '~/utils/interface/auth';

export const handleLogin = createAsyncThunk(
  LOGIN,
  async (payload: ILoginPayload, { rejectWithValue }) => {
    try {
      const resp = await authApi.login(payload);
      return resp.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  FORGOT_PASSWORD,
  async (payload: IForgotPasswordPayload, { rejectWithValue }) => {
    try {
      const resp = await authApi.forgotPassword(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewPassword = createAsyncThunk(
  CREATE_NEW_PASSWORD,
  async (payload: ICreateNewPasswordPayload, { rejectWithValue }) => {
    try {
      const resp = await authApi.createNewPassword(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyCode = createAsyncThunk(
  VERIFY_CODE,
  async (payload: IVerifyCode, { rejectWithValue }) => {
    try {
      const resp = await authApi.verifyCode(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  CHANGE_PASSWORD,
  async (payload: IChangePasswordPayload, { rejectWithValue }) => {
    try {
      const resp = await authApi.changePassword(payload);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
