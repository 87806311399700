// Libs
import { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BasePagination, BaseTable, ButtonStatus, CircleAvatar, Toolbar } from '~/components';
// Others
import { ColumnType } from '~/utils/interface/common';
import { IPayment, IPaymentResp } from '~/utils/interface/mock';
import { formatCurrency, formattedTime, getAvatarWithName, getFullName } from '~/utils/helper';
import { CircleAvatarEnum, CurrencyEnum, PaymentStatusEnum } from '~/utils/enum';
import { EMPTY_STRING, defaultCurrentPage } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ManagePayment.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (t: TFunction<'translation'>): ColumnType<IPayment>[] => {
  return [
    {
      key: 'id',
      dataIndex: 'id',
      title: t('admin_manage_payment_table_label_invoice_id'),
      render: (_, record: IPayment) => (
        <div>
          <span>{record.id || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'invoiceTo',
      title: t('admin_manage_payment_table_label_invoice_to'),
      render: (_, record: IPayment) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName(
            { firstName: record.clientFirstName, lastName: record.clientLastName },
            record.clientAvatarUrl
          ) ? (
            <>
              <CircleAvatar
                type={record.clientAvatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                imageUrl={record.clientAvatarUrl}
                firstName={record.clientFirstName}
                lastName={record.clientLastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record.clientFirstName,
                lastName: record.clientLastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      title: t('admin_manage_payment_table_label_invoice_date'),
      key: 'invoiceDate',
      dataIndex: 'invoiceDate',
      render: (_, record: IPayment) => {
        return record.invoiceDate ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span> {formattedTime(record.invoiceDate)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      title: t('admin_manage_payment_table_label_due_date'),
      key: 'dueDate',
      dataIndex: 'dueDate',
      render: (_, record: IPayment) => {
        return record.dueDate ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span> {formattedTime(record.dueDate)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      title: t('admin_manage_payment_table_label_invoice_open_on'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (_, record: IPayment) => {
        return record.createdAt ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span> {formattedTime(record.createdAt)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      key: 'payment',
      dataIndex: 'payment',
      title: t('admin_manage_payment_table_label_payment'),
      render: (_, record: IPayment) => (
        <div>
          {record.payment ? (
            <span>{formatCurrency(CurrencyEnum.USD, record.payment)}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      title: t('admin_manage_payment_table_label_status'),
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => {
        switch (record.status) {
          case PaymentStatusEnum.PAID:
            return <ButtonStatus width={84} color='sky500' label={record.status} />;

          case PaymentStatusEnum.PENDING:
            return <ButtonStatus width={84} color='orange400' label={record.status} />;

          default:
            return <span>{EMPTY_STRING}</span>;
        }
      },
    },
  ];
};

const ManagePayment = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [paymentResp, setPaymentResp] = useState<IPaymentResp>();
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };
  //#endregion Handle Function
  return (
    <div id='managePaymentContainer' className={cx('managePaymentContainer')}>
      <Toolbar title={t('admin_sidebar_label_manage_payments')} />

      <section className={cx('paymentContent')}>
        <div className={cx('statisticTable')}>
          <BaseTable columns={columns(t)} dataSource={paymentResp?.responses || []} />
        </div>

        <div className={cx('pagePagination')}>
          {paymentResp?.pagination && (
            <BasePagination
              totalPages={paymentResp?.pagination?.totalPages}
              totalItems={paymentResp?.pagination?.totalItems}
              onChange={handleChangePagination}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default ManagePayment;
