// Libs
import { TFunction } from 'i18next';
import * as yup from 'yup';
// Others

export const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      type: yup.string().required(t('create_purchase_required_purchase_type')),
      vendorId: yup.string().required(t('create_purchase_required_vendor')),
      products: yup
        .array()
        .of(
          yup.object().shape({
            productId: yup.string().nullable().optional(),
            productName: yup.string().nullable().optional(),
            jobId: yup.string().nullable().optional(),
            jobName: yup.string().nullable().optional(),
            quantity: yup.number().optional(),
            unitCost: yup.number().optional(),
            subTotal: yup.number().optional(),
          })
        )
        .required(t('create_purchase_required_item')),
      date: yup.string().optional(),
      orderNumber: yup.number().optional(),
      documents: yup.array().nullable(),
      notes: yup.string().optional(),
      total: yup.number().required(t('create_purchase_required_total')),
    })
    .required();
};
