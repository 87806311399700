// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import productApi from '~/apis/product';
import {
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_LIST_PRODUCT,
  UPDATE_PRODUCT,
} from '~/utils/constants/actionType';
import { IFormProductPayload, IQueryListProduct, IUpdateProduct } from '~/utils/interface/product';

export const getListProducts = createAsyncThunk(
  GET_LIST_PRODUCT,
  async (payload: IQueryListProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.getListProducts(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createProduct = createAsyncThunk(
  CREATE_PRODUCT,
  async (body: IFormProductPayload, { rejectWithValue }) => {
    try {
      const res = await productApi.createProduct(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  UPDATE_PRODUCT,
  async (payload: IUpdateProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.updateProduct(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  DELETE_PRODUCT,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await productApi.deleteProduct(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
