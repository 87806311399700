// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseModal, BaseSelect, BaseTextarea, FormInput, Loading } from '~/components';
// Context
// Others
import { useAppDispatch } from '~/redux/hooks';
import { createProduct, updateProduct } from '~/thunks/product/productThunk';
import { getListQuickBookItem } from '~/thunks/quickBook/quickBookThunk';
import { getListVendors } from '~/thunks/vendors/vendorsThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  ISO_DATE_TIME_SEPARATOR,
  PRODUCT_CATEGORY_OPTIONS,
  linkToQuickBookCustomerOptions,
} from '~/utils/constants/common';
import { ButtonTypeEnum, LinkToQuickBookCustomerEnum } from '~/utils/enum';
import { IBaseOption, IQueryBase, ISingleSelectOption } from '~/utils/interface/common';
import { IFormProductPayload, IProduct, IUpdateProduct } from '~/utils/interface/product';
import { IQuickBookItem } from '~/utils/interface/quickBook';
import { IVendor } from '~/utils/interface/vendors';
// Styles, images, icons
import styles from './FormProductModal.module.scss';

type Props = {
  productDetail?: IProduct | null;
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const productSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('admin_manage_products_validate_required_name')),
      description: yup.string().optional(),
      extendedDescription: yup.string().optional(),
      category: yup.string().required(t('admin_manage_products_validate_required_category')),
      sku: yup.string().required(t('admin_manage_products_validate_required_sku')),
      unitPrice: yup
        .number()
        .typeError(t('admin_manage_products_validate_number_unit_price'))
        .required(t('admin_manage_products_validate_required_unit_price')),
      unitCost: yup
        .number()
        .typeError(t('admin_manage_products_validate_number_unit_cost'))
        .required(t('admin_manage_products_validate_required_unit_cost')),
      vendorId: yup.string().required(t('admin_manage_products_validate_required_vendor')),
      unitMeasure: yup
        .string()
        .required(t('admin_manage_products_validate_required_unit_of_measure')),
      costCode: yup.string().required(t('admin_manage_products_validate_required_cost_code')),
      typeLinkQuickBook: yup.string().optional(),
      quickBookItemId: yup.string().optional(),
    })
    .required();
};

const FormProductModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, productDetail, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: IFormProductPayload = useMemo(() => {
    return {
      name: EMPTY_STRING,
      description: EMPTY_STRING,
      extendedDescription: EMPTY_STRING,
      category: EMPTY_STRING,
      unitPrice: DEFAULT_NUMBER_ZERO,
      costCode: EMPTY_STRING,
      unitMeasure: EMPTY_STRING,
      unitCost: DEFAULT_NUMBER_ZERO,
      sku: EMPTY_STRING,
      vendorId: EMPTY_STRING,
      quickBookItemId: EMPTY_STRING,
      typeLinkQuickBook: EMPTY_STRING,
      syncToken: EMPTY_STRING,
      invStartDate: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<IFormProductPayload>({
    resolver: yupResolver(productSchema(t)),
    defaultValues: defaultData,
  });

  const linkQuickBookType = watch('typeLinkQuickBook');
  const quickBookIdSelected = watch('quickBookItemId');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IFormProductPayload>(defaultData);
  const [paramObject] = useState<IQueryBase>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_OPTIONS_SELECT,
  });
  const [vendorOptions, setVendorOptions] = useState<IBaseOption[]>([]);
  const [quickBookItems, setQuickBookItems] = useState<IQuickBookItem[]>([]);
  const [quickBookOptions, setQuickBookOptions] = useState<IBaseOption[]>([]);
  const [loadingQuickBock, setIsLoadingQuickBook] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region useMemo
  const isEditMode = useMemo(
    () => (productDetail && Object.keys(productDetail)?.length > 0 ? true : false),
    [productDetail]
  );
  //#endregion useMemo

  //#region Implement Hook
  // ** Set data when current form is Edit mode **
  useEffect(() => {
    if (!productDetail) return;

    const productDetailPrepareData: IFormProductPayload = {
      category: productDetail.category || EMPTY_STRING,
      costCode: productDetail.costCode || EMPTY_STRING,
      name: productDetail.name || EMPTY_STRING,
      sku: productDetail.sku || EMPTY_STRING,
      unitCost: Number(productDetail.unitCost) || DEFAULT_NUMBER_ZERO,
      unitMeasure: productDetail.unitMeasure || EMPTY_STRING,
      unitPrice: Number(productDetail.unitPrice) || DEFAULT_NUMBER_ZERO,
      vendorId: String(productDetail?.vendor?.id),
      description: productDetail.description || EMPTY_STRING,
      extendedDescription: productDetail.extendedDescription || EMPTY_STRING,
    };

    setInitDataForm(productDetailPrepareData);
    reset(productDetailPrepareData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);

  useEffect(() => {
    if (!isOpen) return;
    fetchListVendor();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (linkQuickBookType !== LinkToQuickBookCustomerEnum.ADD_EXISTING) return;

    const body: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    fetchListQuickBookItems(body);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkQuickBookType]);

  // ** Clear form when select Link to QB is No or Add New **
  useEffect(() => {
    if (isEditMode || linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING || !isOpen) {
      return;
    }

    const dataForm: IFormProductPayload = {
      ...defaultData,
      typeLinkQuickBook: linkQuickBookType,
    };

    reset(dataForm);
  }, [linkQuickBookType, isEditMode, isOpen]);

  useEffect(() => {
    if (quickBookItems.length <= DEFAULT_NUMBER_ZERO || !quickBookIdSelected) return;

    const currentQuickBock = quickBookItems.find(
      (quickBookItem: IQuickBookItem) => quickBookItem.id === quickBookIdSelected
    );
    if (!currentQuickBock) return;
    const { description, extendedDescription, name, sku, unitCost, unitPrice, syncToken } =
      currentQuickBock;
    const currentDate = new Date().toISOString().split(ISO_DATE_TIME_SEPARATOR)[
      DEFAULT_NUMBER_ZERO
    ];

    setValue('name', name ?? EMPTY_STRING, { shouldDirty: true });
    setValue('description', description ?? EMPTY_STRING, { shouldDirty: true });
    setValue('extendedDescription', extendedDescription ?? EMPTY_STRING, { shouldDirty: true });
    setValue('sku', sku ?? EMPTY_STRING, { shouldDirty: true });
    setValue('unitCost', +(unitCost || DEFAULT_NUMBER_ZERO), { shouldDirty: true });
    setValue('unitPrice', +(unitPrice || DEFAULT_NUMBER_ZERO), { shouldDirty: true });
    setValue('syncToken', syncToken || EMPTY_STRING, { shouldDirty: true });
    setValue('invStartDate', currentDate, { shouldDirty: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickBookItems, quickBookIdSelected]);

  useEffect(() => {
    if (!productDetail || !productDetail.quickBookItemId) return;

    const currentDate = new Date().toISOString().split(ISO_DATE_TIME_SEPARATOR)[
      DEFAULT_NUMBER_ZERO
    ];
    setValue('invStartDate', currentDate, { shouldDirty: true });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCleanForm = () => {
    reset(defaultData);
  };

  const handleCloseModal = () => {
    handleCleanForm();
    onClose && onClose();
    setIsLoading(false);
  };

  const fetchListQuickBookItems = (body: IQueryBase) => {
    setIsLoadingQuickBook(true);
    dispatch(getListQuickBookItem(body))
      .unwrap()
      .then((res) => {
        if (!res.data) return;
        const { responses } = res.data;
        const quickBookOptionList: IBaseOption[] = responses.map(
          (quickBookItem: IQuickBookItem) => ({
            label: quickBookItem.name ?? EMPTY_STRING,
            value: quickBookItem.id,
          })
        );

        setQuickBookItems(responses);
        setQuickBookOptions(quickBookOptionList);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingQuickBook(false);
      });
  };

  const fetchListVendor = () => {
    dispatch(getListVendors(paramObject))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;
        const vendorOptionList: IBaseOption[] = responses.map((vendorOption: IVendor) => ({
          label: vendorOption.name ?? EMPTY_STRING,
          value: vendorOption?.id.toString(),
        }));

        setVendorOptions(vendorOptionList);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleChangeSelect = (option: ISingleSelectOption, name: keyof IFormProductPayload) => {
    setValue(name, option.value, { shouldDirty: true });
    trigger(name);
  };

  const handlePreparedData = (data: IFormProductPayload) => {
    if (!data) return {} as IFormProductPayload;

    const formattedData: IFormProductPayload = { ...data };
    const bodyData: Partial<IFormProductPayload> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormProductPayload] = value;
      }
    });

    return bodyData as IFormProductPayload;
  };

  const getChangedValues = (
    originalValues: IFormProductPayload,
    newValues: IFormProductPayload
  ) => {
    const changes: Partial<IFormProductPayload> = {};

    Object.entries(newValues).forEach(([key, newValue]) => {
      const typedKey = key as keyof IFormProductPayload;
      const originalValue = originalValues[typedKey];

      if (newValue !== originalValue) {
        changes[typedKey] = newValue;
      }
    });

    return changes as IFormProductPayload;
  };

  const handleAddProduct = (data: IFormProductPayload) => {
    const bodyData = handlePreparedData(data);

    setIsLoading(true);
    dispatch(createProduct(bodyData))
      .unwrap()
      .then(() => {
        onSuccess && onSuccess();
        handleCloseModal();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateProduct = (data: IFormProductPayload) => {
    if (!productDetail) return;

    const dataChanges = getChangedValues(initDataForm, data);

    const payload: IUpdateProduct = {
      productId: productDetail.id,
      body: dataChanges,
    };

    setIsLoading(true);
    dispatch(updateProduct(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;
        onSuccess && onSuccess();
        handleCloseModal();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const disableSubmit = () => {
    if (productDetail) return !isDirty;

    if (!linkQuickBookType) return true;

    if (
      linkQuickBookType &&
      linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING &&
      !quickBookIdSelected
    )
      return true;

    return false;
  };
  //#endregion Handle Function

  return (
    <BaseModal height={'80vh'} id='modalFormProduct' isOpen={isOpen} onClose={handleCloseModal}>
      <form
        className={cx('formContent')}
        onSubmit={handleSubmit(productDetail ? handleUpdateProduct : handleAddProduct)}
      >
        <div className={cx('modalHeader')}>
          {productDetail
            ? t('admin_manage_products_update_label')
            : t('admin_manage_products_add_label')}
        </div>

        <div className={cx('line')} />

        <div className={cx('modalBody')}>
          {!productDetail && (
            <div className={cx('inputContent')}>
              <Controller
                name='typeLinkQuickBook'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseSelect
                    label={t('admin_manage_products_link_to_quick_book_customer_label')}
                    placeholder={t('common_placeholder_select')}
                    value={value}
                    options={linkToQuickBookCustomerOptions || []}
                    onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                    errorMessage={errors.typeLinkQuickBook?.message}
                  />
                )}
              />

              {linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && (
                <Controller
                  name='quickBookItemId'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <BaseSelect
                      label={t('admin_manage_products_choose_quick_book_items_label')}
                      placeholder={
                        loadingQuickBock ? t('common_text_loading') : t('common_placeholder_select')
                      }
                      value={value}
                      options={quickBookOptions}
                      onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                      errorMessage={errors.quickBookItemId?.message}
                    />
                  )}
                />
              )}
            </div>
          )}

          {(productDetail ||
            linkQuickBookType === LinkToQuickBookCustomerEnum.NO ||
            linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_NEW ||
            (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING &&
              quickBookIdSelected)) && (
            <>
              <Controller
                name='name'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_products_add_name_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    required
                    errorMessage={errors.name?.message}
                  />
                )}
              />

              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_products_add_description_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name='extendedDescription'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <BaseTextarea
                    label={t('admin_manage_products_add_extended_description_label')}
                    value={value || EMPTY_STRING}
                    height={120}
                    maxLength={100}
                    onTextareaChange={onChange}
                  />
                )}
              />

              <Controller
                name='unitMeasure'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    required
                    label={t('admin_manage_products_add_unit_of_measure_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    errorMessage={errors.unitMeasure?.message}
                  />
                )}
              />

              <div className={cx('inputContent')}>
                <Controller
                  name='sku'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_products_add_sku_label')}
                      value={value || EMPTY_STRING}
                      required
                      onChange={onChange}
                      errorMessage={errors.sku?.message}
                    />
                  )}
                />

                <Controller
                  name='costCode'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      required
                      label={t('admin_manage_products_add_cost_code_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.costCode?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('inputContent')}>
                <Controller
                  name='unitPrice'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      label={t('admin_manage_products_add_unit_price_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      required
                      errorMessage={errors.unitPrice?.message}
                    />
                  )}
                />

                <Controller
                  name='unitCost'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormInput
                      required
                      label={t('admin_manage_products_add_unit_cost_label')}
                      value={value || EMPTY_STRING}
                      onChange={onChange}
                      errorMessage={errors.unitCost?.message}
                    />
                  )}
                />
              </div>

              <div className={cx('inputContent')}>
                <Controller
                  name='vendorId'
                  control={control}
                  render={({ field: { value, name } }) => (
                    <BaseSelect
                      label={t('admin_manage_products_add_vendor_label')}
                      placeholder={t('common_placeholder_select')}
                      value={value}
                      options={vendorOptions}
                      isRequired
                      onChange={(optionSelected: IBaseOption) =>
                        handleChangeSelect(optionSelected, name)
                      }
                      errorMessage={errors.vendorId?.message}
                    />
                  )}
                />

                <Controller
                  name='category'
                  control={control}
                  render={({ field: { value, name } }) => (
                    <BaseSelect
                      label={t('admin_manage_products_add_category_label')}
                      placeholder={t('common_placeholder_select')}
                      value={value}
                      options={PRODUCT_CATEGORY_OPTIONS}
                      onChange={(optionSelected: IBaseOption) =>
                        handleChangeSelect(optionSelected, name)
                      }
                      isRequired
                      errorMessage={errors.category?.message}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>

        <div className={cx('modalFooter')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleCloseModal}
            />
          </div>

          <BaseButton
            label={productDetail ? t('common_btn_update') : t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
            isDisable={disableSubmit()}
          />
        </div>

        {isLoading && <Loading />}
      </form>
    </BaseModal>
  );
};

export default FormProductModal;
