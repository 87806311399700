// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  createFixtureLocation,
  deleteFixtureLocation,
  getListFixtureLocations,
  updateFixtureLocation,
} from './fixtureLocationThunk';

export interface FixtureLocationState {
  isRefreshFixtureLocationsList: boolean;
}

const initialState: FixtureLocationState = {
  isRefreshFixtureLocationsList: false,
};

const fixtureLocationSlice = createSlice({
  name: 'fixtureLocation',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshFixtureLocationsList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list fixture locations
    builder
      .addCase(getListFixtureLocations.pending, (state) => {})
      .addCase(getListFixtureLocations.fulfilled, (state) => {})
      .addCase(getListFixtureLocations.rejected, (state) => {});

    // Create fixture location
    builder
      .addCase(createFixtureLocation.pending, (state) => {})
      .addCase(createFixtureLocation.fulfilled, (state) => {})
      .addCase(createFixtureLocation.rejected, (state) => {});

    // Update fixture location
    builder
      .addCase(updateFixtureLocation.pending, (state) => {})
      .addCase(updateFixtureLocation.fulfilled, (state) => {})
      .addCase(updateFixtureLocation.rejected, (state) => {});

    // Delete fixture location
    builder
      .addCase(deleteFixtureLocation.pending, (state) => {})
      .addCase(deleteFixtureLocation.fulfilled, (state) => {})
      .addCase(deleteFixtureLocation.rejected, (state) => {});
  },
});

// Action
export const fixtureLocationActions = fixtureLocationSlice.actions;

// Selectors
export const selectIsRefreshFixtureLocationsList = (state: RootState) =>
  state.fixtureLocation.isRefreshFixtureLocationsList;

// Reducer
const fixtureLocationReducer = fixtureLocationSlice.reducer;
export default fixtureLocationReducer;
