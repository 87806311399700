// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseImageCircle, BasePagination, BaseTable, Status } from '~/components';
// Others
import { mockDataPurchaseOrder } from '~/mockData';
import { ColumnType, IPagination, IQueryBase } from '~/utils/interface/common';
import { IPurchaseOrder } from '~/utils/interface/order';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BaseTableEnum, CurrencyEnum } from '~/utils/enum';
import { formatCurrency, getAvatarWithName, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './PurchaseOrder.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction): ColumnType<IPurchaseOrder>[] => {
  return [
    {
      key: 'id',
      title: t('order_table_label_po'),
      dataIndex: 'id',
      render: (_, record) => <span>{record?.id || EMPTY_STRING}</span>,
    },
    {
      key: 'purchaseOrderBy',
      title: t('order_table_label_po_by'),
      dataIndex: 'purchaseOrderBy',
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName({ ...record.purchaseOrderBy }, record?.purchaseOrderBy.thumbnail) ? (
            <>
              <BaseImageCircle
                url={record?.purchaseOrderBy.thumbnail ?? record?.purchaseOrderBy.avatar}
                firstText={record?.purchaseOrderBy.firstName}
                secondText={record?.purchaseOrderBy.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({ ...record.purchaseOrderBy })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'quantity',
      title: t('order_table_label_qty'),
      dataIndex: 'quantity',
      render: (_, record) => <span>{record?.quantity || EMPTY_STRING}</span>,
    },
    {
      key: 'description',
      title: t('order_table_label_description'),
      dataIndex: 'description',
      render: (_, record) => <span>{record?.description || EMPTY_STRING}</span>,
    },
    {
      key: 'item',
      title: t('order_table_label_item'),
      dataIndex: 'item',
      render: (_, record) => <span>{record?.item || EMPTY_STRING}</span>,
    },
    {
      key: 'price',
      title: t('order_table_label_price'),
      dataIndex: 'price',
      render: (_, record) => <span>{formatCurrency(CurrencyEnum.USD, record?.price)}</span>,
    },
    {
      key: 'subTotal',
      title: t('order_table_label_sub_total'),
      dataIndex: 'subTotal',
      render: (_, record) => <span>{formatCurrency(CurrencyEnum.USD, record?.subTotal)}</span>,
    },
    {
      key: 'status',
      title: t('order_table_label_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record.status} />,
    },
  ];
};

const PurchaseOrder = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder[]>([]);
  // TODO: Mock data purchase order: const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder[]>(mockDataPurchaseOrder || []);
  const [pagination, setPagination] = useState<IPagination>();

  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };
  //#endregion Handle Function

  return (
    <div id='purchaseOrderTab' className={cx('container')}>
      <div className={cx('table')}>
        <BaseTable
          typeStyle={BaseTableEnum.COLOR_TABLE}
          columns={columns(t)}
          dataSource={purchaseOrder || []}
        />
      </div>

      <div className={cx('pagination')}>
        <BasePagination
          defaultCurrentPage={pageSelected}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalItems}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default PurchaseOrder;
