// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { ITableParams } from '~/utils/interface/common';
import { CREATE_NEW_NOTE, GET_LIST_NOTE, GET_NOTE_DETAIL } from '~/utils/constants/actionType';
import noteApi from '~/apis/note';

export const getListNote = createAsyncThunk(
  GET_LIST_NOTE,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await noteApi.getListNote(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNoteDetail = createAsyncThunk(
  GET_NOTE_DETAIL,
  async (payload: string, { rejectWithValue }) => {
    try {
      const res = await noteApi.getNoteDetail(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewNote = createAsyncThunk(
  CREATE_NEW_NOTE,
  async(payload: any, {rejectWithValue})=>{
    try {
      const res = await noteApi.createNewNote(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)