// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './Transactions.module.scss';

const cx = classNames.bind(styles);

const Transactions = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleSearch = (value: string) => {
    // TODO - HuyPahmGRF-ADB: Handle search
  };

  //#endregion Handle Function

  return (
    <div id='transactionsPage' className={cx('container')}>
      <Toolbar title={t('admin_sidebar_label_transactions')} onSearch={handleSearch} />

      <section className={cx('transactionsContent')}>
        <div className=''>{t('common_empty_data')}</div>
      </section>
    </div>
  );
};

export default Transactions;
