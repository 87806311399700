// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './AdminViewLocationModal.module.scss';
import { icons } from '~/assets';

type Props = { onClose: () => void };

const cx = classNames.bind(styles);

const AdminViewLocationModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='adminViewLocationModal' className={cx('adminViewLocationModal')}>
      <div className={cx('containerViewLocationModal')}>
        <img
          src={icons.adminIconCloseViewLocation}
          alt={t('common_img_text_alt')}
          className={cx('iconClosingViewLocationModal')}
          onClick={onClose}
        />
        <div className={cx('viewMapLocation')}>
          {/* View map location */}
        </div>
      </div>
    </div>
  );
};

export default AdminViewLocationModal;
