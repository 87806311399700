// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { AdminAddContractJobModal, BasePagination, Status, Table, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { getListJob } from '~/thunks/job/jobThunk';
import { RootState } from '~/redux/store';
import { jobActions } from '~/thunks/job/jobSlice';
import { ColumnType, IPagination } from '~/utils/interface/common';
import { IGetListJobReq } from '~/utils/interface/job';
import { IContractJob } from '~/utils/interface/contractJob';
import useDebounce from '~/utils/hooks/useDebounce';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, GetListJobTypeEnum, StorageEnum } from '~/utils/enum';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { formatNumber, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './ContractJobs.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetails: (id: string) => void,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void
): ColumnType<IContractJob>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={handleSelectAll}
    //     />
    //   ),
    //   render(_, record: IContractJob) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'jobName',
      title: t('admin_manage_contract_jobs_table_job_name'),
      dataIndex: 'jobName',
      render: (_, record) => <span>{record?.jobName || EMPTY_STRING}</span>,
    },
    {
      key: 'client',
      title: t('admin_manage_contract_jobs_table_client'),
      dataIndex: 'client',
      render: (_, record) => (
        <span>
          {getFullName({
            firstName: record?.client?.firstName,
            lastName: record?.client?.lastName,
          })}
        </span>
      ),
      width: '16%',
    },
    {
      key: 'phaseLeft',
      title: t('admin_manage_contract_jobs_table_phase_left'),
      dataIndex: 'phaseLeft',
      render: (_, record) => (
        <span>{record?.phaseLeft ? `${record?.phaseLeft}` : EMPTY_STRING}</span>
      ),
    },
    {
      key: 'status',
      title: t('admin_manage_contract_jobs_table_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record?.status} />,
    },
    {
      key: 'profit',
      title: t('admin_manage_contract_jobs_table_profit'),
      dataIndex: 'profit',
      render: (_, record) => (
        <span>
          {record?.profit ? t('common_percent_value', { value: record?.profit }) : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_contract_jobs_table_action'),
      render: (_, record) => (
        <span className={cx('tableLink')} onClick={() => onViewDetails(record?.id)}>
          {t('common_action_view')}
        </span>
      ),
      width: '10%',
    },
  ];
};

const ManageContractJobs = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshJobList } = useAppSelector((state: RootState) => state.job);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IGetListJobReq>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    type: GetListJobTypeEnum.CONTRACT,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowAddContractJob, setIsShowAddContractJob] = useState<boolean>(false);
  const [contractJobsList, setContractJobsList] = useState<IContractJob[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isRefreshJobList) return;
    handleGetListContractJob(paramObject);
    dispatch(jobActions.setRefreshList(false));
  }, [isRefreshJobList]);

  useEffect(() => {
    handleGetListContractJob(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (contractJobsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = contractJobsList.every(
      (contractJob: IContractJob) => contractJob.isSelected
    );
    setIsSelectAll(allSelected);

    const selectedIds = contractJobsList
      .filter((contractJob: IContractJob) => contractJob.isSelected)
      .map((contractJob: IContractJob) => contractJob.id);

    //@Todo: Perform interactions with the contract job id list
    console.log('Selected Contract Job IDs:', selectedIds);
  }, [contractJobsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListContractJob = (payload: IGetListJobReq) => {
    loadingData?.show();

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setContractJobsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddContractJob = () => {
    setIsShowAddContractJob(true);
  };

  const handleCloseAddContractJob = () => {
    setIsShowAddContractJob(false);
  };

  const handleClickTable = (id: string) => {
    // TODO: Handle logic
  };

  const handleViewDetails = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);
    if (role === AccountRoleCodesEnum.ADMIN) {
      navigate(`${adminRouteAbsolute.contractJobs}/${id}`);
      return;
    }

    navigate(`${staffRouteAbsolute.contractJobs}/${id}`);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IGetListJobReq = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSelectItem = (id: string) => {
    setContractJobsList((prevList: IContractJob[]) =>
      prevList.map((contractJob: IContractJob) =>
        contractJob.id === id
          ? { ...contractJob, isSelected: !contractJob.isSelected }
          : contractJob
      )
    );
  };

  const handleSelectAll = () => {
    setContractJobsList((prevList) =>
      prevList.map((contractJob: IContractJob) => ({
        ...contractJob,
        isSelected: !isSelectAll,
      }))
    );
  };

  const handleSearchContractJob = (value: string) => {
    setSearchKey(value);
  };
  //#endregion Handle Function

  return (
    <div id='manageContractJobsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_contract_jobs_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleAddContractJob,
          label: t('admin_manage_contract_jobs_btn_add'),
        }}
        onSearch={handleSearchContractJob}
      />

      <div className={cx('tableSection')}>
        <div className={cx('actions')}></div>

        <div className={cx('table')}>
          <Table
            columns={columns(t, handleViewDetails, isSelectAll, handleSelectAll, handleSelectItem)}
            dataSource={contractJobsList || []}
            onClickRow={handleClickTable}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowAddContractJob && <AdminAddContractJobModal onClose={handleCloseAddContractJob} />}
    </div>
  );
};

export default ManageContractJobs;
