// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseTextarea, FormInput, Loading } from '~/components';
// Context
// Others
import { useAppDispatch } from '~/redux/hooks';
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { IFormServicePayload, IService, IUpdateService } from '~/utils/interface/service';
import { createService, updateService } from '~/thunks/service/serviceThunk';
// Styles, images, icons
import styles from './FormServiceModal.module.scss';

type Props = {
  serviceDetail?: IService;
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const serviceSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('admin_manage_services_validate_required_name')),
      description: yup.string().optional(),
      price: yup
        .number()
        .typeError(t('admin_manage_services_validate_number_price'))
        .required(t('admin_manage_services_validate_required_price')),
      workScope: yup.string().required(t('admin_manage_services_validate_required_scope_of_work')),
    })
    .required();
};

const FormServiceModal = (props: Props) => {
  //#region Destructuring Props
  const { serviceDetail, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: IFormServicePayload = useMemo(() => {
    return {
      name: EMPTY_STRING,
      description: EMPTY_STRING,
      workScope: EMPTY_STRING,
      price: DEFAULT_NUMBER_ZERO,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<IFormServicePayload>({
    resolver: yupResolver(serviceSchema(t)),
    defaultValues: defaultData,
    mode: 'onChange',
  });

  const price = watch('price');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IFormServicePayload>(defaultData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!serviceDetail) return;

    const serviceDetailPrepareData: IFormServicePayload = {
      name: serviceDetail.name || EMPTY_STRING,
      description: serviceDetail.description || EMPTY_STRING,
      price: Number(serviceDetail.price) || DEFAULT_NUMBER_ZERO,
      workScope: serviceDetail.workScope || EMPTY_STRING,
    };

    setInitDataForm(serviceDetailPrepareData);
    reset(serviceDetailPrepareData);
  }, [serviceDetail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };

  const handlePreparedData = (data: IFormServicePayload) => {
    if (!data) return {} as IFormServicePayload;

    const formattedData: IFormServicePayload = { ...data };
    const bodyData: Partial<IFormServicePayload> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IFormServicePayload] = value;
      }
    });

    return bodyData as IFormServicePayload;
  };

  const handleAddService = (data: IFormServicePayload) => {
    const bodyData = handlePreparedData(data);

    setIsLoading(true);
    dispatch(createService(bodyData))
      .unwrap()
      .then(() => {
        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getChangedValues = (
    originalValues: IFormServicePayload,
    newValues: IFormServicePayload
  ) => {
    const changes: Partial<IFormServicePayload> = {};

    Object.entries(newValues).forEach(([key, newValue]) => {
      const typedKey = key as keyof IFormServicePayload;
      const originalValue = originalValues[typedKey];

      if (newValue !== originalValue) {
        changes[typedKey] = newValue;
      }
    });

    return changes as IFormServicePayload;
  };

  const handleUpdateService = (data: IFormServicePayload) => {
    if (!serviceDetail) return;

    const dataChanges = getChangedValues(initDataForm, data);

    const payload: IUpdateService = {
      serviceId: serviceDetail.id,
      body: dataChanges,
    };

    setIsLoading(true);
    dispatch(updateService(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;

        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='modalFormService' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}
        <form
          className={cx('modalContent')}
          onSubmit={handleSubmit(serviceDetail ? handleUpdateService : handleAddService)}
        >
          <div className={cx('modalHeader')}>
            {serviceDetail
              ? t('admin_manage_services_update_label')
              : t('admin_manage_services_add_label')}
          </div>

          <div className={cx('line')} />

          <div className={cx('modalBody')}>
            <Controller
              name='name'
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  label={t('admin_manage_services_add_name_label')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  required
                  errorMessage={errors.name?.message}
                />
              )}
            />

            <Controller
              name='description'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseTextarea
                  label={t('admin_manage_services_add_description_label')}
                  value={value || EMPTY_STRING}
                  height={120}
                  maxLength={100}
                  onTextareaChange={onChange}
                />
              )}
            />

            <div className={cx('inputContent')}>
              <Controller
                name='workScope'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_services_add_scope_of_work_label')}
                    value={value || EMPTY_STRING}
                    required
                    onChange={onChange}
                    errorMessage={errors.workScope?.message}
                  />
                )}
              />

              <Controller
                name='price'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_services_add_price_label')}
                    value={value || EMPTY_STRING}
                    onChange={onChange}
                    required
                    errorMessage={errors.price?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('modalFooter')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancelModal}
              />
            </div>
            <BaseButton
              label={serviceDetail ? t('common_btn_update') : t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
              isDisable={!isValid || !isDirty || price === DEFAULT_NUMBER_ZERO}
            />
          </div>
        </form>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default FormServiceModal;
