// Libs
import classNames from 'classnames/bind';
import ReactApexChart from 'react-apexcharts';
// Components, Layouts, Pages
// Others
import { IDonutChartColors, IDonutChartSeries } from '~/utils/interface/common';
import { configChartDonut } from '~/utils/constants/configChart';
import { PERCENT } from '~/utils/constants/common';
// Styles, images, icons
import styles from './DonutChart.module.scss';

type Props = {
  title: string;
  labels: string[];
  colors: IDonutChartColors[];
  series: IDonutChartSeries;
  height?: number | string;
  isShowLabel: boolean;
};

const cx = classNames.bind(styles);

const DonutChart = (props: Props) => {
  //#region Destructuring Props
  const { title, labels, colors, series, height = 200, isShowLabel } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='donutChartComponent' className={cx('donutChartComponent')}>
      <div className={cx('headerChart')}>
        {title && <div className={cx('headTitle')}>{title}</div>}
      </div>
      <div className={cx('bodyChartContainer')}>
        <div className={cx('bodyChart')}>
          <ReactApexChart
            options={configChartDonut({
              isShowLabel: isShowLabel,
              series: series.series,
              labels: labels,
              colors: colors.map((color) => color.value),
            })}
            series={series.series}
            type='donut'
            height={height}
          />
        </div>
        <div className={cx('footerChart')}>
          {labels.map((labelItem, index) => (
            <div key={index} className={cx('legendItem')}>
              <div className={cx('itemWrap')}>
                <div className={cx('dotWrap', `${colors[index].name}`)}>
                  <div className={cx('dot')} />
                </div>
                <div className={cx('label')}>{labelItem}</div>
              </div>

              <div className={cx('percentLabel')}>{`${series.seriesPercent[index]}${PERCENT}`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
