// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseModal, BaseSelect } from '~/components';
// Others
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
  EMPTY_VALUE,
} from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { IEstimateTemplate } from '~/utils/interface/estimate';
import { useAppDispatch } from '~/redux/hooks';
import { getEstimateTemplateList } from '~/thunks/estimate/estimateThunk';
import { IBaseOption, ITableParams } from '~/utils/interface/common';
// Styles, images, icons
import styles from './SelectEstimateTemplateModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onApply?: (idEstimate: string) => void;
};

const cx = classNames.bind(styles);

const SelectEstimateTemplateModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onApply } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [disableButtonApply, setDisableButtonApply] = useState<boolean>(false);
  const [estimateTemplateOptions, setEstimateTemplateOptions] = useState<IBaseOption[]>([]);
  const [estimateTemplateList, setEstimateTemplateList] = useState<IEstimateTemplate[]>([]);
  const [idEstimateTemplate, setIdEstimateTemplate] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isOpen) return;
    const params: ITableParams = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    handleGetEstimateTemplateList(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetEstimateTemplateList = (data: ITableParams) => {
    dispatch(getEstimateTemplateList(data))
      .unwrap()
      .then((res) => {
        if (!res.data.responses) return;
        setEstimateTemplateList(res.data.responses);

        const options: IBaseOption[] = res?.data?.responses.map((estimate) => ({
          value: estimate.id,
          label: estimate?.name || EMPTY_VALUE,
        }));

        setEstimateTemplateOptions(options);
      })
      .catch((error) => {});
  };

  const handleClose = () => {
    setDisableButtonApply(false);
    onClose && onClose();
  };

  const handleApply = () => {
    setDisableButtonApply(false);

    onApply && onApply(idEstimateTemplate);
  };

  const handleOnChange = (option: IBaseOption) => {
    if (!option.value) {
      return;
    }

    setDisableButtonApply(true);
    setIdEstimateTemplate(option.value);
  };
  //#endregion Handle Function

  return (
    <BaseModal id='selectEstimateTemplateModal' isOpen={isOpen} onClose={handleClose}>
      <form className={cx('formContent')}>
        <div className={cx('modalHeader')}>
          {t('template_contract_jobs_estimate_use_template_title')}
        </div>
        <div className={cx('line')} />

        <div className={cx('modalBody')}>
          <BaseSelect
            label={t('template_contract_jobs_estimate_use_template_label_field')}
            placeholder={t('common_placeholder_select')}
            options={estimateTemplateOptions}
            onChange={handleOnChange}
          />
        </div>

        <div className={cx('modalFooter')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            type='button'
            onClick={handleClose}
          />

          <BaseButton
            label={t('common_btn_apply')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='button'
            onClick={handleApply}
            isDisable={!disableButtonApply}
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default SelectEstimateTemplateModal;
