// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '~/redux/hooks';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

// Components, Layouts, Pages
import {
  FormFixtureLocationModal,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  ThreeDotOptions,
  Toolbar,
  ConfirmModal,
} from '~/components';

// Others
import { LoadingData } from '~/context';
import { BaseTableEnum } from '~/utils/enum';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ColumnType, IPagination, ITableParams } from '~/utils/interface/common';
import { IFixtureLocation, IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import {
  deleteFixtureLocation,
  getListFixtureLocations,
} from '~/thunks/fixtureLocation/fixtureLocationThunk';
import {
  fixtureLocationActions,
  selectIsRefreshFixtureLocationsList,
} from '~/thunks/fixtureLocation/fixtureLocationSlice';

// Styles, images, icons
import { icons } from '~/assets';
import styles from './FixtureLocation.module.scss';
import { formatNumber } from '~/utils/helper';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  handleShowEditFixtureLocationModal: (fixtureLocationDetail: IFixtureLocation) => void,
  handleShowConfirmDeleteModal: (id: string) => void
): ColumnType<IFixtureLocation>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => handleSelectAll()}
    //     />
    //   ),
    //   render(_, record: IFixtureLocation) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'name',
      title: t('admin_manage_fixture_locations_table_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
      width: '85%',
    },
    {
      key: 'action',
      title: t('admin_manage_fixture_locations_table_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onEdit={() => handleShowEditFixtureLocationModal(record)}
          onDelete={() => handleShowConfirmDeleteModal(record.id)}
        />
      ),
      width: '10%',
    },
  ];
};

const ManageFixtureLocations = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const isRefreshFixtureLocationsList: boolean = useSelector(selectIsRefreshFixtureLocationsList);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryListFixtureLocations>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [fixtureLocationsList, setFixtureLocationsList] = useState<IFixtureLocation[]>([]);
  const [fixtureLocationId, setFixtureLocationId] = useState<string>(EMPTY_STRING);
  const [fixtureLocationDetail, setFixtureLocationDetail] = useState<IFixtureLocation>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isShowFormAdd, setIsShowFormAdd] = useState<boolean>(false);
  const [isShowFormUpdate, setIsShowFormUpdate] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListFixtureLocations(paramObject);
  }, [params?.page, params?.limit]);

  useEffect(() => {
    if (!isRefreshFixtureLocationsList) return;
    handleGetListFixtureLocations(paramObject);
    dispatch(fixtureLocationActions.setRefreshList(false));
  }, [isRefreshFixtureLocationsList]);

  useEffect(() => {
    if (fixtureLocationsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = fixtureLocationsList.every(
      (fixtureLocation: IFixtureLocation) => fixtureLocation.isSelected
    );
    setIsSelectAll(allSelected);

    const selectedIds = fixtureLocationsList
      .filter((fixtureLocation: IFixtureLocation) => fixtureLocation.isSelected)
      .map((fixtureLocation: IFixtureLocation) => fixtureLocation.id);

    //@Todo: Perform interactions with the employee id list
  }, [fixtureLocationsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListFixtureLocations = (payload: ITableParams) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListFixtureLocations(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setFixtureLocationsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDeleteFixtureLocation = () => {
    if (!fixtureLocationId) return;

    loadingData?.show();
    dispatch(deleteFixtureLocation(fixtureLocationId))
      .unwrap()
      .then((res) => {
        handleActionFixtureLocationSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowAddFixtureLocation = () => {
    setIsShowFormAdd(!isShowFormAdd);
  };

  const handleShowUpdateFixtureLocation = (fixtureLocationDetail: IFixtureLocation) => {
    setFixtureLocationDetail(fixtureLocationDetail);
    handleToggleUpdateFixtureLocation();
  };

  const handleToggleUpdateFixtureLocation = () => {
    setIsShowFormUpdate(!isShowFormUpdate);
  };

  const handleShowConfirmDeleteModal = (id?: string) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (id) {
      setFixtureLocationId(id);
    }
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryListFixtureLocations = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleActionFixtureLocationSuccess = () => {
    dispatch(fixtureLocationActions.setRefreshList(true));
  };

  const handleSelectItem = (id: string) => {
    setFixtureLocationsList((prevList: IFixtureLocation[]) =>
      prevList.map((fixtureLocation: IFixtureLocation) =>
        fixtureLocation.id === id
          ? { ...fixtureLocation, isSelected: !fixtureLocation.isSelected }
          : fixtureLocation
      )
    );
  };

  const handleSelectAll = () => {
    setFixtureLocationsList((prevList) =>
      prevList.map((fixtureLocation: IFixtureLocation) => ({
        ...fixtureLocation,
        isSelected: !isSelectAll,
      }))
    );
  };
  //#endregion Handle Function

  return (
    <div id='manageFixtureLocationsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_fixture_locations_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleShowAddFixtureLocation,
          label: t('admin_manage_fixture_locations_btn_add_fixture_location'),
        }}
      />

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleShowUpdateFixtureLocation,
              handleShowConfirmDeleteModal
            )}
            dataSource={fixtureLocationsList || []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowFormAdd && (
        <FormFixtureLocationModal
          onClose={handleShowAddFixtureLocation}
          onSuccess={handleActionFixtureLocationSuccess}
        />
      )}

      {isShowFormUpdate && fixtureLocationDetail && (
        <FormFixtureLocationModal
          fixtureLocationDetail={fixtureLocationDetail}
          onClose={handleToggleUpdateFixtureLocation}
          onSuccess={handleActionFixtureLocationSuccess}
        />
      )}

      {isShowConfirmDelete && fixtureLocationId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteFixtureLocation}
        />
      )}

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />
      )}
    </div>
  );
};

export default ManageFixtureLocations;
