import axiosClient from '~/apis/axiosClient';
import { urlSchedule } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IParamsGetSchedule,
  IScheduleEvent,
  IScheduleBody,
  IScheduleTimelineData,
} from '~/utils/interface/schedule';

const scheduleApi = {
  getSchedule(params: IParamsGetSchedule) {
    const url = `${urlSchedule.getSchedule}`;
    return axiosClient.get<
      BaseResponse<IScheduleEvent[] | IListDataResponse<IScheduleEvent[] | IScheduleTimelineData[]>>
    >(url, { params });
  },

  createSchedule(body: IScheduleBody) {
    const url = `${urlSchedule.createSchedule}`;
    return axiosClient.post(url, body);
  },

  deleteSchedule(id: string) {
    const url = `${urlSchedule.deleteSchedule}/${id}`;
    return axiosClient.delete(url);
  },
};

export default scheduleApi;
