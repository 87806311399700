// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { DropdownProfile, MessageNotifications, ReceivedNotification } from '~/components';
// Others
import { IUserProfile } from '~/utils/interface/profile';
// Styles, images, icons
import styles from './Header.module.scss';

type Props = {
  userInfo: IUserProfile;
};

const cx = classNames.bind(styles);

const Header = (props: Props) => {
  //#region Destructuring Props
  const { userInfo } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <div id='headerMainLayout' className={cx('headerContainer')}>
      <div className={cx('headerRight')}>
        <div className={cx('headerIconGroup')}>
          <ReceivedNotification />
        </div>

        <div className={cx('line')}></div>

        <DropdownProfile data={userInfo} />
      </div>
    </div>
  );
};

export default Header;
