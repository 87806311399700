// Libs
import classNames from 'classnames/bind';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { IEstimateContent } from '~/utils/interface/estimate';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldUnassignedEst.module.scss';

type Props = {
  phaseIndex: number;
  fieldIndex: number;
  removeField?: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldUnassignedEst = (props: Props) => {
  //#region Destructuring Props
  const { phaseIndex, fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext<IEstimateContent>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldUnassignedEstComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className={cx('colItem')}>
          <Controller
            control={control}
            name={`phases.${phaseIndex}.subcontractor.unassignedWorks.${fieldIndex}.name`}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('template_field_unassigned_est_label_item')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>

        <div className={cx('colValue')}>
          <Controller
            control={control}
            name={`phases.${phaseIndex}.subcontractor.unassignedWorks.${fieldIndex}.value`}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('template_field_unassigned_est_label_value')}
                value={value || EMPTY_STRING}
                onChange={onChange}
                errorMessage={
                  errors?.phases?.[phaseIndex]?.subcontractor?.unassignedWorks?.[fieldIndex]?.value
                    ?.message
                }
              />
            )}
          />
        </div>
      </div>

      <div className={cx('btnRemoveContainer')}>
        <button
          className={cx('btnRemoveField')}
          type='button'
          onClick={() => removeField && removeField(fieldIndex)}
        >
          <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
        </button>
      </div>
    </div>
  );
};

export default FieldUnassignedEst;
