// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useRef, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseInput, QuillEditor, SingleSelect } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { ISingleSelectOption } from '~/utils/interface/common';
import { ICreateNotePayload } from '~/utils/interface/note';
import { EMPTY_STRING } from '~/utils/constants/common';
import { useAppDispatch } from '~/redux/hooks';
import { LoadingContext } from '~/context';
import { createNewNote } from '~/thunks/note/noteThunk';
import { mockJobSelectionData } from '~/mockData';
// Styles, images, icons
import styles from './AdminCreateNoteModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSuccess: () => void;
};

const cx = classNames.bind(styles);

const AdminCreateNoteModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const parentRef = useRef<HTMLDivElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const initialState: ICreateNotePayload = {
    name: '',
    content: '',
    jobId: undefined,
  };

  const [stateDataForm, setStateDataForm] = useState<ICreateNotePayload>(initialState);
  //#endregion Declare State

  //#region Implement Hook
  const handleCreateNote = () => {
    const payload: ICreateNotePayload = {
      name: stateDataForm.name,
      content: stateDataForm.content,
      jobId: stateDataForm.jobId,
    };

    loadingContext?.show();

    dispatch(createNewNote(payload))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        alert('Create new note success');
      })
      .catch((err) => {
        loadingContext?.hide();
        alert('Create new note failed');
      });

    onClose && onClose();

    onSuccess();
  };
  //#endregion Implement Hook

  //#region Handle Function
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateDataForm({ ...stateDataForm, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (value: string, name: string) => {
    setStateDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleQuillEditorChange = (name: string, newText: string) => {
    setStateDataForm({ ...stateDataForm, [name]: newText });
  };
  //#endregion Handle Function

  return (
    <div id='adminCreatNoteModal' className={cx('modalContainer')}>
      <>
        {isOpen && (
          <div className={cx('modalContent')}>
            <div className={cx('modalHead')}>
              <div className={cx('modalTitle')}>
                {t('admin_manage_notes_create_note_modal_title')}
              </div>
            </div>

            <div ref={parentRef} className={cx('modalBody')}>
              <div className={cx('noteName')}>
                <BaseInput
                  label={t('admin_manage_notes_create_note_modal_note_name')}
                  name='name'
                  value={stateDataForm?.name || EMPTY_STRING}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <QuillEditor name='content' onChange={handleQuillEditorChange} />
              </div>

              <div className={cx('jobSelection')}>
                <SingleSelect
                  parentRef={parentRef}
                  label={t('admin_manage_notes_create_note_modal_assign_to_job')}
                  width='auto'
                  name='jobId'
                  options={mockJobSelectionData}
                  labelMargin={8}
                  value={stateDataForm?.jobId || undefined}
                  onChange={handleSelectChange}
                  placeholder={t('common_placeholder_select')}
                />
              </div>
            </div>
            <div className={cx('modalFooter')}>
              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                onClick={handleCreateNote}
              />

              <BaseButton
                label={t('common_btn_close')}
                width={117}
                onClick={onClose}
                typeStyle={ButtonTypeEnum.CANCEL}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default AdminCreateNoteModal;
