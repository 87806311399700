// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import React from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { CommonSidebarIconArrow } from '~/assets/svgComponents';

// Others
import { TButtonType } from '~/utils/type/common';
// Styles, images, icons
import styles from './ButtonDropdown.module.scss';
import { WHITE } from '~/utils/constants/color';

type Props = {
  width?: string | number;
  height?: string | number;
  label: string;
  children?: React.ReactNode;
  type?: TButtonType;
  onClick?: () => void;
};

const cx = classNames.bind(styles);

const ButtonDropdown = (props: Props) => {
  //#region Destructuring Props
  const { children, width = '100%', height = 31, label, type = 'button', onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Handle Function
  const handleClosePopover = (closeFn: Function) => {
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='baseButtonDropdownComponent'>
      <Popover className='relative'>
        <div className={cx('btnWrap')} style={{ height: height, minWidth: width }}>
          <button className={cx('btnLabel')} type={type} onClick={onClick}>
            {label}
          </button>

          <PopoverButton className={cx('iconContainer')}>
            {({ open }) => (
              <div className={cx(open && 'iconActive')}>
                <CommonSidebarIconArrow width={18} height={18} strokePath={WHITE} />
              </div>
            )}
          </PopoverButton>
        </div>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '4px' }}
          className={cx('popupContainer')}
        >
          {({ close }) => (
            <div onClick={() => handleClosePopover(close)} className={cx('popContent')}>
              {children}
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default ButtonDropdown;
