// Others
import { urlApiOverview } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { IOverviewResp } from '~/utils/interface/overview';
import { BaseResponse } from '~/utils/interface/common';

const overviewApi = {
  getListOverview() {
    const url = `${urlApiOverview.getList}`;
    return axiosClient.get<BaseResponse<IOverviewResp>>(url);
  },
};

export default overviewApi;
