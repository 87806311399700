// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
// Components, Layouts, Pages
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { DateFormatEnum } from '~/utils/enum';
import { IBasicInfo } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data?: IBasicInfo;
};

const InvoiceUserInfo = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={styles.col}>
        <View style={styles.row}>
          <Text style={styles.labelLeft}>TO:</Text>
          <Text style={[styles.value, styles.bold]}>{data?.clientName ?? EMPTY_STRING}</Text>
        </View>
      </View>

      <View style={styles.col}>
        <View style={styles.row}>
          <Text style={styles.labelRight}>Project:</Text>
          <Text style={styles.value}>{data?.projectName ?? EMPTY_STRING}</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.labelRight}>Address:</Text>
          <Text style={styles.value}>{data?.address ?? EMPTY_STRING}</Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.labelRight}>Date:</Text>
          <Text style={styles.value}>{`${moment().format(DateFormatEnum['DD MM YYYY'])}`}</Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceUserInfo;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 16,
  },
  col: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
  },
  labelLeft: {
    width: 40,
  },
  labelRight: {
    width: '30%',
  },
  value: {
    flex: 1,
    flexWrap: 'wrap',
  },
  bold: {
    fontWeight: 700,
  },
});
