// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
import { BaseButton, FormInput, Loading, UploadAvatar } from '~/components';
// Others
import { getDetailEmployee, updateEmployee } from '~/thunks/employee/employeeThunk';
import { FORM_EMPLOYEE_KEYS, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, DateFormatEnum } from '~/utils/enum';
import { compressImage, convertCodeToRole } from '~/utils/helper';
import {
  IDetailEmployee,
  IEditEmployeeData,
  IUpdateEmployeeBody,
} from '~/utils/interface/employee';
// Styles, images, icons
import styles from './AdminUpdateEmployeeModal.module.scss';

type Props = {
  employeeId: string;
  onClose?: () => void;
  onSuccess: () => void;
};

const cx = classNames.bind(styles);

const employeeSchema = (t: TFunction) => {
  return yup
    .object({
      firstName: yup.string().required(t('common_validate_require_first_name')),
      lastName: yup.string().required(t('common_validate_require_last_name')),
      email: yup
        .string()
        .required(t('common_validate_require_email'))
        .email(t('common_validate_email_required')),
      phoneNumber: yup.string().optional(),
      role: yup.string().optional(),
      joinedDate: yup.string().optional(),
      avatar: yup.string().optional(),
    })
    .required();
};

const AdminUpdateEmployeeModal = (props: Props) => {
  //#region Destructuring Props
  const { employeeId, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: IEditEmployeeData = useMemo(() => {
    return {
      avatar: EMPTY_STRING,
      firstName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      email: EMPTY_STRING,
      role: EMPTY_STRING,
      joinedDate: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IEditEmployeeData>({
    resolver: yupResolver(employeeSchema(t)),
    defaultValues: defaultData,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initDataForm, setInitDataForm] = useState<IEditEmployeeData>(defaultData);
  const [avatarFile, setAvatarFile] = useState<File>();
  const [thumbnailFile, setThumbnailFile] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!employeeId) return;
    handleGetDetailEmployee();
  }, [employeeId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailEmployee = async () => {
    setIsLoading(true);

    dispatch(getDetailEmployee(Number(employeeId)))
      .unwrap()
      .then((resp) => {
        const data: IDetailEmployee = resp?.data;
        handlePopulateData(data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePopulateData = (dataResponse: IDetailEmployee) => {
    if (!dataResponse) return;

    const dataEmployee: IEditEmployeeData = {
      firstName: dataResponse.firstName,
      lastName: dataResponse.lastName,
      email: dataResponse.email,
      phoneNumber: dataResponse.phoneNumber ?? EMPTY_STRING,
      joinedDate: dataResponse.joinedDate
        ? moment(dataResponse.joinedDate).format(DateFormatEnum['DD MM YYYY'])
        : EMPTY_STRING,
      role: convertCodeToRole(dataResponse.role) ?? EMPTY_STRING,
      avatar: dataResponse.avatarUrl ?? EMPTY_STRING,
    };

    setInitDataForm(dataEmployee);
    reset(dataEmployee);
  };

  const handleCancelModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };

  const handleFileChange = async (file: File, name: keyof IEditEmployeeData) => {
    if (!name || !file) return;
    const thumbnailImageFile = await compressImage(file);

    thumbnailImageFile && setThumbnailFile(thumbnailImageFile);
    setAvatarFile(file);

    const imageURL = URL.createObjectURL(file);
    setValue(name, imageURL, { shouldDirty: true });
  };

  const handlePreparedData = (data: IEditEmployeeData) => {
    if (!data) return;

    const formData = new FormData();
    const formattedData: IEditEmployeeData = { ...data };
    const skipAppendKeys = new Set([FORM_EMPLOYEE_KEYS.THUMBNAIL]);

    Object.entries(formattedData).forEach(([key, value]) => {
      const initialValue = initDataForm[key as keyof IEditEmployeeData];

      if (
        value && // Skip empty values
        value !== initialValue && // Only append changed values
        !skipAppendKeys.has(key) // Skip keys in the skip list
      ) {
        formData.append(key, value);
      }
    });

    avatarFile
      ? formData.set(FORM_EMPLOYEE_KEYS.AVATAR, avatarFile)
      : formData.delete(FORM_EMPLOYEE_KEYS.AVATAR);
    thumbnailFile
      ? formData.set(FORM_EMPLOYEE_KEYS.THUMBNAIL, thumbnailFile)
      : formData.delete(FORM_EMPLOYEE_KEYS.THUMBNAIL);

    return formData;
  };

  const handleUpdateEmployee = (data: IEditEmployeeData) => {
    const formData = handlePreparedData(data);
    if (!employeeId || !formData) return;

    setIsLoading(true);
    const updateEmployeeBody: IUpdateEmployeeBody = { id: employeeId, body: formData };

    dispatch(updateEmployee(updateEmployeeBody))
      .unwrap()
      .then((res) => {
        onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='modalCreateEmployee' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}
        <form className={cx('modalContent')} onSubmit={handleSubmit(handleUpdateEmployee)}>
          <div className={cx('modalHeader')}>
            {t('admin_manage_employee_modal_title_edit_employee')}
          </div>

          <div className={cx('line')} />

          <div className={cx('modalBody')}>
            <div className={cx('avatarContent')}>
              <Controller
                name='avatar'
                control={control}
                render={({ field: { name, value } }) => (
                  <UploadAvatar
                    name='avatar'
                    urlAvatar={value || EMPTY_STRING}
                    firstName={getValues('firstName')}
                    lastName={getValues('lastName')}
                    onFileChange={(_: string, file: File) => handleFileChange(file, name)}
                  />
                )}
              />
            </div>

            <div className={cx('inputContent')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_first_name')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    required
                    errorMessage={errors.firstName?.message}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_last_name')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    required
                    errorMessage={errors.lastName?.message}
                  />
                )}
              />
            </div>

            <div className={cx('inputContent')}>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field }) => (
                  <FormInput
                    type='tel'
                    label={t('admin_manage_employee_label_phone')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field }) => (
                  <FormInput
                    required
                    label={t('admin_manage_employee_label_email')}
                    value={field.value || EMPTY_STRING}
                    onChange={field.onChange}
                    errorMessage={errors.email?.message}
                  />
                )}
              />
            </div>

            <div className={cx('inputContent')}>
              <Controller
                name='role'
                control={control}
                render={({ field: { value } }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_position')}
                    value={value ? t(value) : EMPTY_STRING}
                    disabled
                  />
                )}
              />

              <Controller
                name='joinedDate'
                control={control}
                render={({ field: { value } }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_joinedDate')}
                    value={value || EMPTY_STRING}
                    disabled
                  />
                )}
              />
            </div>
          </div>

          <div className={cx('modalFooter')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancelModal}
              />
            </div>

            <BaseButton
              label={t('common_btn_update')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
              isDisable={!isDirty}
            />
          </div>
        </form>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminUpdateEmployeeModal;
