// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import { FormEstimateContainer, SelectEstimateTemplateModal } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { estimateActions } from '~/thunks/estimate/estimateSlice';
import { getDetailEstimate, getDetailEstimateByJob } from '~/thunks/estimate/estimateThunk';
import { selectJobDetails } from '~/thunks/job/jobSlice';
import { FORM_ESTIMATE_MODE_CREATE, FORM_ESTIMATE_MODE_EDIT } from '~/utils/constants/common';
import {
  IBudgetSubContractor,
  IDetailEstimate,
  IEstimateContent,
  IPhaseContent,
} from '~/utils/interface/estimate';
import { IJobDetails } from '~/utils/interface/job';
import { TFormModalMode } from '~/utils/type/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './JobEstimateTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const JobEstimateTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshEstimateDetail } = useAppSelector((state: RootState) => state.estimate);
  const jobDetails: IJobDetails = useSelector(selectJobDetails);
  //#endregion Selector

  //#region Declare State
  const [isShowUseTemplate, setIsShowUseTemplate] = useState<boolean>(false);
  const [isShowFormAddEstimate, setIsShowFormAddEstimate] = useState<boolean>(false);
  const [modeFormEstimate, setModeFormEstimate] =
    useState<TFormModalMode>(FORM_ESTIMATE_MODE_CREATE);
  const [estimateDetail, setEstimateDetail] = useState<IEstimateContent>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!jobId || (!isRefreshEstimateDetail && !jobDetails.estimateId)) return;

    fetchDetailEstimate(jobId);

    isRefreshEstimateDetail && dispatch(estimateActions.setRefreshDetail(false));
  }, [isRefreshEstimateDetail, jobId, jobDetails.estimateId]);
  //#endregion Implement Hook

  //#region Handle Function
  const fetchDetailEstimate = (jobId: string) => {
    loadingData?.show();

    dispatch(getDetailEstimateByJob(jobId))
      .unwrap()
      .then((resp) => {
        const estimateData: IEstimateContent = resp?.data;
        estimateData && setEstimateDetail(estimateData);
        setModeFormEstimate(FORM_ESTIMATE_MODE_EDIT);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const fetchDetailEstimateTemplate = (estimateId: string) => {
    loadingData?.show();

    dispatch(getDetailEstimate(Number(estimateId)))
      .unwrap()
      .then((resp) => {
        const estimateData: IDetailEstimate = resp?.data;
        estimateData && setEstimateDetail(formatDataEst(estimateData));
        setModeFormEstimate(FORM_ESTIMATE_MODE_CREATE);
        setIsShowFormAddEstimate(true);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowUseTemplate(false);
      });
  };

  const removeId = <T extends { id?: string }>(items: T[]): Omit<T, 'id'>[] => {
    return items.map(({ id, ...rest }) => rest);
  };

  const formatDataEst = (data: IEstimateContent): IEstimateContent => {
    const { phases, ...restData } = data;

    const formatPhases = phases.map((phase: IPhaseContent) => {
      const { id, materials, equipments, labors, subcontractor, miscellanea, ...restPhase } = phase;

      let newBudget: Partial<IPhaseContent> = {
        ...restPhase,
      };

      if (materials && materials?.length > 0) {
        newBudget.materials = removeId(materials);
      }
      if (labors && labors?.length > 0) {
        newBudget.labors = removeId(labors);
      }
      if (equipments && equipments?.length > 0) {
        newBudget.equipments = removeId(equipments);
      }
      if (miscellanea && miscellanea?.length > 0) {
        newBudget.miscellanea = removeId(miscellanea);
      }

      if (subcontractor) {
        let newSubcontractor: Omit<IBudgetSubContractor, 'id'> = {
          unassignedWorks: subcontractor?.unassignedWorks,
          assignedSubcontractors: subcontractor?.assignedSubcontractors,
        };

        if (subcontractor?.unassignedWorks && subcontractor?.unassignedWorks?.length > 0) {
          newSubcontractor.unassignedWorks = removeId(subcontractor.unassignedWorks);
        }
        if (
          subcontractor?.assignedSubcontractors &&
          subcontractor?.assignedSubcontractors?.length > 0
        ) {
          newSubcontractor.assignedSubcontractors = removeId(subcontractor.assignedSubcontractors);
        }

        newBudget.subcontractor = newSubcontractor;
      }

      return newBudget as IPhaseContent;
    });

    return { ...restData, phases: formatPhases };
  };

  const handleOnCloseFormAddEstimate = () => {
    setIsShowFormAddEstimate(false);
  };

  const handleCreateEstimateSuccess = () => {
    setIsShowFormAddEstimate(false);
    dispatch(estimateActions.setRefreshDetail(true));
  };

  const handleApplyTemplate = (idEstimate: string) => {
    fetchDetailEstimateTemplate(idEstimate);
  };
  //#endregion Handle Function

  return (
    <div id='jobEstimateTabComponent' className={cx('container')}>
      {!loadingData?.loading &&
        (estimateDetail || isShowFormAddEstimate ? (
          <FormEstimateContainer
            data={estimateDetail}
            mode={modeFormEstimate}
            onCancel={handleOnCloseFormAddEstimate}
            onSave={handleCreateEstimateSuccess}
          />
        ) : (
          <section className={cx('createScreen')}>
            <button
              className={cx('btnLarge')}
              onClick={() => {
                setIsShowFormAddEstimate(!isShowFormAddEstimate);
              }}
            >
              <img src={icons.commonIconJobEstimateCreate} className={cx('iconBtnLarge')} />
              <p className={cx('textBtnLarge')}>
                {t('template_contract_jobs_estimate_btn_create')}
              </p>
            </button>
            <button
              className={cx('btnLarge')}
              onClick={() => {
                setIsShowUseTemplate(!isShowUseTemplate);
              }}
            >
              <img src={icons.commonIconJobEstimateUseTemplate} className={cx('iconBtnLarge')} />
              <p className={cx('textBtnLarge')}>
                {t('template_contract_jobs_estimate_btn_use_template')}
              </p>
            </button>
          </section>
        ))}

      <SelectEstimateTemplateModal
        isOpen={isShowUseTemplate}
        onClose={() => {
          setIsShowUseTemplate(!isShowUseTemplate);
        }}
        onApply={handleApplyTemplate}
      />
    </div>
  );
};

export default JobEstimateTab;
