// Libs
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { IMenuItemModel } from '~/utils/interface/common';
import { hasActiveChild } from '~/utils/helper';
import { MenuTypeEnum } from '~/utils/enum';
import CommonSidebarIconArrow from '~/assets/svgComponents/CommonSidebarIconArrow';
import { VIOLET200, WHITE_OPACITY_50 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './MenuItem.module.scss';

type Props = {
  menuItem: IMenuItemModel;
};

const cx = classNames.bind(styles);

const MenuItem = (props: Props) => {
  //#region Destructuring Props
  const { path, name, icon, index, children, iconActive, type } = props.menuItem;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const location = useLocation();
  //#endregion Declare Hook
  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isHasChildActive, setIsHasChildActive] = useState<boolean>(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
  const [isSubmenu] = useState<boolean>(children ? children.length > 0 : false);

  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!props.menuItem || !children) {
      return;
    }

    const isHasChildActiveInitial = hasActiveChild(props.menuItem, location.pathname);
    setIsHasChildActive(isHasChildActiveInitial);
  }, [location.pathname]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };
  //#endregion Handle Function

  return (
    <div id='menuItemComponent' className={cx('menuItemComponent')}>
      {type === MenuTypeEnum.DROPDOWN && isSubmenu ? (
        <div className={cx(['submenuWrap', isHasChildActive && 'activeMenu'])}>
          <button className={cx('menuBtn')} onClick={handleShowDropdown}>
            <div className={cx('labelGroup')}>
              <img
                src={isHasChildActive ? iconActive : icon}
                className={cx('icon', isHasChildActive && 'iconActive')}
              />
              <div className={cx(['menuLabel', isHasChildActive && 'menuLabelActive'])}>
                {t(name as string)}
              </div>
            </div>

            <span className={cx(isOpenDropdown ? 'iconMenuDropdownOpen' : 'iconMenuDropdown')}>
              <CommonSidebarIconArrow
                strokePath={isHasChildActive ? VIOLET200 : WHITE_OPACITY_50}
              />
            </span>
          </button>

          {isOpenDropdown && (
            <div className={cx('submenuContainer')}>
              {children?.map((submenuItem) => (
                <NavLink
                  key={submenuItem.path}
                  to={submenuItem.path}
                  end={submenuItem.index}
                  className={({ isActive }) => cx(['submenuLink', isActive && 'submenuLinkActive'])}
                >
                  {({ isActive }) => (
                    <div className={cx('labelGroup')}>
                      <div
                        className={cx([
                          'menuLabel',
                          isActive && isHasChildActive && 'submenuLabelActive',
                        ])}
                      >
                        {t(submenuItem.name as string)}
                      </div>
                    </div>
                  )}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      ) : (
        <NavLink
          to={path}
          end={index}
          className={({ isActive }) =>
            cx(['menuLink', (isActive || isHasChildActive) && 'activeMenu'])
          }
        >
          {({ isActive }) => (
            <div className={cx('labelGroup', (isActive || isHasChildActive) && 'activeMenu')}>
              <img
                className={cx('icon', (isActive || isHasChildActive) && 'iconActive')}
                src={isActive ? iconActive : icon}
                alt={t('common_img_text_alt')}
              />
              <div
                className={cx(['menuLabel', (isActive || isHasChildActive) && 'menuLabelActive'])}
              >
                {t(name as string)}
              </div>
            </div>
          )}
        </NavLink>
      )}
    </div>
  );
};

export default MenuItem;
