// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseImageCircle, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getClientDetails } from '~/thunks/client/clientThunk';
import { IClientDetail } from '~/utils/interface/client';
import { getFullName } from '~/utils/helper';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './AdminClientDetailsModal.module.scss';
import { icons } from '~/assets';

type Props = {
  idClient?: string;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const AdminClientDetailsModal = (props: Props) => {
  //#region Destructuring Props
  const { idClient, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [clientDetail, setClientDetail] = useState<IClientDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!idClient) return;
    handleGetDetailClient(idClient);
  }, [idClient]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetDetailClient = (id: string) => {
    setIsLoading(true);

    dispatch(getClientDetails(id))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        if (!res?.data) return;

        setClientDetail(res?.data);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onCloseModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminClientDetailsModalComponent' className={cx('clientDetailsModalContainer')}>
      <div className={cx('modalDetails')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={onCloseModal}
        />
        <div className={cx('clientDetailsModal')}>
          <div className={cx('modalTitleClientDetails')}>
            {t('admin_manage_clients_modal_details_title_client_detail')}
          </div>
          <div className={cx('contentWrap')}>
            <div className={cx('content')}>
              <div className={cx('body')}>
                <div className={cx('detailClient')}>
                  <div className={cx('rowDetailClient')}>
                    <div className={cx('labelClient')}>
                      {t('admin_manage_clients_modal_details_label_client_name')}
                    </div>
                    <div className={cx('clientDetail')}>
                      {/* <CircleAvatar
                        type={clientDetail?.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                        imageUrl={clientDetail?.avatar || clientDetail?.thumbnail}
                        firstName={clientDetail?.firstName}
                        lastName={clientDetail?.lastName}
                        width={24}
                        height={24}
                        fontSize={12}
                      /> */}

                      {getFullName({
                        firstName: clientDetail?.firstName,
                        lastName: clientDetail?.lastName,
                      })}
                    </div>
                  </div>
                  <div className={cx('rowDetailClient')}>
                    <div className={cx('labelClient')}>
                      {t('admin_manage_clients_modal_details_label_main_contact')}
                    </div>
                    <div className={cx('textDetailClient')}>
                      {clientDetail?.contactName ? clientDetail.contactName : EMPTY_STRING}
                    </div>
                  </div>
                  <div className={cx('rowDetailClient')}>
                    <div className={cx('labelClient')}>
                      {t('admin_manage_clients_modal_details_label_phone')}
                    </div>
                    <div className={cx('textDetailClient')}>
                      {clientDetail?.phoneNumber ? clientDetail.phoneNumber : EMPTY_STRING}
                    </div>
                  </div>
                  <div className={cx('rowDetailClient')}>
                    <div className={cx('labelClient')}>
                      {t('admin_manage_clients_modal_details_label_email')}
                    </div>
                    <div className={cx('textDetailClient')}>
                      {clientDetail?.email ? clientDetail.email : EMPTY_STRING}
                    </div>
                  </div>
                  <div className={cx('rowDetailClient')}>
                    <div className={cx('labelClient')}>
                      {t('admin_manage_clients_modal_details_label_other')}
                    </div>
                    <div className={cx('textDetailClient')}>
                      {clientDetail?.other ? clientDetail.other : EMPTY_STRING}
                    </div>
                  </div>
                </div>
                <div className={cx('viewAvatar')}>
                  {clientDetail && (
                    <BaseImageCircle
                      url={clientDetail?.avatar}
                      firstText={clientDetail?.firstName}
                      secondText={clientDetail?.lastName}
                      width={175}
                      height={175}
                      fontSize={12}
                    />
                  )}
                </div>
              </div>
              <div className={cx('noteDetailsModal')}>
                <div className={cx('modalTitleNote')}>
                  {t('admin_manage_clients_modal_details_title_note')}
                </div>
                <div className={cx('informationNote')}>
                  {clientDetail?.note ? clientDetail.note : EMPTY_STRING}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminClientDetailsModal;
