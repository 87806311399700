// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { MAX_LENGTH_DESCRIPTIONS, toolbarOptions } from '~/utils/constants/common';
import { FormatDataWithOptions, FormatDataWithValue } from '~/utils/interface/note';
// Styles, images, icons
import styles from './CustomToolbar.module.scss';

const cx = classNames.bind(styles);

type Props = {
  textLength: number;
};

type FormatData = FormatDataWithOptions | FormatDataWithValue;

const CustomToolbar = (props: Props) => {
  //#region Destructuring Props
  const { textLength } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const renderOptions = (formatData: FormatDataWithOptions) => {
    const { className, options } = formatData;
    const selectHTML = `
      <select class="${className}">
        <option selected="selected"></option>
        ${
          options &&
          options.map((option: string) => `<option value="${option}">${option}</option>`).join('')
        }
      </select>
    `;
    return <div dangerouslySetInnerHTML={{ __html: selectHTML }} />;
  };

  const renderSingle = (formatData: FormatDataWithValue) => {
    const { className, value } = formatData;
    return <button className={className} value={value}></button>;
  };
  //#endregion Handle Function

  return (
    <div id='toolbar' className={cx('container')}>
      <div className={cx('contentToolbar')}>
        {toolbarOptions.map((classes: FormatData[]) => {
          return (
            <span className='ql-formats'>
              {classes.map((formatData: FormatDataWithOptions) => {
                return formatData.options ? renderOptions(formatData) : renderSingle(formatData);
              })}
            </span>
          );
        })}
      </div>

      <div
        className={cx('limitText', {
          limitExceeded: textLength > MAX_LENGTH_DESCRIPTIONS,
        })}
      >
        {textLength}/{MAX_LENGTH_DESCRIPTIONS}
      </div>
    </div>
  );
};

export default CustomToolbar;
