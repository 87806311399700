// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
// Components, Layouts, Pages
// Others
import { IProposalPdfTotal } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data?: IProposalPdfTotal;
};

const InvoiceTotal = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={styles.col}>
        <View style={styles.row}>
          <Text style={[styles.label, styles.bold]}>{`Subtotal:`}</Text>
          <Text style={[styles.value, styles.bold]}>
            {formatCurrency(CurrencyEnum.USD, data?.subtotal ?? DEFAULT_NUMBER_ZERO)}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={styles.label}>{`*${data?.taxPercent ?? DEFAULT_NUMBER_ZERO}% Tax:`}</Text>
          <Text style={styles.value}>
            {formatCurrency(CurrencyEnum.USD, data?.tax ?? DEFAULT_NUMBER_ZERO)}
          </Text>
        </View>

        <View style={styles.row}>
          <Text style={[styles.label, styles.bold]}>{`TOTAL:`}</Text>
          <Text style={[styles.value, styles.bold]}>
            {formatCurrency(CurrencyEnum.USD, data?.total ?? DEFAULT_NUMBER_ZERO)}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceTotal;

export const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderTopColor: '#000000',
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  col: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 2,
  },
  label: {
    width: '30%',
    textAlign: 'right',
  },
  bold: {
    fontWeight: 700,
  },
  value: {
    flex: 1,
    flexWrap: 'wrap',
    textAlign: 'right',
  },
});
