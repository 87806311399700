import { urlApiProduct } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IFormProductPayload,
  IProduct,
  IQueryListProduct,
  IUpdateProduct,
} from '~/utils/interface/product';
import axiosClient from './axiosClient';

const productApi = {
  getListProducts(params: IQueryListProduct) {
    const url = `${urlApiProduct.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IProduct[]>>>(url, {
      params: params,
    });
  },

  createProduct(body: IFormProductPayload) {
    const url = `${urlApiProduct.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateProduct({ productId, body }: IUpdateProduct) {
    const url = `${urlApiProduct.updateProduct(productId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  deleteProduct(id: string) {
    const url = `${urlApiProduct.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};

export default productApi;
