// Libs
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { INotifications } from '~/utils/interface/notifications';
import { NotificationTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Notification.module.scss';
import icons from '~/assets/icons';

type NotificationProps = {
  data: INotifications;
  onClick: (id: string) => void;
};

const cx = classNames.bind(styles);

const Notification = (props: NotificationProps) => {
  //#region Destructuring Props
  const { data, onClick } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [notificationImage, setNotificationImage] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (data.businessType === NotificationTypeEnum.INFO) {
      setNotificationImage(icons.commonNotificationIconInfo);
    }

    if (data.businessType === NotificationTypeEnum.SUCCESS) {
      setNotificationImage(icons.commonNotificationIconSuccess);
    }
  }, [data.businessType]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleNotificationClicked = () => {
    onClick(data.id);
  };
  //#endregion Handle Function

  return (
    <div id='notificationComponent' onClick={handleNotificationClicked}>
      <div className={cx('notificationContainer')}>
        <div className={cx('notificationImage')}>
          <img src={notificationImage} alt={t('common_image_notification')} />
        </div>

        <div className={cx('notificationContentBox')}>
          <div className={cx('notificationContent')}>
            <div className={cx('notificationTitleBox')}>
              <div className={cx('notificationTitle')}>{t('common_new_notification_title')}</div>
              <span className={cx('notificationTime')}>{''}</span>
            </div>

            <div className={cx('notificationDescription')}>{data.content}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
