// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { ExpensesPageTemplate } from '~/pages';
// Others
// Styles, images, icons
import styles from './Expenses.module.scss';

const cx = classNames.bind(styles);

const Expenses = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return <ExpensesPageTemplate />;
};

export default Expenses;
