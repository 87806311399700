// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
// Components, Layouts, Pages
import {
  BaseCheckbox,
  FormBreakDownContainer,
  FormInput,
  LinkedPhases,
  BaseRichEditor,
} from '~/components';
// Others
import { IProposal } from '~/utils/interface/proposal';
import {
  DEFAULT_HEIGHT_TEXT_EDITOR,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { InputTypeEnum, TextEditorEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './FormLineItemContainer.module.scss';
import { icons } from '~/assets';

type Props = {
  mode: string;
};

const cx = classNames.bind(styles);

const FormLineItemContainer = (props: Props) => {
  //#region Destructuring Props
  const { mode } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext<IProposal>();

  // Line Items useFieldArray
  const {
    fields: lineItemFields,
    append: appendLineItem,
    remove: removeLineItem,
  } = useFieldArray({
    control,
    name: `lineItems`,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='formLineItemContainerComponent' className={cx('container')}>
      <section>
        <div className={cx('viewListItem')}>
          {lineItemFields.map((lineItemField, index) => {
            return (
              <div key={lineItemField.id} className={cx('fieldContainer')}>
                <div key={index} className={cx('itemAddLineItem')}>
                  <div className={cx('inputDescription')}>
                    <p className={cx('numerical')}>{`${index + DEFAULT_NUMBER_ONE}.`}</p>
                    <Controller
                      name={`lineItems.${index}.name`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          value={value}
                          onChange={onChange}
                          required
                          errorMessage={errors.lineItems?.[index]?.name?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={cx('inputValue')}>
                    <Controller
                      name={`lineItems.${index}.price`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormInput
                          onChange={onChange}
                          value={value}
                          type={InputTypeEnum.NUMBER}
                          required
                          errorMessage={errors.lineItems?.[index]?.price?.message}
                        />
                      )}
                    />
                    <div className={cx('checkBox')}>
                      <Controller
                        name={`lineItems.${index}.isTax`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <BaseCheckbox
                            onChange={onChange}
                            value={value}
                            label={t('template_create_proposal_label_taxable')}
                            name={`lineItems.${index}.isTaxable`}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className={cx('buttonRemoveLineItem')}>
                    <button type='button' onClick={() => removeLineItem(index)}>
                      <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
                    </button>
                  </div>
                </div>

                <div className={cx('viewCheckboxComment')}>
                  <img
                    src={icons.adminIconEditContact}
                    alt={t('common_img_text_alt')}
                    className={cx('iconEditContact')}
                  />
                  <div className={cx('viewDetailComment')}>
                    <Controller
                      name={`lineItems.${index}.isShowComment`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          <button
                            type='button'
                            className={cx('buttonAction', 'buttonComment')}
                            onClick={() => onChange(!value)}
                          >
                            {t('template_create_proposal_button_details_or_comments')}
                          </button>

                          {value && (
                            <div className={cx('viewComment')}>
                              <Controller
                                name={`lineItems.${index}.comment`}
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                  return (
                                    <BaseRichEditor
                                      mode={mode}
                                      height={DEFAULT_HEIGHT_TEXT_EDITOR}
                                      onChange={(value: string) => onChange(value)}
                                      value={value ?? EMPTY_STRING}
                                      type={TextEditorEnum.SHORT}
                                    />
                                  );
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>

                <Controller
                  name={`lineItems.${index}.isShowBreakDown`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <button
                        onClick={() => {
                          onChange(!value);
                        }}
                        className={cx('viewCheckBoxBreakdown')}
                      >
                        <BaseCheckbox
                          onChange={() => {
                            onChange(!value);
                          }}
                          value={value}
                          name={`lineItems.${index}.isShowBreakDown`}
                        />
                        <span className={cx('buttonAction')}>
                          {t('template_create_proposal_button_breakdown_or_materials')}
                        </span>
                      </button>

                      <div className={cx('viewItem')}>
                        {value && <FormBreakDownContainer lineItemIndex={index} key={index} />}
                      </div>
                    </>
                  )}
                />

                {lineItemField.linkPhases &&
                  lineItemField.linkPhases.length > DEFAULT_NUMBER_ZERO && (
                    <div className={cx('viewItem')}>
                      <LinkedPhases lineItemIndex={index} key={index} />
                    </div>
                  )}
              </div>
            );
          })}
        </div>

        <div className={cx('btnAddWrap')}>
          <button
            type='button'
            onClick={() =>
              appendLineItem({
                name: EMPTY_STRING,
                price: EMPTY_STRING,
                isTax: false,
                isShowBreakDown: false,
              })
            }
          >
            <span className={cx('buttonAction')}>
              {t('template_create_proposal_button_add_line_item')}
            </span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default FormLineItemContainer;
