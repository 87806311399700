// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ModalUnderDevelopment.module.scss';

type Props = {
  onClose?: () => void;
  subTitle?: string;
};

const cx = classNames.bind(styles);

const ModalUnderDevelopment = (props: Props) => {
  //#region Destructuring Props
  const { onClose, subTitle = EMPTY_STRING } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseModal = () => {
    onClose && onClose();
  };
  //#endregion Handle Function

  return (
    <div id='underDevelopmentModalComponent' className={cx('modalContainer')}>
      <div className={cx('modalContent')}>
        <div className={cx('bodyContent')}>
          <div className={cx('textDevelopment')}>{`${t(
            'common_text_under_development'
          )} ${subTitle}`}</div>
        </div>

        <div className={cx('formButtonWrap')}>
          <div className={cx('buttonGroup')}>
            <BaseButton
              onClick={handleCloseModal}
              width={125}
              height={32}
              label={t('common_btn_ok')}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUnderDevelopment;
