import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';

import {
  forgotPassword,
  createNewPassword,
  handleLogin,
  verifyCode,
  changePassword,
} from '~/thunks/auth/authThunk';
import { StorageEnum } from '~/utils/enum';

export interface AuthState {
  accessToken: string | null | undefined;
  accountId?: string;
}

const initialState: AuthState = {
  accessToken: localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogout() {
      localStorage.removeItem(StorageEnum.ACCESS_TOKEN);
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
      if (state.accountId) sessionStorage.setItem(StorageEnum.ACCOUNT_ID, state.accountId);
    },
    clearAccountId: (state, action) => {
      state.accountId = action.payload;
      sessionStorage.removeItem(StorageEnum.ACCOUNT_ID);
    },
  },
  extraReducers(builder) {
    // Login
    builder
      .addCase(handleLogin.pending, (state, action) => {})
      .addCase(handleLogin.fulfilled, (state, action) => {
        state.accessToken = action.payload;
      })
      .addCase(handleLogin.rejected, (state, action) => {});

    // Forgot password
    builder
      .addCase(forgotPassword.pending, (_state) => {})
      .addCase(forgotPassword.fulfilled, (_state) => {})
      .addCase(forgotPassword.rejected, (_state) => {});

    // Create new password
    builder
      .addCase(createNewPassword.pending, (state) => {})
      .addCase(createNewPassword.fulfilled, (state) => {})
      .addCase(createNewPassword.rejected, (state) => {});

    // Verify code
    builder
      .addCase(verifyCode.pending, (state) => {})
      .addCase(verifyCode.fulfilled, (state) => {})
      .addCase(verifyCode.rejected, (state) => {});

    // Change password
    builder
      .addCase(changePassword.pending, (state) => {})
      .addCase(changePassword.fulfilled, (state) => {})
      .addCase(changePassword.rejected, (state) => {});
  },
});

export const authActions = authSlice.actions;

export const selectAccountId = (state: RootState) => state.auth.accountId;

const authReducer = authSlice.reducer;
export default authReducer;
