// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './Help.module.scss';

const cx = classNames.bind(styles);

const Help = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <div id='helpPage' className={cx('helpContainer')}>
      {t('admin_sidebar_label_help')}
    </div>
  );
};

export default Help;
