// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createTimeClock,
  deleteTimeClock,
  getListTimeClock,
  getTimeClockDetails,
} from './timeClockThunk';

export interface TimeClockState {
  isRefreshTimeClock?: boolean;
}

const initialState: TimeClockState = {
  isRefreshTimeClock: false,
};

const timeClockSlice = createSlice({
  name: 'timeClock',
  initialState,
  reducers: {
    setRefreshTimeClockList(state, action) {
      state.isRefreshTimeClock = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list time Clock
    builder
      .addCase(getListTimeClock.pending, (state) => {})
      .addCase(getListTimeClock.fulfilled, (state) => {})
      .addCase(getListTimeClock.rejected, (state) => {});

    // Get time clock details
    builder
      .addCase(getTimeClockDetails.pending, (state) => {})
      .addCase(getTimeClockDetails.fulfilled, (state) => {})
      .addCase(getTimeClockDetails.rejected, (state) => {});

    // Delete time clock
    builder
      .addCase(deleteTimeClock.pending, (state) => {})
      .addCase(deleteTimeClock.fulfilled, (state) => {})
      .addCase(deleteTimeClock.rejected, (state) => {});

    // Create time clock
    builder
      .addCase(createTimeClock.pending, (state) => {})
      .addCase(createTimeClock.fulfilled, (state) => {})
      .addCase(createTimeClock.rejected, (state) => {});
  },
});

export const timeClockActions = timeClockSlice.actions;

const timeClockReducer = timeClockSlice.reducer;
export default timeClockReducer;
