// Libs
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useAppDispatch } from '~/redux/hooks';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { AdminAddServiceJobModal, BasePagination, Status, Table, Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { getListJob } from '~/thunks/job/jobThunk';
import { IServiceJob, IServiceJobsRes } from '~/utils/interface/serviceJobs';
import { IGetListJobReq } from '~/utils/interface/job';
import { ColumnType } from '~/utils/interface/common';
import {
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_TOTAL_PAGES,
  defaultCurrentPage,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { AccountRoleCodesEnum, GetListJobTypeEnum, StorageEnum } from '~/utils/enum';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { formatNumber, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './ServiceJobs.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetails: (id: string) => void,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void
): ColumnType<IServiceJob>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={handleSelectAll}
    //     />
    //   ),
    //   render(_, record: IServiceJob) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'jobName',
      title: t('admin_manage_service_jobs_table_job_name'),
      dataIndex: 'jobName',
      render: (_, record) => <span>{record?.jobName || EMPTY_STRING}</span>,
    },
    {
      key: 'client',
      title: t('admin_manage_service_jobs_table_client'),
      dataIndex: 'client',
      render: (_, record) => (
        <span>
          {getFullName({
            firstName: record?.client?.firstName,
            lastName: record?.client?.lastName,
          })}
        </span>
      ),
      width: '25%',
    },
    {
      key: 'status',
      title: t('admin_manage_service_jobs_table_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record?.status} />,
    },
    {
      key: 'profit',
      title: t('admin_manage_service_jobs_table_profit'),
      dataIndex: 'profit',
      render: (_, record) => (
        <span>
          {record?.profit ? t('common_percent_value', { value: record?.profit }) : EMPTY_STRING}
        </span>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_service_jobs_table_action'),
      render: (_, record) => (
        <span className={cx('tableLink')} onClick={() => onViewDetails(record?.id)}>
          {t('common_action_view')}
        </span>
      ),
      width: '10%',
    },
  ];
};

const ManageServiceJobs = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [serviceJobs, setServiceJobs] = useState<IServiceJobsRes>();
  const [serviceJobsList, setServiceJobsList] = useState<IServiceJob[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [isShowAddServiceJob, setIsShowAddServiceJob] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const payload = {
      page: currentPage,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
      type: GetListJobTypeEnum.SERVICE,
    };

    handleGetListServiceJob(payload);
  }, [currentPage]);

  useEffect(() => {
    if (serviceJobsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = serviceJobsList.every((serviceJob: IServiceJob) => serviceJob.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = serviceJobsList
      .filter((serviceJob: IServiceJob) => serviceJob.isSelected)
      .map((serviceJob: IServiceJob) => serviceJob.id);

    //@Todo: Perform interactions with the service job id list
    console.log('Selected Service Job IDs:', selectedIds);
  }, [serviceJobsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListServiceJob = (payload: IGetListJobReq) => {
    loadingData?.show();

    dispatch(getListJob(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setServiceJobs(res?.data);
        setServiceJobsList(res?.data?.responses);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleAddServiceJob = () => {
    setIsShowAddServiceJob(true);
  };

  const handleClickTable = (id: string) => {
    // TODO: Handle logic
  };

  const handleViewDetails = (id: string) => {
    const role = localStorage.getItem(StorageEnum.ROLE);
    if (role === AccountRoleCodesEnum.ADMIN) {
      navigate(`${adminRouteAbsolute.serviceJobs}/${id}`);
      return;
    }

    navigate(`${staffRouteAbsolute.serviceJobs}/${id}`);
  };

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleCloseAddServiceJob = () => {
    setIsShowAddServiceJob(false);
  };

  const handleSelectItem = (id: string) => {
    setServiceJobsList((prevList: IServiceJob[]) =>
      prevList.map((serviceJob: IServiceJob) =>
        serviceJob.id === id ? { ...serviceJob, isSelected: !serviceJob.isSelected } : serviceJob
      )
    );
  };

  const handleSelectAll = () => {
    setServiceJobsList((prevList) =>
      prevList.map((serviceJob: IServiceJob) => ({
        ...serviceJob,
        isSelected: !isSelectAll,
      }))
    );
  };
  //#endregion Handle Function

  return (
    <div id='manageServiceJobsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_service_jobs_title', {
          total: formatNumber(serviceJobs?.pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleAddServiceJob,
          label: t('admin_manage_service_jobs_btn_add'),
        }}
      />

      <div className={cx('tableSection')}>
        <div className={cx('actions')}></div>

        <div className={cx('table')}>
          <Table
            columns={columns(t, handleViewDetails, isSelectAll, handleSelectAll, handleSelectItem)}
            dataSource={serviceJobsList || []}
            onClickRow={handleClickTable}
          />
        </div>

        <div className={cx('pagination')}>
          <BasePagination
            totalPages={serviceJobs?.pagination?.totalPages || DEFAULT_TOTAL_PAGES}
            totalItems={serviceJobs?.pagination?.totalItems || DEFAULT_TOTAL_ITEMS}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowAddServiceJob && <AdminAddServiceJobModal onClose={handleCloseAddServiceJob} />}
    </div>
  );
};

export default ManageServiceJobs;
