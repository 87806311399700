// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { InvoicesPageTemplate } from '~/pages';
// Others
import { WebsocketProvider } from '~/context';
// Styles, images, icons
import styles from './ManageInvoices.module.scss';

const cx = classNames.bind(styles);

const ManageInvoices = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <WebsocketProvider>
      <InvoicesPageTemplate />
    </WebsocketProvider>
  );
};

export default ManageInvoices;
