// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListLabor } from '~/thunks/labor/LaborThunk';
import { EMPTY_VALUE } from '~/utils/constants/common';
import { IBaseOption } from '~/utils/interface/common';

export interface LaborState {
  listLabor?: IBaseOption[];
}

const initialState: LaborState = {};

const laborSlice = createSlice({
  name: 'labor',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get List Labor
    builder
      .addCase(getListLabor.pending, (state) => {})
      .addCase(getListLabor.fulfilled, (state, action) => {
        const { data } = action.payload;

        const laborOptionList: IBaseOption[] = data.responses.map((item) => ({
          label: item.name || EMPTY_VALUE,
          value: item.id,
        }));
        state.listLabor = laborOptionList;
      })
      .addCase(getListLabor.rejected, (state) => {});
  },
});

// Action
export const laborActions = laborSlice.actions;

// Reducer
const laborReducer = laborSlice.reducer;
export default laborReducer;
