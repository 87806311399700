// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createNewClient,
  deleteClient,
  getClientDetails,
  getListClient,
  sendToQuickBocksClient,
  updateClient,
} from './clientThunk';
import { RootState } from '~/redux/store';

export interface ClientState {
  isRefreshClientList: boolean;
}

const initialState: ClientState = {
  isRefreshClientList: false,
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshClientList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list client
    builder
      .addCase(getListClient.pending, (state) => {})
      .addCase(getListClient.fulfilled, (state) => {})
      .addCase(getListClient.rejected, (state) => {});

    // Get client details
    builder
      .addCase(getClientDetails.pending, (state) => {})
      .addCase(getClientDetails.fulfilled, (state) => {})
      .addCase(getClientDetails.rejected, (state) => {});

    // Delete client
    builder
      .addCase(deleteClient.pending, (state) => {})
      .addCase(deleteClient.fulfilled, (state) => {})
      .addCase(deleteClient.rejected, (state) => {});

    // Create client
    builder
      .addCase(createNewClient.pending, (state) => {})
      .addCase(createNewClient.fulfilled, (state) => {})
      .addCase(createNewClient.rejected, (state) => {});

    // Update client
    builder
      .addCase(updateClient.pending, (state) => {})
      .addCase(updateClient.fulfilled, (state) => {})
      .addCase(updateClient.rejected, (state) => {});

    // Send to quick bocks
    builder
      .addCase(sendToQuickBocksClient.pending, (state) => {})
      .addCase(sendToQuickBocksClient.fulfilled, (state) => {})
      .addCase(sendToQuickBocksClient.rejected, (state) => {});
  },
});

// Action
export const clientActions = clientSlice.actions;

// Selectors
export const selectIsRefreshClientList = (state: RootState) => state.client.isRefreshClientList;

// Reducer
const clientReducer = clientSlice.reducer;
export default clientReducer;
