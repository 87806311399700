// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { LogoutModal, ModalUnderDevelopment } from '~/components';
// Others
import { EMPTY_STRING, NOT_AVAILABLE_VALUE } from '~/utils/constants/common';
import { IUserProfile } from '~/utils/interface/profile';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';

// Styles, images, icons
import { icons } from '~/assets';
import styles from './DropdownProfile.module.scss';

type Props = {
  data: IUserProfile;
};

const cx = classNames.bind(styles);

const DropdownProfile = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowDropdown, setIsShowDropdown] = useState<boolean>(false);
  const [isLogoutModal, setIsLogoutModal] = useState<boolean>(false);
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const dropdownProfileRef = useRef<HTMLDivElement>(null);
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownProfileRef.current && !dropdownProfileRef.current.contains(event.target as Node)) {
      setIsShowDropdown(false);
    }
  };

  const handleToggleClick = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  const handleChangePassword = () => {
    if (!currentRole) return;

    setIsShowDropdown(false);
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(adminRouteAbsolute.changePassword);
      case AccountRoleCodesEnum.STAFF:
        return navigate(staffRouteAbsolute.changePassword);
    }
  };

  const handleAdminSection = () => {
    setIsShowDropdown(false);
    navigate(adminRouteAbsolute.adminSection);
  };

  const handleNotificationSettings = () => {
    if (!currentRole) return;

    setIsShowDropdown(false);
    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(adminRouteAbsolute.notificationSetting);
      case AccountRoleCodesEnum.STAFF:
        return navigate(staffRouteAbsolute.notificationSetting);
    }
  };

  const handleClickUnderDevelop = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleLogout = () => {
    setIsShowDropdown(false);
    handleLogoutModal();
  };

  const handleLogoutModal = () => {
    setIsLogoutModal(!isLogoutModal);
  };
  //#endregion Handle Function

  return (
    <div id='dropdownProfileComponent'>
      <div className={cx('dropdownProfileWrap')} ref={dropdownProfileRef}>
        <div className={cx('headerAvatarDropdown')}>
          <div className={cx('headerAvatarWrap')} onClick={handleToggleClick}>
            <div className={cx('headerNameWrap')}>
              <span className={cx('headerName')}>
                {data?.firstName ? data.firstName : EMPTY_STRING}
              </span>
            </div>
            <div className={cx('headerAvatarImg')}>
              <img
                src={data?.avatar ? data.avatar : NOT_AVAILABLE_VALUE}
                className={cx('headerAvatar')}
                alt={t('admin_header_alt_avatar')}
              />
            </div>
            <div className={cx('headerDropdownWrap')}>
              <img
                src={icons.commonIconDropdown}
                className={cx('headerDropdown')}
                alt={t('common_img_text_alt')}
              />
            </div>
          </div>
          {isShowDropdown && (
            <div className={cx('headerAvatarOption')}>
              <div className={cx('arrow')}></div>

              <div className={cx('headerOptionAvatar')}>
                <div className={cx('headerAvatarImg')}>
                  <img
                    src={data.avatar ? data.avatar : EMPTY_STRING}
                    className={cx('headerAvatar')}
                    alt={t('admin_header_alt_avatar')}
                  />
                </div>
                <div className={cx('headerNameWrap')}>
                  <span className={cx('headerName')}>
                    {(data?.firstName && data?.lastName) || data?.firstName || data?.lastName
                      ? `${data.firstName} ${data.lastName}`
                      : EMPTY_STRING}
                  </span>
                </div>
              </div>

              <div className={cx('horizontalLine')} />

              <div className={cx('headerOptionGroup')}>
                <div className={cx('headerOptionWrap')}>
                  <button className={cx('headerOptionText')} onClick={handleChangePassword}>
                    <img
                      src={icons.commonIconLock}
                      className={cx('headerDropdown')}
                      alt={t('common_img_text_alt')}
                    />
                    {t('template_sidebar_label_change_password')}
                  </button>
                </div>

                {currentRole === AccountRoleCodesEnum.ADMIN && (
                  <div className={cx('headerOptionWrap')}>
                    <button className={cx('headerOptionText')} onClick={handleAdminSection}>
                      <img
                        src={icons.commonIconSettingDropdownProfile}
                        className={cx('headerDropdown')}
                        alt={t('common_img_text_alt')}
                      />
                      {t('template_sidebar_label_admin_section')}
                    </button>
                  </div>
                )}

                <div className={cx('headerOptionWrap')}>
                  <button className={cx('headerOptionText')} onClick={handleNotificationSettings}>
                    <img
                      src={icons.commonIconNotificationSettings}
                      className={cx('headerDropdown')}
                      alt={t('common_img_text_alt')}
                    />
                    {t('template_sidebar_label_notification_settings')}
                  </button>
                </div>
              </div>

              <div className={cx('horizontalLine')} />

              <div className={cx('headerOptionWrap', 'headerOptionWrapLogout')}>
                <button className={cx('headerOptionLogout')} onClick={handleLogout}>
                  <img
                    src={icons.commonIconLogoutDropdownProfile}
                    className={cx('headerDropdown')}
                    alt={t('common_img_text_alt')}
                  />
                  {t('admin_sidebar_label_logout')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isLogoutModal && <LogoutModal onClose={handleLogoutModal} />}
      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleClickUnderDevelop} />}
    </div>
  );
};

export default DropdownProfile;
