import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { NotFoundPage, ProtectedRoute } from '~/components';
import { MainLayout } from '~/layouts';
import { AccountRoleCodesEnum } from '~/utils/enum';
import {
  privateAdminRoutes,
  privateStaffRoutes,
  publicRoute,
  publicRoutesAdmin,
} from './utils/constants/route';

const App = () => {
  return (
    <BrowserRouter>
      <ToastContainer />

      <Routes>
        <Route path={publicRoute.base} element={<Navigate to={`${publicRoute.auth}`} />} />

        <Route path={`${publicRoute.base}`}>
          {publicRoutesAdmin.map((route, index) => {
            const Page = route.component || '';
            return (
              <Route key={index} path={route.path} element={<Page />}>
                {route.children &&
                  route.children.length > 0 &&
                  route.children.map((childRoute, index) => {
                    const ChildComponent = childRoute.component;
                    return (
                      <Route
                        key={index}
                        path={childRoute.path}
                        index={childRoute.index ?? false}
                        element={<ChildComponent />}
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Route>

        <Route element={<ProtectedRoute role={AccountRoleCodesEnum.ADMIN} />}>
          <Route element={<MainLayout role={AccountRoleCodesEnum.ADMIN} />}>
            {privateAdminRoutes.map((route, index) => {
              const Page = route.component || '';
              return (
                <Route key={index} path={route.path} element={<Page />}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute, index) => {
                      const ChildComponent = childRoute.component;
                      return (
                        <Route
                          key={index}
                          path={childRoute.path}
                          index={childRoute.index ?? false}
                          element={<ChildComponent />}
                        />
                      );
                    })}
                </Route>
              );
            })}
          </Route>
        </Route>

        <Route element={<ProtectedRoute role={AccountRoleCodesEnum.STAFF} />}>
          <Route element={<MainLayout role={AccountRoleCodesEnum.STAFF} />}>
            {privateStaffRoutes.map((route, index) => {
              const Page = route.component || '';
              return (
                <Route key={index} path={route.path} element={<Page />}>
                  {route.children &&
                    route.children.length > 0 &&
                    route.children.map((childRoute, index) => {
                      const ChildComponent = childRoute.component;
                      return (
                        <Route
                          key={index}
                          path={childRoute.path}
                          index={childRoute.index ?? false}
                          element={<ChildComponent />}
                        />
                      );
                    })}
                </Route>
              );
            })}
          </Route>
        </Route>

        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
