import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { InitEditModeValues } from '~/components/specific/formTaskModal/FormTaskModal';
import { IFormTask } from '~/utils/interface/task';

export const taskSchema = (payload: {
  t: TFunction;
  isEditMode: boolean;
  initEditValue: InitEditModeValues;
}) => {
  const { t, isEditMode, initEditValue } = payload;

  return yup.object({
    name: yup.string().required(t('common_validate_form_task_field_name')),
    jobId: yup.number().nullable().notOneOf([null], t('common_validate_form_task_field_job')),
    assigneeIds: yup
      .array(yup.number().required(t('common_validate_form_task_field_assignees')))
      .min(1, t('common_validate_form_task_field_assignees')),
    dueDate: yup
      .string()
      .required(t('common_validate_form_task_field_due_date'))
      .test('isFuture', t('common_validate_form_task_field_date_in_the_past'), (value) => {
        if (isEditMode && value === initEditValue.dueDate) {
          return true;
        }

        return value ? moment(value).isSameOrAfter(moment(), 'day') : true;
      }),
    completionDate: yup
      .string()
      .optional()
      .nullable()
      .test('isFuture', t('common_validate_form_task_field_date_in_the_past'), (value) => {
        if (isEditMode && value === initEditValue?.completionDate) {
          return true;
        }
        return value ? moment(value).isSameOrAfter(moment(), 'day') : true;
      }),
    streetAddress: yup
      .string()
      .nullable()
      .when('isLocation', {
        is: true,
        then: (schema) => schema.required(t('common_validate_form_task_field_address')),
        otherwise: (schema) => schema.optional(),
      }),
    state: yup
      .string()
      .nullable()
      .when('isLocation', {
        is: true,
        then: (schema) => schema.required(t('common_validate_form_task_field_state')),
        otherwise: (schema) => schema.optional(),
      }),
    city: yup
      .string()
      .nullable()
      .when('isLocation', {
        is: true,
        then: (schema) => schema.required(t('common_validate_form_task_field_city')),
        otherwise: (schema) => schema.optional(),
      }),
    zipCode: yup
      .string()
      .nullable()
      .when('isLocation', {
        is: true,
        then: (schema) => schema.required(t('common_validate_form_task_field_zip_code')),
        otherwise: (schema) => schema.optional(),
      }),
    // country: yup.string().optional().nullable(),
    latitude: yup.number().optional().nullable(),
    longitude: yup.number().optional().nullable(),
    note: yup.string().optional().nullable(),
    isLocation: yup.boolean().optional().nullable(),
  });
};

export const DEFAULT_DATA_FROM_TASK: IFormTask = {
  name: '',
  jobId: null,
  assigneeIds: [],
  dueDate: '',
  isLocation: false,
};

export const DEFAULT_INIT_EDIT_VALUES: InitEditModeValues = {
  dueDate: '',
  completionDate: '',
};
