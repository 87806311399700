// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseSelect, BaseUploadImage, FormInput, Loading } from '~/components';
// Context
// Others
import { useAppDispatch } from '~/redux/hooks';
import { createEmployee } from '~/thunks/employee/employeeThunk';
import {
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  FORM_EMPLOYEE_KEYS,
  OPTIONS_ROLE,
} from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { compressImage } from '~/utils/helper';
import { IBaseOption, IUploadImage } from '~/utils/interface/common';
import { ICreateEmployeePayload } from '~/utils/interface/employee';
// Styles, images, icons
import styles from './AdminCreateEmployeeModal.module.scss';

type Props = {
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const employeeSchema = (t: TFunction) => {
  return yup
    .object({
      firstName: yup.string().required(t('common_validate_require_first_name')),
      lastName: yup.string().required(t('common_validate_require_last_name')),
      email: yup
        .string()
        .required(t('common_validate_require_email'))
        .email(t('common_validate_email_required')),
      phoneNumber: yup.string().optional(),
      position: yup.string().optional(),
    })
    .required();
};

const AdminCreateEmployeeModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: ICreateEmployeePayload = useMemo(() => {
    return {
      firstName: EMPTY_STRING,
      lastName: EMPTY_STRING,
      email: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      position: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICreateEmployeePayload>({
    resolver: yupResolver(employeeSchema(t)),
    defaultValues: defaultData,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [avatarFile, setAvatarFile] = useState<File | null>();
  const [thumbnailFile, setThumbnailFile] = useState<File | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };

  const handleImageUploadChange = async (imageUploadList: IUploadImage[]) => {
    if (imageUploadList?.length <= DEFAULT_NUMBER_ZERO) {
      setAvatarFile(null);
      setThumbnailFile(null);
      return;
    }

    const avatarFile = imageUploadList[0]?.file;
    if (!avatarFile) return;

    const thumbnailImageFile = await compressImage(avatarFile);
    thumbnailImageFile && setThumbnailFile(thumbnailImageFile);
    setAvatarFile(avatarFile);
  };

  const handleDateChange = (dateSelected: string, name: keyof ICreateEmployeePayload) => {
    if (!dateSelected || !name) return;
    setValue(name, dateSelected);
  };

  const handlePositionChange = (
    optionSelected: IBaseOption,
    name: keyof ICreateEmployeePayload
  ) => {
    if (!optionSelected?.value || !name) return;
    setValue(name, optionSelected.value);
  };

  const handlePreparedData = (data: ICreateEmployeePayload) => {
    if (!data) return;

    const formData = new FormData();
    const formattedData: ICreateEmployeePayload = { ...data };

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    avatarFile
      ? formData.set(FORM_EMPLOYEE_KEYS.AVATAR, avatarFile)
      : formData.delete(FORM_EMPLOYEE_KEYS.AVATAR);
    thumbnailFile
      ? formData.set(FORM_EMPLOYEE_KEYS.THUMBNAIL, thumbnailFile)
      : formData.delete(FORM_EMPLOYEE_KEYS.THUMBNAIL);

    return formData;
  };

  const handleAddEmployee = (data: ICreateEmployeePayload) => {
    const formData = handlePreparedData(data);
    if (!formData) return;

    setIsLoading(true);
    dispatch(createEmployee(formData))
      .unwrap()
      .then((res) => {
        onSuccess && onSuccess();
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  //#endregion Handle Function

  return (
    <div id='modalCreateEmployee' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}
        <form className={cx('modalContent')} onSubmit={handleSubmit(handleAddEmployee)}>
          <div className={cx('modalHeader')}>
            {t('admin_manage_employee_modal_title_add_employee')}
          </div>

          <div className={cx('line')} />

          <div className={cx('modalBody')}>
            <Controller
              name='avatar'
              control={control}
              render={({ field: { value, onChange } }) => (
                <BaseUploadImage
                  label={t('admin_manage_technician_profile_image_label')}
                  onChange={handleImageUploadChange}
                />
              )}
            />

            <div className={cx('inputContent')}>
              <Controller
                name='firstName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_first_name')}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={errors.firstName?.message}
                  />
                )}
              />

              <Controller
                name='lastName'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    label={t('admin_manage_employee_label_last_name')}
                    value={value}
                    onChange={onChange}
                    required
                    errorMessage={errors.lastName?.message}
                  />
                )}
              />
            </div>

            <div className={cx('inputContent')}>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    type='tel'
                    label={t('admin_manage_employee_label_phone')}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <FormInput
                    required
                    label={t('admin_manage_employee_label_email')}
                    value={value}
                    onChange={onChange}
                    errorMessage={errors.email?.message}
                  />
                )}
              />
            </div>

            <Controller
              name='position'
              control={control}
              render={({ field: { value, name } }) => (
                <BaseSelect
                  label={t('admin_manage_employee_label_position')}
                  placeholder={t('common_placeholder_select')}
                  value={value}
                  options={OPTIONS_ROLE}
                  onChange={(optionSelected: IBaseOption) =>
                    handlePositionChange(optionSelected, name)
                  }
                />
              )}
            />
          </div>

          <div className={cx('modalFooter')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancelModal}
              />
            </div>
            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
              isDisable={!isValid}
            />
          </div>
        </form>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminCreateEmployeeModal;
