// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseSelect,
  BaseTextarea,
  BaseUploadImage,
  FormInput,
  Loading,
} from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { createNewClient } from '~/thunks/client/clientThunk';
import { clientActions } from '~/thunks/client/clientSlice';
import { getListQuickBookCustomer } from '~/thunks/quickBook/quickBookThunk';
import { ICustomerQuickBook } from '~/utils/interface/quickBook';
import { IBaseOption, IQueryBase, IUploadImage } from '~/utils/interface/common';
import { IAddClient } from '~/utils/interface/client';
import { compressImage } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
  linkToQuickBookCustomerOptions,
  REGEX,
} from '~/utils/constants/common';
import { AddClientNameEnum, ButtonTypeEnum, LinkToQuickBookCustomerEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './AdminAddClientModal.module.scss';

type Props = {
  onClose: () => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      name: yup.string().required(t('common_validate_require_name')),
      avatar: yup.mixed<File>().nullable().optional(),
      thumbnail: yup.mixed<File>().nullable().optional(),
      contactName: yup.string().optional(),
      phoneNumber: yup.string().optional(),
      email: yup.string().email(t('common_validate_format_email')).optional(),
      other: yup.string().optional(),
      note: yup.string().optional(),
      typeLinkQuickBook: yup.string().optional(),
      quickBookCustomerId: yup.string().optional(),
    })
    .required();
};

const AdminAddClientModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const initialValues: IAddClient = useMemo(() => {
    return {
      name: EMPTY_STRING,
      contactName: EMPTY_STRING,
      email: EMPTY_STRING,
      other: EMPTY_STRING,
      note: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
      avatar: null,
      thumbnail: null,
      typeLinkQuickBook: EMPTY_STRING,
      quickBookCustomerId: EMPTY_STRING,
      syncToken: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<IAddClient>({
    resolver: yupResolver(schema(t)),
    defaultValues: initialValues,
  });

  const linkQuickBookType = watch('typeLinkQuickBook');
  const quickBookIdSelected = watch('quickBookCustomerId');
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [initialState, setInitialState] = useState<IAddClient>(initialValues);
  const [quickBookCustomers, setQuickBookCustomers] = useState<ICustomerQuickBook[]>([]);
  const [isLoadingSearchQuickBookCustomer, setIsLoadingSearchQuickBookCustomer] =
    useState<boolean>(false);
  const [hasMoreQuickBookCustomer, setHasMoreQuickBookCustomer] = useState<boolean>(false);
  const [loadingQuickBock, setIsLoadingQuickBook] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const dataForm: IAddClient = {
      ...initialValues,
      typeLinkQuickBook: linkQuickBookType,
    };
    reset(dataForm);

    if (linkQuickBookType !== LinkToQuickBookCustomerEnum.ADD_EXISTING) return;

    const body: IQueryBase = {
      page: DEFAULT_CURRENT_PAGE,
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
    };

    handleGetListQuickBookCustomer(body);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkQuickBookType]);

  useEffect(() => {
    if (quickBookCustomers && quickBookIdSelected) {
      handleCustomerQuickBooksChange();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickBookIdSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListQuickBookCustomer = (body: IQueryBase) => {
    setIsLoadingQuickBook(true);
    dispatch(getListQuickBookCustomer(body))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const { responses: customersQuickBook } = res.data;
        setQuickBookCustomers(customersQuickBook);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoadingQuickBook(false);
      });
  };

  const handleAddClient = (data: IAddClient) => {
    const newData = handlePreparedData(data);

    if (!newData || Object.keys(newData).length === 0) return;

    const formData = new FormData();

    Object.entries(newData).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value instanceof File ? value : String(value));
      }
    });

    setIsLoading(true);

    dispatch(createNewClient(formData))
      .unwrap()
      .then((res) => {
        onClose && onClose();
        handleResetForm();
        return dispatch(clientActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePreparedData = (data: IAddClient) => {
    if (!data) return {} as IAddClient;

    const formattedData: IAddClient = { ...data };
    const bodyData: Partial<IAddClient> = {};

    Object.entries(formattedData).forEach(([key, value]) => {
      if (value) {
        bodyData[key as keyof IAddClient] = value;
      }
    });

    return bodyData as IAddClient;
  };

  const handleResetForm = () => {
    reset();
  };

  const handleCancelAddClient = () => {
    onClose && onClose();
    handleResetForm();
    setIsLoading(false);
  };

  const handleSearchQuickBookCustomer = (value: string) => {
    // TODO: Handle logic search quick book customer
  };

  const handleCustomerQuickBooksChange = () => {
    const customerSelected = quickBookCustomers.find(
      (customer) => customer.id === quickBookIdSelected
    );
    if (customerSelected) {
      setValue('name', customerSelected.name || EMPTY_STRING);
      setValue('note', customerSelected.note || EMPTY_STRING);
      setValue(
        'phoneNumber',
        customerSelected.phone?.replace(REGEX.REPLACE_TEXT_ALL, EMPTY_STRING) || EMPTY_STRING
      );
      setValue('email', customerSelected.email || EMPTY_STRING);
      setValue('syncToken', customerSelected.syncToken || EMPTY_STRING);
      setValue('contactName', customerSelected.contactName || EMPTY_STRING);
      setValue('other', customerSelected.other || EMPTY_STRING);
    }
  };

  const disableSubmit = () => {
    if (!linkQuickBookType) return true;

    if (
      linkQuickBookType &&
      linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING &&
      !quickBookIdSelected
    )
      return true;

    return false;
  };
  //#endregion Handle Function

  return (
    <div id='adminAddClientModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('closeIcon')} onClick={onClose}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}

        <div className={cx('modalContent')}>
          <div className={cx('modalHeader')}>{t('admin_manage_clients_add_client_title')}</div>

          <div className={cx('line')} />

          <form className={cx('form')} onSubmit={handleSubmit(handleAddClient)}>
            <div className={cx('body')}>
              <div className={cx('twoColumns')}>
                <Controller
                  name={AddClientNameEnum.TYPE_LINK_QUICK_BOOK}
                  control={control}
                  render={({ field }) => (
                    <BaseSelect
                      label={t('admin_manage_clients_link_to_quick_book_customer_label')}
                      placeholder={t('common_placeholder_select')}
                      value={field.value}
                      options={linkToQuickBookCustomerOptions || []}
                      onChange={({ value }: IBaseOption) => {
                        field.onChange(value);
                      }}
                      errorMessage={errors.typeLinkQuickBook?.message}
                    />
                  )}
                />

                {linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING && (
                  <Controller
                    name={AddClientNameEnum.QUICK_BOOK_CUSTOMER_ID}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      // <BaseSelectSearch
                      //   label={t('admin_manage_clients_choose_quick_book_customer_label')}
                      //   value={value}
                      //   options={
                      //     (quickBookCustomers.map((item) => ({
                      //       label: item.name,
                      //       value: item.id,
                      //     })) as IBaseOption[]) || []
                      //   }
                      //   placeholder={t('common_placeholder_select')}
                      //   onSearch={handleSearchQuickBookCustomer}
                      //   hasMore={hasMoreQuickBookCustomer}
                      //   isLoading={isLoadingSearchQuickBookCustomer}
                      //   onChange={(value: string) => {
                      //     onChange(value);
                      //     handleCustomerQuickBooksChange(value);
                      //   }}
                      //   errorMessage={errors.quickBookCustomerId?.message}
                      // />
                      <BaseSelect
                        label={t('admin_manage_clients_choose_quick_book_customer_label')}
                        placeholder={
                          loadingQuickBock
                            ? t('common_text_loading')
                            : t('common_placeholder_select')
                        }
                        value={value}
                        options={
                          (quickBookCustomers.map((item) => ({
                            label: item.name,
                            value: item.id,
                          })) as IBaseOption[]) || []
                        }
                        onChange={(optionSelected: IBaseOption) => onChange(optionSelected.value)}
                        errorMessage={errors.quickBookCustomerId?.message}
                      />
                    )}
                  />
                )}
              </div>

              {(linkQuickBookType === LinkToQuickBookCustomerEnum.NO ||
                linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_NEW ||
                (linkQuickBookType === LinkToQuickBookCustomerEnum.ADD_EXISTING &&
                  quickBookIdSelected)) && (
                <>
                  <Controller
                    name={AddClientNameEnum.AVATAR}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <BaseUploadImage
                        label={t('admin_manage_clients_profile_image_label')}
                        onChange={async (imageList: IUploadImage[]) => {
                          if (!imageList || imageList.length <= 0) {
                            onChange(null);
                            setValue('thumbnail', null);
                            return;
                          }
                          const profileImage = imageList[0].file;

                          onChange(profileImage);
                          if (profileImage) {
                            const thumbnail = await compressImage(profileImage);

                            if (thumbnail) {
                              const thumbnailFile = new File([thumbnail], profileImage.name, {
                                type: profileImage.type,
                                lastModified: Date.now(),
                              });
                              thumbnailFile && setValue('thumbnail', thumbnailFile);
                            }
                          }
                        }}
                        errorMessage={errors.avatar?.message}
                      />
                    )}
                  />

                  <Controller
                    name={AddClientNameEnum.NAME}
                    control={control}
                    render={({ field }) => (
                      <FormInput
                        required
                        label={t('admin_manage_clients_client_name_label')}
                        value={field.value || ''}
                        onChange={field.onChange}
                        errorMessage={errors.name?.message}
                      />
                    )}
                  />

                  <div className={cx('twoColumns')}>
                    <Controller
                      name={AddClientNameEnum.CONTACT_NAME}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_clients_main_contact_label')}
                          value={field.value || ''}
                          onChange={field.onChange}
                          errorMessage={errors.contactName?.message}
                        />
                      )}
                    />

                    <Controller
                      name={AddClientNameEnum.PHONE_NUMBER}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_clients_phone_number_label')}
                          value={field.value || ''}
                          type='number'
                          onChange={field.onChange}
                          errorMessage={errors.phoneNumber?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('twoColumns')}>
                    <Controller
                      name={AddClientNameEnum.EMAIL}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_clients_email_label')}
                          value={field.value || ''}
                          onChange={field.onChange}
                          errorMessage={errors.email?.message}
                        />
                      )}
                    />

                    <Controller
                      name={AddClientNameEnum.OTHER}
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_clients_other_label')}
                          value={field.value || ''}
                          onChange={field.onChange}
                          errorMessage={errors.other?.message}
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name={AddClientNameEnum.NOTE}
                    control={control}
                    render={({ field }) => (
                      <BaseTextarea
                        label={t('admin_manage_clients_notes_label')}
                        height={118}
                        maxLength={100}
                        value={field.value}
                        onTextareaChange={field.onChange}
                        errorMessage={errors.note?.message}
                      />
                    )}
                  />
                </>
              )}
            </div>

            <div className={cx('actions')}>
              <div className={cx('buttonCancel')}>
                <BaseButton
                  label={t('common_btn_cancel')}
                  width={117}
                  typeStyle={ButtonTypeEnum.CANCEL}
                  onClick={handleCancelAddClient}
                />
              </div>

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                type='submit'
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                isDisable={disableSubmit()}
              />
            </div>
          </form>
        </div>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminAddClientModal;
