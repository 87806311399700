// Libs
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import {
  Calendar,
  CalendarProps,
  DateLocalizer,
  View,
  Views,
  momentLocalizer,
  stringOrDate,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import {
  DEFAULT_BASE_SCHEDULE_FORMATS,
  DEFAULT_BASE_SCHEDULE_VIEWS,
} from '~/utils/constants/component';
// Styles, images, icons
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './BaseSchedule.scss';

export type BaseCalendarProps = Omit<CalendarProps, 'localizer'> & {
  localizer?: DateLocalizer;
};

const localizer = momentLocalizer(moment);

const BaseSchedule = (props: BaseCalendarProps) => {
  //#region Destructuring Props
  const {
    views = DEFAULT_BASE_SCHEDULE_VIEWS,
    formats = DEFAULT_BASE_SCHEDULE_FORMATS,
    ...restProps
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [view, setView] = useState<View>(restProps.view || Views.MONTH);
  const [date, setDate] = useState<stringOrDate>(restProps.date || moment().toDate());
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const onView = useCallback((newView: View) => setView(newView), [setView]);
  const onNavigate = useCallback((newDate: Date) => setDate(newDate), [setDate]);
  //#endregion Handle Function

  return (
    <Calendar
      localizer={localizer}
      startAccessor='start'
      endAccessor='end'
      style={{ height: '100%' }}
      views={views}
      view={view}
      date={date}
      formats={formats}
      dayLayoutAlgorithm={view === Views.DAY ? 'no-overlap' : 'overlap'}
      onView={onView}
      onNavigate={onNavigate}
      {...restProps}
    />
  );
};

export default memo(BaseSchedule);
