// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseImageCircle } from '~/components';
// Others
import { DEFAULT_UPLOAD_ACCEPT_IMAGE } from '~/utils/constants/common';
import { InputTypeEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './UploadAvatar.module.scss';

type Props = {
  name: string;
  urlAvatar?: string;
  sizeImage?: number;
  firstName?: string;
  lastName?: string;
  maxSize?: number;
  fontSize?: number;
  onFileChange: (name: string, file: File) => void;
};

const cx = classNames.bind(styles);

const UploadAvatar = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    urlAvatar,
    firstName,
    lastName,
    maxSize,
    sizeImage = 120,
    fontSize = 30,
    onFileChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    const { name } = event.target;

    if (!file || !name) return;

    if (file && maxSize && file.size > maxSize) {
      return;
    }

    onFileChange(name, file);
  };
  //#endregion Handle Function

  return (
    <div id={'uploadAvatar'} className={cx('container')}>
      <BaseImageCircle
        url={urlAvatar}
        width={sizeImage}
        height={sizeImage}
        firstText={firstName}
        secondText={lastName}
        fontSize={fontSize}
      />

      <label className={cx('viewEdit')}>
        <img src={icons.commonIconEditAvatar} alt={t('common_img_text_alt')} />
        <label htmlFor={name}>
          <input
            ref={fileInputRef}
            name={name}
            type={InputTypeEnum.FILE}
            accept={DEFAULT_UPLOAD_ACCEPT_IMAGE}
            id={name}
            className={cx('fileInput')}
            onChange={handleFileChange}
          />
        </label>
      </label>
    </div>
  );
};

export default UploadAvatar;
