// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
// Components, Layouts, Pages
import {
  BaseButton,
  BasePagination,
  Toolbar,
  BaseTable,
  CircleAvatar,
  AdminNoteDetailModal,
  AdminCreateNoteModal,
} from '~/components';
// Others
import { icons } from '~/assets';
import { LoadingContext } from '~/context';
import { ColumnType } from '~/utils/interface/common';
import { INote, INoteResp } from '~/utils/interface/note';
import { BaseTableEnum, ButtonTypeEnum, CircleAvatarEnum } from '~/utils/enum';
import {
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
  defaultCurrentPage,
} from '~/utils/constants/common';
import { formattedTime, getAvatarWithName, getFullName } from '~/utils/helper';
import { useAppDispatch } from '~/redux/hooks';
import { getListNote, getNoteDetail } from '~/thunks/note/noteThunk';
// Styles, images, icons
import styles from './ManageNote.module.scss';

const cx = classNames.bind(styles);

const ManageNote = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataNoteDetail, setDataNoteDetail] = useState<INote>();
  const [noteResp, setNoteResp] = useState<INoteResp>();
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);
  const [isDetailModal, setIsDetailModal] = useState<boolean>(false);
  const columns: ColumnType<INote>[] = [
    {
      key: 'notesName',
      title: t('admin_management_notes_table_notes_name_title'),
      dataIndex: 'name',
      render(_, record: INote) {
        return <span className={cx('textColor')}>{record.name || EMPTY_STRING}</span>;
      },
    },
    {
      key: 'id',
      title: t('admin_management_notes_table_assigned_to_job_id_title'),
      render(_, record: INote) {
        return <span className={cx('colorTitle')}>{record.jobId || EMPTY_STRING}</span>;
      },
    },
    {
      key: 'createdBy',
      title: t('admin_management_notes_table_created_by_title'),
      render(_, record: INote) {
        return (
          <div className={cx('avatarFirstLastName')}>
            {getAvatarWithName(
              { firstName: record.firstName, lastName: record.lastName },
              record.avatarUrl
            ) ? (
              <>
                <CircleAvatar
                  type={record.avatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                  imageUrl={record.avatarUrl}
                  firstName={record.firstName}
                  lastName={record.lastName}
                  width={24}
                  height={24}
                  fontSize={12}
                />
                {getFullName({ firstName: record.firstName, lastName: record.lastName })}
              </>
            ) : (
              <div>{EMPTY_STRING}</div>
            )}
          </div>
        );
      },
    },
    {
      key: 'notesDate',
      title: t('admin_management_notes_table_created_title'),
      dataIndex: 'createdAt',
      render(_, record: INote) {
        return (
          <>
            {record.createdAt ? (
              <div className={cx('date')}>
                <img
                  src={icons.commonIconCalender}
                  alt={t('common_img_text_alt')}
                  className={cx('icon')}
                />
                <span> {formattedTime(record.createdAt)}</span>
              </div>
            ) : (
              <span>{EMPTY_STRING}</span>
            )}
          </>
        );
      },
    },
    {
      key: 'Action',
      title: t('admin_management_notes_table_action_title'),
      render(_, record: INote) {
        return (
          <BaseButton
            isBorder={false}
            width={15}
            label={t('admin_manage_notes_table_action_button_view')}
            typeStyle={ButtonTypeEnum.ONLY_TEXT}
            onClick={() => handleViewDetail(record.id)}
          />
        );
      },
    },
  ];
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListNote();
  }, [currentPage]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleExport = () => {
    //TODO: CongGRF-ABD Handle Export
  };

  const handleAddNotes = () => {
    //TODO: CongGRF-ABD Handle Add Notes
    setIsCreateModal(true);
  };

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  const handleGetListNote = () => {
    const params = {
      page: currentPage,
      limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
    };

    loadingContext?.show();
    dispatch(getListNote(params))
      .unwrap()
      .then((res) => {
        setNoteResp(res.data);
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
  };

  const handleViewDetail = (id: string) => {
    loadingContext?.show();
    dispatch(getNoteDetail(id))
      .unwrap()
      .then((res) => {
        setDataNoteDetail(res.data);
        loadingContext?.hide();
      })
      .catch((error) => {
        loadingContext?.hide();
      });
    setIsDetailModal(true);
  };

  const onCloseDetailModal = () => {
    setIsDetailModal(false);
  };

  const onCloseCreateModal = () => {
    setIsCreateModal(false);
  };
  //#endregion Handle Function
  return (
    <div id='manageNotePage' className={cx('manageNoteContainer')}>
      {/* <BaseButton
          icon={icons.commonIconExport}
          label={t('common_btn_export')}
          width={117}
          typeStyle={ButtonTypeEnum.SOLID_SECONDARY}
          onClick={handleExport}
        /> */}

      <Toolbar
        title={t('admin_manage_note_title')}
        primaryBtn={{ action: handleAddNotes, label: t('admin_manage_notes_button_add_note') }}
      />

      <section className={cx('noteContent')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            columns={columns}
            dataSource={noteResp?.responses || []}
            typeStyle={BaseTableEnum.COLOR_TABLE}
          />
        </div>

        <div className={cx('pagePagination')}>
          {noteResp?.pagination && (
            <BasePagination
              totalPages={noteResp?.pagination?.totalPages}
              totalItems={noteResp?.pagination?.totalItems}
              onChange={handleChangePagination}
            />
          )}
        </div>
      </section>

      {isDetailModal && (
        <AdminNoteDetailModal
          isOpen={isDetailModal}
          onClose={onCloseDetailModal}
          data={dataNoteDetail}
        />
      )}

      {isCreateModal && (
        <AdminCreateNoteModal
          isOpen={isCreateModal}
          onClose={onCloseCreateModal}
          onSuccess={handleGetListNote}
        />
      )}
    </div>
  );
};

export default ManageNote;
