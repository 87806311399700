// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './Estimations.module.scss';

const cx = classNames.bind(styles);

const Estimations = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAddEstimations = () => {
    //TODO: HuyPahmGRF-ABD Handle Add Estimations
  };
  //#endregion Handle Function

  return (
    <div id='estimationsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_sidebar_label_estimations')}
        primaryBtn={{
          action: handleAddEstimations,
          label: t('admin_manage_notes_button_add_estimations'),
        }}
      />

      <section className={cx('estimationsContent')}>
        <div className=''>{t('common_empty_data')}</div>
      </section>
    </div>
  );
};

export default Estimations;
