// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { getAuthUrl, getListQuickBookCustomer, getListQuickBookItem } from './quickBookThunk';

export interface QuickBookState {}

const initialState: QuickBookState = {};

const quickBookSlice = createSlice({
  name: 'quickBookState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get Auth Url
    builder
      .addCase(getAuthUrl.pending, (state) => {})
      .addCase(getAuthUrl.fulfilled, (state, action) => {})
      .addCase(getAuthUrl.rejected, (state) => {});

    // Get list quick book customer
    builder
      .addCase(getListQuickBookCustomer.pending, (state) => {})
      .addCase(getListQuickBookCustomer.fulfilled, (state, action) => {})
      .addCase(getListQuickBookCustomer.rejected, (state) => {});

    // Get list quick book customer
    builder
      .addCase(getListQuickBookItem.pending, (state) => {})
      .addCase(getListQuickBookItem.fulfilled, (state, action) => {})
      .addCase(getListQuickBookItem.rejected, (state) => {});
  },
});

export const quickBookActions = quickBookSlice.actions;

export const selectJobResp = (state: RootState) => state.quickBook;

const quickBookReducer = quickBookSlice.reducer;

export default quickBookReducer;
