// Libs
import classNames from 'classnames/bind';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import { CircleAvatar } from '~/components';
// Others
import {
  DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM,
  DEFAULT_ASSIGNEE_NUMBER_MORE,
  DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT,
  DEFAULT_ASSIGNEE_SLICE_START,
} from '~/utils/constants/component';
import { IAssignee } from '~/utils/interface/schedule';
import { CircleAvatarEnum } from '~/utils/enum';
import { generateGuid, getFullName } from '~/utils/helper';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './ScheduleAssignee.module.scss';

type Props = {
  assignees: IAssignee[];
  eventColor: string;
};

const cx = classNames.bind(styles);

const ScheduleAssignee = (props: Props) => {
  //#region Destructuring Props
  const { assignees, eventColor } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Declare Memo
  const displayedAssignee = useMemo(() => {
    if (!assignees) return;
    const listAssignee: IAssignee[] = assignees.slice(
      DEFAULT_ASSIGNEE_SLICE_START,
      DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
    );
    const numberMoreAssignee: number =
      assignees.length > DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
        ? assignees.length - DEFAULT_ASSIGNEE_MAX_DISPLAY_ITEM
        : DEFAULT_ASSIGNEE_NUMBER_MORE;

    return { listAssignee, numberMoreAssignee };
  }, [assignees]);

  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    displayedAssignee && (
      <div id='assigneeComponent' className={cx('container')}>
        {displayedAssignee.listAssignee.map((assignee: IAssignee, index: number) => {
          const uniqueId = `${generateGuid()}-${index}`;

          return (
            <div
              key={index}
              className={cx('eventAssigneeContainer')}
              style={{ left: DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT * index }}
            >
              <div
                data-tooltip-id={uniqueId}
                data-tooltip-place='top'
                data-tooltip-variant='light'
                className={cx('eventAssignee')}
                style={{
                  borderColor: eventColor,
                }}
              >
                <CircleAvatar
                  type={assignee.avatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                  imageUrl={assignee.avatarUrl}
                  firstName={assignee.firstName}
                  lastName={assignee.lastName}
                  width={22}
                  height={22}
                  fontSize={10}
                />

                <Tooltip id={uniqueId} className={cx('tooltipWrap')}>
                  <span className={cx('tooltipText')}>
                    {getFullName({
                      firstName: assignee.firstName,
                      lastName: assignee.lastName,
                    })}
                  </span>
                </Tooltip>
              </div>
            </div>
          );
        })}

        {displayedAssignee.numberMoreAssignee > 0 && (
          <div
            className={cx('eventAssigneeMoreContainer')}
            style={{
              left: DEFAULT_ASSIGNEE_NUMBER_POSITION_LEFT * displayedAssignee.listAssignee.length,
              backgroundColor: eventColor,
            }}
          >
            <div className={cx('eventAssigneeMore')}>+{displayedAssignee.numberMoreAssignee}</div>
          </div>
        )}
      </div>
    )
  );
};

export default ScheduleAssignee;
