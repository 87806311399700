// Libs
import classNames from 'classnames/bind';
// Others
import { TimeClockPageTemplate } from '~/pages';
// Styles, images, icons
import styles from './EmployeeTimeClock.module.scss';

const cx = classNames.bind(styles);

const EmployeeTimeClock = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return <TimeClockPageTemplate />;
};

export default EmployeeTimeClock;
