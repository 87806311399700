import { Library } from '@googlemaps/js-api-loader';
import { IBaseOption, IPosition, ISingleSelectOption } from '~/utils/interface/common';
import {
  AccountRoleCodesEnum,
  LinkToQuickBookCustomerEnum,
  OrderTabsEnum,
  ProposalTabEnum,
  PurchaseTypeEnum,
} from '../enum';
import {
  IAssignedSubContractor,
  IBudgetLabor,
  IBudgetPhase,
  IBudgetProduct,
  IEnableBudgetCategory,
  IPhaseContent,
  IUnassignedWork,
} from '../interface/estimate';
import { IBudgetLaborBreakdown, IBudgetProposal } from '../interface/proposal';

export const Languages = ['en'];

export const urlApiAuth = {
  login: 'auth/login',
  forgotPassword: 'auth/forgot-password',
  refreshToken: 'auth/refresh-token',
  createNewPassword: '/auth/new-password',
  verifyCode: 'auth/verify-code',
  changePassword: 'accounts/change-password',
};

export const urlApiEmployee = {
  employee: 'accounts',
  createEmployee: 'accounts/new',
  detail: (employeeId: number) => `accounts/${employeeId}`,
  update: 'accounts',
  updateStatus: (employeeId: string) => `accounts/${employeeId}/status`,
};

export const urlApiFixtureLocation = {
  getList: 'fixture-locations',
  create: 'fixture-locations/new',
  updateFixtureLocation: (id: string) => `fixture-locations/${id}`,
  delete: (id: string) => `fixture-locations/${id}`,
};

export const urlApiTechnician = {
  technician: 'technicians',
  createTechnician: 'technicians/new',
};

export const urlApiNote = {
  note: 'notes',
  getNoteDetail: 'notes',
  createNewNote: 'notes/new',
};

export const urlApiJob = {
  job: 'jobs',
  addNewJob: 'jobs/new',
  getJobAssignees: (id: number) => `jobs/assignee/${id}`,
  createNewContractJob: 'jobs/new',
};

export const urlApiQuickBook = {
  getAuthUrl: 'quick-books/auth-url',
  getToken: 'quick-books/token',
  disconnect: 'quick-books/disconnect',
  getListCustomer: 'quick-books/customers',
  getListItem: 'quick-books/items',
};

export const urlApiAccountant = {
  getAccountList: 'accounts',
};

export const urlApiDocument = {
  document: 'documents',
  getListDocumentJob: 'documents',
  addDocumentJob: 'documents/new',
  downloadDocumentJob: 'documents/download',
  getDocumentJobDetails: 'documents',
  updateDocument: (id: string) => `documents/${id}`,
  delete: (id: string) => `documents/${id}`,
};

export const urlApiClient = {
  getList: 'clients',
  getDetails: 'clients',
  delete: 'clients',
  create: 'clients/new',
  update: 'clients',
  sendToQB: (clientId: string) => `clients/${clientId}/send-to-quick-books`,
};

export const urlApiProduct = {
  getList: 'products',
  create: 'products/new',
  updateProduct: (id: string) => `products/${id}`,
  delete: (id: string) => `products/${id}`,
};

export const urlApiTimeClock = {
  getList: 'time-clocks',
  getDetails: 'time-clocks',
  delete: 'time-clocks',
  create: 'time-clocks/new',
};

export const urlApiVendors = {
  getList: 'vendors',
  create: 'vendors/new',
  changeStatus: (id: string) => `vendors/status/${id}`,
  getDetails: (id: string) => `vendors/${id}`,
  updateVendor: (id: string) => `vendors/${id}`,
  updateContactVendor: (id: string) => `vendors/${id}`,
};

export const urlSchedule = {
  getSchedule: 'schedules',
  createSchedule: 'schedules/new',
  deleteSchedule: 'schedules',
};

export const urlApiProfile = {
  getProfile: 'accounts/profile',
};

export const urlApiTask = {
  getTaskList: 'tasks',
  createTask: 'tasks/new',
  deleteTask: (id: string) => `tasks/${id}`,
  getTaskDetails: (id: string) => `tasks/${id}`,
  updateTask: (id: string) => `tasks/${id}`,
};

export const urlApiService = {
  getList: 'phase-services',
  create: 'phase-services/new',
  delete: (id: string) => `phase-services/${id}`,
  updateService: (id: string) => `phase-services/${id}`,
};

export const urlApiEstimate = {
  getList: 'estimates',
  getListTemplate: 'estimates/template',
  create: 'estimates/new',
  update: (id: string) => `estimates/${id}`,
  delete: (id: string) => `estimates/${id}`,
  detail: (id: number) => `estimates/${id}`,
  detailByJob: (jobId: string) => `estimates/by-job/${jobId}`,
};

export const urlApiOverview = {
  getList: 'overview/total',
};

export const urlTermsAndConditions = {
  createInclusionExclusion: 'term-inclusion-exclusion/new',
  getListInclusionExclusion: 'term-inclusion-exclusion',
  deleteInclusionExclusion: (id: string) => `term-inclusion-exclusion/${id}`,
  updateInclusionExclusion: (id: string) => `term-inclusion-exclusion/${id}`,
  createMainContent: 'term-main-contents/new',
  deleteTermMainContent: (id: string) => `term-main-contents/${id}`,
  getListTermMainContent: 'term-main-contents',
  getMainContentDetail: (id: string) => `term-main-contents/${id}`,
  updateMainContent: (id: string) => `term-main-contents/${id}`,
  getListTermTemplate: 'term-main-contents/term-import',
};

export const urlApiLabor = {
  getList: 'labors',
};

export const urlApiProposal = {
  createProposal: 'proposals/new',
  detailProposal: (jobId: string) => `proposals/jobs/${jobId}`,
  updateProposal: (proposalId: string) => `proposals/${proposalId}`,
  getListLineItem: (jobId: string) => `proposals/${jobId}/line-item`,
  sendDocument: (jobId: string) => `proposals/send-for-signature/${jobId}`,
};

export const urlRefreshToken = 'auth/refresh-token';

export const urlApiInvoice = {
  getList: 'invoices',
  createInvoice: 'invoices/new',
  getDetail: (invoiceId: string) => `invoices/${invoiceId}`,
};

export const urlApiPurchaseOrder = {
  createPurchaseOrder: 'purchase-orders/new',
  getList: 'purchase-orders',
  getPurchaseOrderDetail: (id: string) => `purchase-orders/${id}`,
};

export const urlApiStatistic = {
  getStatisticChart: 'statistics',
};

// Default Value
export const LOGIN_TYPE = 'STANDARD';
export const NOT_AVAILABLE_VALUE = 'N/A';
export const PERCENT = '%';
export const ASTERISK_SYMBOL = '*';
export const DEFAULT_NUMBER_RECORD_TO_FETCH = 10;
export const Z_INDEX_MAX = 99;
export const MIN_PERCENT_VALUE = 0;
export const MAX_PERCENT_VALUE = 100;
export const MAX_VISIBLE_IMAGE = 4;
export const MIN_VISIBLE_IMAGE = 0;
export const DEFAULT_NUMBER_ZERO = 0;
export const MONTHS_IN_YEAR = 12;
export const DEFAULT_NUMBER_ONE = 1;
export const NAVIGATE_GO_BACK = -1;
export const DEFAULT_BOOLEAN_FALSE = false;
export const EMPTY_STRING = '';
export const EMPTY_VALUE = '--';
export const EMPTY_LIST = [];
export const MAX_LENGTH_DESCRIPTIONS = 2000;
export const COUNTRY_CODE = '+1';
export const DEFAULT_TOTAL_PAGES = 0;
export const DEFAULT_TOTAL_ITEMS = 0;
export const DEFAULT_MAP_ZOOM = 17;
export const DEFAULT_HEIGHT_GOOGLE_MAP = 407;
export const DEFAULT_INDEX_OF_NULL = -1;
export const DEFAULT_SLICE_ONE_ITEM = 1;
export const DEFAULT_VALUE_LABOR = '1';
export const DEFAULT_NUMBER_OPTIONS_SELECT = 1000;
export const CONTENT_TYPE_FORM_DATA = 'multipart/form-data';
export const DEFAULT_MIN_MINUS = 10;
export const DIV_ELEMENT = 'div';
export const ATTRIBUTE_ID = 'id';
export const PIXEL = 'px';
export const CURRENT_VALUE = 'current';
export const LIMIT_TAG_ASSIGNEE = 3;
export const PLUS_CHARACTER = '+';
export const UNDERSCORE_CHARACTER = '_';
export const DEFAULT_UPLOAD_ACCEPT_IMAGE = 'image/*';
export const DEFAULT_UPLOAD_ACCEPT_IMAGE_AND_PDF = 'image/*,application/pdf';
export const RANDOM_RANGE = 1000;
export const LENGTH_LIST_SELECTION = 1000;
export const ONE_THOUSAND = 1000;
export const K_SUFFIX = 'k';
export const DEFAULT_CURRENT_PAGE = 1;
export const TIME_CLOSE_TOAST = 4000;
export const LIMIT_TAG_CUSTOMER = 2;
export const NUMBER_COLUMNS_VERIFY = 4;
export const MINUTES_IN_HOUR = 60;
export const MAX_LENGTH_AVATAR = 3;
export const TIME_OUT_SHOW_TOAST = 500;
export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_CURRENCY = '$0.00';
export const DEFAULT_CURRENCY_NUMBER = '0.00';
export const FORM_PHASE_MODE_CREATE = 'create';
export const FORM_PHASE_MODE_EDIT = 'edit';
export const FORM_ESTIMATE_MODE_CREATE = 'create';
export const FORM_ESTIMATE_MODE_EDIT = 'edit';
export const FORM_MAIN_TERM_MODE_CREATE = 'create';
export const FORM_MAIN_TERM_MODE_EDIT = 'edit';
export const DEFAULT_DEBOUNCE_SEARCH = 300;
export const DEFAULT_DELAY_TIME = 1000;
export const DEFAULT_HEIGHT_TEXT_EDITOR = 200;
export const DEFAULT_GG_MAP_LOAD_SCRIPT_LIB: Library[] = ['places'];
export const DEFAULT_MAP_POSITION: IPosition = { lat: 0, lng: 0 };
export const ISO_DATE_TIME_SEPARATOR = 'T';
export const DEFAULT_VALUE_EMPTY_RICH_EDITOR = '<p><br></p>';
export const DEFAULT_VALUE_CURRENCY = '$0';
export const DEFAULT_MAX_PERCENT = 100;
export const DEFAULT_MIN_PERCENT = 0;
export const OBJECT = 'object';
export const A_ELEMENT = 'a';
export const DEFAULT_BYTE_VALUE = 1024;
export const DEFAULT_TO_FIXED = 2;
export const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB'];
export const ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER = '.pdf, .jpg, .jpeg, .png';
export const POUND = '#';
export const PERCENTAGE_FACTOR = 100;
export const DEFAULT_PROPOSAL_FILE_NAME_PDF = 'ProposalDocument.pdf';
export const PDF_POSITION_PLACEHOLDER_TO_SIGNING = '{{sign|1|*}}';
export const PDF_POSITION_PLACEHOLDER_DATE_SIGNED = '{{editdate|1|*|dd MMM,yyyy}}';

// Pagination
export const DEFAULT_LIMIT_PAGE = 10;
export const defaultCurrentPage = 1;
export const MAX_VISIBLE_PAGE = 4;
export const SYMBOL_THREE_DOTS = '•••';
export const DEFAULT_TOTAL_PAGE = 0;
export const DEFAULT_TOTAL_ITEM = 0;

// Websocket
export const REASON_CLOSE_WSS_LOGOUT = 'LOGOUT';
export const WSS_INVOICE_SEND_STATUS_SUCCESS = 'Update Status Success';

// Options Select Type Date
export const DATA_OPTIONS_SELECT_DATE = [
  { label: 'Last Month', value: 'lastMonth' },
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'This Week', value: 'thisWeek' },
  { label: 'This Month', value: 'thisMonth' },
];

export const monthsThreeCharacter = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
// ToolbarOptions.ts
//TODO HuyPahmGRF-ADB Update options when add more option
export const toolbarOptions = [
  [
    {
      className: 'ql-font',
      options: ['serif', 'monospace'],
    },
  ],
  [{ className: 'ql-bold' }, { className: 'ql-italic' }, { className: 'ql-underline' }],
  [
    {
      className: 'ql-align',
      value: [''],
    },
    {
      className: 'ql-align',
      value: ['center'],
    },
    {
      className: 'ql-align',
      value: ['right'],
    },
  ],
  [{ className: 'ql-image' }],
  [
    {
      className: 'ql-list',
      value: 'check',
    },
  ],
];

export const toolbarOptionsRichEditor = [
  [
    { header: [1, 2, 3, 4, 5, 6, false] },
    'bold',
    'italic',
    'underline',
    'strike',
    { list: 'ordered' },
    { list: 'bullet' },
    { indent: '-1' },
    { indent: '+1' },
    'image',
    'link',
    {
      color: [
        '#000000',
        '#e60000',
        '#ff9900',
        '#ffff00',
        '#008a00',
        '#0066cc',
        '#9933ff',
        '#ffffff',
        '#facccc',
        '#ffebcc',
        '#ffffcc',
        '#cce8cc',
        '#cce0f5',
        '#ebd6ff',
        '#bbbbbb',
        '#f06666',
        '#ffc266',
        '#ffff66',
        '#66b966',
        '#66a3e0',
        '#c285ff',
        '#888888',
        '#a10000',
        '#b26b00',
        '#b2b200',
        '#006100',
        '#0047b2',
        '#6b24b2',
        '#444444',
        '#5c0000',
        '#663d00',
        '#666600',
        '#003700',
        '#002966',
        '#3d1466',
      ],
    },
  ],
];

export const toolbarOptionsRichEditorShort = ['bold', 'italic', 'underline'];

export const toolbarOptionsRichEditorCustom = [
  { header: [1, 2, 3, 4, 5, 6, false] },
  'bold',
  'italic',
  'underline',
  { list: 'ordered' },
  { list: 'bullet' },
  'link',
];

// Regex
export const HTML_TAG_REGEX = /(<([^>]+)>)/gi;
export const REGEX = {
  EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  TEXT: /\D/,
  PASSWORD: /^.{8,}$/,
  NUMBER: /[0-9]/,
  NUMBER_MAX_LENGTH_FOUR: /^[0-9]{4}$/,
  UPPERCASE: /[A-Z]/,
  LOWERCASE: /[a-z]/,
  SPECIAL_CHARACTERS: /[!@#$&]/,
  REPLACE_TEXT_ALL: /\D/g,
  CURRENCY: /[^\d]/g,
  REPLACE_NUMBER: /[$,]/g,
};
export const REGEX_FIND_FIRST_CHARACTER = /(?:^\w|[A-Z]|\b\w|\s+)/g;
export const REGEX_FIND_WHITESPACE_OR_UNDERSCORES = /\s+|_+/g;
export const REGEX_REMOVE_NON_NUMERIC_CHARACTERS = /[^0-9]/g;

// Money
export const CURRENCY_UNIT = '$';

//Proposal tabs
export const proposalTab = [
  {
    name: 'admin_manage_proposal_tab_label_active',
    params: ProposalTabEnum.ACTIVE,
  },
  {
    name: 'admin_manage_proposal_tab_label_archive',
    params: ProposalTabEnum.ARCHIVE,
  },
];

export const documentsTypeOptions: ISingleSelectOption[] = [
  { label: 'Contract', value: 'CONTRACT' },
  { label: 'Project Files', value: 'PROJECT_FILES' },
  { label: 'Invoice', value: 'INVOICE' },
  { label: 'Form', value: 'FORM' },
  { label: 'Task Data', value: 'TASK_DATA' },
  { label: 'Template', value: 'TEMPLATE' },
];

export const documentRemarkOptions: ISingleSelectOption[] = [
  {
    label: 'Signed',
    value: 'SIGNED',
  },
  {
    label: 'Paid',
    value: 'PAID',
  },
  {
    label: 'Assigned',
    value: 'ASSIGNED',
  },
];

export const contractJobStatusOptions: ISingleSelectOption[] = [
  {
    label: 'Lead',
    value: 'LEAD',
  },
  {
    label: 'Bidding',
    value: 'BIDDING',
  },
  {
    label: 'Out For Signature',
    value: 'OUT_FOR_SIGNATURE',
  },
  {
    label: 'Proposal Expired',
    value: 'PROPOSAL_EXPIRED',
  },
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Pending Changes',
    value: 'PENDING_CHANGES',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

export const prioryJobOptions: ISingleSelectOption[] = [
  {
    label: 'High',
    value: 'HIGH',
  },
  {
    label: 'Medium',
    value: 'MEDIUM',
  },
  {
    label: 'Low',
    value: 'LOW',
  },
];

export const statesUSOptions: ISingleSelectOption[] = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PA', value: 'PA' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WV', value: 'WV' },
  { label: 'WI', value: 'WI' },
  { label: 'WY', value: 'WY' },
];

export const jobRemindersOptions: ISingleSelectOption[] = [
  { label: 'Daily Standup', value: 'DAILY_STANDUP' },
  { label: 'Weekly Report', value: 'WEEKLY_REPORT' },
  { label: 'Monthly Review', value: 'MONTHLY_REVIEW' },
  { label: 'Quarterly Goals', value: 'QUARTERLY_GOALS' },
  { label: 'Annual Performance', value: 'ANNUAL_PERFORMANCE' },
  { label: 'Project Deadline', value: 'PROJECT_DEADLINE' },
  { label: 'Team Meeting', value: 'TEAM_MEETING' },
  { label: 'Client Follow-Up', value: 'CLIENT_FOLLOW_UP' },
  { label: 'Training Session', value: 'TRAINING_SESSION' },
  { label: 'Feedback Collection', value: 'FEEDBACK_COLLECTION' },
];

// Options State
export const optionsState = [
  {
    label: 'Howland Island',
    value: 'Howland Island',
    latitude: 0.8113219,
    longitude: -176.6182736,
  },
  {
    label: 'Delaware',
    value: 'Delaware',
    latitude: 38.9108325,
    longitude: -75.5276699,
  },
  {
    label: 'Alaska',
    value: 'Alaska',
    latitude: 64.2008413,
    longitude: -149.4936733,
  },
  {
    label: 'Maryland',
    value: 'Maryland',
    latitude: 39.0457549,
    longitude: -76.6412712,
  },
  {
    label: 'Baker Island',
    value: 'Baker Island',
    latitude: 0.1936266,
    longitude: -176.476908,
  },
  {
    label: 'Kingman Reef',
    value: 'Kingman Reef',
    latitude: 6.383333,
    longitude: -162.416667,
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire',
    latitude: 43.1938516,
    longitude: -71.5723953,
  },
  {
    label: 'Wake Island',
    value: 'Wake Island',
    latitude: 19.279619,
    longitude: 166.6499348,
  },
  {
    label: 'Kansas',
    value: 'Kansas',
    latitude: 39.011902,
    longitude: -98.4842465,
  },
  {
    label: 'Texas',
    value: 'Texas',
    latitude: 31.9685988,
    longitude: -99.9018131,
  },
  {
    label: 'Nebraska',
    value: 'Nebraska',
    latitude: 41.4925374,
    longitude: -99.9018131,
  },
  {
    label: 'Vermont',
    value: 'Vermont',
    latitude: 44.5588028,
    longitude: -72.5778415,
  },
  {
    label: 'Jarvis Island',
    value: 'Jarvis Island',
    latitude: -0.3743503,
    longitude: -159.9967206,
  },
  {
    label: 'Hawaii',
    value: 'Hawaii',
    latitude: 19.8967662,
    longitude: -155.5827818,
  },
  {
    label: 'Guam',
    value: 'Guam',
    latitude: 13.444304,
    longitude: 144.793731,
  },
  {
    label: 'United States Virgin Islands',
    value: 'United States Virgin Islands',
    latitude: 18.335765,
    longitude: -64.896335,
  },
  {
    label: 'Utah',
    value: 'Utah',
    latitude: 39.3209801,
    longitude: -111.0937311,
  },
  {
    label: 'Oregon',
    value: 'Oregon',
    latitude: 43.8041334,
    longitude: -120.5542012,
  },
  {
    label: 'California',
    value: 'California',
    latitude: 36.778261,
    longitude: -119.4179324,
  },
  {
    label: 'New Jersey',
    value: 'New Jersey',
    latitude: 40.0583238,
    longitude: -74.4056612,
  },
  {
    label: 'North Dakota',
    value: 'North Dakota',
    latitude: 47.5514926,
    longitude: -101.0020119,
  },
  {
    label: 'Kentucky',
    value: 'Kentucky',
    latitude: 37.8393332,
    longitude: -84.2700179,
  },
  {
    label: 'Minnesota',
    value: 'Minnesota',
    latitude: 46.729553,
    longitude: -94.6858998,
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma',
    latitude: 35.4675602,
    longitude: -97.5164276,
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania',
    latitude: 41.2033216,
    longitude: -77.1945247,
  },
  {
    label: 'New Mexico',
    value: 'New Mexico',
    latitude: 34.5199402,
    longitude: -105.8700901,
  },
  {
    label: 'American Samoa',
    value: 'American Samoa',
    latitude: -14.270972,
    longitude: -170.132217,
  },
  {
    label: 'Illinois',
    value: 'Illinois',
    latitude: 40.6331249,
    longitude: -89.3985283,
  },
  {
    label: 'Michigan',
    value: 'Michigan',
    latitude: 44.3148443,
    longitude: -85.6023643,
  },
  {
    label: 'Virginia',
    value: 'Virginia',
    latitude: 37.4315734,
    longitude: -78.6568942,
  },
  {
    label: 'Johnston Atoll',
    value: 'Johnston Atoll',
    latitude: 16.7295035,
    longitude: -169.5336477,
  },
  {
    label: 'West Virginia',
    value: 'West Virginia',
    latitude: 38.5976262,
    longitude: -80.4549026,
  },
  {
    label: 'Mississippi',
    value: 'Mississippi',
    latitude: 32.3546679,
    longitude: -89.3985283,
  },
  {
    label: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
    latitude: 15.0979,
    longitude: 145.6739,
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
    latitude: 19.2823192,
    longitude: 166.647047,
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts',
    latitude: 42.4072107,
    longitude: -71.3824374,
  },
  {
    label: 'Arizona',
    value: 'Arizona',
    latitude: 34.0489281,
    longitude: -111.0937311,
  },
  {
    label: 'Connecticut',
    value: 'Connecticut',
    latitude: 41.6032207,
    longitude: -73.087749,
  },
  {
    label: 'Florida',
    value: 'Florida',
    latitude: 27.6648274,
    longitude: -81.5157535,
  },
  {
    label: 'District of Columbia',
    value: 'District of Columbia',
    latitude: 38.9071923,
    longitude: -77.0368707,
  },
  {
    label: 'Midway Atoll',
    value: 'Midway Atoll',
    latitude: 28.2072168,
    longitude: -177.3734926,
  },
  {
    label: 'Navassa Island',
    value: 'Navassa Island',
    latitude: 18.4100689,
    longitude: -75.0114612,
  },
  {
    label: 'Indiana',
    value: 'Indiana',
    latitude: 40.2671941,
    longitude: -86.1349019,
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin',
    latitude: 43.7844397,
    longitude: -88.7878678,
  },
  {
    label: 'Wyoming',
    value: 'Wyoming',
    latitude: 43.0759678,
    longitude: -107.2902839,
  },
  {
    label: 'South Carolina',
    value: 'South Carolina',
    latitude: 33.836081,
    longitude: -81.1637245,
  },
  {
    label: 'Arkansas',
    value: 'Arkansas',
    latitude: 35.20105,
    longitude: -91.8318334,
  },
  {
    label: 'South Dakota',
    value: 'South Dakota',
    latitude: 43.9695148,
    longitude: -99.9018131,
  },
  {
    label: 'Montana',
    value: 'Montana',
    latitude: 46.8796822,
    longitude: -110.3625658,
  },
  {
    label: 'North Carolina',
    value: 'North Carolina',
    latitude: 35.7595731,
    longitude: -79.0192997,
  },
  {
    label: 'Palmyra Atoll',
    value: 'Palmyra Atoll',
    latitude: 5.8885026,
    longitude: -162.0786656,
  },
  {
    label: 'Puerto Rico',
    value: 'Puerto Rico',
    latitude: 18.220833,
    longitude: -66.590149,
  },
  {
    label: 'Colorado',
    value: 'Colorado',
    latitude: 39.5500507,
    longitude: -105.7820674,
  },
  {
    label: 'Missouri',
    value: 'Missouri',
    latitude: 37.9642529,
    longitude: -91.8318334,
  },
  {
    label: 'New York',
    value: 'New York',
    latitude: 40.7127753,
    longitude: -74.0059728,
  },
  {
    label: 'Maine',
    value: 'Maine',
    latitude: 45.253783,
    longitude: -69.4454689,
  },
  {
    label: 'Tennessee',
    value: 'Tennessee',
    latitude: 35.5174913,
    longitude: -86.5804473,
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    latitude: 32.1656221,
    longitude: -82.9000751,
  },
  {
    label: 'Alabama',
    value: 'Alabama',
    latitude: 32.3182314,
    longitude: -86.902298,
  },
  {
    label: 'Louisiana',
    value: 'Louisiana',
    latitude: 30.9842977,
    longitude: -91.9623327,
  },
  {
    label: 'Nevada',
    value: 'Nevada',
    latitude: 38.8026097,
    longitude: -116.419389,
  },
  {
    label: 'Iowa',
    value: 'Iowa',
    latitude: 41.8780025,
    longitude: -93.097702,
  },
  {
    label: 'Idaho',
    value: 'Idaho',
    latitude: 44.0682019,
    longitude: -114.7420408,
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island',
    latitude: 41.5800945,
    longitude: -71.4774291,
  },
  {
    label: 'Washington',
    value: 'Washington',
    latitude: 47.7510741,
    longitude: -120.7401385,
  },
  {
    label: 'Ohio',
    value: 'Ohio',
    latitude: 40.4172871,
    longitude: -82.907123,
  },
];

// Options City
export const optionsCity = [
  { label: 'New York', value: 'New York' },
  { label: 'Los Angeles', value: 'Los Angeles' },
  { label: 'Chicago', value: 'Chicago' },
  { label: 'Houston', value: 'Houston' },
  { label: 'Phoenix', value: 'Phoenix' },
  { label: 'Philadelphia', value: 'Philadelphia' },
  { label: 'San Antonio', value: 'San Antonio' },
  { label: 'San Diego', value: 'San Diego' },
  { label: 'Dallas', value: 'Dallas' },
  { label: 'San Jose', value: 'San Jose' },
  { label: 'Austin', value: 'Austin' },
  { label: 'Jacksonville', value: 'Jacksonville' },
  { label: 'Fort Worth', value: 'Fort Worth' },
  { label: 'Columbus', value: 'Columbus' },
  { label: 'Charlotte', value: 'Charlotte' },
  { label: 'San Francisco', value: 'San Francisco' },
  { label: 'Indianapolis', value: 'Indianapolis' },
  { label: 'Seattle', value: 'Seattle' },
  { label: 'Denver', value: 'Denver' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Boston', value: 'Boston' },
  { label: 'El Paso', value: 'El Paso' },
  { label: 'Nashville', value: 'Nashville' },
  { label: 'Detroit', value: 'Detroit' },
  { label: 'Oklahoma City', value: 'Oklahoma City' },
  { label: 'Portland', value: 'Portland' },
  { label: 'Las Vegas', value: 'Las Vegas' },
  { label: 'Memphis', value: 'Memphis' },
  { label: 'Louisville', value: 'Louisville' },
  { label: 'Baltimore', value: 'Baltimore' },
  { label: 'Milwaukee', value: 'Milwaukee' },
  { label: 'Albuquerque', value: 'Albuquerque' },
  { label: 'Tucson', value: 'Tucson' },
  { label: 'Fresno', value: 'Fresno' },
  { label: 'Mesa', value: 'Mesa' },
  { label: 'Sacramento', value: 'Sacramento' },
  { label: 'Atlanta', value: 'Atlanta' },
  { label: 'Kansas City', value: 'Kansas City' },
  { label: 'Colorado Springs', value: 'Colorado Springs' },
  { label: 'Miami', value: 'Miami' },
  { label: 'Raleigh', value: 'Raleigh' },
  { label: 'Omaha', value: 'Omaha' },
  { label: 'Long Beach', value: 'Long Beach' },
  { label: 'Virginia Beach', value: 'Virginia Beach' },
  { label: 'Oakland', value: 'Oakland' },
  { label: 'Minneapolis', value: 'Minneapolis' },
  { label: 'Tulsa', value: 'Tulsa' },
  { label: 'Arlington', value: 'Arlington' },
  { label: 'New Orleans', value: 'New Orleans' },
  { label: 'Wichita', value: 'Wichita' },
];

// Options Zip Code
export const optionsZipCodes = [
  { label: '00501', value: '00501' },
  { label: '00544', value: '00544' },
  { label: '01001', value: '01001' },
  { label: '01002', value: '01002' },
  { label: '01005', value: '01005' },
  { label: '01007', value: '01007' },
  { label: '01008', value: '01008' },
  { label: '01010', value: '01010' },
  { label: '01011', value: '01011' },
  { label: '01012', value: '01012' },
  { label: '01013', value: '01013' },
  { label: '01020', value: '01020' },
  { label: '01027', value: '01027' },
  { label: '01028', value: '01028' },
  { label: '01030', value: '01030' },
  { label: '01031', value: '01031' },
  { label: '01032', value: '01032' },
  { label: '01033', value: '01033' },
  { label: '01034', value: '01034' },
  { label: '01035', value: '01035' },
  { label: '01036', value: '01036' },
  { label: '01037', value: '01037' },
  { label: '01038', value: '01038' },
  { label: '01039', value: '01039' },
  { label: '01040', value: '01040' },
  { label: '01041', value: '01041' },
  { label: '01050', value: '01050' },
  { label: '01053', value: '01053' },
  { label: '01054', value: '01054' },
  { label: '01056', value: '01056' },
  { label: '01057', value: '01057' },
  { label: '01059', value: '01059' },
  { label: '01060', value: '01060' },
  { label: '01061', value: '01061' },
  { label: '01062', value: '01062' },
  { label: '01063', value: '01063' },
  { label: '01066', value: '01066' },
  { label: '01068', value: '01068' },
  { label: '01069', value: '01069' },
  { label: '01070', value: '01070' },
  { label: '01071', value: '01071' },
  { label: '01072', value: '01072' },
  { label: '01073', value: '01073' },
  { label: '01074', value: '01074' },
  { label: '01075', value: '01075' },
  { label: '01077', value: '01077' },
  { label: '01079', value: '01079' },
  { label: '01080', value: '01080' },
  { label: '01081', value: '01081' },
  { label: '01082', value: '01082' },
  { label: '01083', value: '01083' },
  { label: '01084', value: '01084' },
  { label: '01085', value: '01085' },
  { label: '01086', value: '01086' },
  { label: '01088', value: '01088' },
  { label: '01089', value: '01089' },
  { label: '01090', value: '01090' },
  { label: '01092', value: '01092' },
  { label: '01093', value: '01093' },
  { label: '01094', value: '01094' },
  { label: '01095', value: '01095' },
  { label: '01096', value: '01096' },
  { label: '01097', value: '01097' },
  { label: '01098', value: '01098' },
  { label: '01101', value: '01101' },
  { label: '01102', value: '01102' },
  { label: '01103', value: '01103' },
  { label: '01104', value: '01104' },
  { label: '01105', value: '01105' },
  { label: '01106', value: '01106' },
  { label: '01107', value: '01107' },
  { label: '01108', value: '01108' },
  { label: '01109', value: '01109' },
  { label: '01111', value: '01111' },
  { label: '01115', value: '01115' },
  { label: '01116', value: '01116' },
  { label: '01118', value: '01118' },
  { label: '01119', value: '01119' },
  { label: '01128', value: '01128' },
  { label: '01129', value: '01129' },
  { label: '01138', value: '01138' },
  { label: '01139', value: '01139' },
  { label: '01144', value: '01144' },
  { label: '01151', value: '01151' },
  { label: '01152', value: '01152' },
  { label: '01195', value: '01195' },
  { label: '01199', value: '01199' },
];

export const optionType = [
  {
    value: 'MATERIAL_SUPPLIER',
    label: 'Material supplier',
  },
  {
    value: 'SUBCONTRACTOR',
    label: 'Subcontractor',
  },
  {
    value: 'EQUIPMENT_SUPPLIER',
    label: 'Equipment supplier',
  },
  {
    value: 'MISCELLANEOUS_EXPENSES',
    label: 'Miscellaneous expenses',
  },
];

export const dataOptionStatusCreateEmployee = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'ON_LEAVE', label: 'On leave' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const dataMockOptionExperienceLevels = [
  { value: 'BEGINNER', label: 'Beginner' },
  { value: 'INTERMEDIATE', label: 'Intermediate' },
  { value: 'ADVANCED', label: 'Advanced' },
  { value: 'EXPERT', label: 'Expert' },
];

export const OPTIONS_ROLE = [
  { value: AccountRoleCodesEnum.ADMIN, label: 'Admin' },
  { value: AccountRoleCodesEnum.STAFF, label: 'Staff' },
  { value: AccountRoleCodesEnum.TECHNICIAN, label: 'Technician' },
];

export const dataMockCategory = [{ value: 'SERVICE', label: 'Service' }];

export const dataMockVendor = [
  { value: '34', label: 'Beginner' },
  { value: '2', label: 'Intermediate' },
  { value: '3', label: 'Advanced' },
  { value: '4', label: 'Expert' },
];

// Keys of form Employee
export const FORM_EMPLOYEE_KEYS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  AVATAR: 'avatar',
  THUMBNAIL: 'thumbnail',
  JOINED_DATE: 'joinedDate',
  POSITION: 'position',
};

export const contractJobGarageHandOptions = [
  {
    value: 'RIGHT',
    label: 'admin_add_contract_job_garage_hand_right',
  },
  {
    value: 'LEFT',
    label: 'admin_add_contract_job_garage_hand_left',
  },
];

export const contractJobProjectTypeOptions = [
  {
    value: 'FIXED_PRICE',
    label: 'Fixed Price',
  },
  {
    value: 'FIXED_PRICE_WITH_AIA_BILLING',
    label: 'Fixed Price With AIA Billing',
  },
];

// export const DEFAULT_BUDGET_CATEGORY: IBudgetCategory = {
//   productName: '',
//   fixtureLocationName: '',
//   quantity: 0,
//   unitCost: 0,
//   subTotal: 0,
// };

export const DEFAULT_BUDGET_LABOR_BREAKDOWN: IBudgetLaborBreakdown = {
  hours: DEFAULT_NUMBER_ZERO,
  hourlyCost: DEFAULT_NUMBER_ZERO,
  subTotal: DEFAULT_NUMBER_ZERO,
};

export const DEFAULT_BUDGET_CATEGORY_PROPOSAL = {
  productName: EMPTY_STRING,
  fixtureLocationName: EMPTY_STRING,
  quantity: DEFAULT_NUMBER_ONE,
};

export const DEFAULT_UNASSIGNED_PROPOSAL = {
  name: EMPTY_STRING,
  value: DEFAULT_NUMBER_ZERO,
};

export const DEFAULT_ASSIGNED_PROPOSAL = {
  vendorId: DEFAULT_NUMBER_ZERO,
  value: DEFAULT_NUMBER_ZERO,
};

export const DEFAULT_BUDGET_LABOR_PROPOSAL = {
  id: EMPTY_STRING,
  hours: DEFAULT_NUMBER_ZERO,
  hourlyCost: DEFAULT_NUMBER_ZERO,
};

export const DEFAULT_ENABLE_BUDGET_CATEGORY: IEnableBudgetCategory = {
  laborBudget: false,
  subcontractorsBudget: false,
  equipmentBudget: false,
  miscellaneaBudget: false,
};

export const DEFAULT_ENABLE_BUDGET_PROPOSAL: IBudgetProposal = {
  materials: false,
  labors: false,
  subcontractor: false,
  equipments: false,
  miscellanea: false,
};

export const DEFAULT_BUDGET_COST_PHASE = 0;

export const MESSAGE_CONNECTED_QUICK_BOOK = 'MESSAGE_CONNECTED_QUICK_BOOK';

//Proposal tabs
export const orderTabs = [
  {
    name: 'order_tabs_purchase_order',
    params: OrderTabsEnum.PURCHASE_ORDER,
  },
  {
    name: 'order_tabs_change_order',
    params: OrderTabsEnum.CHANGE_ORDER,
  },
];

export const linkToQuickBookCustomerOptions: IBaseOption[] = [
  {
    label: 'No',
    value: LinkToQuickBookCustomerEnum.NO,
  },
  {
    label: 'Add New',
    value: LinkToQuickBookCustomerEnum.ADD_NEW,
  },
  {
    label: 'Add Existing',
    value: LinkToQuickBookCustomerEnum.ADD_EXISTING,
  },
];

export const PRODUCT_CATEGORY_OPTIONS: IBaseOption[] = [
  {
    label: 'Service',
    value: 'SERVICE',
  },
];

export const NAME_VALIDATE_TEST_EMPTY = 'isNotEmpty';

export const LABOR_OPTIONS: IBaseOption[] = [
  {
    label: 'Top Out Plumber',
    value: '1',
  },
  {
    label: 'Trim Plumber',
    value: '2',
  },
];

export const DEFAULT_BUDGET_CATEGORY: IBudgetProduct = {
  quantity: 1,
};

export const DEFAULT_BUDGET_LABOR: IBudgetLabor = {
  hours: 0,
  hourlyCost: 0,
};

export const DEFAULT_BUDGET_UNASSIGNED: IUnassignedWork = {
  value: 0,
};

export const DEFAULT_BUDGET_ASSIGNED: IAssignedSubContractor = {
  value: 0,
};

export const DEFAULT_PHASE_CONTENT: IPhaseContent = {
  name: '',
  materials: [DEFAULT_BUDGET_CATEGORY],
};

export const DEFAULT_INVOICE_SEND_FROM = 'Ancla Plumbing, LLC';

export const purchaseTypeOptions = [
  {
    label: 'Purchase Order (vendor will send an invoice)',
    value: PurchaseTypeEnum.PURCHASE_ORDER,
  },
  {
    label: 'Expense (paid with cash or debit cart)',
    value: PurchaseTypeEnum.EXPENSE,
  },
  {
    label: 'Flexible Spending (open PO)',
    value: PurchaseTypeEnum.FLEXIBLE_SPENDING,
  },
  {
    label: 'Reimbursement (company will reimburse me)',
    value: PurchaseTypeEnum.REIMBURSEMENT,
  },
];

export const defaultProducts = [
  {
    jobId: null,
    jobName: null,
    productId: null,
    productName: null,
    quantity: 1,
    unitCost: 0,
    subTotal: 0,
  },
];
