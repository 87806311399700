// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import accountantApi from '~/apis/accountant';
// Others
import { GET_ACCOUNT_LIST } from '~/utils/constants/actionType';
import { IQueryAccount } from '~/utils/interface/account';

export const getAccountList = createAsyncThunk(
  GET_ACCOUNT_LIST,
  async (payload: IQueryAccount, { rejectWithValue }) => {
    try {
      const res = await accountantApi.getAccountList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
