import { CONTENT_TYPE_FORM_DATA, urlApiDocument } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import {
  IDocumentJobDetails,
  IDocumentResp,
  IGetDocumentJobReq,
  IUpdateDocumentJobReq,
} from '~/utils/interface/document';
import { IJobDocument } from '~/utils/interface/job';

const documentApi = {
  getListDocument(params: ITableParams) {
    const url = `${urlApiDocument.document}`;
    return axiosClient.get<BaseResponse<IDocumentResp>>(url, {
      params: params,
    });
  },

  getListDocumentJob(params: IGetDocumentJobReq) {
    const url = `${urlApiDocument.getListDocumentJob}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IJobDocument[]>>>(url, {
      params: params,
    });
  },

  addDocumentJob(formData: FormData) {
    const url = `${urlApiDocument.addDocumentJob}`;
    return axiosClient.post(url, formData, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  downloadDocumentJob(documentId: string) {
    const url = `${urlApiDocument.downloadDocumentJob}/${documentId}`;
    return axiosClient.get<BaseResponse<ArrayBuffer>>(url);
  },

  getDocumentJobDetails(id: string) {
    const url = `${urlApiDocument.getDocumentJobDetails}/${id}`;
    return axiosClient.get<BaseResponse<IDocumentJobDetails>>(url);
  },

  updateDocumentJob(request: IUpdateDocumentJobReq) {
    const url = `${urlApiDocument.updateDocument(request.documentId)}`;
    return axiosClient.put<BaseResponse>(url, request.formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  deleteDocumentJob(id: string) {
    const url = `${urlApiDocument.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};

export default documentApi;
