// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
// Components, Layouts, Pages
import { FormInput } from '~/components';
// Others
import { CurrencyEnum, InputTypeEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ILinkPhase, IProposal } from '~/utils/interface/proposal';
import {
  DEFAULT_MAX_PERCENT,
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  PERCENT,
  REGEX_REMOVE_NON_NUMERIC_CHARACTERS,
} from '~/utils/constants/common';
// Styles, images, icons
import styles from './LinkedPhases.module.scss';

type Props = {
  lineItemIndex: number;
};

const cx = classNames.bind(styles);

const LinkedPhases = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const { control, setValue } = useFormContext<IProposal>();

  // Link Phases Fields useFieldArray
  const {
    fields: linkPhaseFields,
    append: appendLinkPhase,
    remove: removeLinkPhase,
  } = useFieldArray({
    control,
    name: `lineItems.${lineItemIndex}.linkPhases`,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare Form Watch
  const linkPhasesData = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.linkPhases`,
  });
  //#endregion Form Watch

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (linkPhaseFields.length > DEFAULT_NUMBER_ZERO) return;

    removeLinkPhase();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const dataSubTotal = (cost: number, markup: number): number => {
    return cost + (cost * markup) / DEFAULT_MAX_PERCENT;
  };

  const formatName = (input: string): string => {
    const firstCharacter = input.charAt(DEFAULT_NUMBER_ZERO).toUpperCase();
    const remainingCharacters = input.slice(DEFAULT_NUMBER_ONE).toLowerCase();
    return firstCharacter + remainingCharacters;
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    // const processedData = value.includes(PERCENT)
    //   ? value.replace(REGEX_REMOVE_NON_NUMERIC_CHARACTERS, EMPTY_STRING)
    //   : value.slice(DEFAULT_NUMBER_ZERO, value.length - DEFAULT_NUMBER_ONE);

    // const dataValidateMarkup = Math.min(Number(processedData), DEFAULT_MAX_PERCENT);

    setValue(`lineItems.${lineItemIndex}.linkPhases.${index}.markup`, +value);
    setValue(
      `lineItems.${lineItemIndex}.linkPhases.${index}.total`,
      dataSubTotal(linkPhaseFields[index].cost, +value)
    );
  };

  //#endregion Handle Function

  return (
    <div id='linkedPhasesPage' className={cx('container')}>
      {linkPhasesData && linkPhasesData.length > DEFAULT_NUMBER_ZERO && (
        <>
          <span className={cx('titleLabel')}>{t('linked_phases_title_label')}</span>
          <div className={cx('boxContent')}>
            <div className={cx('viewHeader')}>
              <div className={cx('viewColSpace')} />

              <div className={cx('colTow')}>
                <span>{t('linked_phases_title_label_cost_budget')}</span>
                <span className={cx('titleMarkup')}>{t('linked_phases_title_label_markup')}</span>
              </div>

              <span>{t('linked_phases_title_label_subtotal')}</span>
            </div>

            {linkPhasesData?.map((item: ILinkPhase, index: number) => (
              <div key={index} className={cx('viewItemLinked')}>
                <span className={cx('textTitleItem')}>{formatName(item.type)}</span>

                <div className={cx('colTow')}>
                  <span className={cx('textCostBudget')}>
                    {formatCurrency(CurrencyEnum.USD, item.cost)}
                  </span>

                  <Controller
                    control={control}
                    name={`lineItems.${lineItemIndex}.linkPhases.${index}.markup`}
                    render={({ field: { value, onChange } }) => (
                      <FormInput
                        name={`lineItems.${lineItemIndex}.linkPhases.${index}.markup`}
                        value={value}
                        onChange={(event) => handleChangeValue(event, index)}
                        // onChange={onChange}
                        position='text-center'
                        type={InputTypeEnum.NUMBER}
                        suffix='%'
                      />
                    )}
                  />
                </div>

                <FormInput
                  disabled
                  value={formatCurrency(CurrencyEnum.USD, item.total)}
                  position='text-right'
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LinkedPhases;
