// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { InputTypeEnum, InputTypeStyleEnum } from '~/utils/enum';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseInput.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  id?: string;
  type?: InputTypeEnum;
  typeStyle?: InputTypeStyleEnum;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  value?: string | number;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  messageError?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  icon?: string;
};

const cx = classNames.bind(styles);

const BaseInput = (props: Props) => {
  //#region Destructuring Props
  const {
    id,
    type = InputTypeEnum.TEXT,
    height = 31,
    width = '100%',
    typeStyle,
    placeholder,
    value,
    label,
    name,
    isRequired,
    onChange,
    disabled,
    messageError,
    prefix,
    suffix,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<InputTypeEnum>(type);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (type === InputTypeEnum.PASSWORD) {
      const newType = isShowPassword ? InputTypeEnum.TEXT : InputTypeEnum.PASSWORD;
      setInputType(newType);
    }
  }, [type, isShowPassword]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowPassword = () => {
    setIsShowPassword((prevState) => !prevState);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='baseInputComponent' className={cx('baseInputContainer', typeStyle)}>
      {label && (
        <label className={cx('baseInputLabel', typeStyle)} htmlFor={id}>
          {label}
          {isRequired && <span className={cx('baseInputLabelRequired')}>{ASTERISK_SYMBOL}</span>}
        </label>
      )}
      <div className={cx('baseInputContent', typeStyle)}>
        {prefix && <span className={cx('baseInputPrefix', typeStyle)}>{prefix}</span>}
        <input
          id={id}
          value={value}
          name={name}
          onChange={handleChangeInput}
          placeholder={placeholder}
          type={inputType}
          disabled={disabled}
          className={cx('baseInputBase', typeStyle)}
          style={{ height, width }}
        />
        {type === InputTypeEnum.PASSWORD && (
          <span className={cx('baseInputSuffix', typeStyle)}>
            <img
              className={cx('baseInputSuffixImg', typeStyle)}
              src={isShowPassword ? icons.adminIconShowPassword : icons.adminIconHidePassword}
              alt={t('common_img_text_alt')}
              onClick={handleShowPassword}
            />
          </span>
        )}
        {suffix && <span className={cx('baseInputSuffix', typeStyle)}>{suffix}</span>}
      </div>

      {messageError && <p className={cx('baseInputMessageError', typeStyle)}>{messageError}</p>}
    </div>
  );
};

export default BaseInput;
