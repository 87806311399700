// Libs
import { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { defaultNumberHeaderNotification } from '~/mockData';
import ModalUnderDevelopment from '../modalUnderDevelopment/ModalUnderDevelopment';
// Styles, images, icons
import styles from './MessageNotifications.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const MessageNotifications = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleMessageClicked = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <div id='messageNotifications' className={cx('messageNotificationsContainer')}>
      <div className={cx('messageNotificationsContent')} onClick={handleMessageClicked}>
        <img src={icons.commonIconMessage} alt={t('common_img_text_alt')} />
        <div className={cx('messageNotificationsNumberWrap')}>
          <span className={cx('messageNotificationsNumber')}>
            {defaultNumberHeaderNotification}
          </span>
        </div>
      </div>

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleMessageClicked} />}
    </div>
  );
};

export default MessageNotifications;
