// Libs
import { Document, Page, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
// Components, Layouts, Pages
import {
  InvoiceDescription,
  InvoiceFooter,
  InvoiceLineItems,
  InvoiceLogo,
  InvoiceScopeOfWork,
  InvoiceSignatureApproval,
  InvoiceTermsConditions,
  InvoiceTitle,
  InvoiceTotal,
  InvoiceUserInfo,
  Space,
} from '~/components/specific/proposalInvoicePdf/components';
// Others
import { IProposalPdfData } from '~/utils/interface/proposal';
// Styles, images, icons
import { styles } from '~/components/specific/proposalInvoicePdf/styles';

type Props = {
  dataPdf?: IProposalPdfData;
};

const ProposalInvoicePdf = (props: Props) => {
  //#region Destructuring Props
  const { dataPdf } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.mainContainer}>
          <InvoiceLogo />
          <Space height={20} />

          <InvoiceTitle />
          <Space height={20} />

          <InvoiceUserInfo data={dataPdf?.basicInfo} />
          <Space height={20} />

          {dataPdf?.description && (
            <Fragment>
              <InvoiceDescription description={dataPdf?.description} />
              <Space height={60} />
            </Fragment>
          )}

          <InvoiceScopeOfWork />
          <Space height={20} />

          {dataPdf?.lineItems && dataPdf.lineItems?.length > 0 && (
            <>
              {dataPdf.lineItems?.map((data, index) => (
                <Fragment key={index}>
                  <InvoiceLineItems data={data} index={index + 1} />
                </Fragment>
              ))}
              <Space height={30} />
            </>
          )}

          <InvoiceTotal data={dataPdf?.price} />
          <Space height={60} />

          {dataPdf?.termConditions && (
            <Fragment>
              <InvoiceTermsConditions termConditions={dataPdf?.termConditions} />
              <Space height={60} />
            </Fragment>
          )}

          <InvoiceSignatureApproval />
        </View>

        <InvoiceFooter />
      </Page>
    </Document>
  );
};

export default ProposalInvoicePdf;
