// Libs
import { Description, Field, Input, Label } from '@headlessui/react';
import classNames from 'classnames/bind';
import { ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
import {
  DEFAULT_INPUT_HEIGHT,
  DEFAULT_INPUT_TYPE,
  DEFAULT_INPUT_WIDTH,
} from '~/utils/constants/component';
import { InputTypeEnum, InputTypeStyleEnum } from '~/utils/enum';
import { TPositionTextInput } from '~/utils/type/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormInput.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  typeStyle?: InputTypeStyleEnum;
  name?: string;
  value?: string | number;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  position?: TPositionTextInput;
  suffix?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const cx = classNames.bind(styles);

const FormInput = (props: Props) => {
  //#region Destructuring Props
  const {
    height = DEFAULT_INPUT_HEIGHT,
    width = DEFAULT_INPUT_WIDTH,
    type = DEFAULT_INPUT_TYPE,
    typeStyle,
    label,
    placeholder,
    name,
    value,
    errorMessage,
    required,
    position = 'text-left',
    disabled = false,
    suffix,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [inputType, setInputType] = useState<HTMLInputTypeAttribute>(type);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (type !== InputTypeEnum.PASSWORD) return;
    const newType = isShowPassword ? InputTypeEnum.TEXT : InputTypeEnum.PASSWORD;
    setInputType(newType);
  }, [type, isShowPassword]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  //#endregion Handle Function

  return (
    <Field id='formInputComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('inputContainer')}>
        <Input
          style={{ height }}
          type={inputType}
          className={cx('mainInput', type, typeStyle, position)}
          placeholder={placeholder}
          name={name}
          value={value}
          disabled={disabled}
          onChange={onChange}
        />

        {type === InputTypeEnum.PASSWORD && (
          <span className={cx('suffixIconContainer')} onClick={handleShowPassword}>
            <img
              className={cx('icon', typeStyle)}
              src={isShowPassword ? icons.adminIconShowPassword : icons.adminIconHidePassword}
              alt={t('common_img_text_alt')}
            />
          </span>
        )}

        {suffix && <span className={cx('suffixIconContainer', 'percent')}>{suffix}</span>}
      </div>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default FormInput;
