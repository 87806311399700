// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseModal, FormInput } from '~/components';
import BaseSelect from '~/components/common/baseSelect/BaseSelect';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { selectUserProfile } from '~/thunks/profile/profileSlice';
import { getTaskDetails, getTaskList } from '~/thunks/task/taskThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CreateTimeClockTypeEnum } from '~/utils/enum';
import { getFullName } from '~/utils/helper';
import { IBaseOption, IPosition, IQueryBase, ISingleSelectOption } from '~/utils/interface/common';
import { IUserProfile } from '~/utils/interface/profile';
import { ITaskDetails } from '~/utils/interface/task';
import { ICreateTimeClock } from '~/utils/interface/timeClock';
import { LoadingContext } from '~/context';
import { createTimeClock } from '~/thunks/timeClock/timeClockThunk';
import { timeClockActions } from '~/thunks/timeClock/timeClockSlice';
// Styles, images, icons
import styles from './AdminAddTimeClockModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const cx = classNames.bind(styles);

const timeClockSchema = (t: TFunction) => {
  return yup
    .object({
      taskId: yup.string().required(t('admin_manage_time_clock_task_required')),
    })
    .required();
};

const AdminAddTimeClockModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? '',
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });

  const defaultData: ICreateTimeClock = useMemo(() => {
    return {
      taskId: EMPTY_STRING,
      jobName: EMPTY_STRING,
      taskName: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    setValue,
    handleSubmit,
    trigger,
    reset,
    formState: { errors },
  } = useForm<ICreateTimeClock>({
    resolver: yupResolver(timeClockSchema(t)),
    defaultValues: defaultData,
  });

  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserProfile = useSelector(selectUserProfile);
  //#endregion Selector

  //#region Declare State
  const [taskList, setTaskList] = useState<IBaseOption[]>([]);
  const [taskDetail, setTaskDetail] = useState<ITaskDetails>();
  const [paramObject] = useState<IQueryBase>({
    page: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_NUMBER_OPTIONS_SELECT,
  });
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!userInfo || !isOpen) return;

    setValue(
      CreateTimeClockTypeEnum.USERNAME,
      getFullName({ firstName: userInfo.firstName, lastName: userInfo.lastName })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, isOpen]);

  useEffect(() => {
    if (!taskDetail) return;

    setValue(CreateTimeClockTypeEnum.TASK_NAME, taskDetail.name);
    setValue(CreateTimeClockTypeEnum.JOB_NAME, taskDetail.job.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDetail]);

  useEffect(() => {
    if (!isOpen) return;

    getUserLocation();
    handleGetListTask();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCleanForm = () => {
    reset(defaultData);
    setPositionSelected(DEFAULT_MAP_POSITION);
  };

  const handleCloseModal = () => {
    handleCleanForm();
    onClose && onClose();
  };

  const handleGetListTask = () => {
    dispatch(getTaskList(paramObject))
      .unwrap()
      .then((res) => {
        const { responses } = res?.data;
        if (!responses) return;

        const taskList = responses.map((item) => {
          return {
            label: item.name ? item.name : EMPTY_STRING,
            value: String(item.id),
          };
        });

        setTaskList(taskList);
      })
      .catch((_error) => {})
      .finally(() => {});
  };

  const handleGetDetailTask = (id: string) => {
    dispatch(getTaskDetails(id))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setTaskDetail(res?.data);
      })
      .catch((_error) => {})
      .finally(() => {});
  };

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleChangeSelect = (option: ISingleSelectOption, name: keyof ICreateTimeClock) => {
    setValue(name, option.value, { shouldDirty: true });
    trigger(name);
    handleGetDetailTask(option.value);
  };

  const handleCheckIn = (data: ICreateTimeClock) => {
    loadingContext?.show();
    const body = {
      taskId: data.taskId,
      latitude: positionSelected.lat || DEFAULT_NUMBER_ZERO,
      longitude: positionSelected.lng || DEFAULT_NUMBER_ZERO,
      location: data.location || 'Rose Walk (Berkeley, California)',
    };

    dispatch(createTimeClock(body))
      .unwrap()
      .then((_resp) => {
        dispatch(timeClockActions.setRefreshTimeClockList(true));
      })
      .catch((_error) => {})
      .finally(() => {
        loadingContext?.hide();
        handleCloseModal();
      });
  };
  //#endregion Handle Function

  return (
    <BaseModal id='adminAddTimeClockModalComponent' isOpen={isOpen} onClose={handleCloseModal}>
      <form className={cx('modalContent')} onSubmit={handleSubmit(handleCheckIn)}>
        <div className={cx('modalHeader')}>{t('admin_manage_time_clock_add_time_clock_title')}</div>

        <div className={cx('line')} />

        <div className={cx('modalBody')}>
          <Controller
            name={CreateTimeClockTypeEnum.TASK_ID}
            control={control}
            render={({ field: { value, name } }) => (
              <BaseSelect
                label={t('admin_manage_time_clock_select_task_title')}
                placeholder={t('common_placeholder_select')}
                value={value}
                options={taskList}
                isRequired
                onChange={(optionSelected: IBaseOption) => handleChangeSelect(optionSelected, name)}
                errorMessage={errors.taskId?.message}
              />
            )}
          />

          <div className={cx('inputContent')}>
            <Controller
              name={CreateTimeClockTypeEnum.USERNAME}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  label={t('admin_manage_time_clock_user_name_title')}
                  placeholder={t('common_placeholder_select')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  disabled={true}
                />
              )}
            />

            <Controller
              name={CreateTimeClockTypeEnum.TASK_NAME}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  label={t('admin_manage_time_clock_task_name_title')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                />
              )}
            />
          </div>

          <Controller
            name={CreateTimeClockTypeEnum.JOB_NAME}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                label={t('admin_manage_time_clock_job_name_title')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />

          <div className={cx('mapSection')}>
            <label className={cx('textLabel')} htmlFor='mapView'>
              {t('admin_manage_time_clock_map_view_title')}
            </label>

            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: 350,
                  borderRadius: 12,
                  marginTop: 8,
                }}
                center={positionSelected}
                zoom={DEFAULT_MAP_ZOOM}
              >
                {positionSelected && <Marker position={positionSelected} />}
              </GoogleMap>
            )}
          </div>
        </div>

        <div className={cx('modalFooter')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            onClick={handleCloseModal}
          />
          <BaseButton
            label={t('admin_employee_time_clock_table_title_check_in')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default AdminAddTimeClockModal;
