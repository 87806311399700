// Libs
import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import { IFormSendDocument } from '~/utils/interface/proposal';
// Others

export const formSchema = (t: TFunction) => {
  return yup
    .object({
      emailTo: yup
        .array(yup.string().email(t('common_validate_format_some_emails')).required())
        .required()
        .min(1, t('proposal_send_document_validate_require_email_to')),
      emailCc: yup
        .array(yup.string().email(t('common_validate_format_some_emails')).required())
        .optional(),
      expirationDate: yup
        .string()
        .optional()
        .test('isFuture', t('common_validate_date_in_the_past'), (value) => {
          return value ? moment(value).isSameOrAfter(moment(), 'date') : true;
        }),
    })
    .required();
};

export const DEFAULT_DATA_FROM: IFormSendDocument = { emailTo: [] };
