// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import {
  createInclusionExclusion,
  getListTermMainContent,
  getListInclusionExclusion,
  createMainContent,
  deleteInclusionExclusion,
  deleteTermMainContent,
  updateInclusionExclusion,
  getMainContentDetail,
  updateMainContent,
  getListTermTemplate,
} from './termConditionsThunk';
import { RootState } from '~/redux/store';

export interface TermConditionsState {
  isRefreshListInclusionExclusion: boolean;
  isRefreshListMainContent: boolean;
}

const initialState: TermConditionsState = {
  isRefreshListInclusionExclusion: false,
  isRefreshListMainContent: false,
};

const termConditionsSlice = createSlice({
  name: 'termConditions',
  initialState,
  reducers: {
    setRefreshListInclusionExclusion(state, action) {
      state.isRefreshListInclusionExclusion = action.payload;
    },

    setRefreshListMainContent(state, action) {
      state.isRefreshListMainContent = action.payload;
    },
  },
  extraReducers(builder) {
    // Create Inclusion Exclusion
    builder
      .addCase(createInclusionExclusion.pending, (state) => {})
      .addCase(createInclusionExclusion.fulfilled, (state) => {})
      .addCase(createInclusionExclusion.rejected, (state) => {});

    // Get List Inclusion Exclusion
    builder
      .addCase(getListInclusionExclusion.pending, (state) => {})
      .addCase(getListInclusionExclusion.fulfilled, (state) => {})
      .addCase(getListInclusionExclusion.rejected, (state) => {});

    // Create Main Content
    builder
      .addCase(createMainContent.pending, (state) => {})
      .addCase(createMainContent.fulfilled, (state) => {})
      .addCase(createMainContent.rejected, (state) => {});

    // Delete Inclusion Exclusion
    builder
      .addCase(deleteInclusionExclusion.pending, (state) => {})
      .addCase(deleteInclusionExclusion.fulfilled, (state) => {})
      .addCase(deleteInclusionExclusion.rejected, (state) => {});

    // Delete Term Main Content
    builder
      .addCase(deleteTermMainContent.pending, (state) => {})
      .addCase(deleteTermMainContent.fulfilled, (state) => {})
      .addCase(deleteTermMainContent.rejected, (state) => {});

    // Update Inclusion Exclusion
    builder
      .addCase(updateInclusionExclusion.pending, (state) => {})
      .addCase(updateInclusionExclusion.fulfilled, (state) => {})
      .addCase(updateInclusionExclusion.rejected, (state) => {});

    // Get List Term Main Content
    builder
      .addCase(getListTermMainContent.pending, (state) => {})
      .addCase(getListTermMainContent.fulfilled, (state) => {})
      .addCase(getListTermMainContent.rejected, (state) => {});

    // Get Detail Term Main Content
    builder
      .addCase(getMainContentDetail.pending, (state) => {})
      .addCase(getMainContentDetail.fulfilled, (state) => {})
      .addCase(getMainContentDetail.rejected, (state) => {});

    // Update Term Main Content
    builder
      .addCase(updateMainContent.pending, (state) => {})
      .addCase(updateMainContent.fulfilled, (state) => {})
      .addCase(updateMainContent.rejected, (state) => {});

    // Get List Term Template
    builder
      .addCase(getListTermTemplate.pending, (state) => {})
      .addCase(getListTermTemplate.fulfilled, (state) => {})
      .addCase(getListTermTemplate.rejected, (state) => {});
  },
});

// Action
export const termConditionsAction = termConditionsSlice.actions;

// Selectors
export const selectIsRefreshListInclusionExclusion = (state: RootState) =>
  state.termConditions.isRefreshListInclusionExclusion;
export const selectIsRefreshListMainContent = (state: RootState) =>
  state.termConditions.isRefreshListMainContent;

const termConditionsReducer = termConditionsSlice.reducer;
export default termConditionsReducer;
