// Libs
// Components, Layouts, Pages
// Others
import { WHITE_OPACITY_50 } from '~/utils/constants/color';
// Styles, images, icons

type Props = {
  strokePath?: string;
  width?: number | string;
  height?: number | string;
};

const CommonSidebarIconArrow = (props: Props) => {
  //#region Destructuring Props
  const { strokePath = WHITE_OPACITY_50, width = 24, height = 24 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 9.5L12 14.5L7 9.5'
        stroke={strokePath}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CommonSidebarIconArrow;
