// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { NOT_AVAILABLE_VALUE } from '~/utils/constants/common';
import { IStatisticTop } from '~/utils/interface/common';
// Styles, images, icons

import styles from './StatisticTopItem.module.scss';
import { images } from '~/assets';

type Props = {
  data: IStatisticTop;
};

const cx = classNames.bind(styles);

const StatisticTopItem = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <div id='statisticTopItemComponent' className={cx('statisticTopItemComponent')}>
      <div className={cx('statisticTopItem')}>
        <div className={cx('group')}>
          <img
            className={cx('images')}
            src={data.avatarUrl ? data.avatarUrl : images.adminHeaderImageAvatar}
            alt={t('common_img_text_alt')}
          />

          <div className={cx('labelGroup')}>
            <div className={cx('name')}>
              {`${data.firstName ? data.firstName : NOT_AVAILABLE_VALUE} ${
                data.lastName ? data.lastName : NOT_AVAILABLE_VALUE
              }`}
            </div>
            <div className={cx('description')}>
              {data.experience ? data.experience : NOT_AVAILABLE_VALUE}
            </div>
          </div>
        </div>

        <div className={cx('labelGroup')}>
          <div className={cx('rate')}>
            {data.rateSuccess ? data.rateSuccess : NOT_AVAILABLE_VALUE}
          </div>
          <div className={cx('description')}>
            {data.description ? data.description : NOT_AVAILABLE_VALUE}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticTopItem;
