// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import classNames from 'classnames/bind';
import { useMemo, useState } from 'react';
import {
  Controller,
  FieldError,
  GlobalError,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  BaseButton,
  BaseDatePicker,
  BaseModal,
  FormTagInput,
  Loading,
  ProposalInvoicePdf,
} from '~/components';
// Others
import { DEFAULT_DATA_FROM, formSchema } from '~/components/specific/formSendDocument/helper';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { sendDocumentToSigner } from '~/thunks/proposal/proposalThunk';
import { DEFAULT_PROPOSAL_FILE_NAME_PDF, EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { formatAddress, getFullName, getTimezone } from '~/utils/helper';
import {
  IFormSendDocument,
  IPayloadSendDocument,
  IProposal,
  IProposalPdfData,
} from '~/utils/interface/proposal';
// Styles, images, icons
import styles from './FormSendDocument.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const FormSendDocument = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const { control: proposalControl } = useFormContext<IProposal>();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormSendDocument>({
    resolver: yupResolver(formSchema(t)),
    defaultValues: DEFAULT_DATA_FROM,
  });
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useAppSelector((state) => state.job.jobDetails);
  //#endregion Selector

  //#region Declare State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  const lineItems = useWatch({ control: proposalControl, name: 'lineItems' });
  const proposalValue = useWatch({ control: proposalControl, name: 'value' });
  const proposalTaxRate = useWatch({ control: proposalControl, name: 'taxRate' });
  const proposalTerm = useWatch({ control: proposalControl, name: 'termsConditions' });
  const proposalDesc = useWatch({ control: proposalControl, name: 'description' });

  const dataPDF: IProposalPdfData = useMemo(() => {
    const clientName = getFullName({
      firstName: jobDetails?.client?.firstName,
      lastName: jobDetails?.client?.lastName,
    });

    const projectName = jobDetails?.name;
    const address = formatAddress({
      address: jobDetails?.streetAddress,
      city: jobDetails?.city,
      country: jobDetails?.country,
      state: jobDetails?.state,
      zipCode: jobDetails?.zipCode,
    });

    const taxPercent = +(proposalTaxRate ?? 0);
    const subtotal = proposalValue ?? 0;
    const tax = (+(proposalTaxRate ?? 0) / 100) * subtotal;
    const total = subtotal + tax;

    return {
      basicInfo: { clientName, projectName, address },
      lineItems: lineItems,
      price: { subtotal, tax, taxPercent, total },
      termConditions: proposalTerm ?? '',
      description: proposalDesc ?? '',
    };
  }, [jobDetails, lineItems, proposalValue, proposalTaxRate, proposalTerm]);

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseModal = () => {
    reset(DEFAULT_DATA_FROM);
    onClose && onClose();
  };

  const processSubmit = async (data: IFormSendDocument) => {
    if (!jobId || !data) return;

    try {
      setIsLoading(true);

      const formData: FormData = createFormData(data);

      const pdfBlob = await generatePdfBlob();
      if (pdfBlob) {
        formData.append('document', pdfBlob, DEFAULT_PROPOSAL_FILE_NAME_PDF);
      }

      const payload: IPayloadSendDocument<FormData> = { body: formData, jobId };
      await dispatch(sendDocumentToSigner(payload)).unwrap();

      handleCloseModal();
    } catch (error) {
      console.error('Submit failed', error);
    } finally {
      setIsLoading(false);
    }
  };

  const createFormData = (data: IFormSendDocument): FormData => {
    const formData = new FormData();
    const formDataFields = {
      timeZone: getTimezone(),
      emailTo: data.emailTo ? JSON.stringify(data.emailTo) : EMPTY_STRING,
      emailCc: data.emailCc ? JSON.stringify(data.emailCc) : EMPTY_STRING,
      expirationDate: data.expirationDate || EMPTY_STRING,
    };

    Object.entries(formDataFields).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    return formData;
  };

  const generatePdfBlob = async (): Promise<Blob | null> => {
    try {
      return await pdf(<ProposalInvoicePdf dataPdf={dataPDF} />).toBlob();
    } catch (error) {
      console.error('Generate PDF failed', error);
      return null;
    }
  };

  const displayErrorMessages = (errors: FieldError | GlobalError[]): string => {
    if (Array.isArray(errors)) {
      for (const error of errors) {
        if (error?.message) {
          return error.message;
        }
      }
      return '';
    }

    if (errors?.message) {
      return errors.message;
    }

    return '';
  };
  //#endregion Handle Function

  return (
    <BaseModal
      id='formSendDocumentComponent'
      isOpen={isOpen}
      onClose={handleCloseModal}
      width={1140}
      height={'80vh'}
    >
      <form className={cx('modalContent')} onSubmit={handleSubmit(processSubmit)}>
        <div className={cx('modalHeader')}>{t('proposal_send_document_title')}</div>
        <div className={cx('line')} />

        <div className={cx('modalBody')}>
          <div className={cx('mainContent')}>
            <section className={cx('formSection')}>
              <Controller
                name={'emailTo'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <FormTagInput
                    label={t('proposal_send_document_form_field_email_to')}
                    name={name}
                    required
                    maxTags={10}
                    tagValues={value}
                    onChange={onChange}
                    errorMessage={displayErrorMessages(
                      errors?.emailTo as FieldError | GlobalError[]
                    )}
                  />
                )}
              />

              <Controller
                name={'emailCc'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <FormTagInput
                    label={t('proposal_send_document_form_field_email_cc')}
                    name={name}
                    tagValues={value}
                    maxTags={10}
                    onChange={onChange}
                    errorMessage={displayErrorMessages(
                      errors?.emailCc as FieldError | GlobalError[]
                    )}
                  />
                )}
              />

              <Controller
                name={'expirationDate'}
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <BaseDatePicker
                    label={t('proposal_send_document_form_field_expiration_date')}
                    name={name}
                    placeholderText={t('common_placeholder_select_date')}
                    onDateSelected={onChange}
                    value={value}
                    errorMessage={errors?.expirationDate?.message}
                  />
                )}
              />
            </section>

            <section className={cx('presentPdfSection')}>
              {dataPDF && (
                <PDFViewer style={{ flexDirection: 'column', width: '100%', height: '600px' }}>
                  <ProposalInvoicePdf dataPdf={dataPDF} />
                </PDFViewer>
              )}
            </section>
          </div>
        </div>

        <div className={cx('modalFooter')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            onClick={handleCloseModal}
          />
          <BaseButton
            label={t('proposal_send_document_btn_send')}
            width={220}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='submit'
          />
        </div>
      </form>
      {isLoading && <Loading />}
    </BaseModal>
  );
};

export default FormSendDocument;
