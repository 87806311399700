// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import purchaseOrderApi from '~/apis/purchaseOrder';
import { CREATE_PURCHASE_ORDER, GET_DETAIL_PO, GET_LIST_PO } from '~/utils/constants/actionType';
import { IQueryListPO } from '~/utils/interface/purchaseOrder';

export const createPurchaseOrder = createAsyncThunk(
  CREATE_PURCHASE_ORDER,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.createPurchaseOrder(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListPO = createAsyncThunk(
  GET_LIST_PO,
  async (payload: IQueryListPO, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getListPurchaseOrder(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailPurchaseOrder = createAsyncThunk(
  GET_DETAIL_PO,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await purchaseOrderApi.getPurchaseOrderDetail(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
