// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import {
  ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER,
  ASTERISK_SYMBOL,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IValueFile } from '~/utils/interface/common';
// Styles, images, icons
import styles from './BaseUploadDocument.module.scss';
import { icons } from '~/assets';

type Props<T> = {
  label?: string;
  name?: string;
  values?: T[];
  multiple?: boolean;
  content?: string;
  accept?: string;
  errorMessage?: string;
  required?: boolean;
  onFilesChange?: (data: T[]) => void;
};

const cx = classNames.bind(styles);

const BaseUploadDocument = <T extends IValueFile>(props: Props<T>) => {
  //#region Destructuring Props
  const {
    label,
    name,
    values = [],
    multiple = false,
    content,
    accept = ACCEPT_UPLOAD_DOCUMENT_PURCHASE_ORDER,
    errorMessage,
    required,
    onFilesChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileList, setFileList] = useState<T[]>(values);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!values || values?.length === 0) return;
    setFileList(values);
  }, [values]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files ? Array.from(event.target.files) : [];

    if (files?.length === 0) return;

    const newFiles = files.map((file) => ({ url: URL.createObjectURL(file), file: file } as T));
    const updatedFileList = [...fileList, ...newFiles];
    setFileList(updatedFileList);

    if (onFilesChange) {
      onFilesChange(updatedFileList);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = EMPTY_STRING;
    }
  };

  const handleRemoveFile = (index: number) => {
    const updatedFileList = fileList.filter((_, i) => i !== index);
    setFileList(updatedFileList);

    if (onFilesChange) {
      onFilesChange(updatedFileList);
    }
  };
  //#endregion Handle Function

  return (
    <div id='baseUploadDocumentComponent' className={cx('container')}>
      {label && (
        <div className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className={cx('filesUpload')}>
        {fileList && fileList.length > 0 && (
          <>
            {fileList.map((item, index) => (
              <div className={cx('file')} key={index}>
                {item.file?.type?.startsWith('image/') || item.url?.match(/\.(jpg|jpeg)$/i) ? (
                  <img src={item.url} alt={t('common_img_text_alt')} className={cx('fileImg')} />
                ) : (
                  <img
                    src={icons.commonIconFile}
                    alt={t('common_img_text_alt')}
                    className={cx('fileIcon')}
                  />
                )}

                <div className={cx('fileClose')} onClick={() => handleRemoveFile(index)}>
                  <img
                    src={icons.commonIconClose}
                    alt={t('common_img_text_alt')}
                    className={cx('fileCloseImg')}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        <label htmlFor={name} className={cx('uploadContainer')}>
          <input
            ref={fileInputRef}
            type='file'
            name={name}
            id={name}
            className={cx('uploadInput')}
            multiple={multiple}
            accept={accept}
            onChange={handleUploadFile}
          />

          <div className={cx('uploadContentGroup')}>
            <img
              src={icons.commonIconUpload}
              alt={t('common_img_text_alt')}
              className={cx('uploadIcon')}
            />

            <span className={cx('uploadContent')}>{content || t('common_upload_content')}</span>
          </div>
        </label>
      </div>

      {errorMessage && <div className={cx('errorMessage')}>{errorMessage}</div>}
    </div>
  );
};

export default BaseUploadDocument;
