// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import { Button } from '@headlessui/react';
import { useContext, useEffect, useMemo } from 'react';
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getToken } from '~/thunks/quickBook/quickBookThunk';
import styles from './QuickBookCallBack.module.scss';
import { useSearchParams } from 'react-router-dom';
import { IQueryGetTokenQuickBook } from '~/utils/interface/quickBook';
import { profileActions } from '~/thunks/profile/profileSlice';
import { MESSAGE_CONNECTED_QUICK_BOOK } from '~/utils/constants/common';

const cx = classNames.bind(styles);

const QuickBookCallBack = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State

  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const payload: IQueryGetTokenQuickBook = {
      code: params.code,
      state: params.state,
      realmId: params.realmId,
    };
    handleGetTokenQuickBook(payload);
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTokenQuickBook = (payload: IQueryGetTokenQuickBook) => {
    loadingContext?.show();

    dispatch(getToken(payload))
      .unwrap()
      .then((res) => {
        window.opener.postMessage(MESSAGE_CONNECTED_QUICK_BOOK, '*');
        window.close();
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };
  //#endregion Handle Function
  return <>Loading...</>;
};

export default QuickBookCallBack;
