import moment from 'moment';
import { Event, View, Views } from 'react-big-calendar';
import {
  IScheduleEvent,
  IScheduleTimelineData,
  ITimelineEvent,
  ITimelineGroup,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';

/** CALENDAR **/
export const prepareMonthEventsData = (events: IScheduleEvent[]): Event[] => {
  const monthEvents: Event[] = [];
  for (let event of events) {
    let monthEvent = monthEvents.find((eventItem: Event) => {
      return moment(eventItem.start).isSame(moment(event.startDate), 'date');
    });

    if (!monthEvent) {
      monthEvent = {
        start: moment(event.startDate).toDate(),
        end: moment(event.startDate).endOf('date').toDate(),
        resource: [],
      };
      monthEvents.push(monthEvent);
    }

    const eventResource: IScheduleEvent = {
      ...event,
      startTime: moment(event.startTime).format('hh:mm A'),
      endTime: moment(event.endTime).format('hh:mm A'),
    };

    monthEvent.resource.push(eventResource);
  }
  return monthEvents;
};

export const prepareTimeEventsData = (events: IScheduleEvent[]): Event[] => {
  return events.map((event: IScheduleEvent) => {
    return {
      start: moment(event.startTime).toDate(),
      end: moment(event.endTime).toDate(),
      resource: [
        {
          ...event,
          startTime: moment(event.startTime).format('hh:mm A'),
          endTime: moment(event.endTime).format('hh:mm A'),
        },
      ],
    };
  });
};

/** TIMELINE **/
export const prepareTimelineEventsData = (
  events: IScheduleTimelineData[],
  view: View
): ITimelinePreparedData => {
  const timelineEvent: ITimelineEvent[] =
    view === Views.WEEK ? getWeekItemTimelineEvent(events) : getDayItemTimelineEvent(events);
  const timelineGroup: ITimelineGroup[] = getGroupTimelineEvent(events);
  return { event: timelineEvent, group: timelineGroup };
};

export const getGroupTimelineEvent = (events: IScheduleTimelineData[]): ITimelineGroup[] => {
  if (events.length <= 0) return [];
  const result: ITimelineGroup[] = [];

  for (let event of events) {
    const { id, avatarUrl, firstName, lastName, schedules } = event;

    let newGroupItem = result.find(
      (groupAvailable: ITimelineGroup) => groupAvailable.id === id.toString()
    );

    if (!newGroupItem) {
      newGroupItem = {
        id: id,
        title: `${firstName} ${lastName}`,
        avatarUrl: avatarUrl ?? '',
        schedules: schedules,
      };

      result?.push(newGroupItem);
    }
  }

  return result;
};

export const getWeekItemTimelineEvent = (events: IScheduleTimelineData[]): ITimelineEvent[] => {
  if (events.length <= 0) return [];
  const result: ITimelineEvent[] = [];

  for (let eventItem of events) {
    const { id, schedules } = eventItem;
    const groupedSchedules: { [key: string]: ITimelineEvent } = {};

    if (!schedules) return [];
    schedules.forEach((scheduleItem: IScheduleEvent) => {
      const groupKey = moment(scheduleItem.startDate).format('YYYY-MM-DD');
      const start_time = moment(scheduleItem.startDate).startOf('day');
      const end_time = moment(scheduleItem.startDate).endOf('day');

      if (!groupedSchedules[groupKey]) {
        groupedSchedules[groupKey] = {
          id: `${scheduleItem.id}-${id}`,
          group: id,
          start_time,
          end_time,
          resource: [],
        };
      }

      const scheduleItemResource: IScheduleEvent = {
        ...scheduleItem,
        startTime: moment(scheduleItem.startTime).format('hh:mm A'),
        endTime: moment(scheduleItem.endTime).format('hh:mm A'),
      };

      groupedSchedules[groupKey]?.resource?.push(scheduleItemResource);
    });

    result.push(...Object.values(groupedSchedules));
  }

  return result;
};

export const getDayItemTimelineEvent = (events: IScheduleTimelineData[]): ITimelineEvent[] => {
  if (events.length <= 0) return [];
  const result: ITimelineEvent[] = [];

  for (let eventItem of events) {
    const { id, schedules } = eventItem;
    const groupedSchedules: { [key: string]: ITimelineEvent } = {};

    if (!schedules) return [];
    schedules.forEach((scheduleItem: IScheduleEvent) => {
      const startTimeString = moment(scheduleItem.startTime).format('HH:mm:ss');
      const endTimeString = moment(scheduleItem.endTime).format('HH:mm:ss');

      const groupKey = moment(`${scheduleItem.startDate} ${startTimeString}`)
        .startOf('hour')
        .format('YYYY-MM-DDTHH:mm:ss');

      const startDateTime = `${scheduleItem.startDate} ${startTimeString}`;
      const endDateTime = `${scheduleItem.endDate} ${endTimeString}`;

      const start_time = moment(startDateTime).startOf('hour');
      const end_time = moment(endDateTime).endOf('hour');

      if (!groupedSchedules[groupKey]) {
        groupedSchedules[groupKey] = {
          id: `${scheduleItem.id}-${id}`,
          group: id,
          start_time,
          end_time,
          resource: [],
        };
      }

      const scheduleItemResource: IScheduleEvent = {
        ...scheduleItem,
        startTime: moment(scheduleItem.startTime).format('hh:mm A'),
        endTime: moment(scheduleItem.endTime).format('hh:mm A'),
      };

      groupedSchedules[groupKey]?.resource?.push(scheduleItemResource);
    });

    result.push(...Object.values(groupedSchedules));
  }

  return result;
};

export const capitalizeFirstLetter = (view: string) => {
  return view.charAt(0).toUpperCase() + view.slice(1).toLowerCase();
};

/**
 * Combines the provided date and time, then converts the result to UTC format (ISO 8601).
 * @param date The date portion in format YYYY-MM-DD.
 * @param time The time portion as a Date object or string that can be parsed by Moment.js.
 * @returns The combined date and time in UTC format (ISO 8601).
 */
export const getDateTimeInUTC = (date: string, time: Date | string): string => {
  return moment(`${date} ${moment(time).format('HH:mm:ss')}`).toISOString();
};
