import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { createSchedule, deleteSchedule, getScheduleEvents } from '~/thunks/schedule/scheduleThunk';
import { DEFAULT_SCHEDULE_VIEW, SCHEDULE_MODE_MYSELF } from '~/utils/constants/component';
import { DateFormatEnum } from '~/utils/enum';
import { TCalendarViewMode, TScheduleMode } from '~/utils/type/schedule';

export interface ScheduleState {
  scheduleMode: TScheduleMode;
  calendarView: TCalendarViewMode;
  currentTime: string;
  refreshSchedule: boolean;
}

const initialState: ScheduleState = {
  scheduleMode: SCHEDULE_MODE_MYSELF,
  calendarView: DEFAULT_SCHEDULE_VIEW,
  currentTime: moment().format(DateFormatEnum['YYYY-MM-DD']),
  refreshSchedule: false,
};

const scheduleSlice = createSlice({
  name: 'scheduleState',
  initialState,
  reducers: {
    goToday(state) {
      state.currentTime = moment().format(DateFormatEnum['YYYY-MM-DD']);
    },
    goPreviousTime(state) {
      const previousTime = moment(state.currentTime).subtract(1, state.calendarView);
      state.currentTime = previousTime.format(DateFormatEnum['YYYY-MM-DD']);
    },
    goNextTime(state) {
      const nextTime = moment(state.currentTime).add(1, state.calendarView);
      state.currentTime = nextTime.format(DateFormatEnum['YYYY-MM-DD']);
    },
    changeScheduleMode(state, action: PayloadAction<TScheduleMode>) {
      state.scheduleMode = action.payload;
    },
    changeCalendarViewMode(state, action: PayloadAction<TCalendarViewMode>) {
      state.currentTime = moment().format(DateFormatEnum['YYYY-MM-DD']);
      state.calendarView = action.payload;
    },
    setRefreshScheduleList(state, action) {
      state.refreshSchedule = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getScheduleEvents.pending, (state) => {})
      .addCase(getScheduleEvents.fulfilled, (state) => {})
      .addCase(getScheduleEvents.rejected, (state) => {});

    // Create schedule
    builder
      .addCase(createSchedule.pending, (state) => {})
      .addCase(createSchedule.fulfilled, (state, action) => {})
      .addCase(createSchedule.rejected, (state) => {});

    // Delete schedule
    builder
      .addCase(deleteSchedule.pending, (state) => {})
      .addCase(deleteSchedule.fulfilled, (state) => {})
      .addCase(deleteSchedule.rejected, (state) => {});
  },
});

// Action
export const scheduleActions = scheduleSlice.actions;

// Reducer
const scheduleReducer = scheduleSlice.reducer;
export default scheduleReducer;
