import { TFunction } from 'i18next';
import * as yup from 'yup';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_VALUE_LABOR,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IBreakDown, ILineItems } from '~/utils/interface/proposal';

export const lineItemSchema = (t: TFunction) => {
  return yup.object({
    name: yup.string().required(t('template_proposal_line_items_name_required')),
    price: yup.string().required(t('template_proposal_line_items_price_required')),
    isTax: yup.boolean().optional(),
    comment: yup.string().optional().nullable(),
    linkPhase: yup.object().optional(),
    materials: yup.array().optional(),
    subcontractor: yup.object().optional(),
    labors: yup.array().optional(),
    equipments: yup.array().optional(),
    miscellanea: yup.array().optional(),
  });
};

export const proposalSchema = (t: TFunction) => {
  return yup.object({
    jobId: yup.string().optional(),
    attn: yup.string().optional().nullable(),
    po: yup.string().optional().nullable(),
    contract: yup.string().optional(),
    estimateEnd: yup.string().optional().nullable(),
    paymentTerm: yup.string().optional().nullable(),
    description: yup.string().optional().nullable(),
    termsConditions: yup.string().optional().nullable(),
    taxRate: yup.string().optional().nullable(),
    estimateStart: yup.string().required(t('template_proposal_estimate_start_date_required')),
    lineItems: yup.array(lineItemSchema(t)).required(t('template_proposal_line_items_required')),
  });
};

const defaultDataBreakDownLineItem: IBreakDown = {
  materials: [
    {
      productName: EMPTY_STRING,
      fixtureLocationName: EMPTY_STRING,
      quantity: DEFAULT_NUMBER_ONE,
    },
  ],
  subcontractor: {
    assignedSubcontractors: [
      {
        value: DEFAULT_NUMBER_ZERO,
        vendorId: DEFAULT_NUMBER_ZERO,
      },
    ],
    unassignedWorks: [
      {
        name: EMPTY_STRING,
        value: DEFAULT_NUMBER_ZERO,
      },
    ],
  },
  equipments: [
    {
      productName: EMPTY_STRING,
      fixtureLocationName: EMPTY_STRING,
      quantity: DEFAULT_NUMBER_ONE,
    },
  ],
  labor: [
    {
      id: DEFAULT_VALUE_LABOR,
      hours: DEFAULT_NUMBER_ZERO,
      hourlyCost: DEFAULT_NUMBER_ZERO,
      subTotal: DEFAULT_NUMBER_ZERO,
    },
  ],
  miscellanea: [
    {
      productName: EMPTY_STRING,
      fixtureLocationName: EMPTY_STRING,
      quantity: DEFAULT_NUMBER_ONE,
    },
  ],
};

export const defaultDataLineItem: ILineItems = {
  name: EMPTY_STRING,
  price: EMPTY_STRING,
  isTax: false,
  subcontractor: {},
  equipments: [],
  labors: [],
  materials: [],
  miscellanea: [],
  isShowBreakDown: false,
  linkPhases: [],
};
