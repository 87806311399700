// Libs
import classNames from 'classnames/bind';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { InputTypeEnum } from '~/utils/enum';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Others
// Styles, images, icons
import styles from './DatePicker.module.scss';
import { icons } from '~/assets';

type Props = {
  name?: string;
  width?: number;
  height?: number;
  label?: string;
  value?: string;
  type?: string;
  isRequired?: boolean;
  errorMessage?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const cx = classNames.bind(styles);

const DatePicker = (props: Props) => {
  //#region Destructuring Props
  const { name, width, height, label, value, type, isRequired, errorMessage, onChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleDatePickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };
  //#endregion Handle Function

  return (
    <div id='datePickerComponent' className={cx('container')} style={{ width, height }}>
      {label && (
        <label htmlFor={name} className={cx('label')}>
          {label}
          {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </label>
      )}

      <div className={cx('datePickerGroup')}>
        <input
          type={type ?? InputTypeEnum.DATE}
          name={name}
          id={name}
          value={value}
          className={cx('inputDatePicker')}
          onChange={handleDatePickerChange}
        />

        <img
          src={icons.commonIconDatePicker}
          className={cx('datePickerIcon')}
          alt={t('common_img_text_alt')}
        />
      </div>

      {errorMessage && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default DatePicker;
