// Libs
import { Dialog, DialogPanel } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseModal, BaseSelect } from '~/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { IBaseOption } from '~/utils/interface/common';
import { IContractItem } from '~/utils/interface/estimate';
// Styles, images, icons
import styles from './SetContractItemModal.module.scss';

type Props = {
  listLineItem: IBaseOption[];
  lineSelected?: string;
  isOpen: boolean;
  description: string;
  onClose: () => void;
  onChange?: (value: string) => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      contractItem: yup.string().required(t('set_contract_item_validate_required_contract_item')),
    })
    .required();
};

const SetContractItemModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, description, listLineItem, lineSelected, onClose, onChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const defaultFormValues = useMemo(() => {
    return {
      contractItem: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IContractItem>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultFormValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!listLineItem || !lineSelected) return;
    reset({ contractItem: lineSelected });
  }, [listLineItem, lineSelected]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSave = (dataForm: IContractItem) => {
    if (!dataForm) return;
    onChange && onChange(dataForm.contractItem);
    handleCancel();
  };

  const handleCancel = () => {
    reset({ contractItem: lineSelected });
    onClose();
  };
  //#endregion Handle Function

  return (
    <BaseModal isOpen={isOpen} onClose={handleCancel}>
      <div className={cx('header')}>{t('set_contract_item_title')}</div>

      <div className={cx('line')} />

      <span className={cx('description')}>{description}</span>

      <form>
        <div className={cx('modalBody')}>
          <Controller
            name={'contractItem'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                isRequired
                label={t('set_contract_item_label_contract_item')}
                placeholder={t('common_placeholder_select')}
                value={value}
                options={listLineItem || []}
                onChange={(option: IBaseOption, name: string) => {
                  onChange(option.value);
                }}
                errorMessage={errors.contractItem?.message}
              />
            )}
          />
        </div>

        <div className={cx('modalFooter')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            type='button'
            onClick={handleCancel}
          />

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='button'
            onClick={handleSubmit(handleSave)}
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default SetContractItemModal;
