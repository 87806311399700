// Libs
import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
// Others
import jobApi from '~/apis/job';
import {
  ADD_NEW_JOB,
  CREATE_NEW_CONTRACT_JOB,
  GET_JOB_ASSIGNEES,
  GET_JOB_DETAILS,
  GET_LIST_JOB,
} from '~/utils/constants/actionType';
import { ICreateNewContractJob, IGetJobDetailsReq, IGetListJobReq } from '~/utils/interface/job';

export const getListJob = createAsyncThunk(GET_LIST_JOB, async (payload: IGetListJobReq) => {
  try {
    const res = await jobApi.getListJob(payload);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const getJobDetails = createAsyncThunk(
  GET_JOB_DETAILS,
  async ({ id, params }: { id: string; params: IGetJobDetailsReq }) => {
    try {
      const res = await jobApi.getJobDetails(id, params);
      return res.data;
    } catch (error) {
      return isRejectedWithValue(error);
    }
  }
);

export const addNewJob = createAsyncThunk(ADD_NEW_JOB, async (formData: FormData) => {
  try {
    const res = await jobApi.addNewJob(formData);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const getJobAssignees = createAsyncThunk(GET_JOB_ASSIGNEES, async (id: number) => {
  try {
    const res = await jobApi.getJobAssignees(id);
    return res.data;
  } catch (error) {
    return isRejectedWithValue(error);
  }
});

export const createNewContractJob = createAsyncThunk(
  CREATE_NEW_CONTRACT_JOB,
  async (body: ICreateNewContractJob) => {
    try {
      const res = await jobApi.createNewContractJob(body);
      return res.data;
    } catch (error) {
      return isRejectedWithValue(error);
    }
  }
);
