// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, BaseInput, BaseTextarea, Loading, UploadAvatar } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { updateClient } from '~/thunks/client/clientThunk';
import { IClientDetail, IUpdateClient, IUpdateClientBody } from '~/utils/interface/client';
import { EMPTY_STRING, REGEX } from '~/utils/constants/common';
import { ButtonTypeEnum, InputTypeEnum } from '~/utils/enum';
import { compressImage, getFullName, validateWithRegex } from '~/utils/helper';
// Styles, images, icons
import styles from './AdminUpdateClientModal.module.scss';

type Props = {
  dataClientUpdate: IClientDetail;
  onClose: () => void;
  onSuccess: () => void;
};

const cx = classNames.bind(styles);

const AdminUpdateClientModal = (props: Props) => {
  //#region Destructuring Props
  const { dataClientUpdate, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formValues, setFormValues] = useState<IUpdateClient>({
    contactName: dataClientUpdate.contactName,
    email: dataClientUpdate.email,
    name: getFullName({ ...dataClientUpdate }),
    note: dataClientUpdate.note,
    other: dataClientUpdate.other,
    phoneNumber: dataClientUpdate.phoneNumber,
    avatar: dataClientUpdate.avatar,
  });
  const [formErrors, setFormErrors] = useState<IUpdateClient>({
    name: EMPTY_STRING,
  });
  const [fileAvatar, setFileAvatar] = useState<File>();
  const [thumbnailClient, setThumbnailClient] = useState<File>();
  const [isDisableSave, setIsDisableSave] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setIsDisableSave(!validateForm(formValues));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleUpdateClient = () => {
    if (!formValues?.name) return;

    const formData = new FormData();

    fileAvatar && formData.append('avatar', fileAvatar);
    thumbnailClient && formData.append('thumbnail', thumbnailClient);
    formValues?.name && formData.append('name', formValues.name);
    formValues?.contactName && formData.append('contactName', formValues.contactName);
    formValues?.phoneNumber && formData.append('phoneNumber', formValues.phoneNumber);
    formValues?.email && formData.append('email', formValues.email);
    formValues?.other && formData.append('other', formValues.other);
    formValues?.note && formData.append('note', formValues.note);

    setIsLoading(true);

    const updateClientBody: IUpdateClientBody = {
      id: dataClientUpdate.id,
      body: formData,
    };

    dispatch(updateClient(updateClientBody))
      .unwrap()
      .then((res) => {
        // TODO: Handle show toast message success

        onSuccess();
      })
      .catch((error) => {
        // TODO: Handle show toast message error
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFormData = { ...formValues, [event.target.name]: event.target.value };
    setFormValues(newFormData);
    setIsDisableSave(!validateForm(newFormData));
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;

    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = async (name: string, file: File | null) => {
    if (!name || !file) return;

    const imageURL = URL.createObjectURL(file);

    setFormValues((prevState) => ({ ...prevState, [name]: imageURL }));
    setThumbnailClient(await compressImage(file));
    setFileAvatar(file);
  };

  const validateForm = (form: IUpdateClient): boolean => {
    const requiredKeys: Array<keyof IUpdateClient> = ['name'];

    setFormErrors((prevState) => ({
      ...prevState,
      name: form.name ? EMPTY_STRING : 'common_validate_require_name',
    }));

    setFormErrors((prevState) => ({
      ...prevState,
      email: form.email
        ? validateWithRegex(form.email, REGEX.EMAIL)
          ? EMPTY_STRING
          : 'common_validate_email_required'
        : EMPTY_STRING,
    }));

    if (!form.name || (!validateWithRegex(form.email!, REGEX.EMAIL) && form.email)) {
      return false;
    }

    return requiredKeys.every((key) => (form[key] as string)?.trim() !== EMPTY_STRING);
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='AdminUpdateClientModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={onClose}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}

        <div className={cx('modalContent')}>
          <div className={cx('modalHeader')}>{t('admin_manage_clients_update_client_title')}</div>

          <div className={cx('line')} />

          <div className={cx('body')}>
            <div className={cx('quickBooks')}>
              <span className={cx('quickBooksLabel')}>{`${t(
                'admin_manage_clients_quick_books_label'
              )}:`}</span>

              <div
                className={cx('quickBooksStatus', { active: dataClientUpdate.quickBookCustomerId })}
              ></div>
            </div>

            <div className={cx('avatarContent')}>
              <UploadAvatar
                name='avatar'
                urlAvatar={formValues.avatar!}
                firstName={formValues.name}
                onFileChange={handleFileChange}
              />
            </div>

            <BaseInput
              name='name'
              label={t('admin_manage_clients_client_name_label')}
              type={InputTypeEnum.TEXT}
              isRequired
              onChange={handleInputChange}
              messageError={t(formErrors.name!)}
              value={formValues.name}
            />

            <div className={cx('bodyGroup')}>
              <BaseInput
                name='contactName'
                label={t('admin_manage_clients_main_contact_label')}
                type={InputTypeEnum.TEXT}
                onChange={handleInputChange}
                value={formValues.contactName}
              />

              <BaseInput
                name='phoneNumber'
                label={t('admin_manage_clients_phone_number_label')}
                type={InputTypeEnum.NUMBER}
                onChange={handleInputChange}
                value={formValues.phoneNumber}
              />

              <BaseInput
                name='email'
                label={t('admin_manage_clients_email_label')}
                type={InputTypeEnum.TEXT}
                onChange={handleInputChange}
                value={formValues.email}
                messageError={t(formErrors.email!)}
              />

              <BaseInput
                name='other'
                label={t('admin_manage_clients_other_label')}
                type={InputTypeEnum.TEXT}
                onChange={handleInputChange}
                value={formValues.other}
              />
            </div>

            <BaseTextarea
              name='note'
              label={t('admin_manage_clients_notes_label')}
              height={118}
              maxLength={100}
              onTextareaChange={handleTextAreaChange}
              value={formValues.note}
            />
          </div>

          <div className={cx('actions')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={handleCancel}
                typeStyle={ButtonTypeEnum.CANCEL}
              />
            </div>

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              onClick={handleUpdateClient}
              isDisable={isDisableSave}
            />
          </div>
        </div>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminUpdateClientModal;
