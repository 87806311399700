// Libs
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { publicRouteAbsolute } from '~/utils/constants/route';
// Components, Layouts, Pages
// Others
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
// Styles, images, icons

type Props = {
  role: AccountRoleCodesEnum;
};

const ProtectedRoute = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoggedIn] = useState<string | null>(
    localStorage.getItem(StorageEnum.ACCESS_TOKEN)! || null
  );
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return isLoggedIn && currentRole === role ? (
    <Outlet />
  ) : (
    <Navigate to={publicRouteAbsolute.login} />
  );
};

export default ProtectedRoute;
