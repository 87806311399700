import { StorageEnum, WebSocketEventEnum, WebSocketTypeEnum } from '~/utils/enum';
import { IPayloadWSS } from '~/utils/interface/wss';

export const wssLogin = (webSocket: WebSocket) => {
  const accountId = localStorage.getItem(StorageEnum.ACCOUNT_ID);
  if (!accountId) return;

  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.USE_APP,
    data: {
      accountId: +accountId,
      event: WebSocketEventEnum.LOGIN,
    },
  };
  webSocket.send(JSON.stringify(payload));
};

export const wssJoinInvoice = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.INVOICE,
    data: {
      event: WebSocketEventEnum.JOIN_LIST_INVOICE,
    },
  };
  websocket.send(JSON.stringify(payload));
};

export const wssLeaveInvoice = async (websocket: WebSocket) => {
  const payload: IPayloadWSS = {
    typeFunction: WebSocketTypeEnum.INVOICE,
    data: {
      event: WebSocketEventEnum.LEAVE_LIST_INVOICE,
    },
  };
  websocket.send(JSON.stringify(payload));
};
