// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { ModalUnderDevelopment, NavTab, Toolbar } from '~/components';
import { ChangeOrderTabTemplate, PurchaseOrderTabTemplate } from '~/pages';
// Others
import { orderTabs } from '~/utils/constants/common';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { AccountRoleCodesEnum, OrderTabsEnum, SearchParamsEnum, StorageEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Order.module.scss';

const cx = classNames.bind(styles);

const ManagePoCo = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!location.search) {
      const role = localStorage.getItem(StorageEnum.ROLE);
      const currentParams = new URLSearchParams(location.search);
      currentParams.set('tab', OrderTabsEnum.PURCHASE_ORDER);

      if (role === AccountRoleCodesEnum.STAFF) {
        navigate(`${staffRouteAbsolute.poco}?${currentParams.toString()}`);
      }

      if (role === AccountRoleCodesEnum.ADMIN) {
        navigate(`${adminRouteAbsolute.poco}?${currentParams.toString()}`);
      }
    }
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowAddOrder = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);

    // TODO: Handle show add order
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const renderTabContent = () => {
    const currentTab = searchParams.get(SearchParamsEnum.TAB);
    const role = localStorage.getItem(StorageEnum.ROLE);

    if (!currentTab) return;

    if (role === AccountRoleCodesEnum.ADMIN) {
      switch (currentTab) {
        case OrderTabsEnum.PURCHASE_ORDER:
          return <PurchaseOrderTabTemplate />;

        case OrderTabsEnum.CHANGE_ORDER:
          return <ChangeOrderTabTemplate />;

        default:
          return <div className=''>{t('common_empty_data')}</div>;
      }
    }

    if (role === AccountRoleCodesEnum.STAFF) {
      switch (currentTab) {
        case OrderTabsEnum.PURCHASE_ORDER:
          return <PurchaseOrderTabTemplate />;

        case OrderTabsEnum.CHANGE_ORDER:
          return <ChangeOrderTabTemplate />;

        default:
          return <div className=''>{t('common_empty_data')}</div>;
      }
    }
  };
  //#endregion Handle Function

  return (
    <div id='managePoCoPage' className={cx('container')}>
      <Toolbar
        title={t('order_title')}
        primaryBtn={{
          action: handleShowAddOrder,
          label: t('order_btn_add_order'),
        }}
      />

      <NavTab items={orderTabs} searchParams={SearchParamsEnum.TAB} />

      <div className={cx('tabContent')}>{renderTabContent()}</div>

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}
    </div>
  );
};

export default ManagePoCo;
