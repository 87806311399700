// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
import { BaseButton, BaseDatePicker, BaseInput, BaseUploadImage, BaseSelect } from '~/components';
// Context
// Others
import { IBaseOption, IUploadImage } from '~/utils/interface/common';
import {
  dataMockOptionExperienceLevels,
  dataOptionStatusCreateEmployee,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ButtonTypeEnum, InputTypeEnum } from '~/utils/enum';
import { ICreateTechnicianPayload } from '~/utils/interface/technician';
import { LoadingContext } from '~/context';
import { createTechnician } from '~/thunks/technician/technicianThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import styles from './AdminCreateTechnicianModal.module.scss';
import { icons } from '~/assets';

type Props = {
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const AdminCreateTechnicianModal = (props: Props) => {
  //#region Destructuring Props
  const { onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const parentRef = useRef<HTMLDivElement>(null);
  const loadingContext = useContext(LoadingContext);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const initialState: ICreateTechnicianPayload = {
    email: EMPTY_STRING,
    countryCode: EMPTY_STRING,
    phoneNumber: EMPTY_STRING,
    firstName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    experience: EMPTY_STRING,
    joinedDate: EMPTY_STRING,
    status: EMPTY_STRING,
    jobSuccessRate: EMPTY_STRING,
  };

  const [files, setFiles] = useState<File[]>([]);
  const [isDisableSave, setIsDisableSave] = useState<boolean>(true);
  const [stateDataForm, setStateDataForm] = useState<ICreateTechnicianPayload>(initialState);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setIsDisableSave(!validateForm(stateDataForm));
  }, [stateDataForm]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStateDataForm({ ...stateDataForm, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (option: IBaseOption, name?: string) => {
    if (!name) return;
    const { value } = option;

    setStateDataForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleAddTechnician = () => {
    const formData = new FormData();
    if (files?.length > DEFAULT_NUMBER_ZERO) {
      files.forEach((file: File) => {
        formData.append('avatar', file);
        formData.append('thumbnail', file);
      });
    }

    Object.keys(stateDataForm).forEach((key: string) => {
      if (!stateDataForm[key as keyof ICreateTechnicianPayload]) return;
      formData.append(key, stateDataForm[key as keyof ICreateTechnicianPayload] as string);
    });

    loadingContext?.show();
    dispatch(createTechnician(formData))
      .unwrap()
      .then((res) => {
        loadingContext?.hide();
        //@Todo: Handle show toast message success
        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch((err) => {
        loadingContext?.hide();
        //@Todo: Handle show toast message error
      });
  };

  const handleImageUploadChange = (imageUploadList: IUploadImage[]) => {
    if (!imageUploadList?.length) return;

    const fileList: File[] = [];
    imageUploadList.forEach((imageUploadItem: IUploadImage) => {
      if (!imageUploadItem?.file) return;
      fileList.push(imageUploadItem.file);
    });
    setFiles(fileList);
  };

  const handleDateChange = (date: string, name?: string) => {
    setStateDataForm((prevState) => ({
      ...prevState,
      joinedDate: date,
    }));
  };

  const validateForm = (form: ICreateTechnicianPayload): boolean => {
    const keys = Object.keys(form) as Array<keyof ICreateTechnicianPayload>;

    return keys.every((key) => {
      if (
        key === 'avatar' ||
        key === 'countryCode' ||
        key === 'joinedDate' ||
        key === 'phoneNumber' ||
        key === 'experience' ||
        key === 'status' ||
        key === 'thumbnail' ||
        key === 'jobSuccessRate'
      )
        return true;

      if (typeof form[key] === 'string') {
        return form[key] !== EMPTY_STRING;
      }

      return true;
    });
  };
  //#endregion Handle Function

  return (
    <div id='modalCreateTechnician' className={cx('container')}>
      <div className={cx('modal')}>
        <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div>
        <div className={cx('modalContent')}>
          <div className={cx('modalHeader')}>
            {t('admin_manage_technician_modal_title_add_technician')}
          </div>

          <div className={cx('line')} />

          <div ref={parentRef} className={cx('modalBody')}>
            <div className={cx('inputContent')}>
              <BaseInput
                id='firstName'
                label={t('admin_manage_technician_label_first_name')}
                name='firstName'
                type={InputTypeEnum.TEXT}
                value={stateDataForm?.firstName || EMPTY_STRING}
                height={35}
                onChange={handleInputChange}
              />

              <BaseInput
                id='lastName'
                label={t('admin_manage_technician_label_last_name')}
                name='lastName'
                type={InputTypeEnum.TEXT}
                value={stateDataForm?.lastName || EMPTY_STRING}
                height={35}
                onChange={handleInputChange}
              />
            </div>

            <BaseUploadImage
              label={t('admin_manage_technician_profile_image_label')}
              onChange={handleImageUploadChange}
            />

            <div className={cx('inputContent')}>
              <BaseInput
                id='phone'
                label={t('admin_manage_technician_label_phone')}
                name='phoneNumber'
                type={InputTypeEnum.NUMBER}
                value={stateDataForm?.phoneNumber || EMPTY_STRING}
                height={35}
                onChange={handleInputChange}
              />

              <BaseInput
                id='email'
                value={stateDataForm?.email || EMPTY_STRING}
                type={InputTypeEnum.TEXT}
                label={t('admin_manage_technician_label_email')}
                name='email'
                height={35}
                onChange={handleInputChange}
              />
            </div>

            <div className={cx('inputContent')}>
              <BaseDatePicker
                label={t('admin_manage_technician_label_joined_date')}
                value={stateDataForm.joinedDate}
                placeholderText={t('common_placeholder_select_date')}
                onDateSelected={handleDateChange}
              />

              <BaseSelect
                height={35}
                width='100%'
                label={t('admin_manage_technician_label_experience')}
                options={dataMockOptionExperienceLevels}
                placeholder={t('common_placeholder_select')}
                name='experience'
                value={stateDataForm?.experience || EMPTY_STRING}
                onChange={handleSelectChange}
              />
            </div>

            <div className={cx('inputContent')}>
              <BaseInput
                id='jobSuccessRate'
                label={t('admin_manage_technician_label_job_success_rate')}
                name='jobSuccessRate'
                value={stateDataForm?.jobSuccessRate || EMPTY_STRING}
                type={InputTypeEnum.TEXT}
                height={35}
                onChange={handleInputChange}
              />

              <BaseSelect
                height={35}
                width='100%'
                label={t('admin_manage_technician_label_status')}
                placeholder={t('common_placeholder_select')}
                name='status'
                value={stateDataForm?.status || EMPTY_STRING}
                onChange={handleSelectChange}
                options={dataOptionStatusCreateEmployee}
              />
            </div>
          </div>

          <div className={cx('modalFooter')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              onClick={handleCancelModal}
            />

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              onClick={handleAddTechnician}
              isDisable={isDisableSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateTechnicianModal;
