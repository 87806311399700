// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { IJobDetails, IJobResp } from '~/utils/interface/job';
import {
  addNewJob,
  createNewContractJob,
  getJobAssignees,
  getJobDetails,
  getListJob,
} from './jobThunk';
import { RootState } from '~/redux/store';

export interface JobState {
  jobResp: IJobResp;
  jobDetails: IJobDetails;
  isRefreshJobList: boolean;
}

const initialState: JobState = {
  jobResp: {} as IJobResp,
  jobDetails: {} as IJobDetails,
  isRefreshJobList: false,
};

const jobSlice = createSlice({
  name: 'jobState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshJobList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list job
    builder
      .addCase(getListJob.pending, (state) => {})
      .addCase(getListJob.fulfilled, (state, action) => {
        state.jobResp = action.payload.data;
      })
      .addCase(getListJob.rejected, (state) => {});

    // Get job details
    builder
      .addCase(getJobDetails.pending, (state) => {})
      .addCase(getJobDetails.fulfilled, (state, action) => {
        state.jobDetails = action.payload?.data;
      })
      .addCase(getJobDetails.rejected, (state) => {});

    // Add new job
    builder
      .addCase(addNewJob.pending, (state) => {})
      .addCase(addNewJob.fulfilled, (state, action) => {})
      .addCase(addNewJob.rejected, (state) => {});

    // Get job assignees
    builder
      .addCase(getJobAssignees.pending, (state) => {})
      .addCase(getJobAssignees.fulfilled, (state, action) => {})
      .addCase(getJobAssignees.rejected, (state) => {});

    // Create new contract job
    builder
      .addCase(createNewContractJob.pending, (state) => {})
      .addCase(createNewContractJob.fulfilled, (state, action) => {})
      .addCase(createNewContractJob.rejected, (state) => {});
  },
});

export const jobActions = jobSlice.actions;

// Get list job
export const selectJobResp = (state: RootState) => state.job.jobResp;
export const selectJobDetails = (state: RootState) => state.job.jobDetails;

const jobReducer = jobSlice.reducer;

export default jobReducer;
