import { createAsyncThunk } from '@reduxjs/toolkit';
import scheduleApi from '~/apis/schedule';
import {
  CREATE_SCHEDULE,
  DELETE_SCHEDULE,
  GET_SCHEDULE_EVENTS,
} from '~/utils/constants/actionType';
import { IParamsGetSchedule, IScheduleBody } from '~/utils/interface/schedule';

export const getScheduleEvents = createAsyncThunk(
  GET_SCHEDULE_EVENTS,
  async (params: IParamsGetSchedule, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.getSchedule(params);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSchedule = createAsyncThunk(
  CREATE_SCHEDULE,
  async (body: IScheduleBody, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.createSchedule(body);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  DELETE_SCHEDULE,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await scheduleApi.deleteSchedule(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
