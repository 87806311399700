// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// Components, Layouts, Pages
// Others
import { getFileNameFromUrl } from '~/utils/helper';
import { ASTERISK_SYMBOL, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './FileInput.module.scss';

type Props = {
  name: string;
  label?: string;
  width?: number | string;
  height?: number | string;
  maxSize?: number;
  resetFile?: boolean;
  errorMessage?: string;
  viewed?: boolean;
  fileUrl?: string;
  placeholder?: string;
  isRequired?: boolean;
  onFileChange: (name: string, file: File | null) => void;
};

const cx = classNames.bind(styles);

const FileInput = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    label,
    width,
    height,
    maxSize,
    resetFile,
    errorMessage,
    viewed,
    fileUrl,
    placeholder,
    isRequired,
    onFileChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fileName, setFileName] = useState<string>(EMPTY_STRING);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (resetFile) {
      resetInputFile();
    }

    if (fileUrl) {
      const fileNameConverted = getFileNameFromUrl(fileUrl);
      fileNameConverted && setFileName(fileNameConverted);
    }
  }, [resetFile, fileUrl]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    const { name } = event.target;

    if (!file || !name) return;

    if (file && maxSize && file.size > maxSize) {
      return;
    }

    setFileName(file.name);
    onFileChange(name, file);
  };

  const resetInputFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = EMPTY_STRING;
      setFileName(EMPTY_STRING);
      onFileChange(name, null);
    }
  };
  //#endregion Handle Function

  return (
    <div id='fileInputComponent' className={cx('container')}>
      {label && (
        <p className={cx('label')}>
          {label}
          {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </p>
      )}

      {viewed && fileUrl ? (
        <div className={cx('boxView')} style={{ width, height }}>
          <Link className={cx('linkFile')} to={fileUrl} target='_blank'>
            {fileUrl || EMPTY_STRING}
          </Link>
        </div>
      ) : (
        <div className={cx('boxInput')} style={{ width, height }}>
          <p className={cx('fileName', !fileName && { placeholder })}>
            {placeholder && !fileName ? placeholder : fileName}
          </p>

          <label htmlFor={name} className={cx('fileUpload')}>
            {t('common_upload_file_browse')}

            <input
              ref={fileInputRef}
              name={name}
              type='file'
              id={name}
              className={cx('fileInput')}
              onChange={handleFileChange}
            />
          </label>
        </div>
      )}

      {errorMessage && !viewed && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default FileInput;
