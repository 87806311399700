// Libs
import { createSlice } from '@reduxjs/toolkit';
import {
  changeStatusVendor,
  createVendor,
  getListVendors,
  getListVendorsSubcontractors,
  getVendorDetails,
  updateContactsVendor,
  updateVendor,
} from './vendorsThunk';
import { IBaseOption } from '~/utils/interface/common';
import { IProductOption } from '~/utils/interface/estimate';
import { EMPTY_STRING } from '~/utils/constants/common';
// Others

export interface vendorsState {
  isRefreshVendorList: boolean;
  listVendorTypeSubcontractors?: IBaseOption[];
}

const initialState: vendorsState = {
  isRefreshVendorList: false,
};

const vendorsSlice = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    setRefreshVendorList(state, action) {
      state.isRefreshVendorList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list vendors
    builder
      .addCase(getListVendors.pending, (state) => {})
      .addCase(getListVendors.fulfilled, (state) => {})
      .addCase(getListVendors.rejected, (state) => {});

    // Add new vendors
    builder
      .addCase(createVendor.pending, (state, action) => {})
      .addCase(createVendor.fulfilled, (state, action) => {})
      .addCase(createVendor.rejected, (state, action) => {});

    // Change status vendor
    builder
      .addCase(changeStatusVendor.pending, (state, action) => {})
      .addCase(changeStatusVendor.fulfilled, (state, action) => {})
      .addCase(changeStatusVendor.rejected, (state, action) => {});

    // Get vendor details
    builder
      .addCase(getVendorDetails.pending, (state, action) => {})
      .addCase(getVendorDetails.fulfilled, (state, action) => {})
      .addCase(getVendorDetails.rejected, (state, action) => {});

    // Update vendor
    builder
      .addCase(updateVendor.pending, (state, action) => {})
      .addCase(updateVendor.fulfilled, (state, action) => {})
      .addCase(updateVendor.rejected, (state, action) => {});

    // Update contacts vendor
    builder
      .addCase(updateContactsVendor.pending, (state, action) => {})
      .addCase(updateContactsVendor.fulfilled, (state, action) => {})
      .addCase(updateContactsVendor.rejected, (state, action) => {});

    // Get list vendors by subcontractors
    builder
      .addCase(getListVendorsSubcontractors.pending, (state) => {})
      .addCase(getListVendorsSubcontractors.fulfilled, (state, action) => {
        const { data } = action.payload;
        const listVendorSubcontractors: IProductOption[] = data.data.responses.map((item) => ({
          label: `${item.name} ${item.contactName ? `(${item.contactName})` : EMPTY_STRING} `,
          value: item.id,
        }));
        state.listVendorTypeSubcontractors = listVendorSubcontractors;
      })
      .addCase(getListVendorsSubcontractors.rejected, (state) => {});
  },
});

export const vendorsActions = vendorsSlice.actions;

const vendorsReducer = vendorsSlice.reducer;
export default vendorsReducer;
