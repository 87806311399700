// Libs
import { ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { RootState } from '~/redux/store';
// Components, Layouts, Pages
// Others
import { useAppSelector } from '~/redux/hooks';
import { REASON_CLOSE_WSS_LOGOUT } from '~/utils/constants/common';
import { wssURL } from '~/utils/constants/env';
import { StorageEnum } from '~/utils/enum';
import { wssLogin } from '~/utils/helpers/wss';
import { IDataWSSSendMessage, IMessageWSS } from '~/utils/interface/wss';
// Styles, images, icons

type ContextValueWS = {
  wss?: WebSocket | null;
  wsData?: IMessageWSS<IDataWSSSendMessage>;
};

type Props = {
  children: ReactNode;
};

const WebsocketContext = createContext<ContextValueWS>({});

const WebsocketProvider = (props: Props) => {
  const labelLog = '[WebSocketProvider]';

  //#region Destructuring Props
  const { children } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const wssRef = useRef<WebSocket | null>(null);
  const timeoutReconnect = useRef<NodeJS.Timeout>();
  //#endregion Declare Hook

  //#region Selector
  const { accessToken } = useAppSelector((state: RootState) => state.auth);
  //#endregion Selector

  //#region Declare State
  const [wsData, setWSData] = useState<IMessageWSS<IDataWSSSendMessage>>();
  const contextValueWS: ContextValueWS = { wss: wssRef.current, wsData };
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (wssRef.current) return;

    const accessTokenPersist = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
    if (accessTokenPersist || accessToken) {
      connectWebSocket();
    }
  }, [accessToken]);

  useEffect(() => {
    return () => {
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const connectWebSocket = () => {
    const labelLogLocal = `${labelLog} [connectWebSocket]`;
    if (!wssURL) return;

    const ws = new WebSocket(wssURL);
    wssRef.current = ws;

    wssRef.current.onopen = () => {
      console.log(`${labelLogLocal} [onopen] webSocket connected`);
      wssRef.current && wssLogin(wssRef.current);
      timeoutReconnect.current && clearTimeout(timeoutReconnect.current); // Clear timeout reconnect
    };

    wssRef.current.onmessage = (event) => {
      const data = JSON.parse(event?.data);
      setWSData(data);
    };

    wssRef.current.onclose = (event) => {
      if (event.reason === REASON_CLOSE_WSS_LOGOUT) {
        wssRef.current = null;
        return;
      }

      // Reconnect
      timeoutReconnect.current = setTimeout(() => {
        console.log(`${labelLogLocal} [onclose] reconnect`);
        connectWebSocket();
      }, 1000);
    };

    wssRef.current.onerror = () => {
      wssRef.current?.close();
    };
  };
  //#endregion Handle Function

  return <WebsocketContext.Provider value={contextValueWS}>{children}</WebsocketContext.Provider>;
};

export { WebsocketContext, WebsocketProvider };
