// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import {
  BasePagination,
  BaseTable,
  ButtonStatus,
  CircleAvatar,
  Toolbar,
  NavTab,
} from '~/components';
// Others
import { EMPTY_STRING, defaultCurrentPage, proposalTab } from '~/utils/constants/common';
import { adminRouteAbsolute, staffRouteAbsolute } from '~/utils/constants/route';
import { ColumnType } from '~/utils/interface/common';
import { IProposal, IProposalResp } from '~/utils/interface/mock';
import {
  formatCurrency,
  formattedTime,
  getAvatarWithName,
  getColorByStatus,
  getFullName,
} from '~/utils/helper';
import {
  AccountRoleCodesEnum,
  CircleAvatarEnum,
  CurrencyEnum,
  ProposalTabEnum,
  SearchParamsEnum,
  StorageEnum,
} from '~/utils/enum';
// Styles, images, icons
import styles from './Proposal.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (t: TFunction<'translation'>): ColumnType<IProposal>[] => {
  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('admin_manage_proposal_table_label_project_name'),
      render: (_, record) => (
        <div className={cx('textCell')}>
          <span>{record.name || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      title: t('admin_manage_proposal_table_label_client'),
      key: 'client',
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName(
            { firstName: record.clientFirstName, lastName: record.clientLastName },
            record.clientAvatarUrl
          ) ? (
            <>
              <CircleAvatar
                type={record.clientAvatarUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
                imageUrl={record.clientAvatarUrl}
                firstName={record.clientFirstName}
                lastName={record.clientLastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({
                firstName: record.clientFirstName,
                lastName: record.clientLastName,
              })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      title: t('admin_manage_proposal_table_label_estimated_date'),
      key: 'estDate',
      dataIndex: 'estDate',
      render: (_, record) => {
        return record.estDate ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span> {formattedTime(record.estDate)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      key: 'estCost',
      dataIndex: 'estCost',
      title: t('admin_manage_proposal_table_label_estimated_cost'),
      render: (_, record) => {
        return record.estCost ? (
          <div className={cx('currency')}>
            <span> {formatCurrency(CurrencyEnum.USD, record.estCost)}</span>

            <img
              src={icons.adminIconProposalEstCost}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      key: 'status',
      title: t('admin_manage_proposal_table_label_status'),
      render: (_, record) => {
        return record.status ? (
          <ButtonStatus width={90} color={getColorByStatus(record.status)} label={record.status} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
  ];
};

const ManageProposal = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  const location = useLocation();
  const navigate = useNavigate();
  //#endregion Selector

  //#region Declare State
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [proposalResp, setProposalResp] = useState<IProposalResp>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!location.search) {
      const role = localStorage.getItem(StorageEnum.ROLE);
      const currentParams = new URLSearchParams(location.search);
      currentParams.set('tab', ProposalTabEnum.ACTIVE);

      if (role === AccountRoleCodesEnum.STAFF) {
        navigate(`${staffRouteAbsolute.proposal}?${currentParams.toString()}`);
      }

      if (role === AccountRoleCodesEnum.ADMIN) {
        navigate(`${adminRouteAbsolute.proposal}?${currentParams.toString()}`);
      }
    }
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };

  const handleShowCreateProposal = () => {
    // Handle Logic Later
  };
  //#endregion Handle Function
  return (
    <div id='manageProposalPage' className={cx('manageProposalContainer')}>
      <Toolbar
        title={t('admin_sidebar_label_manage_proposal')}
        primaryBtn={{
          action: handleShowCreateProposal,
          label: t('admin_manage_proposal_btn_create_proposal'),
        }}
      />

      <NavTab items={proposalTab} searchParams={SearchParamsEnum.TAB} />

      <div className={cx('manageProposalBody')}>
        <div className={cx('statisticTable')}>
          <BaseTable columns={columns(t)} dataSource={proposalResp?.responses || []} />
        </div>

        <div className={cx('pagePagination')}>
          {proposalResp?.pagination && (
            <BasePagination
              totalPages={proposalResp?.pagination?.totalPages}
              totalItems={proposalResp?.pagination?.totalItems}
              onChange={handleChangePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageProposal;
