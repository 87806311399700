// Others
import { urlTermsAndConditions } from '~/utils/constants/common';
import {
  BaseResponse,
  IListDataResponse,
  IListQueryParams,
  IQueryBase,
} from '~/utils/interface/common';
import {
  IAddInclusionAndExclusion,
  IFormMainContent,
  IDetailTerm,
  IInclusionAndExclusionItem,
  IMainContent,
  IUpdateMainContent,
  ITermTemplate,
} from '~/utils/interface/termsAndConditions';
import axiosClient from './axiosClient';

const termConditionsApi = {
  createInclusionExclusion(body: IAddInclusionAndExclusion) {
    const url = `${urlTermsAndConditions.createInclusionExclusion}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  getListInclusionExclusion(params: IListQueryParams) {
    const url = `${urlTermsAndConditions.getListInclusionExclusion}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IInclusionAndExclusionItem[]>>>(url, {
      params,
    });
  },

  deleteInclusionExclusion(id: string) {
    const url = `${urlTermsAndConditions.deleteInclusionExclusion(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  updateInclusionExclusion(item: IInclusionAndExclusionItem) {
    const url = `${urlTermsAndConditions.updateInclusionExclusion(item.id)}`;
    return axiosClient.put<BaseResponse>(url, { name: item.name });
  },

  createMainContent(body: IFormMainContent) {
    const url = `${urlTermsAndConditions.createMainContent}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  deleteTermMainContent(id: string) {
    const url = `${urlTermsAndConditions.deleteTermMainContent(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  getListTermMainContent(params: IListQueryParams) {
    const url = `${urlTermsAndConditions.getListTermMainContent}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IMainContent[]>>>(url, { params });
  },

  getMainContentDetail(id: string) {
    const url = `${urlTermsAndConditions.getMainContentDetail(id)}`;
    return axiosClient.get<BaseResponse<IDetailTerm>>(url);
  },

  updateMainContent({ mainContentId, body }: IUpdateMainContent) {
    const url = `${urlTermsAndConditions.updateMainContent(mainContentId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  getListTermTemplate(params: IQueryBase) {
    const url = `${urlTermsAndConditions.getListTermTemplate}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITermTemplate[]>>>(url, { params });
  },
};

export default termConditionsApi;
