import { urlApiAuth } from '~/utils/constants/common';
import {
  ILoginPayload,
  IForgotPasswordPayload,
  ICreateNewPasswordPayload,
  IForgotPasswordResp,
  ICreateNewPasswordResp,
  IVerifyCode,
  IChangePasswordPayload,
} from '~/utils/interface/auth';
import axiosClient from './axiosClient';
import { BaseResponse } from '~/utils/interface/common';

const authApi = {
  login(params: ILoginPayload) {
    const url = `${urlApiAuth.login}`;
    return axiosClient.post(url, params);
  },

  forgotPassword(params: IForgotPasswordPayload) {
    const url = `${urlApiAuth.forgotPassword}`;
    return axiosClient.post<BaseResponse<IForgotPasswordResp>>(url, params);
  },

  createNewPassword(params: ICreateNewPasswordPayload) {
    const url = `${urlApiAuth.createNewPassword}`;
    return axiosClient.post<BaseResponse<ICreateNewPasswordResp>>(url, params);
  },

  verifyCode(body: IVerifyCode) {
    const url = `${urlApiAuth.verifyCode}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  changePassword(body: IChangePasswordPayload) {
    const url = `${urlApiAuth.changePassword}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default authApi;
