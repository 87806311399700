// Libs
import { createPortal } from 'react-dom';
import React, { useLayoutEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { ATTRIBUTE_ID, DIV_ELEMENT } from '~/utils/constants/common';
// Styles, images, icons

type Props = {
  children: React.ReactElement;
  wrapperId: string;
};

const ReactPortal = (props: Props) => {
  //#region Destructuring Props
  const { children, wrapperId } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [wrapperElement, setWrapperElement] = useState<HTMLElement>();
  //#endregion Declare State

  //#region Implement Hook
  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    let systemCreated = false;

    if (!element) {
      systemCreated = true;
      element = createWrapperAndAppendToBody(wrapperId);
    }

    setWrapperElement(element!);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);
  //#endregion Implement Hook

  //#region Handle Function
  const createWrapperAndAppendToBody = (wrapperId: string) => {
    if (!document) return null;
    const wrapperElement = document.createElement(DIV_ELEMENT);
    wrapperElement.setAttribute(ATTRIBUTE_ID, wrapperId);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  };
  //#endregion Handle Function

  if (!wrapperElement) return null;

  return createPortal(children, wrapperElement);
};

export default ReactPortal;
