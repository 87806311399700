// Libs
import classNames from 'classnames/bind';
import { PropsWithChildren } from 'react';
import { Dialog, DialogPanel, Transition } from '@headlessui/react';
// Components, Layouts, Pages
// Others
import { DIV_ELEMENT } from '~/utils/constants/common';
// Styles, images, icons
import styles from './BaseModal.module.scss';

type Props = {
  id?: string;
  isOpen?: boolean;
  width?: number | string;
  height?: number | string;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const BaseModal = (props: PropsWithChildren<Props>) => {
  //#region Destructuring Props
  const { id, isOpen, width, height, onClose, children } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose && onClose();
  };
  //#endregion Handle Function

  return (
    <Transition
      show={isOpen}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-200'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <Dialog
        id='baseModal'
        className={cx('container')}
        as={DIV_ELEMENT}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogPanel id={id} className={cx('modal')} style={{ minWidth: width, height: height }}>
          {children}
        </DialogPanel>
      </Dialog>
    </Transition>
  );
};

export default BaseModal;
