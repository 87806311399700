// Libs
import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './BaseTextarea.module.scss';

type TextareaProps = {
  label?: string;
  required?: boolean;
  placeholder?: string;
  value?: string | null;
  height?: number | string;
  px?: number;
  py?: number;
  gap?: number | string;
  errorMessage?: string;
  name?: string;
  maxLength?: number;
  disabled?: boolean;
  onTextareaChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};

const cx = classNames.bind(styles);

const BaseTextarea = (props: TextareaProps) => {
  //#region Destructuring Props
  const {
    label,
    required,
    placeholder,
    value,
    height,
    px,
    py,
    gap,
    errorMessage,
    name,
    maxLength,
    disabled,
    onTextareaChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [text, setText] = useState<string>('');
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setText(value ?? '');
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setText(newValue);

    if (onTextareaChange) {
      onTextareaChange(event);
    }
  };
  //#endregion Handle Function

  return (
    <div id='textareaComponent' className={cx('textareaContainer')}>
      {label && (
        <div className={cx('textareaLabel')} style={{ marginBottom: gap }}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div
        className={cx('textareaWrap')}
        style={{
          height,
          paddingLeft: px,
          paddingRight: px,
          paddingTop: py,
          paddingBottom: py,
        }}
      >
        <textarea
          name={name}
          className={cx('textarea')}
          placeholder={placeholder}
          value={text}
          maxLength={maxLength}
          disabled={disabled}
          onChange={handleTextareaChange}
        />
      </div>

      {errorMessage && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default BaseTextarea;
