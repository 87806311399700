// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
import { BaseTable, ButtonStatus, BasePagination } from '~/components';
// Others
import { ColumnType } from '~/utils/interface/common';
import { IMaterialUsed, IMaterialUsedResp } from '~/utils/interface/mock';
import { formattedTime, getColorByStatus } from '~/utils/helper';
import { EMPTY_STRING, defaultCurrentPage } from '~/utils/constants/common';
import { BaseTableEnum } from '~/utils/enum';
import { mockMaterialUsedData } from '~/mockData';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './MaterialUsedTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction): ColumnType<IMaterialUsed>[] => {
  return [
    {
      title: t('admin_job_detail_material_used_tab_label_po_id'),
      key: 'id',
      dataIndex: 'id',
      render: (_, record) => {
        return (
          <div>
            <span>{record.id}</span>
          </div>
        );
      },
    },
    {
      title: t('admin_job_detail_material_used_tab_label_material_name'),
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => {
        return (
          <div className={cx('textCell')}>
            <span>{record.name || EMPTY_STRING}</span>
          </div>
        );
      },
    },
    {
      title: t('admin_job_detail_material_used_tab_label_vendor_name'),
      key: 'vendorName',
      dataIndex: 'vendorName',
      render: (_, record) => {
        return (
          <div className={cx('textColorCell')}>
            <span>{record.vendorName || EMPTY_STRING}</span>
          </div>
        );
      },
    },
    {
      title: t('admin_job_detail_material_used_tab_label_created'),
      key: 'created',
      dataIndex: 'createdAt',
      render: (_, record) => {
        return record.createdAt ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span className={cx('textCell')}> {formattedTime(record.createdAt)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      key: 'remark',
      title: t('admin_job_detail_material_used_tab_label_remark'),
      dataIndex: 'remark',
      render: (_, record) => {
        return record.remark ? (
          <ButtonStatus width={100} color={getColorByStatus(record.remark)} label={record.remark} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
  ];
};

const MaterialUsedTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [materialUsedResp, setMaterialUsedResp] = useState<IMaterialUsedResp>(mockMaterialUsedData);
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };
  //#endregion Handle Function

  return (
    <div id='materialUsedTabComponent' className={cx('tabContainer')}>
      <div className={cx('tabHead')}>
        <div className={cx('tabTitle')}>{t('admin_job_detail_material_used_tab_title')}</div>
      </div>
      <div className={cx('tabContent')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            columns={columns(t)}
            dataSource={materialUsedResp?.responses || []}
            typeStyle={BaseTableEnum.COLOR_TABLE}
          />
        </div>
        <div className={cx('pagePagination')}>
          {materialUsedResp?.pagination && (
            <BasePagination
              totalPages={materialUsedResp?.pagination?.totalPages}
              totalItems={materialUsedResp?.pagination?.totalItems}
              onChange={handleChangePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialUsedTab;
