// Libs
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { CircleAvatar, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getTimeClockDetails } from '~/thunks/timeClock/timeClockThunk';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { CircleAvatarEnum, TimeFormatEnum } from '~/utils/enum';
import { convertTime, formattedTime, formatTotalTimeToHour, getFullName } from '~/utils/helper';
import { IPosition } from '~/utils/interface/common';
import { ITimeClockDetails } from '~/utils/interface/timeClock';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './AdminTimeClockDetailsModal.module.scss';

type Props = {
  timeClockId: string;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const AdminTimeClockDetailsModal = (props: Props) => {
  //#region Destructuring Props
  const { timeClockId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? '',
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [timeClockDetails, setTimeClockDetails] = useState<ITimeClockDetails>();
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetTimeClockDetails(timeClockId);
    getUserLocation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTimeClockDetails = (id: string) => {
    if (!id) return;

    setIsLoading(true);

    dispatch(getTimeClockDetails(id))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setTimeClockDetails(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleCloseModal = () => {
    onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminTimeClockDetailsModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={handleCloseModal}
        />

        <div className={cx('title')}>{t('admin_time_clock_details_title')}</div>

        <div className={cx('body')}>
          <div className={cx('timeClockDetailsSection')}>
            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t(
                'admin_time_clock_details_resource_name'
              )}:`}</div>
              <div className={cx('item', 'val')}>
                {timeClockDetails?.assignee?.firstName || timeClockDetails?.assignee?.lastName ? (
                  <div className={cx('avatarGroup')}>
                    <CircleAvatar
                      type={
                        timeClockDetails?.assignee?.avatar
                          ? CircleAvatarEnum.IMAGE
                          : CircleAvatarEnum.TEXT
                      }
                      imageUrl={timeClockDetails?.assignee?.avatar}
                      firstName={timeClockDetails?.assignee?.firstName}
                      lastName={timeClockDetails?.assignee?.lastName}
                      width={24}
                      height={24}
                      fontSize={12}
                    />

                    <span>
                      {getFullName({
                        firstName: timeClockDetails?.assignee?.firstName,
                        lastName: timeClockDetails?.assignee?.lastName,
                      })}
                    </span>
                  </div>
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t('admin_time_clock_details_date')}:`}</div>
              <div className={cx('item', 'val')}>
                {timeClockDetails?.startTime
                  ? formattedTime(timeClockDetails?.startTime)
                  : EMPTY_STRING}
              </div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t('admin_time_clock_details_job')}:`}</div>
              <div className={cx('item', 'val')}>{timeClockDetails?.job?.name || EMPTY_STRING}</div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t(
                'admin_time_clock_details_client_name'
              )}:`}</div>
              <div className={cx('item', 'val')}>
                {timeClockDetails?.client?.firstName || timeClockDetails?.client?.lastName ? (
                  <div className={cx('avatarGroup')}>
                    <CircleAvatar
                      type={
                        timeClockDetails?.client?.avatar
                          ? CircleAvatarEnum.IMAGE
                          : CircleAvatarEnum.TEXT
                      }
                      imageUrl={timeClockDetails?.client?.avatar}
                      firstName={timeClockDetails?.client?.firstName}
                      lastName={timeClockDetails?.client?.lastName}
                      width={24}
                      height={24}
                      fontSize={12}
                    />

                    <span>
                      {getFullName({
                        firstName: timeClockDetails?.client?.firstName,
                        lastName: timeClockDetails?.client?.lastName,
                      })}
                    </span>
                  </div>
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t(
                'admin_time_clock_details_check_in'
              )}:`}</div>
              <div className={cx('item', 'val')}>
                {timeClockDetails?.startTime
                  ? convertTime(timeClockDetails?.startTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                  : EMPTY_STRING}
              </div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t(
                'admin_time_clock_details_check_out'
              )}:`}</div>
              <div className={cx('item', 'val')}>
                {timeClockDetails?.endTime
                  ? convertTime(timeClockDetails?.endTime, TimeFormatEnum.HOUR_MINUTE_AM_PM)
                  : EMPTY_STRING}
              </div>
            </div>

            <div className={cx('groupItem')}>
              <div className={cx('item', 'key')}>{`${t(
                'admin_time_clock_details_total_time'
              )}:`}</div>
              <div className={cx('item', 'val')}>
                {typeof timeClockDetails?.hours === 'number'
                  ? formatTotalTimeToHour(Number(timeClockDetails?.hours))
                  : EMPTY_STRING}
              </div>
            </div>
          </div>

          <div className={cx('locationSection')}>
            <h3 className={cx('locationTitle')}>{`${t('admin_time_clock_details_location')}:`}</h3>

            <p className={cx('locationVal')}>{timeClockDetails?.location || EMPTY_STRING}</p>
          </div>

          <div className={cx('mapSection')}>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: 300 }}
                center={{
                  lat: timeClockDetails?.latitude || positionSelected.lat,
                  lng: timeClockDetails?.longitude || positionSelected.lng,
                }}
                zoom={DEFAULT_MAP_ZOOM}
              >
                {timeClockDetails?.latitude && timeClockDetails?.longitude && (
                  <Marker
                    position={{
                      lat: timeClockDetails?.latitude || positionSelected.lat,
                      lng: timeClockDetails?.longitude || positionSelected.lng,
                    }}
                  />
                )}
              </GoogleMap>
            )}
          </div>
        </div>

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminTimeClockDetailsModal;
