// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
// Components, Layouts, Pages
import Space from '../space/Space';
// Others
import { DateFormatEnum } from '~/utils/enum';
import {
  PDF_POSITION_PLACEHOLDER_DATE_SIGNED,
  PDF_POSITION_PLACEHOLDER_TO_SIGNING,
} from '~/utils/constants/common';
// Styles, images, icons

const InvoiceSignatureApproval = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <View style={styles.col}>
        <View style={styles.row}>
          <Text style={styles.label}>{'Contractor:'}</Text>
          <View style={styles.columnValue}>
            <View style={styles.underlineContainer}></View>
            <Text style={[styles.value, styles.fontSize]}>{'Ancla Plumbing, LLC'}</Text>
          </View>

          <View style={[styles.columnValue, styles.columnWidth]}>
            <Text style={styles.value}>{`${moment().format(DateFormatEnum['DD MM YYYY'])}`}</Text>
            <View style={styles.underlineContainer}></View>
            <Text style={[styles.value, styles.fontSize]}>{'Date'}</Text>
          </View>
        </View>
        <Space height={30} />

        <View>
          <Text>
            ACCEPTANCE OF PROPOSAL: The above prices, scope, specifications and conditions are
            satisfactory and hereby accepted. You are authorized to do the work specified.
          </Text>
        </View>
        <Space height={30} />

        <View style={styles.row}>
          <Text style={styles.label}>{'Client:'}</Text>
          <View style={styles.columnValue}>
            <View style={styles.underlineContainer}>
              <Text style={[styles.invisible, styles.signature]}>
                {PDF_POSITION_PLACEHOLDER_TO_SIGNING}
              </Text>
            </View>
          </View>

          <View style={[styles.columnValue, styles.columnWidth]}>
            <View style={styles.underlineContainer}>
              <Text style={[styles.invisible, styles.dateSigned]}>
                {PDF_POSITION_PLACEHOLDER_DATE_SIGNED}
              </Text>
            </View>
            <Text style={[styles.value, styles.fontSize]}>{'Date'}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvoiceSignatureApproval;

export const styles = StyleSheet.create({
  container: {
    paddingTop: 8,
    paddingBottom: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  col: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  columnValue: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    width: '60%',
  },
  columnWidth: {
    width: '40%',
  },
  underlineContainer: {
    borderBottomWidth: 1,
    borderColor: 'black',
    paddingBottom: 5,
    marginTop: 5,
    marginBottom: 5,
    position: 'relative',
  },
  label: {
    width: '20%',
    textAlign: 'right',
    marginRight: 5,
  },
  value: {
    flex: 1,
    flexWrap: 'wrap',
    marginBottom: 2,
    marginLeft: 5,
  },
  fontSize: {
    fontSize: 8,
  },
  invisible: {
    opacity: 0,
  },
  signature: {
    position: 'absolute',
    fontSize: 26,
    top: -26,
    left: 0,
  },
  dateSigned: {
    position: 'absolute',
    fontSize: 10,
    left: 0,
    top: -10,
  },
});
