// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import {
  DonutChartJob,
  GreetingUser,
  ModalUnderDevelopment,
  StackedColumnChart,
  StatisticCard,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListOverview } from '~/thunks/overview/overviewThunk';
import { selectUserProfile } from '~/thunks/profile/profileSlice';
import { IOverviewResp } from '~/utils/interface/overview';
import { IUserProfile } from '~/utils/interface/profile';
import {
  DEFAULT_NUMBER_ONE,
  DEFAULT_NUMBER_ZERO,
  PERCENTAGE_FACTOR,
} from '~/utils/constants/common';
import { BLUE, GREEN400, ORANGE, ORANGE800 } from '~/utils/constants/color';
import { ColorEnum, CurrencyEnum } from '~/utils/enum';
import {
  IEstimateRevenue,
  IStatisticChart,
  IStatisticChartParams,
  ITotalJobByYear,
} from '~/utils/interface/statistic';
import { getStatisticChart } from '~/thunks/statistic/statisticThunk';
import { formatCurrency, getMonthsWithYear, getTimezone } from '~/utils/helper';
import { IDonutChartJobSeries } from '~/utils/interface/common';
// Styles, images, icons
import { AdminIconEmployee, AdminIconJobs, AdminIconTechnician } from '~/assets/svgComponents';
import styles from './Dashboard.module.scss';

const cx = classNames.bind(styles);

const Dashboard = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserProfile = useSelector(selectUserProfile);
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDev, setIsShowModalUnderDev] = useState<boolean>(false);
  const [listOverView, setListOverView] = useState<IOverviewResp>();
  const [totalJobByYear, setTotalJobByYear] = useState<ITotalJobByYear>();
  const [statisticDonutChart, setStatisticDonutChart] = useState<IDonutChartJobSeries>({
    seriesPercent: [0, 0],
  });
  const [estimateRevenue, setEstimateRevenue] = useState<IEstimateRevenue>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params: IStatisticChartParams = {
      timeZone: getTimezone(),
    };

    handleGetStatisticChart(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleToggleModalDev = () => {
    setIsShowModalUnderDev(!isShowModalUnderDev);
  };

  const handleGetListOverview = () => {
    loadingData?.show();

    dispatch(getListOverview())
      .unwrap()
      .then((res) => {
        if (!res) return;
        const responses = res.data;

        setListOverView(responses);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const calculateJobPercentage = (totalContractJob: number, totalServiceJob: number) => {
    const totalJobs = totalContractJob + totalServiceJob;
    if (totalJobs === DEFAULT_NUMBER_ZERO) return [0, 0];

    const contractJobPercent = (totalContractJob / totalJobs) * PERCENTAGE_FACTOR;
    const serviceJobPercent = (totalServiceJob / totalJobs) * PERCENTAGE_FACTOR;

    return [contractJobPercent, serviceJobPercent];
  };

  const handleGetStatisticChart = (params: IStatisticChartParams) => {
    loadingData?.show();

    dispatch(getStatisticChart(params))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const statisticChart: IStatisticChart = res.data;
        const [contractJobPercent, serviceJobPercent] = calculateJobPercentage(
          statisticChart.totalJob.totalContractJob,
          statisticChart.totalJob.totalServiceJob
        );

        setEstimateRevenue(statisticChart.estimateRevenue);
        setStatisticDonutChart({
          seriesPercent: [contractJobPercent, serviceJobPercent],
        });

        setTotalJobByYear(statisticChart.totalJobByYear);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='adminDashboardPage' className={cx('dashboardContainer')}>
      <div className={cx('headerWrap')}>
        <GreetingUser username={userInfo?.firstName} />
      </div>
      <div className={cx('content')}>
        <div className={cx('statisticContainer')}>
          <StatisticCard
            icon={<AdminIconEmployee />}
            data={{
              title:
                Number(listOverView?.totalStaff) > DEFAULT_NUMBER_ONE
                  ? t('admin_report_total_staffs')
                  : t('admin_report_total_staff'),
              value: Number(listOverView?.totalStaff) || DEFAULT_NUMBER_ZERO,
            }}
          />

          <StatisticCard
            icon={<AdminIconTechnician />}
            data={{
              title:
                Number(listOverView?.totalTechnician) > DEFAULT_NUMBER_ONE
                  ? t('admin_report_total_technicians')
                  : t('admin_report_total_technician'),
              value: Number(listOverView?.totalTechnician) || DEFAULT_NUMBER_ZERO,
            }}
          />
          <StatisticCard
            icon={<AdminIconJobs />}
            data={{
              title:
                Number(listOverView?.totalJob) > DEFAULT_NUMBER_ONE
                  ? t('admin_dashboard_current_jobs_title')
                  : t('admin_dashboard_current_job_title'),
              value: Number(listOverView?.totalJob) || DEFAULT_NUMBER_ZERO,
            }}
          />
          {/* <StatisticCard
            icon={<AdminIconPayment />}
            data={{
              title: t('admin_dashboard_revenue_title'),
              value: 0,
            }}
          /> */}
        </div>

        <div className={cx('body')}>
          <div className={cx('chartsContainer')}>
            <div className={cx('analyticsChartWrap')}>
              <DonutChartJob
                isShowLabel={false}
                height={300}
                title={t('admin_dashboard_donut_chart_job_label')}
                labels={[
                  t('admin_dashboard_donut_chart_job_contract_job'),
                  t('admin_dashboard_donut_chart_job_service_job'),
                ]}
                colors={[
                  {
                    name: ColorEnum.BLUE,
                    value: BLUE,
                  },
                  {
                    name: ColorEnum.ORANGE,
                    value: ORANGE,
                  },
                ]}
                series={statisticDonutChart}
              />
            </div>

            <div className={cx('analyticsChartWrap')}>
              <StackedColumnChart
                data={totalJobByYear?.chart}
                categories={getMonthsWithYear()}
                height={350}
                colors={[GREEN400, ORANGE800]}
                title={t('admin_dashboard_column_chart_projects_created_title')}
                label={t('admin_dashboard_column_chart_projects_created_description')}
                total={totalJobByYear?.total}
                descriptionTotal={t(
                  'admin_dashboard_column_chart_projects_created_description_of_data'
                )}
              />
            </div>

            <div className={cx('analyticsChartWrap')}>
              <StackedColumnChart
                data={estimateRevenue?.chart}
                categories={getMonthsWithYear()}
                height={350}
                colors={[GREEN400, ORANGE800]}
                title={t('admin_dashboard_column_chart_projects_estimated_title')}
                label={t('admin_dashboard_column_chart_projects_estimated_description')}
                total={formatCurrency(CurrencyEnum.USD, Number(estimateRevenue?.total))}
                descriptionTotal={t(
                  'admin_dashboard_column_chart_projects_estimated_description_of_data'
                )}
              />
            </div>

            <div className={cx('analyticsChartWrap')}>
              {/* //TODO DuongDucGRF - ABD: UI chart here */}
            </div>
          </div>
          {/* <div className={cx('topTechniciansContainer')}>
            <StatisticTop
              title={t('admin_dashboard_top_technician_title')}
              // dataItem={topTechnicianData}
              dataItem={[]}
              onViewAll={handleToggleModalDev}
            />
          </div> */}
        </div>
      </div>

      {isShowModalUnderDev && <ModalUnderDevelopment onClose={handleToggleModalDev} />}
    </div>
  );
};

export default Dashboard;
