// Libs
import classNames from 'classnames/bind';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { Option } from '~/utils/interface/common';
// Styles, images, icons
import styles from './DropdownChart.module.scss';
import { icons } from '~/assets';

type BaseDropdownProps = {
  defaultSelected?: Option;
  listOption?: Option[];
  placeholder?: string;
};

const cx = classNames.bind(styles);

const DropdownChart = (props: BaseDropdownProps) => {
  //#region Destructuring Props
  const { defaultSelected, listOption, placeholder } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(defaultSelected);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  //#endregion Handle Function

  return (
    <div id='dropdownChart'>
      <div className={cx('dropdownChartContainer')} ref={dropdownRef}>
        <div className={cx('dropdownChartToggle')} onClick={handleToggleClick}>
          <span className={cx('dropdownChartToggleText')}>
            {selectedOption?.label || placeholder}
          </span>

          <div className={cx('iconClass')}>
            <img src={icons.adminIconDropdown} alt={t('common_img_text_alt')} />
          </div>
        </div>
        {isOpen && (
          <>
            <div className={cx('dropdownChartOverlay')} onClick={handleToggleClick}></div>
            <ul className={cx('dropdownChartList')}>
              {listOption && listOption.length > 0 ? (
                listOption.map((option: Option, index: number) => (
                  <li
                    className={cx(
                      'dropdownChartItem',
                      option.value === selectedOption?.value && 'dropdownChartItemSelected'
                    )}
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    title={option.label}
                  >
                    {option.label}
                  </li>
                ))
              ) : (
                <li className={cx('dropdownChartNoData')}>{t('admin_multiple_select_no_data')}</li>
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default DropdownChart;
