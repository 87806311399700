// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { GET_LIST_LABOR } from '~/utils/constants/actionType';
import { ITableParams } from '~/utils/interface/common';
import laborApi from '~/apis/labor';

export const getListLabor = createAsyncThunk(
  GET_LIST_LABOR,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await laborApi.getListLabor(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
