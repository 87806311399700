import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { urlApiTimeClock } from '~/utils/constants/common';
import { ICreateTimeClock, ITimeClockDetails, ITimeClock } from '~/utils/interface/timeClock';

const timeClockApi = {
  getListTimeClock(params: ITableParams) {
    const url = `${urlApiTimeClock.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<ITimeClock[]>>>(url, {
      params: params,
    });
  },

  getTimeClockDetails(id: string) {
    const url = `${urlApiTimeClock.getDetails}/${id}`;
    return axiosClient.get<BaseResponse<ITimeClockDetails>>(url);
  },

  deleteTimeClock(id: string) {
    const url = `${urlApiTimeClock.delete}/${id}`;
    return axiosClient.delete(url);
  },

  createTimeClock(body: ICreateTimeClock) {
    const url = `${urlApiTimeClock.create}`;
    return axiosClient.post(url, body);
  },
};

export default timeClockApi;
