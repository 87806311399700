// Libs
import { useState } from 'react';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BasePagination, BaseTable, ButtonStatus, CircleAvatar, Toolbar } from '~/components';
import { EMPTY_STRING, defaultCurrentPage } from '~/utils/constants/common';
import { IInventory, IInventoryResp } from '~/utils/interface/mock';
import { ColumnType } from '~/utils/interface/common';
import { formatCurrency, formattedTime, getColorByStatus } from '~/utils/helper';
import { CircleAvatarEnum, CurrencyEnum } from '~/utils/enum';
// Others
// Styles, images, icons
import styles from './Inventory.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const columns = (t: TFunction<'translation'>): ColumnType<IInventory>[] => {
  return [
    {
      key: 'image',
      title: EMPTY_STRING,
      dataIndex: 'imageUrl',
      width: 40,
      render: (_, record) => {
        return (
          <CircleAvatar
            type={record.imageUrl ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
            imageUrl={record.imageUrl}
            borderRadius={4}
          />
        );
      },
    },
    {
      key: 'name',
      title: t('admin_inventory_management_table_label_item_name'),
      dataIndex: 'name',
      render: (_, record) => (
        <div>
          <span>{record.name || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'vendor',
      title: t('admin_inventory_management_table_label_vendor'),
      dataIndex: 'vendor',
      render: (_, record) => (
        <div>
          <span>{record.vendor || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'skuNo',
      title: t('admin_inventory_management_table_label_sku_number'),
      dataIndex: 'skuNo',
      render: (_, record) => (
        <div>
          <span>{record.skuNo}</span>
        </div>
      ),
    },
    {
      key: 'quantity',
      title: t('admin_inventory_management_table_label_quantity'),
      dataIndex: 'quantity',
      render: (_, record) => (
        <div>
          <span>{record.quantity || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'location',
      title: t('admin_inventory_management_table_label_location'),
      dataIndex: 'location',
      render: (_, record) => (
        <div>
          <span>{record.location || EMPTY_STRING}</span>
        </div>
      ),
    },
    {
      key: 'createdAt',
      title: t('admin_inventory_management_table_label_date_added'),
      dataIndex: 'createdAt',
      render: (_, record) => {
        return record.createdAt ? (
          <div className={cx('date')}>
            <img
              src={icons.commonIconCalender}
              alt={t('common_img_text_alt')}
              className={cx('icon')}
            />
            <span> {formattedTime(record.createdAt)}</span>
          </div>
        ) : (
          <div>{EMPTY_STRING}</div>
        );
      },
    },
    {
      key: 'cost',
      title: t('admin_inventory_management_table_label_cost'),
      dataIndex: 'cost',
      render: (_, record) => (
        <div>
          {record.cost ? (
            <span>{formatCurrency(CurrencyEnum.USD, record.cost)}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'status',
      title: t('admin_inventory_management_table_label_status'),
      dataIndex: 'status',
      render: (_, record) => {
        return record.status ? (
          <ButtonStatus width={100} color={getColorByStatus(record.status)} label={record.status} />
        ) : (
          <span>{EMPTY_STRING}</span>
        );
      },
    },
  ];
};

const Inventory = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  const [currentPage, setCurrentPage] = useState<number>(defaultCurrentPage);
  const [inventoryResp, setInventoryResp] = useState<IInventoryResp>();
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleAdd = () => {
    //TODO: CongGRF-ABD Handle Add
  };

  const handleChangePagination = (page: number) => {
    setCurrentPage(page);
  };
  //#endregion Handle Function

  return (
    <div id='inventoryPage' className={cx('container')}>
      <Toolbar
        title={t('admin_sidebar_label_inventory_management')}
        primaryBtn={{
          action: handleAdd,
          label: t('common_btn_add'),
        }}
      />

      <section className={cx('inventoryContent')}>
        <div className={cx('statisticTable')}>
          <BaseTable columns={columns(t)} dataSource={inventoryResp?.responses || []} />
        </div>

        <div className={cx('pagePagination')}>
          {inventoryResp?.pagination && (
            <BasePagination
              totalPages={inventoryResp?.pagination?.totalPages}
              totalItems={inventoryResp?.pagination?.totalItems}
              onChange={handleChangePagination}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default Inventory;
