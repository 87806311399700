// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { IEmployeeResp } from '~/utils/interface/employee';
import {
  createEmployee,
  getDetailEmployee,
  getListEmployee,
  updateEmployee,
  updateStatusEmployee,
} from './employeeThunk';

export interface EmployeeState {
  isRefreshListEmployee?: boolean;
}

const initialState: EmployeeState = {};

const employeeSlice = createSlice({
  name: 'employeeState',
  initialState,
  reducers: {
    setRefreshListEmployee(state, action) {
      state.isRefreshListEmployee = action.payload;
    },
  },
  extraReducers(builder) {
    // Add new employee
    builder
      .addCase(createEmployee.pending, (state) => {})
      .addCase(createEmployee.fulfilled, (state) => {})
      .addCase(createEmployee.rejected, (state) => {});

    // Get list employee
    builder
      .addCase(getListEmployee.pending, (state) => {})
      .addCase(getListEmployee.fulfilled, (state, action) => {})
      .addCase(getListEmployee.rejected, (state) => {});

    // Get detail employee
    builder
      .addCase(getDetailEmployee.pending, (state) => {})
      .addCase(getDetailEmployee.fulfilled, (state, action) => {})
      .addCase(getDetailEmployee.rejected, (state) => {});

    // Update employee
    builder
      .addCase(updateEmployee.pending, (state) => {})
      .addCase(updateEmployee.fulfilled, (state, action) => {})
      .addCase(updateEmployee.rejected, (state) => {});

    // Deactivate employee
    builder
      .addCase(updateStatusEmployee.pending, (state) => {})
      .addCase(updateStatusEmployee.fulfilled, (state, action) => {})
      .addCase(updateStatusEmployee.rejected, (state) => {});
  },
});

export const employeeActions = employeeSlice.actions;

const employeeReducer = employeeSlice.reducer;
export default employeeReducer;
