// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
import { Toolbar } from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { profileActions, selectUserProfile } from '~/thunks/profile/profileSlice';
import { disconnect, getAuthUrl } from '~/thunks/quickBook/quickBookThunk';
import { IUserProfile } from '~/utils/interface/profile';
import { MESSAGE_CONNECTED_QUICK_BOOK } from '~/utils/constants/common';
import { StatusQuickBookEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Integration.module.scss';
import { icons } from '~/assets';

const cx = classNames.bind(styles);

const Integration = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserProfile = useSelector(selectUserProfile);

  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === MESSAGE_CONNECTED_QUICK_BOOK) {
        dispatch(profileActions.connectedQuickBook());
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleConnectQuickBook = () => {
    loadingData?.show();

    dispatch(getAuthUrl())
      .unwrap()
      .then((res) => {
        if (!res.data) {
          return;
        }
        const url = res.data.url;
        window.open(url, '_blank');
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDisconnectQuickBook = () => {
    loadingData?.show();

    dispatch(disconnect())
      .unwrap()
      .then((res) => {
        return dispatch(profileActions.disconnectedQuickBook());
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleChangeStatusQuickBooks = () => {
    if (userInfo.quickBook?.status === StatusQuickBookEnum.CONNECTED) {
      handleDisconnectQuickBook();
    } else {
      handleConnectQuickBook();
    }
  };
  //#endregion Handle Function

  return (
    <div id='integrationPage' className={cx('container')}>
      <Toolbar title={t('integration_title')} />

      <div className={cx('quickBooks')}>
        <div className={cx('title')}>{t('integration_quick_books_title')}</div>

        <div className={cx('body')}>
          <div className={cx('status')}>
            {userInfo.quickBook?.status || t('integration_quick_books_status_not_connected')}
          </div>

          <button className={cx('switchStatus')} onClick={handleChangeStatusQuickBooks}>
            <img
              src={icons.adminIconIntegrationSwitchStatusQuickBooks}
              alt={t('common_img_text_alt')}
              className={cx('switchStatusIcon')}
            />

            <span>
              {userInfo.quickBook?.status === StatusQuickBookEnum.CONNECTED
                ? t('integration_quick_books_button_disconnect')
                : t('integration_quick_books_button_connection')}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Integration;
