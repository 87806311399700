// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, BaseModal } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './CommonConfirmModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
};

const cx = classNames.bind(styles);

const CommonConfirmModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, title } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseModal = () => {
    onClose();
  };
  //#endregion Handle Function

  return (
    <BaseModal
      id='commonConfirmModalComponent'
      width={'auto'}
      isOpen={isOpen}
      onClose={handleCloseModal}
    >
      <p className={cx('modalDesc')}>{title}</p>

      <div className={cx('modalActions')}>
        <BaseButton
          label={t('common_btn_ok')}
          width={'fit-content'}
          minWidth={'fit-content'}
          onClick={handleCloseModal}
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
        />
      </div>
    </BaseModal>
  );
};

export default CommonConfirmModal;
