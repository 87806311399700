// Libs
import classNames from 'classnames/bind';
import { ReactCalendarGroupRendererProps } from 'react-calendar-timeline';
// Components, Layouts, Pages
import { CircleAvatar } from '~/components';
// Others
import { ITimelineGroup } from '~/utils/interface/schedule';
import { CircleAvatarEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './GroupTimeline.module.scss';
import { icons } from '~/assets';

type Props = Omit<ReactCalendarGroupRendererProps, 'group'> & {
  groupData: ITimelineGroup;
};

const cx = classNames.bind(styles);

const GroupTimeline = (props: Props) => {
  //#region Destructuring Props
  const { groupData } = props;
  //#endregion Destructuring Props

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div className={cx('groupContainer')}>
      <CircleAvatar
        imageUrl={groupData.avatarUrl}
        firstName={groupData.title}
        type={CircleAvatarEnum.IMAGE}
      />
      <div className={cx('groupName')}>{`${groupData?.title}`}</div>
    </div>
  );
};

export default GroupTimeline;
