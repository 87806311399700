// Libs
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import classNames from 'classnames/bind';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '~/redux/hooks';
// Components, Layouts, Pages
// Others
import { LoadingContext } from '~/context';
import { jobDetailsSummaryMockupData } from '~/mockData';
import { getJobDetails } from '~/thunks/job/jobThunk';
import {
  DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  DEFAULT_HEIGHT_GOOGLE_MAP,
  DEFAULT_MAP_POSITION,
  DEFAULT_MAP_ZOOM,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { JobDetailsTypeEnum } from '~/utils/enum';
import { convertToTitleCase, getFullName } from '~/utils/helper';
import { IPosition } from '~/utils/interface/common';
import { IGetJobDetailsReq, IJobDetailsRes } from '~/utils/interface/job';
// Styles, images, icons
import styles from './JobDetailsTab.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const JobDetailsTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY_GOOGLE_MAP ?? '',
    libraries: DEFAULT_GG_MAP_LOAD_SCRIPT_LIB,
  });
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);
  const { jobId } = useParams();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [jobDetails, setJobDetails] = useState<IJobDetailsRes>();
  const [positionSelected, setPositionSelected] = useState<IPosition>(DEFAULT_MAP_POSITION);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!jobId || !JobDetailsTypeEnum.SUMMARY) return;

    handleGetJobDetails(jobId, { type: JobDetailsTypeEnum.SUMMARY });
    getUserLocation();
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetJobDetails = (id: string, params: IGetJobDetailsReq) => {
    loadingContext?.show();

    dispatch(getJobDetails({ id, params }))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setJobDetails(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setPositionSelected({ lat: latitude, lng: longitude });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  //#endregion Handle Function

  return (
    <div id={'adminJobDetailsTabComponent'} className={cx('container')}>
      <div className={cx('jobDetailsSection')}>
        <div className={cx('jobDetailsItem')}>
          <span className={cx('key')}>{t('admin_manage_jobs_tabs_summary_job_status')}</span>
          <span className={cx('value')}>
            {jobDetails?.status ? convertToTitleCase(jobDetails?.status) : EMPTY_STRING}
          </span>
        </div>

        <div className={cx('jobDetailsItem')}>
          <span className={cx('key')}>{t('admin_manage_jobs_tabs_summary_job_type')}</span>
          <span className={cx('value')}>{jobDetails?.type || EMPTY_STRING}</span>
        </div>

        <div className={cx('jobDetailsItem')}>
          <span className={cx('key')}>
            {t('admin_manage_jobs_tabs_summary_job_project_manager')}
          </span>
          <span className={cx('value')}>
            {getFullName({
              firstName: jobDetails?.projectManager?.firstName,
              lastName: jobDetails?.projectManager?.lastName,
            })}
          </span>
        </div>

        <div className={cx('jobDetailsItem')}>
          <span className={cx('key')}>{t('admin_manage_jobs_tabs_summary_job_lot_number')}</span>
          <span className={cx('value')}>{jobDetails?.lotNo || EMPTY_STRING}</span>
        </div>
      </div>

      <div className={cx('mapSection')}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: DEFAULT_HEIGHT_GOOGLE_MAP }}
            center={{
              lat: jobDetailsSummaryMockupData.location.lat || positionSelected.lat,
              lng: jobDetailsSummaryMockupData.location.lng || positionSelected.lng,
            }}
            zoom={DEFAULT_MAP_ZOOM}
          >
            {jobDetailsSummaryMockupData.location && (
              <Marker
                position={{
                  lat: jobDetailsSummaryMockupData.location.lat || positionSelected.lat,
                  lng: jobDetailsSummaryMockupData.location.lng || positionSelected.lng,
                }}
              />
            )}
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

export default JobDetailsTab;
