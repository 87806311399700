// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Controller, UseFieldArrayRemove, useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { useEffect } from 'react';
// Components, Layouts, Pages
import { BaseSelect, FormInput } from '~/components';
// Others
import { IQueryListVendor } from '~/utils/interface/vendors';
import { getListVendorsProposal } from '~/thunks/proposal/proposalThunk';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { IBaseOption } from '~/utils/interface/common';
import { RootState } from '~/redux/store';
import { IProposal } from '~/utils/interface/proposal';
// Styles, images, icons
import styles from './FieldAssignedProposal.module.scss';
import { icons } from '~/assets';
import { VendorTypeEnum } from '~/utils/enum';

type Props = {
  lineItemIndex: number;
  fieldIndex: number;
  removeField?: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldAssignedProposal = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex, fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { control } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  const { vendorProposalList } = useAppSelector((state: RootState) => state.proposal);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!vendorProposalList) {
      handleGetVendorProposalList({
        page: DEFAULT_CURRENT_PAGE,
        limit: DEFAULT_NUMBER_OPTIONS_SELECT,
        type: VendorTypeEnum.SUBCONTRACTOR,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetVendorProposalList = (payload: IQueryListVendor) => {
    dispatch(getListVendorsProposal(payload));
  };
  //#endregion Handle Function

  return (
    <div id='fieldAssignedProposalComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className={cx('colItem')}>
          <Controller
            control={control}
            name={`lineItems.${lineItemIndex}.subcontractor.assignedSubcontractors.${fieldIndex}.vendorId`}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                name={`lineItems.${lineItemIndex}.subcontractor.assignedSubcontractors.${fieldIndex}.vendorId`}
                label={t('admin_manage_proposal_subcontractors_item')}
                placeholder={t('common_placeholder_select')}
                options={vendorProposalList || []}
                value={`${value}` || EMPTY_STRING}
                onChange={(data: IBaseOption) => onChange(data.value)}
              />
            )}
          />
        </div>

        <div className={cx('colValue')}>
          <Controller
            control={control}
            name={`lineItems.${lineItemIndex}.subcontractor.assignedSubcontractors.${fieldIndex}.value`}
            render={({ field: { value, onChange } }) => (
              <FormInput label={t('Value')} value={value || EMPTY_STRING} onChange={onChange} />
            )}
          />
        </div>
      </div>

      <button
        className={cx('buttonClose')}
        type='button'
        onClick={() => removeField && removeField(fieldIndex)}
      >
        <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
      </button>
    </div>
  );
};

export default FieldAssignedProposal;
