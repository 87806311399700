// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import overviewApi from '~/apis/overview';
import { GET_OVERVIEW_LIST } from '~/utils/constants/actionType';

export const getListOverview = createAsyncThunk(
  GET_OVERVIEW_LIST,
  async (_, { rejectWithValue }) => {
    try {
      const res = await overviewApi.getListOverview();
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
