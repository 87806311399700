// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { getListOverview } from './overviewThunk';
export interface OverviewState {}

const initialState: OverviewState = {};
const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list overview
    builder
      .addCase(getListOverview.pending, (state) => {})
      .addCase(getListOverview.fulfilled, (state, action) => {})
      .addCase(getListOverview.rejected, (state) => {});
  },
});

export const overviewActions = overviewSlice.actions;

const overviewReducer = overviewSlice.reducer;
export default overviewReducer;
