// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { FormEstimateContainer, ModalUnderDevelopment, Toolbar } from '~/components';
// Others
// Styles, images, icons
import styles from './CreateNewEstimate.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const CreateNewEstimate = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  //#endregion Handle Function

  return (
    <div id='createNewEstimatePage' className={cx('container')}>
      <Toolbar title={t('estimate_add_new_estimate')} />

      <div className={cx('body')}>
        <FormEstimateContainer
          mode='create'
          isTemplatePage
          onCancel={handleGoBack}
          onSave={handleGoBack}
        />
      </div>

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
      )}
    </div>
  );
};

export default CreateNewEstimate;
