// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ColumnType, IDataTable } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { BaseTableEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './BaseTable.module.scss';

type Props<T> = {
  columns: ColumnType<T>[];
  dataSource: T[];
  typeStyle?: BaseTableEnum;
  onClickRow?: (record: T) => void;
};

const cx = classNames.bind(styles);

const BaseTable = <T extends Object>(props: Props<T>) => {
  //#region Destructuring Props
  const { dataSource = [], columns, typeStyle, onClickRow } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickRow = (record: T) => {
    onClickRow && onClickRow(record);
  };
  //#endregion Handle Function
  return (
    <div id='baseTableComponent' className={cx('baseTableComponent', typeStyle)}>
      <table className={cx('tableContainer', typeStyle)}>
        <thead className={cx('thead', typeStyle)}>
          <tr>
            {columns.map((column) => (
              <th className={cx('colTable', typeStyle)} key={column.key}>
                {column.title && column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={cx('tbody', typeStyle)}>
          {dataSource.length > DEFAULT_NUMBER_ZERO ? (
            dataSource.map((data, rowIndex: number) => (
              <tr
                key={rowIndex}
                onClick={() => handleClickRow(data)}
                className={cx('rowTableBody')}
              >
                {columns.map((column) => (
                  <td
                    key={column.key}
                    style={{ maxWidth: column.width, width: column.width }}
                    className={cx('colTableBody', typeStyle)}
                  >
                    <div className={cx('cellContainer')}>
                      {column.render
                        ? column.render(data[column.dataIndex!], data, rowIndex)
                        : (data[column.dataIndex!] as React.ReactNode)}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className={cx('noDataAvailable')}>{t('common_empty_data')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default BaseTable;
