// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import {
  FormProductModal,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  ThreeDotOptions,
  Toolbar,
  ConfirmModal,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { BaseTableEnum, CurrencyEnum } from '~/utils/enum';
import { IProduct, IProductRes } from '~/utils/interface/product';
import { formatCurrency, formatNumber } from '~/utils/helper';
import {
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  DEFAULT_CURRENT_PAGE,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ColumnType, IPagination, ITableParams } from '~/utils/interface/common';
import { IQueryListEmployee } from '~/utils/interface/employee';
import { productActions, selectIsRefreshProductList } from '~/thunks/product/productSlice';
import { deleteProduct, getListProducts } from '~/thunks/product/productThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './Products.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  handleShowEditProductModal: (productDetail: IProduct) => void,
  handleShowConfirmDeleteModal: (id: string) => void
): ColumnType<IProduct>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => handleSelectAll()}
    //     />
    //   ),
    //   render(_, record: IProduct) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'name',
      title: t('admin_manage_products_table_product_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
      width: '10%',
    },
    {
      key: 'description',
      title: t('admin_manage_products_table_description'),
      dataIndex: 'description',
      render: (_, record) => <span>{record?.description || EMPTY_STRING}</span>,
      width: '10%',
    },
    {
      key: 'extendedDescription',
      title: t('admin_manage_products_table_extended_description'),
      dataIndex: 'extendedDescription',
      render: (_, record) => <span>{record?.extendedDescription || EMPTY_STRING}</span>,
      width: '20%',
    },
    {
      key: 'unitMeasure',
      title: t('admin_manage_products_table_unit_of_measure'),
      dataIndex: 'unitMeasure',
      render: (_, record) => <span>{record?.unitMeasure || EMPTY_STRING}</span>,
    },
    {
      key: 'unitPrice',
      title: t('admin_manage_products_table_unit_price'),
      dataIndex: 'unitPrice',
      render: (_, record: IProduct) => (
        <div>
          {record.unitPrice ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitPrice))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'unitCost',
      title: t('admin_manage_products_table_unit_cost'),
      dataIndex: 'unitCost',
      render: (_, record: IProduct) => (
        <div>
          {record.unitCost ? (
            <span>{formatCurrency(CurrencyEnum.USD, Number(record.unitCost))}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'quickBookItemId',
      title: (
        <span className={cx('titleQuickBook')}>{t('admin_manage_products_table_quick_book')}</span>
      ),
      dataIndex: 'quickBookItemId',
      render: (_, record) => (
        <div className={cx('quickBookWrap')}>
          {record?.quickBookItemId ? (
            <div className={cx('greenCircle')} />
          ) : (
            <div className={cx('grayCircle')} />
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_products_table_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onEdit={() => handleShowEditProductModal(record)}
          onDelete={() => handleShowConfirmDeleteModal(record.id)}
        />
      ),
    },
  ];
};

const ManageProducts = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const isRefreshProductsList: boolean = useSelector(selectIsRefreshProductList);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryListEmployee>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [productsList, setProductsList] = useState<IProduct[]>([]);
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isShowFormProduct, setIsShowFormProduct] = useState<boolean>(false);
  const [productId, setProductId] = useState<string>(EMPTY_STRING);
  const [productDetail, setProductDetail] = useState<IProduct | null>(null);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListProducts(paramObject);
  }, [params?.page, params?.limit]);

  useEffect(() => {
    if (!isRefreshProductsList) return;
    handleGetListProducts(paramObject);
    dispatch(productActions.setRefreshList(false));
  }, [isRefreshProductsList]);

  useEffect(() => {
    if (productsList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = productsList.every((product: IProduct) => product.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = productsList
      .filter((employee: IProduct) => employee.isSelected)
      .map((employee: IProduct) => employee.id);

    //@Todo: Perform interactions with the employee id list
  }, [productsList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListProducts = (payload: ITableParams) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListProducts(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setProductsList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDeleteProduct = () => {
    if (!productId) return;

    loadingData?.show();
    dispatch(deleteProduct(productId))
      .unwrap()
      .then((res) => {
        handleActionProductSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowModalAddProduct = () => {
    setIsShowFormProduct(true);
  };

  const handleShowConfirmDeleteModal = (id?: string) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (id) {
      setProductId(id);
    }
  };

  const handleShowUpdateProductModal = (productDetail: IProduct) => {
    setProductDetail(productDetail);
    setIsShowFormProduct(true);
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryListEmployee = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleActionProductSuccess = () => {
    dispatch(productActions.setRefreshList(true));
    setIsShowFormProduct(false);
    setProductDetail(null);
  };

  const handleCloseFormProduct = () => {
    setIsShowFormProduct(false);
    setProductDetail(null);
  };

  const handleSelectItem = (id: string) => {
    setProductsList((prevList: IProduct[]) =>
      prevList.map((employee: IProduct) =>
        employee.id === id ? { ...employee, isSelected: !employee.isSelected } : employee
      )
    );
  };

  const handleSelectAll = () => {
    setProductsList((prevList) =>
      prevList.map((employee: IProduct) => ({
        ...employee,
        isSelected: !isSelectAll,
      }))
    );
  };
  //#endregion Handle Function

  return (
    <div id='manageProductsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_products_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleShowModalAddProduct,
          label: t('admin_manage_products_btn_add_product'),
        }}
      />

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleShowUpdateProductModal,
              handleShowConfirmDeleteModal
            )}
            dataSource={productsList || []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      <FormProductModal
        isOpen={isShowFormProduct}
        productDetail={productDetail}
        onClose={handleCloseFormProduct}
        onSuccess={handleActionProductSuccess}
      />

      {isShowConfirmDelete && productId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteProduct}
        />
      )}

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />
      )}
    </div>
  );
};

export default ManageProducts;
