export enum ResponseStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum EnvironmentEnum {
  LOCAL = 'local',
  DEVELOPMENT = 'development',
  STAG = 'stag',
  PROD = 'prod',
}

export enum RolesEnum {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}

export enum StorageEnum {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER = 'user',
  ROLE = 'role',
  STATUS_LOGIN = 'statusLogin',
  ACCOUNT_ID = 'accountId',
}

export enum LanguageEnum {
  EN = 'en',
}

export enum ButtonTypeEnum {
  AUTH = 'auth',
  PRIMARY = 'primary',
  SOLID_PRIMARY = 'solidPrimary',
  SOLID_SECONDARY = 'solidSecondary',
  SOLID_LINE = 'solidLine',
  ONLY_TEXT = 'onlyText',
  BORDER = 'border',
  CANCEL = 'cancel',
  GRAY = 'gray',
  RED = 'red',
  SMALL = 'small',
  DEACTIVATE = 'deactivate',
}

export enum InputTypeEnum {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  FILE = 'file',
}

export enum InputTypeStyleEnum {
  AUTH = 'auth',
}

export enum InputAuthName {
  EMAIL = 'email',
  PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'passwordConfirm',
  CURRENT_PASSWORD = 'currentPassword',
}

export enum AccountRoleCodesEnum {
  ADMIN = '00001',
  STAFF = '00002',
  TECHNICIAN = '00003',
}

export enum AdminRoutesGroup {
  ADMIN_MAIN = 'adminMain',
  HELP_SETTINGS = 'helpSettings',
}

export enum StaffRoutesGroup {
  STAFF_MAIN = 'staffMain',
  HELP_SETTINGS = 'helpSettings',
}

export enum StatisticCardEnum {
  BLACK = 'black',
  DARK_BLUE = 'darkBlue',
}

export enum ColorEnum {
  BLUE = 'blue',
  SKY = 'sky',
  VIOLET = 'violet',
  GREEN = 'green',
  ORANGE = 'orange',
  PURPLE = 'purple',
  AMBER = 'amber',
}

export enum SingleSelectTypeEnum {
  BG_FILL = 'bgFill',
}

export enum FieldFilterTypeEnum {
  SELECT = 'SELECT',
}

export enum BaseTableEnum {
  COLOR_TABLE = 'colorTable',
}

export enum StatusTableEnum {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
}

export enum RemarkTableEnum {
  ON_TIME = 'On time',
  LATE = 'LATE',
  PAID = 'Paid',
  SIGNED = 'Signed',
}

export enum PriorityEnum {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

export enum CircleAvatarEnum {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export enum ScheduleNavigateActionEnum {
  PREV = 'PREV',
  NEXT = 'NEXT',
  TODAY = 'TODAY',
  DATE = 'DATE',
}

export enum TimeFormatEnum {
  HOUR_MINUTE_AM_PM = 'h:mm A',
}

export enum PaymentStatusEnum {
  PAID = 'PAID',
  PENDING = 'PENDING',
}

export enum CurrencyEnum {
  USD = 'USD',
}

export enum ProposalEnum {
  ACCEPTED = 'Accepted',
  IN_PROGRESS = 'In Progress',
  ON_HOLD = 'On hold',
  DECLINED = 'Declined',
}

export enum NavTabTypeEnum {
  TAB_VALUE = 'tabValue',
}

export enum ProposalTabEnum {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export enum SearchParamsEnum {
  TAB = 'tab',
}

export enum InventoryStatusEnum {
  ACTIVE = 'Active',
  OUT_OF_STOCK = 'Out of Stock',
}

export enum MaterialUsedRemarkEnum {
  IN_PROGRESS = 'In Progress',
  ON_HOLD = 'On Hold',
  CLOSED = 'Closed',
}

export enum TranslationEnum {
  TRANSLATION = 'translation',
}

export enum InputSearchTypeStyleEnum {
  SEARCH_ANYTHING = 'searchAnything',
  WITH_FULL = 'withFull',
}

export enum ContractJobStatusEnum {
  LEAD = 'LEAD',
  BIDDING = 'BIDDING',
  OUT_FOR_SIGNATURE = 'OUT_FOR_SIGNATURE',
  PROPOSAL_EXPIRED = 'PROPOSAL_EXPIRED',
  ACTIVE = 'ACTIVE',
  PENDING_CHANGES = 'PENDING_CHANGES',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

export enum ServiceJobStatusEnum {
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  PENDING = 'PENDING',
  INVOICED = 'INVOICED',
  CLOSED_WITHOUT_INVOICING = 'CLOSED_WITHOUT_INVOICING',
  CLOSED_WITHOUT_PERFORMING = 'CLOSED_WITHOUT_PERFORMING',
}

export enum GetListJobTypeEnum {
  CONTRACT = 'CONTRACT',
  SERVICE = 'SERVICE',
}

export enum ManageJobsTabsEnum {
  SUMMARY = 'summary',
  ESTIMATE = 'estimate',
  PROPOSAL = 'proposal',
  DOCUMENTS = 'documents',
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  INVOICED = 'INVOICED',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
  ASSIGNED = 'ASSIGNED',
  PAID = 'PAID',
  DUE = 'DUE',
  NO_BALANCE_DUE = 'NO_BALANCE_DUE',
  SIGNED = 'SIGNED',
  LEAD = 'LEAD',
  BIDDING = 'BIDDING',
  OUT_FOR_SIGNATURE = 'OUT_FOR_SIGNATURE',
  PROPOSAL_EXPIRED = 'PROPOSAL_EXPIRED',
  PENDING_CHANGES = 'PENDING_CHANGES',
  SCHEDULED = 'SCHEDULED',
  CLOSED_WITHOUT_INVOICING = 'CLOSED_WITHOUT_INVOICING',
  CLOSED_WITHOUT_PERFORMING = 'CLOSED_WITHOUT_PERFORMING',
  ON_GOING = 'ON_GOING',
  CANCELED = 'CANCELED',
  IN_PROGRESS = 'IN_PROGRESS',
  INPROGRESS = 'INPROGRESS',
  APPROVED = 'APPROVED',
  ON_HOLD = 'ON_HOLD',
  NEW = 'NEW',
  PAUSED = 'PAUSED',
  PAUSE = 'PAUSE',
}

export enum JobDetailsTypeEnum {
  SUMMARY = 'SUMMARY',
}

export enum AddJobBusinessTypeEnum {
  CONTRACT = 'CONTRACT',
  SERVICE = 'SERVICE',
}

export enum MultipleSelectTypeEnum {
  DEFAULT = 'default',
  TEXT = 'text',
}

export enum DurationTypeEnum {
  DAYS = 'DAYS',
}

export enum DocumentTypeEnum {
  CONTRACT = 'CONTRACT',
  PROJECT_FILES = 'PROJECT_FILES',
  INVOICE = 'INVOICE',
  FORM = 'FORM',
  TASK_DATA = 'TASK_DATA',
  TEMPLATE = 'TEMPLATE',
}

export enum VendorTypeEnum {
  MATERIAL_SUPPLIER = 'MATERIAL_SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  EQUIPMENT_SUPPLIER = 'EQUIPMENT_SUPPLIER',
  MISCELLANEOUS_EXPENSES = 'MISCELLANEOUS_EXPENSES',
}

export enum ContactJobTypeEnum {
  FIXED_PRICE = 'FIXED_PRICE',
  FIXED_PRICE_WITH_AIA_BILLING = 'FIXED_PRICE_WITH_AIA_BILLING',
}

export enum ContactJobGarageHandEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum VendorLabelTypeEnum {
  MATERIAL_SUPPLIER = 'admin_manage_vendors_label_type_material_supplier',
  SUBCONTRACTOR = 'admin_manage_vendors_label_type_subcontractor',
  EQUIPMENT_SUPPLIER = 'admin_manage_vendors_label_type_equipment_supplier',
  MISCELLANEOUS_EXPENSES = 'admin_manage_vendors_label_type_miscellaneous_expenses',
}

export enum NotificationTypeEnum {
  DEFAULT = 'default',
  INFO = 'info',
  SUCCESS = 'success',
}

// Date Format
export enum DateFormatEnum {
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'DD MM YYYY' = 'DD MMM YYYY',
}

export enum MenuTypeEnum {
  DROPDOWN = 'dropdown',
}

export enum HTMLEventEnum {
  KEYDOWN = 'keydown',
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  RESIZE = 'resize',
  SCROLL = 'scroll',
}

export enum StatusAssigneeEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ON_LEAVE = 'ON_LEAVE',
}

export enum ToastTypeEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

export enum ToastPositionTypeEnum {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum KeyboardEnum {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
}

export enum MethodRequestServerEnum {
  GET = 'get',
}

export enum AddTaskNameEnum {
  NAME = 'name',
  ASSIGNEE_IDS = 'assigneeIds',
  STREET_ADDRESS = 'streetAddress',
  CITY = 'city',
  STATE = 'state',
  ZIP_CODE = 'zipCode',
  DATE = 'date',
  JOB_ID = 'jobId',
  STATUS = 'status',
  CLIENT_ID = 'clientId',
  DUE_DATE = 'dueDate',
  TASK_CREATOR = 'taskCreator',
  COMMENT = 'comment',
}

export enum CreateNewContractJobEnum {
  NAME = 'title',
  SYNC_WITH_QUICK_BOOKS = 'isSyncQuickBook',
  CLIENT = 'clientId',
  PROJECT_TYPE = 'type',
  PLAN_NUMBER = 'planNumber',
  LOT_NUMBER = 'lotNo',
  ELEVATION = 'elevation',
  GARAGE_HAND = 'garageHand',
  GENERAL_NOTE = 'note',
  PROJECT_MANAGER = 'managerId',
  BID_DUE_DATE = 'dueDate',
  ESTIMATOR = 'estimatorId',
  ADDRESS = 'streetAddress',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zipCode',
}

export enum CreateNewServiceJobEnum {
  NAME = 'title',
  SYNC_WITH_QUICK_BOOKS = 'isSyncQuickBook',
  CLIENT = 'clientId',
  PROJECT_TYPE = 'type',
  PLAN_NUMBER = 'planNumber',
  LOT_NUMBER = 'lotNo',
  ELEVATION = 'elevation',
  GARAGE_HAND = 'garageHand',
  GENERAL_NOTE = 'note',
  PROJECT_MANAGER = 'managerId',
  BID_DUE_DATE = 'dueDate',
  ESTIMATOR = 'estimatorId',
  ADDRESS = 'streetAddress',
  CITY = 'city',
  STATE = 'state',
  ZIP = 'zipCode',
}

export enum AddPhaseContentEnum {
  NAME = 'name',
  PERCENTAGE = 'percentage',
  MATERIAL_BUDGET = 'materialBudget',
  LABOR_BUDGET = 'laborBudget',
  SUBCONTRACTORS_BUDGET = 'subcontractorsBudget',
  EQUIPMENT_BUDGET = 'equipmentBudget',
  MISCELLANEA_BUDGET = 'miscellaneaBudget',
  EDIT_WO = 'editWO',
  SET_CONTRACT = 'setContract',
  MATERIALS = 'materials',
  LABORS = 'labors',
  SUBCONTRACTORS = 'subContractors',
  EQUIPMENTS = 'equipments',
  MISCELLANEA = 'miscellanea',
}

export enum ProposalContentEnum {
  MATERIALS = 'materials',
  LABORS = 'labors',
  SUBCONTRACTORS = 'subcontractor',
  EQUIPMENTS = 'equipments',
  MISCELLANEA = 'miscellanea',
}

export enum AddEstimateEnum {
  SAVE_TEMPLATE = 'isTemplate',
  PHASES = 'phases',
}

export enum LabelColumnChartEnum {
  CONTRACT_JOB = 'CONTRACT_JOB',
  SERVICE_JOB = 'SERVICE_JOB',
}

export enum BudgetCategoryNameEnum {
  PRODUCT_NAME = 'productName',
  FIXTURE_LOCATION_NAME = 'fixtureLocationName',
  QUANTITY = 'quantity',
  UNIT_COST = 'unitCost',
  SUBTOTAL = 'subTotal',
}

export enum StatusQuickBookEnum {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum OrderTabsEnum {
  PURCHASE_ORDER = 'purchase-order',
  CHANGE_ORDER = 'change-order',
}

export enum LocaleEnum {
  EN_US = 'en-Us',
}

export enum AddClientNameEnum {
  NAME = 'name',
  AVATAR = 'avatar',
  CONTACT_NAME = 'contactName',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL = 'email',
  OTHER = 'other',
  NOTE = 'note',
  TYPE_LINK_QUICK_BOOK = 'typeLinkQuickBook',
  QUICK_BOOK_CUSTOMER_ID = 'quickBookCustomerId',
}

export enum LinkToQuickBookCustomerEnum {
  NO = 'NO',
  ADD_NEW = 'ADD_NEW',
  ADD_EXISTING = 'ADD_EXISTING',
}

export enum TypeProductEnum {
  MATERIAL_SUPPLIER = 'MATERIAL_SUPPLIER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  EQUIPMENT_SUPPLIER = 'EQUIPMENT_SUPPLIER',
  MISCELLANEOUS_EXPENSES = 'MISCELLANEOUS_EXPENSES',
}

export enum ManageTermsAndConditionsTabEnum {
  MAIN_CONTENT = 'main-content',
  INCLUSION_AND_EXCLUSION_ITEMS = 'inclusion-and-exclusion-items',
}

export enum CreateTimeClockTypeEnum {
  TASK_ID = 'taskId',
  USERNAME = 'username',
  TASK_NAME = 'taskName',
  JOB_NAME = 'jobName',
}

export enum AccountTypeEnum {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  TECHNICIAN = 'TECHNICIAN',
  ADMIN_STAFF = 'ADMIN_STAFF',
  ADMIN_TECHNICIAN = 'ADMIN_TECHNICIAN',
  STAFF_TECHNICIAN = 'STAFF_TECHNICIAN',
}

export enum JobDocumentNameEnum {
  JOB_ID = 'jobId',
  NAME = 'name',
  TYPE = 'type',
  REMARK = 'remark',
  DOCUMENT = 'document',
  CREATE_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  LAST_UPDATED = 'lastUpdated',
}

export enum CreateNewProposalEnum {
  CONTACT_NAME = 'contactName',
  PO = 'po',
  ID_CONTRACT = 'idContract',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  PAYMENT_TERMS = 'paymentTerms',
  TAX_RATE = 'taxRate',
  DESCRIPTION = 'description',
  TAX_VALUE = 'taxValue',
  TAXABLE = 'taxable',
  LINE_ITEMS = 'lineItems',
  TERMS_CONDITIONS = 'termsConditions',
  PROJECT_DESCRIPTION = 'projectDescription',
  DETAILS_OR_COMMENTS = 'detailsOrComments',
  SHOW_DETAILS_OR_COMMENTS = 'showDetailsOrComments',
  SHOW_BREAKDOWN_OR_BILL = 'showBreakdownOrBill',
}

export enum ModeTypeEnum {
  VIEW = 'view',
  CREATE = 'create',
  EDIT = 'edit',
}

export enum CreateMainTermEnum {
  NAME = 'name',
  UPPER_CONTENT = 'upperContent',
  LOWER_CONTENT = 'lowerContent',
  IS_TEMPLATE = 'isTemplate',
}

export enum UnitEnum {
  HRS = 'hrs',
  DOLLAR = '$',
  PERCENT = '%',
}

export enum TextEditorEnum {
  FULL = 'full',
  SHORT = 'short',
  CUSTOM = 'custom',
}

export enum FormModalWorkOrderEnum {
  DESCRIPTION = 'description',
}

export enum PhaseTypeEnum {
  MATERIAL = 'MATERIAL',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  LABOR = 'LABOR',
  EQUIPMENT = 'EQUIPMENT',
  MISCELLANEA = 'MISCELLANEA',
}

export enum PurchaseTypeEnum {
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  EXPENSE = 'EXPENSE',
  FLEXIBLE_SPENDING = 'FLEXIBLE_SPENDING',
  REIMBURSEMENT = 'REIMBURSEMENT',
}

// WebSocket
export enum WebSocketTypeEnum {
  USE_APP = 'USE_APP',
  INVOICE = 'INVOICE',
}

export enum WebSocketEventEnum {
  LOGIN = 'LOGIN',
  JOIN_LIST_INVOICE = 'JOIN_LIST_INVOICE',
  LEAVE_LIST_INVOICE = 'LEAVE_LIST_INVOICE',
  SEND_INVOICE = 'SEND_INVOICE',
}
