// Libs
import classNames from 'classnames/bind';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { MenuItem } from '~/components';
// Others
import {
  adminRouteAbsolute,
  privateAdminRoutesGroup,
  privateStaffRoutesGroup,
  privateTechnicianRoutesGroup,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import { IMenuGroupModel } from '~/utils/interface/common';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './Sidebar.module.scss';
import { images } from '~/assets';

const cx = classNames.bind(styles);

type Props = {
  role: AccountRoleCodesEnum;
};

const Sidebar = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [currentRole] = useState<string | null>(localStorage.getItem(StorageEnum.ROLE) || null);

  const [routes] = useState<IMenuGroupModel[]>(() => {
    switch (role) {
      case AccountRoleCodesEnum.ADMIN:
        return privateAdminRoutesGroup;

      case AccountRoleCodesEnum.STAFF:
        return privateStaffRoutesGroup;

      case AccountRoleCodesEnum.TECHNICIAN:
        return privateTechnicianRoutesGroup;

      default:
        return [];
    }
  });
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleNavigateOverview = () => {
    if (!currentRole) return;

    switch (currentRole) {
      case AccountRoleCodesEnum.ADMIN:
        return navigate(adminRouteAbsolute.dashboard);

      case AccountRoleCodesEnum.STAFF:
        return navigate(staffRouteAbsolute.dashboard);
    }
  };
  //#endregion Handle Function
  return (
    <div id='sidebarMainLayout' className={cx('sidebarContainer')}>
      <p className={cx('head')}>
        <img
          className={cx('images')}
          src={images.commonImageLogoTextWhite}
          alt={t('common_img_text_alt')}
          onClick={handleNavigateOverview}
        />
      </p>

      <div className={cx('body')}>
        {routes.map((menuGroup) => (
          <div key={menuGroup.name} className={cx('menuGroup')}>
            {menuGroup.menu.map((menu, index) => {
              if (!menu.path || !menu.name) {
                return;
              }

              return (
                <div key={index} className={cx('menuList')}>
                  <MenuItem key={index} menuItem={menu} />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
