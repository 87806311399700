// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { PRIMARY_RED_600 } from '~/utils/constants/color';
// Styles, images, icons
import { icons } from '~/assets';
import { CommonIconInactive } from '~/assets/svgComponents';
import styles from './ThreeDotOptions.module.scss';

type Props = {
  onView?: () => void;
  onViewQuickBooks?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onUpdateStatus?: () => void;
};

const cx = classNames.bind(styles);

const ThreeDotOptions = (props: Props) => {
  //#region Destructuring Props
  const { onView, onViewQuickBooks, onEdit, onDelete, onUpdateStatus } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State

  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleView = (closeFn: Function) => () => {
    onView && onView();
    closeFn();
  };

  const handleViewQuickBooks = (closeFn: Function) => () => {
    onViewQuickBooks && onViewQuickBooks();
    closeFn();
  };

  const handleEdit = (closeFn: Function) => () => {
    onEdit && onEdit();
    closeFn();
  };

  const handleDelete = (closeFn: Function) => () => {
    onDelete && onDelete();
    closeFn();
  };

  const handleUpdateStatus = (closeFn: Function) => () => {
    onUpdateStatus && onUpdateStatus();
    closeFn();
  };
  //#endregion Handle Function

  return (
    <div id='baseSortComponent'>
      <Popover className={cx('relative')}>
        <PopoverButton className={cx('btnGroup')}>
          <img
            src={icons.commonIconThreeDot}
            alt={t('common_img_text_alt')}
            className={cx('btnImg')}
          />
        </PopoverButton>

        <PopoverPanel
          transition
          anchor={{ to: 'bottom end', gap: '4px' }}
          className={cx('popupContainer')}
        >
          {({ close }) => (
            <div className={cx('popBtnGroup')}>
              {onView && (
                <button onClick={handleView(close)} className={cx('buttonOption')}>
                  <img
                    className={cx('popIcon')}
                    src={icons.commonIconView}
                    alt={t('common_img_text_alt')}
                    width={16}
                    height={16}
                  />
                  <span className={cx('labelText')}>{t('common_action_view')}</span>
                </button>
              )}

              {onViewQuickBooks && (
                <button onClick={handleViewQuickBooks(close)} className={cx('buttonOption')}>
                  <img
                    className={cx('popIcon')}
                    src={icons.commonIconView}
                    alt={t('common_img_text_alt')}
                    width={16}
                    height={16}
                  />
                  <span className={cx('labelText')}>{t('common_action_view_on_quick_books')}</span>
                </button>
              )}

              {onEdit && (
                <button onClick={handleEdit(close)} className={cx('buttonOption')}>
                  <img
                    className={cx('popIcon')}
                    src={icons.commonIconEdit}
                    alt={t('common_img_text_alt')}
                    width={16}
                    height={16}
                  />
                  <span className={cx('labelText')}>{t('common_action_edit')}</span>
                </button>
              )}

              {onDelete && (
                <button onClick={handleDelete(close)} className={cx('buttonDelete')}>
                  <img
                    className={cx('popIcon')}
                    src={icons.commonIconTrash}
                    alt={t('common_img_text_alt')}
                    width={16}
                    height={16}
                  />
                  <span className={cx('labelDelete')}>{t('common_btn_delete')}</span>
                </button>
              )}

              {onUpdateStatus && (
                <button onClick={handleUpdateStatus(close)} className={cx('buttonUpdateStatus')}>
                  <CommonIconInactive fill={PRIMARY_RED_600} width={16} height={16} />
                  <span className={cx('labelUpdateStatus')}>{t('common_btn_update_status')}</span>
                </button>
              )}
            </div>
          )}
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default ThreeDotOptions;
