// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
// Components, Layouts, Pages
import { Space } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { DEFAULT_NUMBER_ONE, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { ILinkPhase } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data?: ILinkPhase[];
};

const LinkedPhase = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const formatName = (input: string): string => {
    const firstCharacter = input.charAt(DEFAULT_NUMBER_ZERO).toUpperCase();
    const remainingCharacters = input.slice(DEFAULT_NUMBER_ONE).toLowerCase();
    return firstCharacter + remainingCharacters;
  };
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{'Linked Phases'}</Text>
      {/* <Divider /> */}
      <Space height={6} />

      <View style={styles.row}>
        <Text style={[styles.budgetCol, styles.fontItalic]}>{``}</Text>
        <Text style={[styles.costCol, styles.fontItalic]}>{`Cost Budget`}</Text>
        <Text style={[styles.markupCol, styles.fontItalic]}>{`Markup`}</Text>
        <Text style={[styles.subtotalCol, styles.fontItalic]}>{`Subtotal`}</Text>
      </View>
      <Space height={6} />

      {data && data?.length > 0 && (
        <>
          {data?.map((item, index) => (
            <Fragment key={index}>
              <View style={styles.row}>
                <Text style={styles.budgetCol}>
                  {item.type ? formatName(item.type) : EMPTY_STRING}
                </Text>
                <Text style={styles.costCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.cost ?? DEFAULT_NUMBER_ZERO)}
                </Text>
                <Text style={styles.markupCol}>{`${item?.markup ?? 0} %`}</Text>
                <Text style={styles.subtotalCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.total ?? DEFAULT_NUMBER_ZERO)}
                </Text>
              </View>
              <Space height={6} />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );
};

export default LinkedPhase;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  budgetCol: {
    flex: 1,
  },
  costCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  markupCol: {
    flexBasis: '10%',
    textAlign: 'right',
  },
  subtotalCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  fontItalic: {
    fontStyle: 'italic',
  },
});
