// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { BaseModal, BaseButton, BaseTable, ModalUnderDevelopment, Loading } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getDetailInvoice } from '~/thunks/invoice/invoiceThunk';
import { ColumnType } from '~/utils/interface/common';
import { IInvoiceDetail, IInvoiceDetailProduct } from '~/utils/interface/invoices';
import {
  DEFAULT_INVOICE_SEND_FROM,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BaseTableEnum, ButtonTypeEnum, CurrencyEnum } from '~/utils/enum';
import { formatAddress, formatCurrency, formattedTime, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './InvoiceDetailModal.module.scss';
import { icons } from '~/assets';

type Props = {
  invoiceId: string;
  isOpen: boolean;
  onClose: () => void;
};

const cx = classNames.bind(styles);

const columns = (t: TFunction): ColumnType<IInvoiceDetailProduct>[] => {
  return [
    {
      key: 'productName',
      title: t('invoice_detail_table_product_name'),
      dataIndex: 'productName',
      render: (_, record) => <span>{record?.productName || EMPTY_STRING}</span>,
    },
    {
      key: 'price',
      title: t('invoice_detail_table_price'),
      dataIndex: 'price',
      render: (_, record) => (
        <span>{formatCurrency(CurrencyEnum.USD, record?.price) ?? EMPTY_STRING}</span>
      ),
    },
    {
      key: 'quantity',
      title: t('invoice_detail_table_quantity'),
      dataIndex: 'quantity',
      render: (_, record) => <span>{record?.quantity ?? EMPTY_STRING}</span>,
    },
    {
      key: 'subtotal',
      title: t('invoice_detail_table_amount'),
      dataIndex: 'subTotal',
      render: (_, record) => (
        <span>{formatCurrency(CurrencyEnum.USD, record?.subTotal) ?? EMPTY_STRING}</span>
      ),
    },
  ];
};

const InvoiceDetailModal = (props: Props) => {
  //#region Destructuring Props
  const { invoiceId, isOpen, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoiceDetail>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!invoiceId) return;

    handleGetInvoiceDetail(invoiceId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetInvoiceDetail = (invoiceId: string) => {
    if (!invoiceId) return;

    setIsLoading(true);

    dispatch(getDetailInvoice(invoiceId))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        setInvoiceDetail(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownloadInvoiceDetail = () => {
    // TODO: Handle download invoice detail

    setIsShowUnderDevelopment(true);
  };

  const handleCloseUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <BaseModal id='InvoiceDetailModalComponent' isOpen={isOpen} onClose={onClose}>
      <div className={cx('container')}>
        <div className={cx('header')}>{t('invoice_detail_title')}</div>

        <div className={cx('line')} />

        <div className={cx('body')}>
          <div className={cx('twoCol')}>
            <div className={cx('alignLeft')}>
              {invoiceDetail?.id && (
                <div className={cx('id')}>{invoiceDetail?.quickBookInvoiceId || EMPTY_STRING}</div>
              )}
            </div>

            <div className={cx('alignRight')}>
              <img
                src={icons.adminIconInvoiceDetailLogoPlumbing}
                alt={t('common_img_text_alt')}
                className={cx('logo')}
              />
            </div>
          </div>

          <div className={cx('twoCol')}>
            <div className={cx('alignLeft')}>
              <h6 className={cx('titleContent', 'billTitle')}>{t('invoice_detail_bill_to')}</h6>

              <p className={cx('content', 'billContent')}>
                {getFullName({ ...invoiceDetail?.client }) || EMPTY_STRING}
              </p>

              <p className={cx('content')}>
                {formatAddress({ ...invoiceDetail?.client }) || EMPTY_STRING}
              </p>
            </div>

            <div className={cx('alignRight')}>
              <h6 className={cx('titleContent', 'sentTitle')}>{t('invoice_detail_sent_from')}</h6>

              <p className={cx('content', 'sentContent')}>{DEFAULT_INVOICE_SEND_FROM}</p>

              <p className={cx('content')}></p>
            </div>
          </div>

          <div className={cx('lineCenter')}></div>

          {/* <div className={cx('twoCol')}>
            <div className={cx('alignLeft')}>
              <h6 className={cx('titleContent', 'titleSpace')}>
                {t('invoice_detail_invoice_information_title')}
              </h6>

              <p className={cx('content', 'contentSpace')}>
                {t('invoice_detail_invoice_information')}
              </p>
            </div>

            <div className={cx('alignLeft')}>
              <h6 className={cx('titleContent', 'titleSpace')}>{t('invoice_detail_title')}</h6>

              <div className={cx('invoiceDetailItem', 'contentSpace')}>
                <span className={cx('key')}>{t('invoice_detail_generated')}</span>
                <span className={cx('value')}>{EMPTY_STRING}</span>
              </div>

              <div className={cx('invoiceDetailItem', 'contentSpace')}>
                <span className={cx('key')}>{t('invoice_detail_invoice_number')}</span>
                <span className={cx('value')}>{EMPTY_STRING}</span>
              </div>

              <div className={cx('invoiceDetailItem', 'contentSpace')}>
                <span className={cx('key')}>{t('invoice_detail_sent_from')}</span>
                <span className={cx('value')}>{}</span>
              </div>

              <div className={cx('invoiceDetailItem', 'contentSpace')}>
                <span className={cx('key')}>{t('invoice_detail_total_invoice_amount')}</span>
                <span className={cx('value')}>{formatCurrency(CurrencyEnum.USD, 0)}</span>
              </div>
            </div>
          </div> */}

          <div>
            <h6 className={cx('titleContent', 'titleSpace')}>{t('invoice_detail_title')}</h6>

            <div className={cx('twoCol')}>
              <div className={cx('alignLeft')}>
                <div className={cx('invoiceDetailItem', 'contentSpace')}>
                  <span className={cx('key')}>{t('invoice_detail_generated')}</span>
                  <span className={cx('value')}>
                    {invoiceDetail?.createdAt
                      ? formattedTime(invoiceDetail?.createdAt)
                      : EMPTY_STRING}
                  </span>
                </div>

                <div className={cx('invoiceDetailItem', 'contentSpace')}>
                  <span className={cx('key')}>{t('invoice_detail_invoice_number')}</span>
                  <span className={cx('value')}>
                    {invoiceDetail?.quickBookInvoiceId || EMPTY_STRING}
                  </span>
                </div>
              </div>

              <div className={cx('alignLeft')}>
                <div className={cx('invoiceDetailItem', 'contentSpace')}>
                  <span className={cx('key')}>{t('invoice_detail_sent_from')}</span>
                  <span className={cx('value')}>{}</span>
                </div>

                <div className={cx('invoiceDetailItem', 'contentSpace')}>
                  <span className={cx('key')}>{t('invoice_detail_total_invoice_amount')}</span>
                  <span className={cx('value')}>
                    {formatCurrency(CurrencyEnum.USD, invoiceDetail?.total || DEFAULT_NUMBER_ZERO)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={cx('lineCenter')}></div>

          <div className={cx('tableWrap')}>
            <BaseTable
              columns={columns(t)}
              dataSource={invoiceDetail?.products || []}
              typeStyle={BaseTableEnum.COLOR_TABLE}
            />
          </div>

          <div className={cx('lineCenter')}></div>

          <div className={cx('twoCol')}>
            <div className={cx('alignLeft')}></div>

            <div className={cx('alignRight')}>
              <div className={cx('totalItem')}>
                <span className={cx('totalKey')}>{t(`invoice_detail_subtotal`)}</span>

                <span className={cx('totalValue')}>
                  {formatCurrency(CurrencyEnum.USD, invoiceDetail?.subTotal || DEFAULT_NUMBER_ZERO)}
                </span>
              </div>

              <div className={cx('lineCenter')}></div>

              <div className={cx('totalItem')}>
                <span className={cx('totalKey')}>{t(`invoice_detail_total`)}</span>

                <span className={cx('totalValue')}>
                  {formatCurrency(CurrencyEnum.USD, invoiceDetail?.total || DEFAULT_NUMBER_ZERO)}
                </span>
              </div>
            </div>
          </div>

          {/* <div className={cx('lineCenter')}></div>

          <div className={cx('twoCol')}>
            <div className={cx('alignLeft')}>
              <h6 className={cx('titleContent', 'titleSpace')}>{t('invoice_detail_note_title')}</h6>

              <p className={cx('content', 'contentSpace')}>{t('invoice_detail_note')}</p>
            </div>

            <div className={cx('alignLeft')}>
              <h6 className={cx('titleContent', 'titleSpace')}>
                {t('invoice_detail_payment_terms_title')}
              </h6>

              <p className={cx('content', 'contentSpace')}>{t('invoice_detail_payment_terms')}</p>
            </div>
          </div>

          <div className={cx('contactWrap')}>
            <p className={cx('contactContent')}>{t('invoice_detail_contact_note')}</p>

            <p className={cx('contactContent')}>
              <span className={cx('contactContentHightLight')}>
                {t('invoice_detail_need_help')}
              </span>

              {t(`invoice_detail_contact`, {
                phoneNumber: mockDataInvoiceDetailPhoneNumber,
                email: mockDataInvoiceDetailEmail,
              })}
            </p>
          </div> */}
        </div>

        <div className={cx('lineBot')} />

        <div className={cx('actions')}>
          <div className={cx('buttonCancel')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              onClick={handleCancel}
              typeStyle={ButtonTypeEnum.CANCEL}
            />
          </div>

          <BaseButton
            label={t('Download')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='button'
            onClick={handleDownloadInvoiceDetail}
          />
        </div>
      </div>

      {isShowUnderDevelopment && <ModalUnderDevelopment onClose={handleCloseUnderDevelopment} />}

      {isLoading && <Loading />}
    </BaseModal>
  );
};

export default InvoiceDetailModal;
