// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Others
import { IStatisticCard } from '~/utils/interface/common';
import { StatisticCardEnum } from '~/utils/enum';
// Styles, Images, Icons
import styles from './StatisticCard.module.scss';

type Props = {
  icon?: React.ReactNode;
  data?: IStatisticCard;
  type?: StatisticCardEnum;
};

const cx = classNames.bind(styles);

const StatisticCard = (props: Props) => {
  //#region Destructuring Props
  const { icon, data, type } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='statisticCard' className={cx('statisticCard')}>
      <section className={cx('mainContent')}>
        <div className={cx('iconCard', type)}>{icon && icon}</div>
        <div className={cx('labelGroup')}>
          <div className={cx('statisticTitle')}>{data && t(data.title)}</div>
          <div className={cx('statisticValue')}>{data && data.value}</div>
        </div>
      </section>
    </div>
  );
};

export default StatisticCard;
