// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import { CREATE_TECHNICIAN, GET_LIST_TECHNICIAN } from '~/utils/constants/actionType';
import technicianApi from '~/apis/technician';
import { IParamGetListTechnician } from '~/utils/interface/technician';

export const getListTechnician = createAsyncThunk(
  GET_LIST_TECHNICIAN,
  async (payload: IParamGetListTechnician, { rejectWithValue }) => {
    try {
      const res = await technicianApi.getListTechnician(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTechnician = createAsyncThunk(
  CREATE_TECHNICIAN,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const res = await technicianApi.createTechnician(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
