// Others
import { INavTabItem } from '~/utils/interface/common';
import { ManageJobsTabsEnum, ManageTermsAndConditionsTabEnum } from '~/utils/enum';

// Manage jobs tabs
export const manageJobsTabs: INavTabItem[] = [
  {
    name: 'admin_manage_jobs_tabs_summary',
    params: ManageJobsTabsEnum.SUMMARY,
  },
  {
    name: 'admin_manage_jobs_tabs_estimate',
    params: ManageJobsTabsEnum.ESTIMATE,
  },
  {
    name: 'admin_manage_jobs_tabs_proposal',
    params: ManageJobsTabsEnum.PROPOSAL,
  },
  {
    name: 'admin_manage_jobs_tabs_documents',
    params: ManageJobsTabsEnum.DOCUMENTS,
  },
];

// Manage terms and conditions tabs
export const manageTermsAndConditionsTabs: INavTabItem[] = [
  {
    name: 'terms_and_conditions_tab_main_content',
    params: ManageTermsAndConditionsTabEnum.MAIN_CONTENT,
  },
  {
    name: 'terms_and_conditions_tab_inclusion_and_exclusion_items',
    params: ManageTermsAndConditionsTabEnum.INCLUSION_AND_EXCLUSION_ITEMS,
  },
];
