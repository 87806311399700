// Libs
import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
// Others
import { IAssignee } from '~/utils/interface/common';
import { MAX_LENGTH_AVATAR, DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { generateGuid, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './AvatarStack.module.scss';
import { icons } from '~/assets';

type Props = {
  avatars?: IAssignee[];
  maxlength?: number;
  size?: number;
  remaining?: boolean;
  onAdd?: () => void;
};

const cx = classNames.bind(styles);

const AvatarStack = React.memo((props: Props) => {
  //#region Destructuring Props
  const { avatars, maxlength = MAX_LENGTH_AVATAR, size, remaining, onAdd } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare Memo
  const remainingCount = useMemo(() => {
    if (!avatars || avatars.length <= maxlength) return;

    return avatars?.length - maxlength;
  }, [avatars, maxlength]);
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='avatarStackComponent' className={cx('container')}>
      {avatars?.slice(DEFAULT_NUMBER_ZERO, maxlength)?.map((item, index) => {
        const uniqueId = `${generateGuid()}-${index}`;

        return (
          <div
            key={uniqueId}
            data-tooltip-id={uniqueId}
            data-tooltip-place='top'
            data-tooltip-variant='light'
          >
            <img
              key={index}
              src={item.avatarUrl ? item.avatarUrl : icons.commonIconLogo}
              alt={t('common_img_text_alt')}
              className={cx('avatarStackItem')}
              style={{ width: size, height: size }}
            />

            <Tooltip id={uniqueId} className={cx('tooltipWrap')}>
              <span className={cx('tooltipText')}>
                {getFullName({ firstName: item.firstName, lastName: item.lastName })}
              </span>
            </Tooltip>
          </div>
        );
      })}

      {remaining && remainingCount && remainingCount > 0 && (
        <div className={cx('avatarRemaining')} style={{ width: size, height: size }}>
          <span>{`+${remainingCount}`}</span>
        </div>
      )}

      {/* {onAdd && (
        <div
          className={cx('avatarStackPlus')}
          style={{ width: size, height: size }}
          onClick={onAdd}
        >
          <img
            src={icons.avatarStackIconPlus}
            alt={t('common_img_alt')}
            className={cx('avatarStackIconPlus')}
          />
        </div>
      )} */}
    </div>
  );
});

export default AvatarStack;
