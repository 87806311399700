// Libs
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, ProposalInvoicePdf } from '~/components';
import { useAppSelector } from '~/redux/hooks';
import { DEFAULT_PROPOSAL_FILE_NAME_PDF } from '~/utils/constants/common';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { formatAddress, getFullName } from '~/utils/helper';
import { IProposal, IProposalPdfData } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {};

const ExportProposalPdfButton = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const { control } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  const jobDetails = useAppSelector((state) => state.job.jobDetails);
  //#endregion Selector

  const lineItems = useWatch({ control, name: 'lineItems' });
  const proposalValue = useWatch({ control, name: 'value' });
  const proposalTaxRate = useWatch({ control, name: 'taxRate' });
  const proposalTerm = useWatch({ control, name: 'termsConditions' });
  const proposalDesc = useWatch({ control, name: 'description' });

  const dataPDF: IProposalPdfData = useMemo(() => {
    const clientName = getFullName({
      firstName: jobDetails?.client?.firstName,
      lastName: jobDetails?.client?.lastName,
    });
    const projectName = jobDetails?.name;
    const address = formatAddress({
      address: jobDetails?.streetAddress,
      city: jobDetails?.city,
      country: jobDetails?.country,
      state: jobDetails?.state,
      zipCode: jobDetails?.zipCode,
    });

    const taxPercent = +(proposalTaxRate ?? 0);
    const subtotal = proposalValue ?? 0;
    const tax = (+(proposalTaxRate ?? 0) / 100) * subtotal;
    const total = subtotal + tax;

    return {
      basicInfo: { clientName, projectName, address },
      lineItems: lineItems,
      price: { subtotal, tax, taxPercent, total },
      termConditions: proposalTerm ?? '',
      description: proposalDesc ?? '',
    };
  }, [jobDetails, lineItems, proposalValue, proposalTaxRate, proposalTerm]);

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    dataPDF && (
      <PDFDownloadLink
        document={<ProposalInvoicePdf dataPdf={dataPDF} />}
        fileName={DEFAULT_PROPOSAL_FILE_NAME_PDF}
      >
        <BaseButton
          label={t('template_create_proposal_button_export_pdf')}
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
          type='button'
          width={170}
        />
      </PDFDownloadLink>
    )
  );
};

export default ExportProposalPdfButton;
