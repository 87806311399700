// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import taskApi from '~/apis/task';
import { ITableParams } from '~/utils/interface/common';
import { IFormTask, IUpdateTask } from '~/utils/interface/task';
import {
  CREATE_TASK,
  GET_TASK_LIST,
  DELETE_TASK,
  GET_TASK_DETAILS,
  UPDATE_TASK,
} from '~/utils/constants/actionType';

export const getTaskList = createAsyncThunk(
  GET_TASK_LIST,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await taskApi.getTaskList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTask = createAsyncThunk(
  CREATE_TASK,
  async (payload: IFormTask, { rejectWithValue }) => {
    try {
      const res = await taskApi.createTask(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTask = createAsyncThunk(DELETE_TASK, async (id: string, { rejectWithValue }) => {
  try {
    const res = await taskApi.deleteTask(id);
    return res.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTaskDetails = createAsyncThunk(
  GET_TASK_DETAILS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await taskApi.getTaskDetails(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTask = createAsyncThunk(
  UPDATE_TASK,
  async (payload: IUpdateTask, { rejectWithValue }) => {
    try {
      const res = await taskApi.updateTask(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
