// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import statisticsApi from '~/apis/statistic';
import { GET_STATISTIC } from '~/utils/constants/actionType';
import { IStatisticChartParams } from '~/utils/interface/statistic';

export const getStatisticChart = createAsyncThunk(
  GET_STATISTIC,
  async (payload: IStatisticChartParams, { rejectWithValue }) => {
    try {
      const res = await statisticsApi.getStatisticChart(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
