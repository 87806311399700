// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
import serviceApi from '~/apis/service';
// Others
import {
  CREATE_SERVICE,
  DELETE_SERVICE,
  GET_LIST_SERVICE,
  UPDATE_SERVICE,
} from '~/utils/constants/actionType';
import { ITableParams } from '~/utils/interface/common';
import { IFormServicePayload, IUpdateService } from '~/utils/interface/service';

export const getListServices = createAsyncThunk(
  GET_LIST_SERVICE,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await serviceApi.getListServices(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteService = createAsyncThunk(
  DELETE_SERVICE,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await serviceApi.deleteService(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createService = createAsyncThunk(
  CREATE_SERVICE,
  async (body: IFormServicePayload, { rejectWithValue }) => {
    try {
      const res = await serviceApi.createService(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateService = createAsyncThunk(
  UPDATE_SERVICE,
  async (payload: IUpdateService, { rejectWithValue }) => {
    try {
      const res = await serviceApi.updateService(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
