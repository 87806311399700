import { urlApiStatistic } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { IStatisticChartParams } from '~/utils/interface/statistic';

const statisticsApi = {
  getStatisticChart: (params: IStatisticChartParams) => {
    const url = `${urlApiStatistic.getStatisticChart}`;
    return axiosClient.get(url, {
      params: params,
    });
  },
};

export default statisticsApi;
