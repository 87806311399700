// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, FormInput, Loading, ModalUnderDevelopment } from '~/components';
// Context
// Others
import { useAppDispatch } from '~/redux/hooks';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import {
  IFixtureLocation,
  IFormFixtureLocationPayload,
  IUpdateFixtureLocation,
} from '~/utils/interface/fixtureLocation';
import {
  createFixtureLocation,
  updateFixtureLocation,
} from '~/thunks/fixtureLocation/fixtureLocationThunk';
// Styles, images, icons
import styles from './FormFixtureLocationModal.module.scss';

type Props = {
  fixtureLocationDetail?: IFixtureLocation;
  onClose?: () => void;
  onSuccess?: () => void;
};

const cx = classNames.bind(styles);

const fixtureLocationSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('admin_manage_fixture_locations_name_required')),
    })
    .required();
};

const FormFixtureLocationModal = (props: Props) => {
  //#region Destructuring Props
  const { fixtureLocationDetail, onClose, onSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData: IFormFixtureLocationPayload = useMemo(() => {
    return {
      name: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm<IFormFixtureLocationPayload>({
    resolver: yupResolver(fixtureLocationSchema(t)),
    defaultValues: defaultData,
    mode: 'onChange',
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [initDataForm, setInitDataForm] = useState<IFormFixtureLocationPayload>(defaultData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!fixtureLocationDetail) return;

    const fixtureLocationDetailPrepareData: IFormFixtureLocationPayload = {
      name: fixtureLocationDetail.name || EMPTY_STRING,
    };

    setInitDataForm(fixtureLocationDetailPrepareData);
    reset(fixtureLocationDetailPrepareData);
  }, [fixtureLocationDetail]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCancelModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };

  const handleAddFixtureLocation = (data: IFormFixtureLocationPayload) => {
    setIsLoading(true);
    dispatch(createFixtureLocation(data))
      .unwrap()
      .then(() => {
        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleUpdateFixtureLocation = (data: IFormFixtureLocationPayload) => {
    if (!fixtureLocationDetail) return;

    const payload: IUpdateFixtureLocation = {
      fixtureLocationId: fixtureLocationDetail.id,
      body: data,
    };

    setIsLoading(true);
    dispatch(updateFixtureLocation(payload))
      .unwrap()
      .then((res) => {
        if (!res) return;

        onClose && onClose();
        onSuccess && onSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleShowModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(!isShowUnderDevelopment);
  };
  //#endregion Handle Function

  return (
    <div id='modalFormFixtureLocation' className={cx('container')}>
      <div className={cx('modal')}>
        {/* <div className={cx('iconCloseModal')} onClick={handleCancelModal}>
          <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
        </div> */}
        <form
          className={cx('modalContent')}
          onSubmit={handleSubmit(
            fixtureLocationDetail ? handleUpdateFixtureLocation : handleAddFixtureLocation
          )}
        >
          <div className={cx('modalHeader')}>
            {fixtureLocationDetail
              ? t('admin_manage_fixture_locations_update_fixture_location_label')
              : t('admin_manage_fixture_locations_add_fixture_location_label')}
          </div>

          <div className={cx('line')} />

          <div className={cx('modalBody')}>
            <Controller
              name='name'
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormInput
                  label={t('admin_manage_fixture_locations_table_fixture_location_name')}
                  value={value || EMPTY_STRING}
                  onChange={onChange}
                  required
                  errorMessage={errors.name?.message}
                />
              )}
            />
          </div>

          <div className={cx('modalFooter')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancelModal}
              />
            </div>
            <BaseButton
              label={fixtureLocationDetail ? t('common_btn_update') : t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
              isDisable={!isValid || !isDirty}
            />
          </div>
        </form>
      </div>

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
      )}

      {isLoading && <Loading />}
    </div>
  );
};

export default FormFixtureLocationModal;
