// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { publicRouteAbsolute } from '~/utils/constants/route';
// Styles, images, icons
import styles from './CreatePasswordSuccessful.module.scss';
import gif from '~/assets/gif';

type Props = {};

const cx = classNames.bind(styles);

const CreatePasswordSuccessful = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const navigate = useNavigate();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleConfirmOk = () => {
    navigate(publicRouteAbsolute.login);
  };
  //#endregion Handle Function

  return (
    <div id='createPasswordSuccessfulPage' className={cx('container')}>
      <div className={cx('content')}>
        <span className={cx('title')}>{t('admin_create_new_password_success_title')}</span>

        <img className={cx('gifSuccess')} src={gif.commonSuccessImageCheck} />

        <BaseButton
          borderRadius={8}
          height={56}
          typeStyle={ButtonTypeEnum.AUTH}
          isBorder={true}
          onClick={handleConfirmOk}
          label={t('admin_create_new_password_success_button_ok')}
          width={331}
        />
      </div>
    </div>
  );
};

export default CreatePasswordSuccessful;
