// Libs
import classNames from 'classnames/bind';
import { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseButton, BaseInput } from '~/components';
// Others
import { LoadingContext } from '~/context/loadingContext/LoadingContext';
import { useAppDispatch } from '~/redux/hooks';
import { handleLogin } from '~/thunks/auth/authThunk';
import { LOGIN_TYPE } from '~/utils/constants/common';
import {
  adminRouteAbsolute,
  publicRouteAbsolute,
  staffRouteAbsolute,
} from '~/utils/constants/route';
import {
  AccountRoleCodesEnum,
  ButtonTypeEnum,
  InputAuthName,
  InputTypeEnum,
  InputTypeStyleEnum,
  StorageEnum,
} from '~/utils/enum';
import { ILoginForm, ILoginPayload, ILoginResp } from '~/utils/interface/auth';
// Styles, images, icons
import styles from './Login.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Login = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loadingContext = useContext(LoadingContext);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [formLogin, setFormLogin] = useState<ILoginForm>({
    email: '',
    password: '',
  });
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const onValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormLogin({
      ...formLogin,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    loadingContext?.show();

    const payload: ILoginPayload = {
      ...formLogin,
      type: LOGIN_TYPE,
    };

    dispatch(handleLogin(payload))
      .unwrap()
      .then((res: ILoginResp) => {
        loadingContext?.hide();

        const accountInfo = res.account;
        const tokenInfo = res.token;

        localStorage.setItem(StorageEnum.ACCESS_TOKEN, tokenInfo.access);
        localStorage.setItem(StorageEnum.REFRESH_TOKEN, tokenInfo.refresh);
        localStorage.setItem(StorageEnum.ROLE, accountInfo.role);
        localStorage.setItem(StorageEnum.ACCOUNT_ID, accountInfo.id);

        switch (accountInfo.role) {
          case AccountRoleCodesEnum.ADMIN:
            navigate(`${adminRouteAbsolute.dashboard}`);
            break;

          case AccountRoleCodesEnum.STAFF:
            navigate(`${staffRouteAbsolute.dashboard}`);
            break;
        }
      })
      .catch((error) => {
        loadingContext?.hide();

        // const code = error?.response?.data?.code;
        // const message = error?.response?.data?.message;

        // if (httpResponse[code]) {
        //   handleError(t(httpResponse[code]));
        // }

        // if (!httpResponse[code] && isOnline) {
        //   handleError(message);
        // }
      });
  };

  const handleNavigateForgotPassword = () => {
    navigate(`${publicRouteAbsolute.forgotPassword}`);
  };
  //#endregion Handle Function

  return (
    <div id='signInPage' className={cx('signInContainer')}>
      <div className={cx('content')}>
        <div className={cx('greeting')}>{t('auth_login_greeting')}</div>

        <div className={cx('title')}>{t('auth_login_title')}</div>

        <div className={cx('form')}>
          <div className={cx('formItem')}>
            <BaseInput
              id={InputAuthName.EMAIL}
              name={InputAuthName.EMAIL}
              height={56}
              typeStyle={InputTypeStyleEnum.AUTH}
              placeholder={t('admin_login_email_placeholder')}
              onChange={onValueChange}
            />
          </div>
          <div className={cx('formItem')}>
            <BaseInput
              height={56}
              typeStyle={InputTypeStyleEnum.AUTH}
              placeholder={t('admin_login_password_placeholder')}
              type={InputTypeEnum.PASSWORD}
              name={InputAuthName.PASSWORD}
              id={InputAuthName.PASSWORD}
              onChange={onValueChange}
            />
          </div>
        </div>

        <div className={cx('forgotPassword')}>
          <span onClick={handleNavigateForgotPassword} className={cx('forgotPasswordText')}>
            {t('auth_login_forgot_password')}
          </span>
        </div>

        <div className={cx('action')}>
          <BaseButton
            borderRadius={8}
            height={56}
            typeStyle={ButtonTypeEnum.AUTH}
            isBorder={true}
            onClick={handleSubmit}
            label={t('admin_login_btn')}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
