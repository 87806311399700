// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
// Components, Layouts, Pages
import { Space } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBudgetLaborBreakdown } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data: IBudgetLaborBreakdown[];
};

const LaborBudget = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{'Labor'}</Text>
      <Space height={6} />

      <View style={styles.row}>
        <Text style={[styles.resourceCol, styles.fontItalic]}>{`Role/Resource`}</Text>
        <Text style={[styles.hourCol, styles.fontItalic]}>{`Hours`}</Text>
        <Text style={[styles.hourlyCostCol, styles.fontItalic]}>{`Hourly Cost`}</Text>
        <Text style={[styles.subtotalCol, styles.fontItalic]}>{`Subtotal`}</Text>
      </View>
      <Space height={6} />

      {data && data?.length > 0 && (
        <>
          {data.map((item, index) => (
            <Fragment key={index}>
              <View style={styles.row}>
                <Text style={styles.resourceCol}>{item?.laborName ?? EMPTY_STRING}</Text>
                <Text style={styles.hourCol}>{item?.hours ?? EMPTY_STRING}</Text>
                <Text style={styles.hourlyCostCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.hourlyCost ?? 0)}
                </Text>
                <Text style={styles.subtotalCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.subTotal ?? 0)}
                </Text>
              </View>
              <Space height={6} />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );
};

export default LaborBudget;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  resourceCol: {
    flexBasis: '50%',
    flexWrap: 'wrap',
  },
  hourCol: {
    flexBasis: '10%',
    textAlign: 'right',
  },
  hourlyCostCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  subtotalCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  fontItalic: {
    fontStyle: 'italic',
  },
});
