// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// Components, Layouts, Pages
// Others
import { selectToasts, toastActions } from '~/thunks/toast/toastSlice';
import { useAppDispatch } from '~/redux/hooks';
import { IToast } from '~/utils/interface/toast';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ToastContainer.module.scss';
import { icons } from '~/assets';

type ToastContainerProps = {};

const cx = classNames.bind(styles);

const ToastContainer: React.FC<ToastContainerProps> = (props: ToastContainerProps) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const toasts: IToast[] = useSelector(selectToasts);
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseToast = () => {
    dispatch(toastActions.removeToast());
  };
  //#endregion Handle Function

  return (
    <div className={cx('toastContainer')} id='toastContainerComponent'>
      <div className={cx('toast')}>
        {toasts.map((toast) => (
          <div className={cx(['toastItem', toast.type, toast.position])} key={toast.id}>
            <div className={cx('toastItemContent')}>
              <img
                className={cx('toastItemLogo')}
                src={icons.commonIconLogo}
                alt={t('common_img_text_alt')}
              />

              <div className={cx('toastItemMessage')}>
                {toast.message.title ? (
                  <div className={cx('toastItemTitle')}>{toast.message.title}</div>
                ) : (
                  EMPTY_STRING
                )}

                {toast.message.description ? (
                  <div className={cx('toastItemDescription')}>{toast.message.description}</div>
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>

            {toast.allowClose ? (
              <img
                className={cx('toastItemClose')}
                src={icons.commonIconCancelButton}
                alt={t('common_img_text_alt')}
                onClick={handleCloseToast}
              />
            ) : (
              <div className={cx('toastItemTime')}>{t('component_toast_now')}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToastContainer;
