// Libs
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
import { MoreActionItem } from '~/utils/interface/common';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './BaseMoreAction.module.scss';

type Props = {
  actions: MoreActionItem[];
};

const cx = classNames.bind(styles);

const BaseMoreAction = (props: Props) => {
  //#region Destructuring Props
  const { actions } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Popover id='baseMoreActionComponent'>
      <PopoverButton className={cx('btnGroup')}>
        <img
          src={icons.commonIconThreeDot}
          alt={t('common_img_text_alt')}
          className={cx('btnImg')}
        />
      </PopoverButton>

      <PopoverPanel
        transition
        anchor={{ to: 'bottom end', gap: '4px' }}
        className={cx('popupContainer')}
      >
        {({ close }) => (
          <div className={cx('popBtnGroup')}>
            {actions.length > DEFAULT_NUMBER_ZERO ? (
              actions.map((action, index) => (
                <button
                  key={index}
                  onClick={() => {
                    action.onClick();
                    close();
                  }}
                  className={cx('buttonOption', action.hasOtherColor ? 'otherBg' : 'defaultBg')}
                >
                  {action.icon}
                  <span
                    className={cx(
                      'labelText',
                      action.hasOtherColor ? 'otherColor' : 'defaultColor'
                    )}
                  >
                    {action.label}
                  </span>
                </button>
              ))
            ) : (
              <div className={cx('optionNoData')}>{t('common_label_no_data_available')}</div>
            )}
          </div>
        )}
      </PopoverPanel>
    </Popover>
  );
};

export default BaseMoreAction;
