// Admin
import adminIconOverview from '~/assets/icons/admin_icon_overview.svg';
import adminIconOverviewActive from '~/assets/icons/admin_icon_overview_active.svg';
import adminIconManagementEmployee from '~/assets/icons/admin_icon_management_employee.svg';
import adminIconManagementEmployeeActive from '~/assets/icons/admin_icon_management_employee_active.svg';
import adminIconManageTechnician from '~/assets/icons/admin_icon_manage_technician.svg';
import adminIconManageTechnicianActive from '~/assets/icons/admin_icon_manage_technician_active.svg';
import adminIconManageSchedule from '~/assets/icons/admin_icon_manage_schedule.svg';
import adminIconManageScheduleActive from '~/assets/icons/admin_icon_manage_schedule_active.svg';
import adminIconManageJobs from '~/assets/icons/admin_icon_manage_jobs.svg';
import adminIconManageJobsActive from '~/assets/icons/admin_icon_manage_jobs_active.svg';
import adminIconManageNotes from '~/assets/icons/admin_icon_manage_notes.svg';
import adminIconManageNotesActive from '~/assets/icons/admin_icon_manage_notes_active.svg';
import adminIconManageDocuments from '~/assets/icons/admin_icon_manage_documents.svg';
import adminIconManageDocumentsActive from '~/assets/icons/admin_icon_manage_documents_active.svg';
import adminIconEmployeeTimeClock from '~/assets/icons/admin_icon_employee_timeclock.svg';
import adminIconEmployeeTimeClockActive from '~/assets/icons/admin_icon_employee_timeclock_active.svg';
import adminIconManagePayment from '~/assets/icons/admin_icon_manage_payments.svg';
import adminIconManagePaymentActive from '~/assets/icons/admin_icon_manage_payments_active.svg';
import adminIconManageProposal from '~/assets/icons/admin_icon_manage_proposal.svg';
import adminIconCompanyActive from '~/assets/icons/admin_icon_company_active.svg';
import adminIconCompany from '~/assets/icons/admin_icon_company.svg';
import adminIconManageProposalActive from '~/assets/icons/admin_icon_manage_proposal_active.svg';
import adminIconInventoryManagement from '~/assets/icons/admin_icon_inventory_management.svg';
import adminIconInventoryManagementActive from '~/assets/icons/admin_icon_inventory_management_active.svg';
import adminIconReport from '~/assets/icons/admin_icon_report.svg';
import adminIconReportActive from '~/assets/icons/admin_icon_report_active.svg';
import adminIconManagePoCo from '~/assets/icons/admin_icon_manage_po_co.svg';
import adminIconManagePoCoActive from '~/assets/icons/admin_icon_manage_po_co_active.svg';
import adminIconManageTask from '~/assets/icons/admin_icon_manage_task.svg';
import adminIconManageTaskActive from '~/assets/icons/admin_icon_manage_task_active.svg';
import adminIconHeaderNotification from '~/assets/icons/admin_icon_header_notification.svg';
import adminIconShowPassword from '~/assets/icons/admin_icon_show_password.svg';
import adminIconHidePassword from '~/assets/icons/admin_icon_hide_password.svg';
import adminIconDropdown from '~/assets/icons/admin_icon_dropdown.svg';
import adminIconSmallPrev from '~/assets/icons/admin_icon_small_prev.svg';
import adminIconSmallNext from '~/assets/icons/admin_icon_small_next.svg';
import adminIconView from '~/assets/icons/admin_icon_view.svg';
import AdminIconClose from '~/assets/icons/admin_icon_close.svg';
import adminIconCloseViewLocation from '~/assets/icons/admin_icon_close_view_location.svg';
import adminIconProposalEstCost from '~/assets/icons/admin_icon_proposal_est_cost.svg';
import adminIconManageClients from '~/assets/icons/adminIconManageClients.svg';
import adminCreateNewPasswordIconCheckbox from '~/assets/icons/admin_create_new_password_icon_checkbox.svg';
import adminCreateNewPasswordIconCheckboxEmpty from '~/assets/icons/admin_create_new_password_icon_checkbox_empty.svg';
import adminIconCustomerName from '~/assets/icons/admin_icon_customer_name.svg';
import adminIconCustomerTag from '~/assets/icons/admin_icon_customer_tag.svg';
import adminIconLocation from '~/assets/icons/admin_icon_location.svg';
import adminIconLocationLot from '~/assets/icons/admin_icon_location_lot.svg';
import adminIconEmail from '~/assets/icons/admin_icon_email.svg';
import adminIconPhone from '~/assets/icons/admin_icon_phone.svg';
import adminIconManageVendorEditContact from '~/assets/icons/admin_icon_manage_vendor_edit_contact.svg';
import adminIconCloseContact from '~/assets/icons/admin_icon_close_contact.svg';
import adminIconEditContact from '~/assets/icons/admin_icon_edit_contact.svg';
import adminIconDeleteContact from '~/assets/icons/admin_icon_delete_contact.svg';
import adminIconTickContact from '~/assets/icons/admin_icon_tick_contact.svg';
import adminIconManageCategory from '~/assets/icons/admin_icon_manage_category.svg';
import adminIconManageCategoryActive from '~/assets/icons/admin_icon_manage_category_active.svg';
import adminIconIntegrationSwitchStatusQuickBooks from '~/assets/icons/admin_icon_integration_switch_status_quick_books.svg';
import adminIconAddWorkOrder from '~/assets/icons/admin_icon_add_work_order.svg';
import adminIconEditWorkOrder from '~/assets/icons/admin_icon_edit_work_order.svg';
import adminIconInvoiceDetailLogoPlumbing from '~/assets/icons/admin_icon_invoice_detail_logo_plumbing.svg';

// Common
import commonIconSetting from '~/assets/icons/common_icon_setting.svg';
import commonIconSettingActive from '~/assets/icons/common_icon_setting_active.svg';
import commonIconHelp from '~/assets/icons/common_icon_help.svg';
import commonIconHelpActive from '~/assets/icons/common_icon_help_active.svg';
import commonIconLogout from '~/assets/icons/common_icon_logout.svg';
import commonIconLogoutActive from '~/assets/icons/common_icon_logout_active.svg';
import commonIconDropdown from '~/assets/icons/common_icon_dropdown.svg';
import commonPaginationIconChevron from '~/assets/icons/common_pagination_icon_chevron.svg';
import commonIconFilter from '~/assets/icons/common_icon_filter.svg';
import commonIconCalender from '~/assets/icons/common_icon_calender.svg';
import commonIconExport from '~/assets/icons/common_icon_export.svg';
import commonIconSearch from '~/assets/icons/common_icon_search.svg';
import commonIconSearchAnything from '~/assets/icons/common_icon_search_anything.svg';
import commonIconChevronRight from '~/assets/icons/common_icon_chevron_right.svg';
import commonIconSingleSelect from '~/assets/icons/common_icon_single_select.svg';
import commonIconCloseModal from '~/assets/icons/common_icon_close_modal.svg';
import commonIconDatePicker from '~/assets/icons/common_icon_date_picker.svg';
import commonIconTimePicker from '~/assets/icons/common_icon_time_picker.svg';
import commonIconSearchToolBar from '~/assets/icons/commonIconSearchToolBar.svg';
import commonIconTableDetails from '~/assets/icons/commonIconTableDetails.svg';
import commonIconArrowBottom from './common_icon_arrow_bottom.svg';
import commonIconLogo from './common_icon_logo.svg';
import commonNotificationIconInfo from '~/assets/icons/common_notification_icon_info.svg';
import commonNotificationIconSuccess from '~/assets/icons/common_notification_icon_success.svg';
import commonIconPrev from '~/assets/icons/common_icon_prev.svg';
import commonIconNext from '~/assets/icons/common_icon_next.svg';
import commonIconLogoutDropdownProfile from '~/assets/icons/common_icon_logout_dropdown_profile.svg';
import commonIconLock from '~/assets/icons/common_icon_lock.svg';
import commonIconSettingDropdownProfile from '~/assets/icons/common_icon_setting_dropdown_profile.svg';
import commonIconNotificationSettings from '~/assets/icons/common_icon_notification_settings.svg';
import commonIconMessage from '~/assets/icons/common_icon_message.svg';
import commonIconCheckboxActive from '~/assets/icons/common_icon_checkbox_active.svg';
import commonIconCheckboxInActive from '~/assets/icons/common_icon_checkbox_inactive.svg';
import commonIconUpload from '~/assets/icons/common_icon_upload.svg';
import commonIconDownload from './common_icon_download.svg';
import commonIconClose from '~/assets/icons/common_icon_close.svg';
import commonIconThreeDot from '~/assets/icons/common_icon_three_dot.svg';
import commonIconTrash from '~/assets/icons/common_icon_trash.svg';
import commonIconCross from '~/assets/icons/common_icon_cross.svg';
import commonIconCheckMark from '~/assets/icons/common_icon_check_mark.svg';
import commonIconActivateStatus from '~/assets/icons/common_icon_activate_status.svg';
import commonIconEdit from '~/assets/icons/common_icon_edit.svg';
import commonIconSend from '~/assets/icons/common_icon_send.svg';
import commonIconView from '~/assets/icons/common_icon_view.svg';
import commonIconCancelButton from '~/assets/icons/common_icon_cancel_button.svg';
import commonIconEditAvatar from '~/assets/icons/common_icon_edit_avatar.svg';
import commonIconRemoveField from '~/assets/icons/common_icon_remove_field.svg';
import commonIconAddField from '~/assets/icons/common_icon_add_field.svg';
import commonIconJobEstimateCreate from '~/assets/icons/common_icon_job_estimate_create.svg';
import commonIconJobEstimateUseTemplate from '~/assets/icons/common_icon_job_estimate_use_template.svg';
import commonIconJobEstimateImportFile from '~/assets/icons/common_icon_job_estimate_import_file.svg';
import commonIconUploadFile from '~/assets/icons/common_icon_upload_file.svg';
import commonIconFile from '~/assets/icons/common_icon_file.svg';

const icons = {
  adminIconOverview,
  adminIconOverviewActive,
  adminIconManagementEmployee,
  adminIconManagementEmployeeActive,
  adminIconManageTechnician,
  adminIconManageTechnicianActive,
  adminIconManageSchedule,
  adminIconManageScheduleActive,
  adminIconManageJobs,
  adminIconManageJobsActive,
  adminIconManageNotes,
  adminIconManageNotesActive,
  adminIconManageDocuments,
  adminIconManageDocumentsActive,
  adminIconEmployeeTimeClock,
  adminIconEmployeeTimeClockActive,
  adminIconManagePayment,
  adminIconManagePaymentActive,
  adminIconManageProposal,
  adminIconManageProposalActive,
  adminIconInventoryManagement,
  adminIconInventoryManagementActive,
  adminIconReport,
  adminIconReportActive,
  adminIconManagePoCo,
  adminIconManagePoCoActive,
  adminIconManageTask,
  adminIconManageTaskActive,
  adminIconHeaderNotification,
  adminIconShowPassword,
  adminIconHidePassword,
  adminIconDropdown,
  adminIconSmallPrev,
  adminIconSmallNext,
  adminIconView,
  AdminIconClose,
  adminIconCloseViewLocation,
  adminIconProposalEstCost,
  adminIconManageClients,
  adminCreateNewPasswordIconCheckboxEmpty,
  adminCreateNewPasswordIconCheckbox,
  adminIconLocationLot,
  adminIconCustomerTag,
  adminIconCustomerName,
  adminIconLocation,
  adminIconPhone,
  adminIconEmail,
  adminIconManageVendorEditContact,
  adminIconCloseContact,
  adminIconEditContact,
  adminIconDeleteContact,
  adminIconTickContact,
  adminIconCompanyActive,
  adminIconCompany,
  adminIconManageCategory,
  adminIconManageCategoryActive,
  adminIconIntegrationSwitchStatusQuickBooks,
  adminIconAddWorkOrder,
  adminIconEditWorkOrder,
  adminIconInvoiceDetailLogoPlumbing,

  // Common
  commonIconSetting,
  commonIconSettingActive,
  commonIconHelp,
  commonIconHelpActive,
  commonIconLogout,
  commonIconLogoutActive,
  commonIconDropdown,
  commonPaginationIconChevron,
  commonIconFilter,
  commonIconCalender,
  commonIconExport,
  commonIconSearch,
  commonIconSearchAnything,
  commonIconChevronRight,
  commonIconSingleSelect,
  commonIconCloseModal,
  commonIconDatePicker,
  commonIconTimePicker,
  commonIconSearchToolBar,
  commonIconTableDetails,
  commonIconArrowBottom,
  commonIconLogo,
  commonIconDownload,
  commonNotificationIconInfo,
  commonNotificationIconSuccess,
  commonIconPrev,
  commonIconNext,
  commonIconLogoutDropdownProfile,
  commonIconLock,
  commonIconSettingDropdownProfile,
  commonIconNotificationSettings,
  commonIconMessage,
  commonIconCheckboxInActive,
  commonIconCheckboxActive,
  commonIconUpload,
  commonIconClose,
  commonIconThreeDot,
  commonIconTrash,
  commonIconCross,
  commonIconCheckMark,
  commonIconActivateStatus,
  commonIconEdit,
  commonIconView,
  commonIconSend,
  commonIconCancelButton,
  commonIconEditAvatar,
  commonIconRemoveField,
  commonIconAddField,
  commonIconJobEstimateCreate,
  commonIconJobEstimateUseTemplate,
  commonIconJobEstimateImportFile,
  commonIconUploadFile,
  commonIconFile,
};

export default icons;
