// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { MAX_VISIBLE_IMAGE, MIN_VISIBLE_IMAGE } from '~/utils/constants/common';
// Styles, images, icons
import styles from './StackedAvatars.module.scss';

type Props = {
  avatars: string[];
  maxVisibleImage?: number;
};

const cx = classNames.bind(styles);

const StackedAvatars = (props: Props) => {
  //#region Destructuring Props
  const { avatars, maxVisibleImage } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='stackedAvatarsComponent' className={cx('container')}>
      {avatars
        .slice(MIN_VISIBLE_IMAGE, maxVisibleImage ? maxVisibleImage : MAX_VISIBLE_IMAGE)
        ?.map((avatar, index) => {
          return (
            <img key={index} src={avatar} className={cx('avatar')} alt={t('common_img_text_alt')} />
          );
        })}
      {avatars.length > MAX_VISIBLE_IMAGE && (
        <div className={cx('avatarMore')}>
          <div className={cx('dot')}></div>
          <div className={cx('dot')}></div>
          <div className={cx('dot')}></div>
        </div>
      )}
    </div>
  );
};

export default StackedAvatars;
