// Libs
// Components, Layouts, Pages
// Others
import { ZINC800 } from '~/utils/constants/color';
// Styles, images, icons

type Props = {
  strokePath?: string;
  width?: number | string;
  height?: number | string;
};

const AdminIconTechnician = (props: Props) => {
  //#region Destructuring Props
  const { strokePath = ZINC800, width = 24, height = 24 } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_52_1989)'>
        <path
          d='M6.99985 9.99998H9.99985V6.99998L6.49985 3.49998C7.61948 2.96525 8.87735 2.79078 10.1003 3.0006C11.3232 3.21041 12.4509 3.79419 13.3283 4.67155C14.2056 5.5489 14.7894 6.67668 14.9992 7.89958C15.209 9.12248 15.0346 10.3803 14.4999 11.5L20.4999 17.5C20.8977 17.8978 21.1212 18.4374 21.1212 19C21.1212 19.5626 20.8977 20.1022 20.4999 20.5C20.102 20.8978 19.5625 21.1213 18.9999 21.1213C18.4372 21.1213 17.8977 20.8978 17.4999 20.5L11.4999 14.5C10.3802 15.0347 9.12235 15.2092 7.89946 14.9994C6.67656 14.7895 5.54878 14.2058 4.67143 13.3284C3.79407 12.451 3.21029 11.3233 3.00048 10.1004C2.79066 8.87748 2.96513 7.61961 3.49985 6.49998L6.99985 9.99998Z'
          stroke={strokePath}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_52_1989'>
          <rect width={width} height={height} fill={strokePath} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AdminIconTechnician;
