// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  AdminDeactivatePopup,
  AdminDetailVendorModal,
  BaseMoreAction,
  BasePagination,
  BaseTable,
  FormVendorModal,
  ModalUnderDevelopment,
  Status,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { changeStatusVendor, getListVendors } from '~/thunks/vendors/vendorsThunk';
import useDebounce from '~/utils/hooks/useDebounce';
import { BaseTableEnum } from '~/utils/enum';
import { ColumnType, IPagination, MoreActionItem } from '~/utils/interface/common';
import { IQueryListVendor, IVendor } from '~/utils/interface/vendors';
import { formatNumber, formatVendorType } from '~/utils/helper';
import { vendorsActions } from '~/thunks/vendors/vendorSlice';
import {
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  EMPTY_STRING,
  DEFAULT_NUMBER_ZERO,
} from '~/utils/constants/common';
import { PRIMARY_RED_600 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './Vendor.module.scss';
import { icons } from '~/assets';
import { CommonIconInactive } from '~/assets/svgComponents';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  onViewDetailAction: (id: string) => void,
  onEditAction: (values: IVendor) => void,
  onEditVendorContactAction: (id: string) => void,
  onAcInVendorAction: (values: IVendor) => void
): ColumnType<IVendor>[] => {
  return [
    {
      key: 'name',
      dataIndex: 'name',
      title: t('admin_manage_vendors_table_name'),
      render: (_, record: IVendor) => {
        return <div>{record?.name || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'contactName',
      dataIndex: 'contactName',
      title: t('admin_manage_vendors_table_contact_name'),
      render: (_, record: IVendor) => {
        return <div>{record?.contactName || EMPTY_STRING}</div>;
      },
    },
    {
      key: 'type',
      dataIndex: 'type',
      title: t('admin_manage_vendors_table_type'),
      render: (_, record: IVendor) => {
        return <div>{t(formatVendorType(record.type))}</div>;
      },
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('admin_manage_vendors_table_status'),
      render: (_, record: IVendor) => {
        // return <div>{record?.status || EMPTY_STRING}</div>;
        return <Status type='tag' status={record?.status} />;
      },
    },
    {
      key: 'action',
      title: t('admin_manage_vendors_table_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onViewDetailAction(record.id),
          },
          {
            label: t('admin_manage_vendors_table_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onEditAction(record),
          },
          {
            label: t('admin_manage_vendors_table_action_edit_contact'),
            icon: (
              <img
                src={icons.adminIconManageVendorEditContact}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => onEditVendorContactAction(record.id),
          },
          {
            label: t('common_btn_update_status'),
            icon: <CommonIconInactive fill={PRIMARY_RED_600} width={16} height={16} />,
            hasOtherColor: true,
            onClick: () => onAcInVendorAction(record),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
      width: '10%',
    },
  ];
};

const ManageVendors = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const { isRefreshVendorList } = useAppSelector((state: RootState) => state.vendors);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryListVendor>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [pagination, setPagination] = useState<IPagination>();
  const [vendorList, setVendorList] = useState<IVendor[]>([]);
  const [vendorId, setVendorId] = useState<string>(EMPTY_STRING);

  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [isShowAddVendorModal, setIsShowAddVendorModal] = useState<boolean>(false);
  const [isShowDetailVendorModal, setIsShoDetailVendorModal] = useState<boolean>(false);
  const [isShowFormEdit, setIsShowFormEdit] = useState<boolean>(false);

  const [vendorSelected, setVendorSelected] = useState<IVendor>();
  const [isShowConfirmChangeStatusVendor, setIsShowConfirmChangeStatusVendor] =
    useState<boolean>(false);
  const [isEditContacts, setIsEditContacts] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListVendors(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  // ** Re-fresh list when CRUD **
  useEffect(() => {
    if (!isRefreshVendorList) return;

    handleGetListVendors(paramObject);
    dispatch(vendorsActions.setRefreshVendorList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshVendorList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListVendors = (payload: IQueryListVendor) => {
    loadingData?.show();

    dispatch(getListVendors(payload))
      .unwrap()
      .then((res) => {
        const { pagination, responses } = res?.data;
        setVendorList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleOpenModalAddVendor = () => {
    setIsShowAddVendorModal(!isShowAddVendorModal);
  };

  const handleOpenModalUpdateVendor = (values: IVendor) => {
    if (!values) return;

    setVendorSelected(values);
    handleToggleModalEdit();
  };

  const handleToggleModalEdit = () => {
    setIsShowFormEdit(!isShowFormEdit);
  };

  const handleViewDetailAction = (id: string) => {
    setVendorId(id);
    setIsShoDetailVendorModal(!isShowDetailVendorModal);
  };

  const handleToggleModalDetail = () => {
    setVendorId(EMPTY_STRING);
    setIsEditContacts(false);
    setIsShoDetailVendorModal(!isShowDetailVendorModal);
  };

  const handleActionVendorSuccess = () => {
    dispatch(vendorsActions.setRefreshVendorList(true));
  };

  const handleViewClientDetails = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleEditVendorContact = (id: string) => {
    if (!id) return;

    setVendorId(id);
    setIsEditContacts(true);
    setIsShoDetailVendorModal(!isShowDetailVendorModal);
  };

  const handleAcInVendor = () => {
    if (!vendorSelected?.id) return;

    loadingData?.show();

    dispatch(changeStatusVendor(vendorSelected.id))
      .unwrap()
      .then((res) => {
        return dispatch(vendorsActions.setRefreshVendorList(true));
      })
      .catch(() => {})
      .finally(() => {
        setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
        loadingData?.hide();
      });
  };

  const handleShowConfirmChangeStatus = (values: IVendor) => {
    if (!values) return;

    setVendorSelected(values);
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryListVendor = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleSearchVendor = (value: string) => {
    setSearchKey(value);
  };

  const handleTogglePopupUpdateStatus = () => {
    setIsShowConfirmChangeStatusVendor(!isShowConfirmChangeStatusVendor);
  };
  //#endregion Handle Function

  return (
    <div id='manageVendorsComponent' className={cx('manageVendorsContainer')}>
      <Toolbar
        title={t('admin_manage_vendors_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleOpenModalAddVendor,
          label: t('admin_manage_vendors_btn_add'),
        }}
        onSearch={handleSearchVendor}
      />

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewDetailAction,
              handleOpenModalUpdateVendor,
              handleEditVendorContact,
              handleShowConfirmChangeStatus
            )}
            dataSource={vendorList || []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowAddVendorModal && (
        <FormVendorModal onClose={handleOpenModalAddVendor} onSuccess={handleActionVendorSuccess} />
      )}

      {isShowFormEdit && vendorSelected && (
        <FormVendorModal
          vendor={vendorSelected}
          onSuccess={handleActionVendorSuccess}
          onClose={handleToggleModalEdit}
        />
      )}

      {isShowDetailVendorModal && vendorId && (
        <AdminDetailVendorModal
          vendorId={vendorId}
          onClose={handleToggleModalDetail}
          isEdit={isEditContacts}
        />
      )}

      <AdminDeactivatePopup
        activeDesc={t('admin_manage_vendors_change_status_title_active')}
        deactivateDesc={t('admin_manage_vendors_change_status_title_deactivate')}
        currentStatus={vendorSelected?.status}
        isOpen={isShowConfirmChangeStatusVendor}
        onClose={handleTogglePopupUpdateStatus}
        onUpdateStatus={handleAcInVendor}
      />

      {isShowModalUnderDevelopment && <ModalUnderDevelopment onClose={handleViewClientDetails} />}
    </div>
  );
};

export default ManageVendors;
