// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
import { Loading, Status, CircleAvatar, AvatarStack } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getTaskDetails } from '~/thunks/task/taskThunk';
import { ITaskDetails } from '~/utils/interface/task';
import { CircleAvatarEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
import { formatAddress, formattedTime, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './StaffTaskDetailsModal.module.scss';
import { icons } from '~/assets';

type Props = {
  taskId: string | number;
  onClose: () => void;
  onEdit?: () => void;
  onDeleteSuccess?: () => void;
};

const cx = classNames.bind(styles);

const StaffTaskDetailsModal = (props: Props) => {
  //#region Destructuring Props
  const { taskId, onClose, onEdit, onDeleteSuccess } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [taskDetails, setTaskDetails] = useState<ITaskDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!taskId) return;

    handleGetTaskDetails(taskId.toString());
  }, [taskId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetTaskDetails = (id: string) => {
    setIsLoading(true);

    dispatch(getTaskDetails(id))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setTaskDetails(res?.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='staffTaskDetailsModalComponent' className={cx('container')}>
      <div className={cx('modal')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={handleCancel}
        />

        <div className={cx('title')}>{t('admin_task_details_title')}</div>

        <div className={cx('body')}>
          <div className={cx('info')}>
            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_task_name')}</p>

              <div className={cx('value')}>{taskDetails?.name || EMPTY_STRING}</div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_assignees')}</p>

              <div className={cx('value')}>
                {taskDetails?.assignees && taskDetails.assignees?.length > 0 ? (
                  <AvatarStack
                    avatars={taskDetails.assignees?.map((item) => ({
                      ...item,
                      avatarUrl: item?.avatar,
                    }))}
                    remaining
                  />
                ) : (
                  EMPTY_STRING
                )}
              </div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_location')}</p>

              <div className={cx('value')}>
                {formatAddress({
                  ...taskDetails,
                  address: taskDetails?.streetAddress,
                })}
              </div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_due_date')}</p>

              <div className={cx('value')}>
                {taskDetails?.dueDate ? formattedTime(taskDetails?.dueDate) : EMPTY_STRING}
              </div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_job')}</p>

              <div className={cx('value')}>{taskDetails?.job.name || EMPTY_STRING}</div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_status')}</p>

              <div className={cx('value')}>
                <Status type='tag' status={taskDetails?.status || EMPTY_STRING} />
              </div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_client_name')}</p>

              <div className={cx('value', 'group')}>
                <CircleAvatar
                  type={
                    taskDetails?.client?.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT
                  }
                  imageUrl={taskDetails?.client?.avatar}
                  firstName={taskDetails?.client?.firstName}
                  lastName={taskDetails?.client?.lastName}
                  width={24}
                  height={24}
                  fontSize={12}
                />

                <span>{getFullName({ ...taskDetails?.client })}</span>
              </div>
            </div>

            <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_completion_date')}</p>

              <div className={cx('value')}>
                {taskDetails?.completionDate
                  ? formattedTime(taskDetails?.completionDate)
                  : EMPTY_STRING}
              </div>
            </div>

            {/* <div className={cx('infoItem')}>
              <p className={cx('key')}>{t('admin_task_details_key_task_creator')}</p>

              <div className={cx('value', 'group')}>
                <CircleAvatar
                  type={
                    taskDetails?.taskCreator.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT
                  }
                  imageUrl={taskDetails?.taskCreator.avatar}
                  firstName={taskDetails?.taskCreator.firstName}
                  lastName={taskDetails?.taskCreator.lastName}
                  width={24}
                  height={24}
                  fontSize={12}
                />

                <span>{getFullName({ ...taskDetails?.taskCreator })}</span>
              </div>
            </div> */}
          </div>

          <div className={cx('comment')}>
            <p className={cx('commentLabel')}>{t('admin_task_details_key_note')}</p>

            <p className={cx('commentValue')}>{taskDetails?.note || EMPTY_STRING}</p>
          </div>
        </div>

        {/* <div className={cx('actions')}>
          <BaseButton
            label={t('common_btn_delete')}
            width={117}
            onClick={handleDeleteTask}
            typeStyle={ButtonTypeEnum.CANCEL}
          />

          <BaseButton
            label={t('common_action_edit')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            onClick={onEdit}
          />
        </div> */}

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default StaffTaskDetailsModal;
