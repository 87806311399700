// Libs
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/redux/store';
// Others
import { createInvoice, getDetailInvoice, getListInvoice } from './invoiceThunk';
import { IInvoiceResp } from '~/utils/interface/invoices';

export interface InvoiceState {
  invoiceResp: IInvoiceResp;
  isRefreshInvoiceList: boolean;
}

const initialState: InvoiceState = {
  invoiceResp: {} as IInvoiceResp,
  isRefreshInvoiceList: false,
};

const invoiceSlice = createSlice({
  name: 'invoiceState',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshInvoiceList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list invoice
    builder
      .addCase(getListInvoice.pending, (state) => {})
      .addCase(getListInvoice.fulfilled, (state, action) => {
        state.invoiceResp = action.payload.data;
      })
      .addCase(getListInvoice.rejected, (state) => {});

    // Create invoice
    builder
      .addCase(createInvoice.pending, (state) => {})
      .addCase(createInvoice.fulfilled, (state, action) => {})
      .addCase(createInvoice.rejected, (state) => {});

    // Get detail invoice
    builder
      .addCase(getDetailInvoice.pending, (state) => {})
      .addCase(getDetailInvoice.fulfilled, (state) => {})
      .addCase(getDetailInvoice.rejected, (state) => {});
  },
});

export const invoiceActions = invoiceSlice.actions;

// Get list invoice
export const selectInvoiceResp = (state: RootState) => state.invoice.invoiceResp;

const invoiceReducer = invoiceSlice.reducer;

export default invoiceReducer;
