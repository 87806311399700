// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import estimateApi from '~/apis/estimate';
import fixtureLocationApi from '~/apis/fixtureLocation';
import productApi from '~/apis/product';
import {
  CREATE_ESTIMATE,
  DELETE_ESTIMATE,
  GET_DETAIL_ESTIMATE,
  GET_DETAIL_ESTIMATE_BY_JOB,
  GET_ESTIMATE_LIST,
  GET_ESTIMATE_TEMPLATE_LIST,
  GET_LINE_ITEM_LIST,
  EST_GET_FIXTURE_LOCATION_OPTION,
  GET_PRODUCT_BY_BUDGET,
  UPDATE_ESTIMATE,
} from '~/utils/constants/actionType';
import { IQueryBase, ITableParams } from '~/utils/interface/common';
import {
  ICreateEstimatePayload,
  IEstimateContent,
  IEstimateTableParams,
  IUpdateEstimatePayload,
} from '~/utils/interface/estimate';
import { IQueryListFixtureLocations } from '~/utils/interface/fixtureLocation';
import { IQueryListProduct } from '~/utils/interface/product';

export const getEstimateList = createAsyncThunk(
  GET_ESTIMATE_LIST,
  async (payload: IEstimateTableParams, { rejectWithValue }) => {
    try {
      const res = await estimateApi.getEstimateList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEstimate = createAsyncThunk(
  CREATE_ESTIMATE,
  // async (body: ICreateEstimatePayload, { rejectWithValue }) => {
  async (body: IEstimateContent, { rejectWithValue }) => {
    try {
      const res = await estimateApi.createEstimate(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEstimate = createAsyncThunk(
  UPDATE_ESTIMATE,
  async (body: IUpdateEstimatePayload, { rejectWithValue }) => {
    try {
      const res = await estimateApi.updateEstimate(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEstimate = createAsyncThunk(
  DELETE_ESTIMATE,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await estimateApi.deleteEstimate(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailEstimate = createAsyncThunk(
  GET_DETAIL_ESTIMATE,
  async (estimateId: number, { rejectWithValue }) => {
    try {
      const res = await estimateApi.getDetailEstimate(estimateId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailEstimateByJob = createAsyncThunk(
  GET_DETAIL_ESTIMATE_BY_JOB,
  async (jobId: string, { rejectWithValue }) => {
    try {
      const res = await estimateApi.getDetailEstimateByJob(jobId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getEstimateTemplateList = createAsyncThunk(
  GET_ESTIMATE_TEMPLATE_LIST,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await estimateApi.getEstimateTemplateList(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/** Get list product by budget **/
export const getOptionProductByBudget = createAsyncThunk(
  GET_PRODUCT_BY_BUDGET,
  async (payload: IQueryListProduct, { rejectWithValue }) => {
    try {
      const res = await productApi.getListProducts(payload);
      return { data: res.data.data, type: payload.type };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionFixtureLocations = createAsyncThunk(
  EST_GET_FIXTURE_LOCATION_OPTION,
  async (payload: IQueryListFixtureLocations, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.getListFixtureLocations(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
