// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { createNewNote, getListNote, getNoteDetail } from './noteThunk';
import { INote, INoteResp } from '~/utils/interface/note';

export interface NoteState {
  noteResp: INoteResp;
  note: INote;
}

const initialState: NoteState = {
  noteResp: {} as INoteResp,
  note: {} as INote,
};

const noteSlice = createSlice({
  name: 'noteState',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // Get list note
    builder
      .addCase(getListNote.pending, (state) => {})
      .addCase(getListNote.fulfilled, (state, action) => {})
      .addCase(getListNote.rejected, (state) => {});

    // Get detail note
    builder
      .addCase(getNoteDetail.pending, (state) => {})
      .addCase(getNoteDetail.fulfilled, (state, action) => {})
      .addCase(getNoteDetail.rejected, (state) => {});

    // Create new note
    builder
      .addCase(createNewNote.pending, (state) => {})
      .addCase(createNewNote.fulfilled, (state, action) => {})
      .addCase(createNewNote.rejected, (state) => {});
  },
});

export const noteActions = noteSlice.actions;

// Get list note
export const selectNoteResp = (state: RootState) => state.note.noteResp;

// Get detail note
export const selectNote = (state: RootState) => state.note.note;

const noteReducer = noteSlice.reducer;
export default noteReducer;
