// Libs
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './GreetingUser.module.scss';

type Props = {
  username: string;
};

const cx = classNames.bind(styles);

const GreetingUser = (props: Props) => {
  //#region Destructuring Props
  const { username } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='greetingUser' className={cx('greetingUserWrap')}>
      {t('common_welcome_username', { username })}
    </div>
  );
};

export default GreetingUser;
