// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
// Components, Layouts, Pages
import { Space } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
// Styles, images, icons

type Props = {
  name?: string;
  value?: number;
};

const SubcontractorRow = (props: Props) => {
  //#region Destructuring Props
  const { name, value } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Fragment>
        <View style={styles.row}>
          <Text style={styles.itemCol}>{name ?? EMPTY_STRING}</Text>
          <Text style={styles.valueCol}>{formatCurrency(CurrencyEnum.USD, value ?? 0)}</Text>
        </View>
        <Space height={6} />
      </Fragment>
    </View>
  );
};

export default SubcontractorRow;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  itemCol: {
    flex: 1,
    flexWrap: 'wrap',
  },
  valueCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
});
