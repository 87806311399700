// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import fixtureLocationApi from '~/apis/fixtureLocation';
import {
  CREATE_FIXTURE_LOCATION,
  DELETE_FIXTURE_LOCATION,
  GET_LIST_FIXTURE_LOCATION,
  UPDATE_FIXTURE_LOCATION,
} from '~/utils/constants/actionType';
import {
  IFormFixtureLocationPayload,
  IQueryListFixtureLocations,
  IUpdateFixtureLocation,
} from '~/utils/interface/fixtureLocation';

export const getListFixtureLocations = createAsyncThunk(
  GET_LIST_FIXTURE_LOCATION,
  async (payload: IQueryListFixtureLocations, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.getListFixtureLocations(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFixtureLocation = createAsyncThunk(
  CREATE_FIXTURE_LOCATION,
  async (body: IFormFixtureLocationPayload, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.createFixtureLocation(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFixtureLocation = createAsyncThunk(
  UPDATE_FIXTURE_LOCATION,
  async (payload: IUpdateFixtureLocation, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.updateFixtureLocation(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFixtureLocation = createAsyncThunk(
  DELETE_FIXTURE_LOCATION,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await fixtureLocationApi.deleteFixtureLocation(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
