// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import termConditionsApi from '~/apis/termConditions';
import {
  CREATE_INCLUSION_EXCLUSION,
  CREATE_MAIN_CONTENT,
  DELETE_INCLUSION_EXCLUSION,
  DELETE_TERM_MAIN_CONTENT,
  GET_DETAIL_TERM_MAIN_CONTENT,
  GET_LIST_INCLUSION_EXCLUSION,
  GET_LIST_TERM_MAIN_CONTENT,
  GET_LIST_TERM_TEMPLATE,
  UPDATE_INCLUSION_EXCLUSION,
  UPDATE_TERM_MAIN_CONTENT,
} from '~/utils/constants/actionType';
import { IListQueryParams, IQueryBase } from '~/utils/interface/common';
import {
  IAddInclusionAndExclusion,
  IFormMainContent,
  IInclusionAndExclusionItem,
  IUpdateMainContent,
} from '~/utils/interface/termsAndConditions';

export const createInclusionExclusion = createAsyncThunk(
  CREATE_INCLUSION_EXCLUSION,
  async (payload: IAddInclusionAndExclusion, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.createInclusionExclusion(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListTermMainContent = createAsyncThunk(
  GET_LIST_TERM_MAIN_CONTENT,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.getListTermMainContent(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListInclusionExclusion = createAsyncThunk(
  GET_LIST_INCLUSION_EXCLUSION,
  async (payload: IListQueryParams, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.getListInclusionExclusion(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMainContent = createAsyncThunk(
  CREATE_MAIN_CONTENT,
  async (payload: IFormMainContent, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.createMainContent(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInclusionExclusion = createAsyncThunk(
  DELETE_INCLUSION_EXCLUSION,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.deleteInclusionExclusion(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTermMainContent = createAsyncThunk(
  DELETE_TERM_MAIN_CONTENT,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.deleteTermMainContent(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInclusionExclusion = createAsyncThunk(
  UPDATE_INCLUSION_EXCLUSION,
  async (item: IInclusionAndExclusionItem, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.updateInclusionExclusion(item);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMainContentDetail = createAsyncThunk(
  GET_DETAIL_TERM_MAIN_CONTENT,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.getMainContentDetail(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateMainContent = createAsyncThunk(
  UPDATE_TERM_MAIN_CONTENT,
  async (payload: IUpdateMainContent, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.updateMainContent(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListTermTemplate = createAsyncThunk(
  GET_LIST_TERM_TEMPLATE,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await termConditionsApi.getListTermTemplate(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
