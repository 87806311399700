// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
// Components, Layouts, Pages
// Others
import { CircleAvatarEnum } from '~/utils/enum';
import { NEUTRAL400 } from '~/utils/constants/color';
import { getInitialsName } from '~/utils/helper';
// Styles, images, icons
import styles from './CircleAvatar.module.scss';
import { icons } from '~/assets';

type Props = {
  type: CircleAvatarEnum;
  imageUrl?: string;
  firstName?: string;
  lastName?: string;
  width?: number | string;
  height?: number | string;
  borderRadius?: number | string;
  fontSize?: number;
  backgroundColor?: string;
  margin?: number | string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

const cx = classNames.bind(styles);

const CircleAvatar = (props: Props) => {
  //#region Destructuring Props
  const {
    type,
    imageUrl,
    firstName,
    lastName,
    width,
    height,
    fontSize,
    backgroundColor,
    margin,
    onMouseEnter,
    onMouseLeave,
    borderRadius,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isImageError, setIsImageError] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleImageError = () => {
    setIsImageError(true);
  };

  const handleImageSuccess = () => {
    setIsImageError(false);
  };

  const renderElementByType = (type: CircleAvatarEnum) => {
    switch (type) {
      case CircleAvatarEnum.IMAGE:
        return isImageError ? (
          firstName || lastName ? (
            <div
              className={cx('avatar')}
              style={{
                width: width ?? 28,
                height: height ?? 28,
                borderRadius,
                fontSize,
                margin,
                backgroundColor: backgroundColor ?? NEUTRAL400,
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {getInitialsName(firstName, lastName)}
            </div>
          ) : (
            <img
              src={icons.commonIconLogo}
              alt={t('common_img_text_alt')}
              style={{
                width: width ?? 28,
                height: height ?? 28,
              }}
            />
          )
        ) : (
          <img
            alt={t('common_img_text_alt')}
            className={cx('avatar')}
            src={imageUrl}
            style={{ width: width ?? 28, height: height ?? 28, margin, borderRadius }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onError={handleImageError}
            onLoad={handleImageSuccess}
          />
        );

      case CircleAvatarEnum.TEXT:
        return (
          <div
            className={cx('avatar')}
            style={{
              width: width ?? 28,
              height: height ?? 28,
              fontSize,
              margin,
              backgroundColor: backgroundColor ?? NEUTRAL400,
              borderRadius,
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            {getInitialsName(firstName, lastName)}
          </div>
        );
    }
  };
  //#endregion Handle Function

  return <>{renderElementByType(type)}</>;
};

export default CircleAvatar;
