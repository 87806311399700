// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useMemo } from 'react';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, FormInput, Toolbar } from '~/components';
// Others
import { IChangePasswordForm, IChangePasswordPayload } from '~/utils/interface/auth';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, InputAuthName, InputTypeEnum } from '~/utils/enum';
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { changePassword } from '~/thunks/auth/authThunk';
// Styles, images, icons
import styles from './ChangePassword.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const schema = (t: TFunction) =>
  yup
    .object()
    .shape({
      currentPassword: yup
        .string()
        .required(t('common_validate_require_current_password'))
        .min(8, t('common_error_message_password_invalid')),
      newPassword: yup
        .string()
        .required(t('common_validate_require_new_password'))
        .min(8, t('common_error_message_password_invalid')),
      passwordConfirm: yup
        .string()
        .required(t('common_validate_require_confirm_new_password'))
        .oneOf([yup.ref('newPassword')], t('common_validate_confirm_new_password_not_match')),
    })
    .required();

const ChangePassword = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const initialValues: IChangePasswordForm = useMemo(() => {
    return {
      currentPassword: EMPTY_STRING,
      newPassword: EMPTY_STRING,
      passwordConfirm: EMPTY_STRING,
    };
  }, []);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IChangePasswordForm>({
    resolver: yupResolver(schema(t)),
    defaultValues: initialValues,
  });
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleForgotPassword = async (data: IChangePasswordPayload) => {
    loadingData?.show();

    const payload: IChangePasswordPayload = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };

    dispatch(changePassword(payload))
      .unwrap()
      .then((res) => {
        loadingData?.hide();

        if (!res) return;
        reset();
      })
      .catch((error) => {
        loadingData?.hide();
      });
  };

  const handleCancel = () => {
    reset();
  };
  //#endregion Handle Function

  return (
    <div id='changePassword' className={cx('container')}>
      <Toolbar title={t('template_sidebar_label_change_password')} />

      <div className={cx('bodyContent')}>
        <form className={cx('form')} onSubmit={handleSubmit(handleForgotPassword)}>
          <div className={cx('formChangePassword')}>
            <Controller
              name={InputAuthName.CURRENT_PASSWORD}
              control={control}
              render={({ field }) => (
                <FormInput
                  label={t('common_label_current_password')}
                  type={InputTypeEnum.PASSWORD}
                  value={field.value || EMPTY_STRING}
                  onChange={field.onChange}
                  errorMessage={errors.currentPassword?.message}
                />
              )}
            />

            <Controller
              name={InputAuthName.NEW_PASSWORD}
              control={control}
              render={({ field }) => (
                <FormInput
                  label={t('common_label_new_password')}
                  type={InputTypeEnum.PASSWORD}
                  value={field.value || EMPTY_STRING}
                  onChange={field.onChange}
                  errorMessage={errors.newPassword?.message}
                />
              )}
            />

            <Controller
              name={InputAuthName.CONFIRM_PASSWORD}
              control={control}
              render={({ field }) => (
                <FormInput
                  label={t('common_label_confirm_new_password')}
                  type={InputTypeEnum.PASSWORD}
                  value={field.value || EMPTY_STRING}
                  onChange={field.onChange}
                  errorMessage={errors.passwordConfirm?.message}
                />
              )}
            />
          </div>

          <div className={cx('groupButton')}>
            <div className={cx('buttonCancel')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                typeStyle={ButtonTypeEnum.CANCEL}
                onClick={handleCancel}
              />
            </div>

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='submit'
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
