// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {};

const InvoiceFooter = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text
        style={styles.content}
      >{`Ancla Plumbing, LLC · 18942 West Reade Avenue · Litchfield Park, AZ 85340`}</Text>
    </View>
  );
};

export default InvoiceFooter;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  content: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: 400,
  },
});
