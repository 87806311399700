// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseSelect, BaseModal, BaseButton } from '~/components';
// Others
import { LoadingContext } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { getListTermTemplate } from '~/thunks/termConditions/termConditionsThunk';
import { IBaseOption, IQueryBase } from '~/utils/interface/common';
import { IFormTermTemplate, ITermTemplate } from '~/utils/interface/termsAndConditions';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_OPTIONS_SELECT,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ImportTermTemplateModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onApply?: (template: ITermTemplate) => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({ template: yup.string().required(t('term_template_modal_template_required')) })
    .required();
};

const ImportTermTemplateModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onApply } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingContext = useContext(LoadingContext);

  const defaultFormValues = useMemo(() => ({ template: EMPTY_STRING }), []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IFormTermTemplate>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultFormValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [termTemplates, setTermTemplates] = useState<ITermTemplate[]>([]);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!isOpen) return;

    const payload: IQueryBase = {
      limit: DEFAULT_NUMBER_OPTIONS_SELECT,
      page: DEFAULT_CURRENT_PAGE,
    };

    handleGetListTermTemplate(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListTermTemplate = (payload: IQueryBase) => {
    loadingContext?.show();

    dispatch(getListTermTemplate(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses } = res.data;

        setTermTemplates(responses);
      })
      .catch((error) => {})
      .finally(() => {
        loadingContext?.hide();
      });
  };

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  const handleImportTermTemplate = (data: IFormTermTemplate) => {
    if (!data) return;

    const templateSelected = termTemplates?.find(
      (template) => template.id === data.template
    ) as ITermTemplate;

    if (templateSelected) {
      onApply && onApply(templateSelected);
      handleCloseModal();
    }
  };
  //#endregion Handle Function

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <div id='importTermTemplateModal' className={cx('container')}>
        <div className={cx('header')}>{t('term_template_modal_title')}</div>

        <div className={cx('line')}></div>

        <form className={cx('form')}>
          <div className={cx('body')}>
            <Controller
              name={'template'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <BaseSelect
                  label={t('term_template_modal_available_templates')}
                  placeholder={t('common_placeholder_select')}
                  value={value}
                  options={termTemplates?.map((template) => ({
                    label: template.name,
                    value: template.id,
                  }))}
                  onChange={({ value }: IBaseOption) => {
                    onChange(value);
                  }}
                  errorMessage={errors.template?.message}
                />
              )}
            />
          </div>

          <div className={cx('actions')}>
            <BaseButton
              label={t('common_btn_cancel')}
              width={117}
              typeStyle={ButtonTypeEnum.CANCEL}
              type='button'
              onClick={handleCloseModal}
            />

            <BaseButton
              label={t('common_btn_save')}
              width={117}
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              type='button'
              onClick={handleSubmit(handleImportTermTemplate)}
            />
          </div>
        </form>
      </div>
    </BaseModal>
  );
};

export default ImportTermTemplateModal;
