import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
  isAxiosError,
} from 'axios';
import { t } from 'i18next';
import { TIME_OUT_SHOW_TOAST, urlRefreshToken } from '~/utils/constants/common';
import { baseURL } from '~/utils/constants/env';
import {
  httpResponse,
  httpStatusCode,
  skipHttpResponseArray,
} from '~/utils/constants/httpResponse';
import { MethodRequestServerEnum, StorageEnum, ToastTypeEnum } from '~/utils/enum';
import { customToast } from '~/utils/helper';
import { IRefreshTokenResponse } from '~/utils/interface/auth';
import { IBaseErrorData, BaseResponse } from '~/utils/interface/common';

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const axiosClient = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

/**
 * ==============================================================
 * Helper Functions
 * ==============================================================
 */
const isUnAuthorizedError = (error: AxiosError) => {
  return error?.response?.status === httpStatusCode.UNAUTHORIZED;
};

const forceLogout = () => {
  localStorage.clear();
  window.location.href = '/auth';
};

const getRefreshToken = async (refreshToken: string) => {
  try {
    const resp = await axiosClient.post<BaseResponse<IRefreshTokenResponse>>(urlRefreshToken, {
      refreshToken,
    });

    return resp?.data?.data;
  } catch (err) {
    return Promise.reject(err);
  }
};

/**
 * ==============================================================
 * Error Handling
 * ==============================================================
 */
const handleUnauthorizedError = async (originalRequest: AxiosRequestConfig) => {
  const currentRefreshToken = localStorage.getItem(StorageEnum.REFRESH_TOKEN);

  if (!isRefreshing) {
    isRefreshing = true;

    try {
      if (!currentRefreshToken) {
        return forceLogout();
      }

      const refreshResponse = await getRefreshToken(currentRefreshToken);
      const { access, refresh } = refreshResponse;

      localStorage.setItem(StorageEnum.ACCESS_TOKEN, access);
      localStorage.setItem(StorageEnum.REFRESH_TOKEN, refresh);

      // Ensure headers exist before assignment
      if (originalRequest.headers) {
        originalRequest.headers.Authorization = `Bearer ${access}`;
      }

      // Retry all requests in the queue with the new token
      refreshAndRetryQueue.forEach(({ config, resolve, reject }: RetryQueueItem) => {
        axiosClient
          .request(config)
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      });

      // Clear the queue
      refreshAndRetryQueue.length = 0;

      // Retry the original request
      return axiosClient(originalRequest);
    } catch (error) {
      forceLogout();
    } finally {
      isRefreshing = false;
    }
  } else {
    // Add the original request to the queue
    return new Promise<void>((resolve, reject) => {
      refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
    });
  }
};

/**
 * ==============================================================
 * Request handle functions
 * ==============================================================
 */
const onRequestFulfilled = (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem(StorageEnum.ACCESS_TOKEN);
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

const onRequestRejected = (error: AxiosError | Error) => {
  return Promise.reject(error);
};

/**
 * ==============================================================
 * Response handle functions
 * ==============================================================
 */
const onResponseFulfilled = (response: AxiosResponse) => {
  const { method } = response.config;
  const { code, message } = response.data;

  if (skipHttpResponseArray.includes(code) || method === MethodRequestServerEnum.GET) {
    return response;
  }

  const displayMessage = httpResponse[code] || message;

  setTimeout(() => {
    customToast(ToastTypeEnum.SUCCESS, t(displayMessage));
  }, TIME_OUT_SHOW_TOAST);

  return response;
};

const onResponseRejected = (error: AxiosError | Error) => {
  // Handle Exception case
  if (!isAxiosError(error)) {
    return Promise.reject(error);
  }

  const originalRequest: AxiosRequestConfig | undefined = error.config;
  if (!originalRequest) {
    return Promise.reject(error);
  }

  // Handle Unauthorize case
  if (isUnAuthorizedError(error)) {
    return handleUnauthorizedError(originalRequest);
  }

  if (error.config?.method === MethodRequestServerEnum.GET) {
    return Promise.reject(error);
  }

  // Handle display error case
  const { code, message } = error?.response?.data as IBaseErrorData;
  const displayMessage = httpResponse[code] || message;

  customToast(ToastTypeEnum.ERROR, t(displayMessage));

  return Promise.reject(error);
};

// Add a request interceptor
axiosClient.interceptors.request.use(onRequestFulfilled, onRequestRejected);

// Add a response interceptor
axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export default axiosClient;
