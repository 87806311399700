// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {};

const InvoiceScopeOfWork = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Text style={styles.value}>
      We propose to furnish all materials, equipment, and labor, subject to any exclusions listed
      below, required to complete the following:
    </Text>
  );
};

const styles = StyleSheet.create({
  value: {
    fontStyle: 'italic',
  },
});

export default InvoiceScopeOfWork;
