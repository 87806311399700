// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import {
  FormServiceModal,
  BasePagination,
  BaseTable,
  ModalUnderDevelopment,
  ThreeDotOptions,
  Toolbar,
  ConfirmModal,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { BaseTableEnum, CurrencyEnum } from '~/utils/enum';
import { IQueryListService, IService } from '~/utils/interface/service';
import { formatCurrency, formatNumber } from '~/utils/helper';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { ColumnType, IPagination, ITableParams } from '~/utils/interface/common';
import { selectIsRefreshServiceList, serviceActions } from '~/thunks/service/serviceSlice';
import { deleteService, getListServices } from '~/thunks/service/serviceThunk';
import { useAppDispatch } from '~/redux/hooks';
// Styles, images, icons
import styles from './Services.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  isSelectAll: boolean,
  handleSelectAll: () => void,
  handleSelectItem: (id: string) => void,
  handleShowEditServiceModal: (serviceDetail: IService) => void,
  handleShowConfirmDeleteModal: (id: string) => void
): ColumnType<IService>[] => {
  return [
    // {
    //   key: 'checkbox',
    //   title: (
    //     <img
    //       src={isSelectAll ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive}
    //       alt={t('common_img_text_alt')}
    //       onClick={() => handleSelectAll()}
    //     />
    //   ),
    //   render(_, record: IService) {
    //     return (
    //       <img
    //         src={
    //           record.isSelected ? icons.commonIconCheckboxActive : icons.commonIconCheckboxInActive
    //         }
    //         alt={t('common_img_text_alt')}
    //         onClick={() => handleSelectItem(record.id)}
    //       />
    //     );
    //   },
    //   width: '5%',
    // },
    {
      key: 'name',
      title: t('admin_manage_services_table_service_name'),
      dataIndex: 'name',
      render: (_, record) => <span>{record?.name || EMPTY_STRING}</span>,
    },
    {
      key: 'description',
      title: t('admin_manage_services_table_description'),
      dataIndex: 'description',
      render: (_, record) => <span>{record?.description || EMPTY_STRING}</span>,
    },
    {
      key: 'workScope',
      title: t('admin_manage_services_table_scope_of_work'),
      dataIndex: 'workScope',
      render: (_, record) => <span>{record?.workScope || EMPTY_STRING}</span>,
    },
    {
      key: 'price',
      title: t('admin_manage_services_table_price'),
      dataIndex: 'price',
      render: (_, record: IService) => (
        <div>
          {record.price ? (
            <span>{formatCurrency(CurrencyEnum.USD, record.price)}</span>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_services_table_action'),
      dataIndex: 'id',
      render: (_, record) => (
        <ThreeDotOptions
          onEdit={() => handleShowEditServiceModal(record)}
          onDelete={() => handleShowConfirmDeleteModal(record.id)}
        />
      ),
    },
  ];
};

const ManageServices = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const isRefreshServicesList: boolean = useSelector(selectIsRefreshServiceList);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryListService>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [servicesList, setServicesList] = useState<IService[]>([]);
  const [serviceId, setServiceId] = useState<string>(EMPTY_STRING);
  const [serviceDetail, setServiceDetail] = useState<IService>();
  const [isShowUnderDevelopment, setIsShowUnderDevelopment] = useState<boolean>(false);
  const [isShowFormCreate, setIsShowFormCreate] = useState<boolean>(false);
  const [isShowFormUpdate, setIsShowFormUpdate] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListServices(paramObject);
  }, [params?.page, params?.limit]);

  useEffect(() => {
    if (!isRefreshServicesList) return;
    handleGetListServices(paramObject);
    dispatch(serviceActions.setRefreshList(false));
  }, [isRefreshServicesList]);

  useEffect(() => {
    if (servicesList.length === DEFAULT_NUMBER_ZERO) return;

    const allSelected = servicesList.every((product: IService) => product.isSelected);
    setIsSelectAll(allSelected);

    const selectedIds = servicesList
      .filter((employee: IService) => employee.isSelected)
      .map((employee: IService) => employee.id);
  }, [servicesList]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListServices = (payload: ITableParams) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListServices(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setServicesList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleDeleteService = () => {
    if (!serviceId) return;

    loadingData?.show();
    dispatch(deleteService(serviceId))
      .unwrap()
      .then((res) => {
        handleActionServiceSuccess();
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleShowModalAddService = () => {
    setIsShowFormCreate(!isShowFormCreate);
  };

  const handleShowConfirmDeleteModal = (id?: string) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);

    if (id) {
      setServiceId(id);
    }
  };

  const handleShowUUpdateServiceModal = (serviceDetail: IService) => {
    setServiceDetail(serviceDetail);
    handleToggleUpdateService();
  };

  const handleToggleUpdateService = () => {
    setIsShowFormUpdate(!isShowFormUpdate);
  };

  const handleCloseModalUnderDevelopment = () => {
    setIsShowUnderDevelopment(false);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryListService = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleActionServiceSuccess = () => {
    dispatch(serviceActions.setRefreshList(true));
  };

  const handleSelectItem = (id: string) => {
    setServicesList((prevList: IService[]) =>
      prevList.map((service: IService) =>
        service.id === id ? { ...service, isSelected: !service.isSelected } : service
      )
    );
  };

  const handleSelectAll = () => {
    setServicesList((prevList) =>
      prevList.map((service: IService) => ({
        ...service,
        isSelected: !isSelectAll,
      }))
    );
  };
  //#endregion Handle Function

  return (
    <div id='manageServicePage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_services_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleShowModalAddService,
          label: t('admin_manage_services_btn_add_service'),
        }}
      />

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              isSelectAll,
              handleSelectAll,
              handleSelectItem,
              handleShowUUpdateServiceModal,
              handleShowConfirmDeleteModal
            )}
            dataSource={servicesList || []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowFormCreate && (
        <FormServiceModal
          onClose={handleShowModalAddService}
          onSuccess={handleActionServiceSuccess}
        />
      )}

      {isShowFormUpdate && serviceDetail && (
        <FormServiceModal
          serviceDetail={serviceDetail}
          onClose={handleToggleUpdateService}
          onSuccess={handleActionServiceSuccess}
        />
      )}

      {isShowConfirmDelete && serviceId && (
        <ConfirmModal
          title={t('common_modal_confirm_delete_title')}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteService}
        />
      )}

      {isShowUnderDevelopment && (
        <ModalUnderDevelopment onClose={handleCloseModalUnderDevelopment} />
      )}
    </div>
  );
};

export default ManageServices;
