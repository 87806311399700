// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo } from 'react';
import { Controller, UseFieldArrayRemove, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseSelect, FormInput } from '~/components';
// Others
import { DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_INPUT_HEIGHT } from '~/utils/constants/component';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBaseOption } from '~/utils/interface/common';
import { useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { IProposal } from '~/utils/interface/proposal';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FieldBudgetLaborProposal.module.scss';

type Props = {
  lineItemIndex: number;
  fieldIndex: number;
  removeField: UseFieldArrayRemove;
};

const cx = classNames.bind(styles);

const FieldBudgetLaborProposal = (props: Props) => {
  //#region Destructuring Props
  const { lineItemIndex, fieldIndex, removeField } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<IProposal>();
  //#endregion Declare Hook

  //#region Selector
  const { laborProposalList } = useAppSelector((state: RootState) => state.proposal);
  //#endregion Selector

  //#region Declare Form Watch
  const hours = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.labors.${fieldIndex}.hours`,
  });

  const hourlyCost = useWatch({
    control,
    name: `lineItems.${lineItemIndex}.labors.${fieldIndex}.hourlyCost`,
  });
  //#endregion Form Watch

  //#region Declare Memo
  const subTotal = useMemo(
    () => (Number(hourlyCost) ?? DEFAULT_NUMBER_ZERO) * (Number(hours) ?? DEFAULT_NUMBER_ZERO),
    [hourlyCost, hours]
  );
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setValue(`lineItems.${lineItemIndex}.labors.${fieldIndex}.subTotal`, subTotal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='fieldBudgetLaborProposalComponent' className={cx('container')}>
      <div className={cx('fieldContent')}>
        <div className='col-span-3'>
          <Controller
            name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.laborId`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <BaseSelect
                name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.laborId`}
                label={t('template_proposal_breakdown_labors_field_role')}
                placeholder={t('common_placeholder_select')}
                options={laborProposalList || []}
                value={String(value) || EMPTY_STRING}
                onChange={(data: IBaseOption) => onChange(data.value)}
              />
            )}
          />
        </div>
        <div className='col-span-2'>
          <Controller
            name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.hours`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                type='number'
                name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.hours`}
                label={t('template_proposal_breakdown_labors_field_hours')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className='col-span-2'>
          <Controller
            name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.hourlyCost`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                type='number'
                name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.hourlyCost`}
                label={t('template_proposal_breakdown_labors_field_hourly_cost')}
                value={value || EMPTY_STRING}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={'readOnlyField'}>
          <div className={cx('label')}>
            {t('template_proposal_breakdown_labors_field_subtotal')}
          </div>
          <Controller
            name={`lineItems.${lineItemIndex}.labors.${fieldIndex}.subTotal`}
            control={control}
            render={({ field: { value } }) => (
              <div className={cx('value')} style={{ height: DEFAULT_INPUT_HEIGHT }}>
                {formatCurrency(CurrencyEnum.USD, value ?? DEFAULT_NUMBER_ZERO)}
              </div>
            )}
          />
        </div>
      </div>
      <div className={cx('btnRemoveContainer')}>
        <button
          className={cx('btnRemoveField')}
          type='button'
          onClick={() => removeField(fieldIndex)}
        >
          <img src={icons.commonIconRemoveField} alt={t('common_img_text_alt')} />
        </button>
      </div>
    </div>
  );
};

export default FieldBudgetLaborProposal;
