// Libs
import classNames from 'classnames/bind';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Components, Layouts, Pages
import {
  AdminAddClientModal,
  AdminClientDetailsModal,
  AdminUpdateClientModal,
  BaseMoreAction,
  BasePagination,
  BaseTable,
  CircleAvatar,
  ConfirmModal,
  Toolbar,
} from '~/components';
// Others
import { LoadingData } from '~/context';
import { useAppDispatch } from '~/redux/hooks';
import { deleteClient, getListClient, sendToQuickBocksClient } from '~/thunks/client/clientThunk';
import { clientActions, selectIsRefreshClientList } from '~/thunks/client/clientSlice';
import { ColumnType, IPagination, ITableParams, MoreActionItem } from '~/utils/interface/common';
import { IClient, IClientDetail } from '~/utils/interface/client';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_DELAY_TIME,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BaseTableEnum, CircleAvatarEnum } from '~/utils/enum';
import { formatNumber, getAvatarWithName, getFullName } from '~/utils/helper';
import { IQueryListEmployee } from '~/utils/interface/employee';
import useDebounce from '~/utils/hooks/useDebounce';
import { PRIMARY_RED_600 } from '~/utils/constants/color';
// Styles, images, icons
import styles from './Clients.module.scss';
import { icons } from '~/assets';
import { CommonIconInactive } from '~/assets/svgComponents';

type Props = {};

const cx = classNames.bind(styles);

const columns = (
  t: TFunction,
  handleViewClientDetails: (id: string) => void,
  handleShowEditClientModal: (clientDetail?: IClientDetail) => void,
  handleShowConfirmDeleteModal: (clientDetail?: IClientDetail) => void,
  handleSendToQBClient: (clientId: string) => void
): ColumnType<IClient>[] => {
  return [
    {
      key: 'firstName',
      title: t('admin_manage_clients_table_client_name'),
      dataIndex: 'firstName',
      render: (_, record) =>
        getAvatarWithName(
          { firstName: record?.firstName, lastName: record?.lastName },
          record?.avatar
        ) ? (
          <div className={cx('clientTable')}>
            <CircleAvatar
              type={record?.avatar ? CircleAvatarEnum.IMAGE : CircleAvatarEnum.TEXT}
              imageUrl={record?.avatar}
              firstName={record?.firstName}
              lastName={record?.lastName}
              width={24}
              height={24}
              fontSize={12}
            />

            {getFullName({
              firstName: record?.firstName,
              lastName: record?.lastName,
            })}
          </div>
        ) : (
          <span>{EMPTY_STRING}</span>
        ),
    },
    {
      key: 'contactName',
      title: t('admin_manage_clients_table_main_contact'),
      dataIndex: 'contactName',
      render: (_, record) => <span>{record?.contactName || EMPTY_STRING}</span>,
    },
    {
      key: 'phoneNumber',
      title: t('admin_manage_clients_table_phone_number'),
      dataIndex: 'phoneNumber',
      render: (_, record) => <span>{record?.phoneNumber || EMPTY_STRING}</span>,
    },
    {
      key: 'email',
      title: t('admin_manage_clients_table_email'),
      dataIndex: 'email',
      width: '18%',
      render: (_, record) => <span>{record?.email || EMPTY_STRING}</span>,
    },
    {
      key: 'quickBookCustomerId',
      title: (
        <span className={cx('titleQuickBook')}>{t('admin_manage_clients_table_quick_book')}</span>
      ),
      dataIndex: 'quickBookCustomerId',
      render: (_, record) => (
        <div className={cx('quickBookWrap')}>
          {record?.quickBookCustomerId ? (
            <div className={cx('greenCircle')} />
          ) : (
            <div className={cx('grayCircle')} />
          )}
        </div>
      ),
    },
    {
      key: 'action',
      title: t('admin_manage_contract_jobs_table_action'),
      dataIndex: 'id',
      render: (_, record) => {
        const isQBLinked = record?.quickBookCustomerId;

        const actions: MoreActionItem[] = [
          {
            label: t('common_action_view'),
            icon: (
              <img
                src={icons.commonIconView}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleViewClientDetails(record.id),
          },
          {
            label: t('common_action_edit'),
            icon: (
              <img
                src={icons.commonIconEdit}
                alt={t('common_img_text_alt')}
                width={16}
                height={16}
              />
            ),
            onClick: () => handleShowEditClientModal(record),
          },
          ...(!isQBLinked
            ? [
                {
                  label: t('common_action_send_quick_bocks'),
                  icon: (
                    <img
                      src={icons.commonIconSend}
                      alt={t('common_img_text_alt')}
                      width={16}
                      height={16}
                    />
                  ),
                  onClick: () => handleSendToQBClient(record?.id),
                },
              ]
            : []),
          {
            label: t('common_btn_delete'),
            icon: <CommonIconInactive fill={PRIMARY_RED_600} width={16} height={16} />,
            hasOtherColor: true,
            onClick: () => handleShowConfirmDeleteModal(record),
          },
        ];

        return <BaseMoreAction actions={actions} />;
      },
    },
  ];
};

const ManageClients = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const loadingData = useContext(LoadingData);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  const isRefreshClientList: boolean = useSelector(selectIsRefreshClientList);
  //#endregion Selector

  //#region Declare State
  const [paramObject, setParamObject] = useState<IQueryListEmployee>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  const [idDetailClient, setIdDetailClient] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>(EMPTY_STRING);
  const debouncedSearchKey = useDebounce<string>(searchKey, DEFAULT_DELAY_TIME);
  const [clientList, setClientList] = useState<IClient[]>([]);
  const [isShowAddClient, setIsShowAddClient] = useState<boolean>(false);
  const [isShowUpdateClient, setIsShowUpdateClient] = useState<boolean>(false);
  const [isShowClientDetails, setIsShowClientDetails] = useState<boolean>(false);
  const [clientDetailData, setClientDetailData] = useState<IClientDetail>();
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false);
  const [pagination, setPagination] = useState<IPagination>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetListClient(paramObject);
    const newParam = debouncedSearchKey
      ? {
          ...params,
          page: paramObject.page.toString(),
          limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString(),
          searchKey: debouncedSearchKey,
        }
      : { page: paramObject.page.toString(), limit: DEFAULT_NUMBER_RECORD_TO_FETCH.toString() };
    setSearchParams(newParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramObject]);

  useEffect(() => {
    if (!pagination) return;
    setParamObject({
      ...paramObject,
      ...(debouncedSearchKey ? { searchKey: debouncedSearchKey } : { searchKey: undefined }),
      page: DEFAULT_CURRENT_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchKey]);

  useEffect(() => {
    if (!isRefreshClientList) return;
    handleGetListClient(paramObject);
    dispatch(clientActions.setRefreshList(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshClientList]);

  useEffect(() => {
    if (!location.state || !location.state.clientId) return;

    setIdDetailClient(location.state.clientId);
    setIsShowClientDetails(true);
  }, [location.state]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetListClient = (payload: ITableParams) => {
    if (!payload) return;

    loadingData?.show();

    dispatch(getListClient(payload))
      .unwrap()
      .then((res) => {
        if (!res?.data) return;

        const { responses, pagination } = res?.data;

        setClientList(responses);
        setPagination(pagination);
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };

  const handleShowModalAddClient = () => {
    setIsShowAddClient(true);
  };

  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryListEmployee = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };

  const handleCloseAddClient = () => {
    setIsShowAddClient(false);
  };

  const handleViewClientDetails = (id: string) => {
    setIdDetailClient(id);
    setIsShowClientDetails(true);
  };

  const handleCloseViewClientDetails = () => {
    setIsShowClientDetails(false);
  };

  const handleSearchClient = (value: string) => {
    setSearchKey(value);
  };

  const handleDeleteClientSuccess = () => {
    handleCloseViewClientDetails();
    dispatch(clientActions.setRefreshList(true));
  };
  const handleShowEditClientModal = (clientDetail?: IClientDetail) => {
    setIsShowUpdateClient(!isShowUpdateClient);
    isShowClientDetails && setIsShowClientDetails(false);
    setClientDetailData(clientDetail);
  };

  const handleUpdateClientSuccess = () => {
    isShowUpdateClient && setIsShowUpdateClient(false);
    dispatch(clientActions.setRefreshList(true));
  };

  const handleShowConfirmDeleteModal = (clientDetail?: IClientDetail) => {
    setIsShowConfirmDelete(!isShowConfirmDelete);
    setClientDetailData(clientDetail);
  };

  const handleDeleteClient = () => {
    if (!clientDetailData) return;

    loadingData?.show();
    dispatch(deleteClient(clientDetailData?.id))
      .unwrap()
      .then((res) => {
        // TODO - HuyPahmGRF-ABD: Handle logic show toast message success
        handleDeleteClientSuccess();
      })
      .catch((error) => {
        // TODO - HuyPahmGRF-ABD: Handle logic show toast message error
      })
      .finally(() => {
        loadingData?.hide();
        setIsShowConfirmDelete(false);
      });
  };

  const handleSendToQBClient = (clientId: string) => {
    if (!clientId) return;

    loadingData?.show();
    dispatch(sendToQuickBocksClient(clientId))
      .unwrap()
      .then((res) => {
        dispatch(clientActions.setRefreshList(true));
      })
      .catch((error) => {})
      .finally(() => {
        loadingData?.hide();
      });
  };
  //#endregion Handle Function

  return (
    <div id='manageClientsPage' className={cx('container')}>
      <Toolbar
        title={t('admin_manage_clients_title', {
          total: formatNumber(pagination?.totalItems ?? DEFAULT_NUMBER_ZERO),
        })}
        primaryBtn={{
          action: handleShowModalAddClient,
          label: t('admin_manage_clients_btn_add_client'),
        }}
        onSearch={handleSearchClient}
      />

      <div className={cx('body')}>
        <div className={cx('statisticTable')}>
          <BaseTable
            typeStyle={BaseTableEnum.COLOR_TABLE}
            columns={columns(
              t,
              handleViewClientDetails,
              handleShowEditClientModal,
              handleShowConfirmDeleteModal,
              handleSendToQBClient
            )}
            dataSource={clientList || []}
          />
        </div>

        <div className={cx('paginationTable')}>
          <BasePagination
            defaultCurrentPage={pageSelected}
            totalPages={pagination?.totalPages}
            totalItems={pagination?.totalItems}
            onChange={handlePaginationChange}
          />
        </div>
      </div>

      {isShowAddClient && <AdminAddClientModal onClose={handleCloseAddClient} />}

      {isShowUpdateClient && clientDetailData && (
        <AdminUpdateClientModal
          onClose={handleShowEditClientModal}
          onSuccess={handleUpdateClientSuccess}
          dataClientUpdate={clientDetailData}
        />
      )}

      {isShowClientDetails && (
        <AdminClientDetailsModal onClose={handleCloseViewClientDetails} idClient={idDetailClient} />
      )}

      {isShowConfirmDelete && clientDetailData && (
        <ConfirmModal
          title={t('common_confirm_delete_title', {
            name: getFullName({
              firstName: clientDetailData.firstName,
              lastName: clientDetailData.lastName,
            }),
          })}
          titleAction={t('common_btn_delete')}
          onCancel={handleShowConfirmDeleteModal}
          onAction={handleDeleteClient}
        />
      )}
    </div>
  );
};

export default ManageClients;
