// Libs
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useEffect, useMemo } from 'react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
// Components, Layouts, Pages
import { BaseButton, BaseModal, FormInput } from '~/components';
// Others

import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum } from '~/utils/enum';
import { ICreateEstimateName, IEstimateContent } from '~/utils/interface/estimate';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './EstTemplateNameModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (payload: IEstimateContent) => void;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({ name: yup.string().required(t('common_validate_require_name')) })
    .required();
};

const EstTemplateNameModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSave } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const defaultFormValues = useMemo(() => ({ name: EMPTY_STRING }), []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICreateEstimateName>({
    resolver: yupResolver(schema(t)),
    defaultValues: defaultFormValues,
  });

  const { setValue, getValues, control: controlFormEst } = useFormContext<IEstimateContent>();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Form Watch
  const templateName = useWatch({ name: `name`, control: controlFormEst });
  //#endregion Form Watch

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!templateName) return;
    reset({ name: templateName });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseModal = () => {
    onClose();
    reset({ name: templateName ?? '' });
  };

  const handleAddEstimate = (value: ICreateEstimateName) => {
    if (!value) return;

    setValue('name', value?.name);
    const body = getValues();
    onSave(body);
    handleCloseModal();
  };
  //#endregion Handle Function

  return (
    <BaseModal id='addEstimateModalComponent' isOpen={isOpen} onClose={handleCloseModal}>
      <div className={cx('iconCloseModal')} onClick={handleCloseModal}>
        <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
      </div>

      <div className={cx('header')}>{t('add_estimate_title')}</div>

      <div className={cx('line')}></div>

      <form className={cx('form')}>
        <div className={cx('body')}>
          <Controller
            name='name'
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormInput
                required
                label={t('add_estimate_label_name')}
                value={value}
                onChange={onChange}
                errorMessage={errors.name?.message}
              />
            )}
          />
        </div>

        <div className={cx('actions')}>
          <BaseButton
            label={t('common_btn_cancel')}
            width={117}
            typeStyle={ButtonTypeEnum.CANCEL}
            onClick={handleCloseModal}
          />

          <BaseButton
            label={t('common_btn_save')}
            width={117}
            typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
            type='button'
            onClick={handleSubmit(handleAddEstimate)}
          />
        </div>
      </form>
    </BaseModal>
  );
};

export default EstTemplateNameModal;
