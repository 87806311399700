// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { DEFAULT_NUMBER_ZERO, LIMIT_TAG_CUSTOMER, PLUS_CHARACTER } from '~/utils/constants/common';
import { dataCustomerInfo } from '~/mockData';
// Styles, images, icons
import styles from './JobCustomerInfoTab.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const JobCustomerInfoTab = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='jobCustomerInfoTab' className={cx('container')}>
      <div className={cx('contentCustomerInfo')}>
        <div className={cx('contentChildren')}>
          <h3 className={cx('title')}>{t('admin_customer_info_tab_customer_title')}</h3>
          <div className={cx('row')}>
            <img src={icons.adminIconCustomerName} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.customerName}
          </div>

          <div className={cx('row')}>
            <img src={icons.adminIconLocation} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.location}
          </div>

          <div className={cx('row')}>
            <img src={icons.adminIconLocationLot} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.lot}
          </div>

          <div className={cx('row')}>
            <img src={icons.adminIconCustomerTag} alt={t('common_img_text_alt')} />
            <div className={cx('listCustomerTag')}>
              {dataCustomerInfo.tagCustomer
                ?.slice(DEFAULT_NUMBER_ZERO, LIMIT_TAG_CUSTOMER)
                .map((tag) => (
                  <div key={tag.id} className={cx('itemCustomerTag')}>
                    {tag.content}
                  </div>
                ))}
              {dataCustomerInfo?.tagCustomer?.length > LIMIT_TAG_CUSTOMER && (
                <span className={cx('itemCustomerTag')}>
                  {`${PLUS_CHARACTER}
                  ${dataCustomerInfo?.tagCustomer?.length - LIMIT_TAG_CUSTOMER}`}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className={cx('contentChildren')}>
          <h3 className={cx('title')}>{t('admin_customer_info_tab_contacts_title')}</h3>

          <p className={cx('nameCustomer')}>{dataCustomerInfo.contactName}</p>

          <div className={cx('row')}>
            <img src={icons.adminIconPhone} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.phoneNumber}
          </div>

          <div className={cx('row')}>
            <img src={icons.adminIconEmail} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.email}
          </div>
        </div>

        <div className={cx('contentChildren')}>
          <h3 className={cx('title')}>{t('admin_customer_info_tab_bill_to_title')}</h3>

          <div className={cx('row')}>
            <img src={icons.adminIconCustomerName} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.billCustomerName}
          </div>

          <div className={cx('row')}>
            <img src={icons.adminIconLocation} alt={t('common_img_text_alt')} />{' '}
            {dataCustomerInfo.billLocation}
          </div>
        </div>
      </div>

      <div className={cx('contentChildren', 'contentPropertyPhoto')}>
        <h3 className={cx('title')}>{t('admin_customer_info_tab_property_photo_title')}</h3>

        <img
          src={dataCustomerInfo.propertyPhotoUrl}
          className={cx('propertyPhoto')}
          alt={t('common_img_text_alt')}
        />
      </div>
    </div>
  );
};

export default JobCustomerInfoTab;
