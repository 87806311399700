// Libs
import classNames from 'classnames/bind';
import { useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// Components, Layouts, Pages
import { BaseButton, FormInput, Loading, ModalUnderDevelopment, Status } from '~/components';
// Others
import { useAppDispatch } from '~/redux/hooks';
import { getVendorDetails, updateContactsVendor } from '~/thunks/vendors/vendorsThunk';
import { IDetailVendorData, IContactFullVendor, IContactVendor } from '~/utils/interface/vendors';
import { vendorsActions } from '~/thunks/vendors/vendorSlice';
import {
  DEFAULT_CURRENCY,
  DEFAULT_NUMBER_ZERO,
  EMPTY_STRING,
  REGEX,
} from '~/utils/constants/common';
import { ButtonTypeEnum, CurrencyEnum } from '~/utils/enum';
import { formatAddress, formatCurrency, formatVendorType } from '~/utils/helper';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './AdminDetailVendorModal.module.scss';

type Props = {
  isEdit?: boolean;
  vendorId: string;
  onClose?: () => void;
};

const cx = classNames.bind(styles);

const addContactSchema = (t: TFunction) => {
  return yup
    .object({
      name: yup.string().required(t('common_validate_require_name')),
      email: yup
        .string()
        .required(t('common_validate_require_email'))
        .email(t('common_validate_email_required')),
      phoneNumber: yup
        .string()
        .matches(REGEX.NUMBER, t('common_validate_phone_invalid'))
        .required(t('common_validate_require_phone')),
    })
    .required();
};

const AdminDetailVendorModal = (props: Props) => {
  //#region Destructuring Props
  const { isEdit = false, vendorId, onClose } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const defaultData = useMemo(() => {
    return {
      name: EMPTY_STRING,
      email: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<IContactVendor>({
    resolver: yupResolver(addContactSchema(t)),
    defaultValues: defaultData,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [dataDetail, setDataDetail] = useState<IDetailVendorData>();
  const [isShowModalUnderDevelopment, setIsShowModalUnderDevelopment] = useState<boolean>(false);
  const [contacts, setContacts] = useState<IContactFullVendor[]>([]);
  const [contactSelected, setContactSelected] = useState<string>(EMPTY_STRING);
  const [disableButtonSave, setDisableButtonSave] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!vendorId) return;

    handleGetVendorDetails(vendorId);
  }, [vendorId]);

  useEffect(() => {
    if (isDirty) {
      setDisableButtonSave(true);
      return;
    }
    setDisableButtonSave(false);
  }, [contacts]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetVendorDetails = (id: string) => {
    setIsLoading(true);

    dispatch(getVendorDetails(id))
      .unwrap()
      .then((res) => {
        if (!res.data) return;

        const contactData: IContactFullVendor[] = res.data.contacts.map((item, index) => ({
          id: index.toString(),
          name: item.name,
          email: item.email,
          phoneNumber: item.phoneNumber,
        }));

        setContacts(contactData);
        setDataDetail(res.data);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditContacts = () => {
    const newContacts: IContactVendor[] = contacts.map((item) => ({
      name: item.name,
      email: item.email,
      phoneNumber: item.phoneNumber,
    }));

    setIsLoading(true);

    dispatch(updateContactsVendor({ id: vendorId, body: { contacts: newContacts } }))
      .unwrap()
      .then((res) => {
        return dispatch(vendorsActions.setRefreshVendorList(true));
      })
      .catch((error) => {})
      .finally(() => {
        handleCloseModal();
        setIsLoading(false);
      });
  };

  const handleShowModalUnderDevelopment = () => {
    setIsShowModalUnderDevelopment(!isShowModalUnderDevelopment);
  };

  const handleAddContacts = (data: IContactVendor) => {
    const newContact = { id: Date.now().toString(), ...data };

    if (!isDirty) return;

    if (contactSelected) {
      const itemChecked = contacts.map((item) =>
        item.id === contactSelected ? { ...item, ...data } : item
      );
      setContacts(itemChecked);
    } else {
      setContacts([...contacts, newContact]);
    }

    handleResetForm();
  };

  const handleResetForm = () => {
    setContactSelected(EMPTY_STRING);
    reset({
      name: EMPTY_STRING,
      email: EMPTY_STRING,
      phoneNumber: EMPTY_STRING,
    });
  };

  const handleEditContactItem = (id: string) => {
    const foundItem = contacts.find((item) => item.id === id);

    if (!foundItem) return;

    setContactSelected(id);
    reset({
      name: foundItem.name,
      email: foundItem.email,
      phoneNumber: foundItem.phoneNumber,
    });
  };

  const handleDeleteContactItem = (id: string) => {
    const newContact = contacts.filter((item) => item.id !== id);

    setContacts(newContact);
    handleResetForm();
  };

  const handleCloseModal = () => {
    onClose && onClose();
    setIsLoading(false);
  };
  //#endregion Handle Function

  return (
    <div id='adminDetailVendorModal' className={cx('adminDetailVendorModal')}>
      <div className={cx('modalDetails')}>
        <img
          src={icons.commonIconCloseModal}
          className={cx('closeIcon')}
          alt={t('common_img_text_alt')}
          onClick={handleCloseModal}
        />
        <div className={cx('vendorDetailsModal')}>
          <div className={cx('modalTitleVendorDetails')}>
            {isEdit
              ? t('admin_manage_edit_vendors_contact_title')
              : t('admin_manage_vendors_detail_title')}
          </div>
          <div className={cx('contentVendorDetails')}>
            {!isEdit && (
              <div className={cx('detailVendor')}>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_vendor_name_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.name ? dataDetail.name : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_contact_name_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.contactName ? dataDetail.contactName : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_address_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {formatAddress({
                      ...dataDetail,
                    })}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_phone_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.phoneNumber ? dataDetail.phoneNumber : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_email_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.email ? dataDetail.email : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_other_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.other ? dataDetail.other : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_vendor_type_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.type ? formatVendorType(dataDetail.type) : EMPTY_STRING}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_pos_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.pos
                      ? formatCurrency(CurrencyEnum.USD, dataDetail.pos)
                      : DEFAULT_CURRENCY}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_outstanding_bills_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.outstandingBills
                      ? formatCurrency(CurrencyEnum.USD, dataDetail.outstandingBills)
                      : DEFAULT_CURRENCY}
                  </div>
                </div>
                <div className={cx('rowDetailVendor')}>
                  <div className={cx('labelVendor')}>
                    {t('admin_manage_vendors_detail_status_title')}
                  </div>
                  <div className={cx('textDetailVendor')}>
                    {dataDetail?.status ? <Status status={dataDetail?.status} /> : EMPTY_STRING}
                  </div>
                </div>
              </div>
            )}

            <div className={cx('additionalContactDetailsModal')}>
              <div className={cx('modalTitleContacts')}>
                {t('admin_manage_vendors_detail_contacts_title')}
              </div>

              {contacts && contacts.length > DEFAULT_NUMBER_ZERO ? (
                contacts.map((item, index) => (
                  <div key={item.id} className={cx('additionalContactDetailItem')}>
                    <span className={cx('titleContact')}>
                      {item.name ? item.name : EMPTY_STRING}
                    </span>

                    <span className={cx('titleContact')}>
                      {item.email ? item.email : EMPTY_STRING}
                    </span>

                    <span className={cx('titleContact')}>
                      {item.phoneNumber ? item.phoneNumber : EMPTY_STRING}
                    </span>

                    {isEdit && (
                      <div className={cx('formActions')}>
                        <button
                          className={cx('buttonActionContact')}
                          type='button'
                          onClick={() => handleEditContactItem(item.id)}
                        >
                          <img src={icons.adminIconEditContact} alt={t('common_img_text_alt')} />
                        </button>

                        <button
                          className={cx('buttonActionContact')}
                          type='button'
                          onClick={() => handleDeleteContactItem(item.id)}
                        >
                          <img src={icons.adminIconDeleteContact} alt={t('common_img_text_alt')} />
                        </button>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className={cx('textNoData')}>{isEdit ? EMPTY_STRING : t('common_empty_data')}</p>
              )}

              {isEdit && (
                <form className={cx('formAddContacts')} onSubmit={handleSubmit(handleAddContacts)}>
                  <div className={cx('formAddContactsContainer')}>
                    <Controller
                      name='name'
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_vendors_detail_form_name_title')}
                          value={field.value || EMPTY_STRING}
                          onChange={field.onChange}
                          errorMessage={errors.name?.message}
                        />
                      )}
                    />

                    <Controller
                      name='email'
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_vendors_detail_form_email_title')}
                          value={field.value || EMPTY_STRING}
                          onChange={field.onChange}
                          errorMessage={errors.email?.message}
                        />
                      )}
                    />

                    <Controller
                      name='phoneNumber'
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          label={t('admin_manage_vendors_detail_form_phone_title')}
                          value={field.value || EMPTY_STRING}
                          onChange={field.onChange}
                          errorMessage={errors.phoneNumber?.message}
                        />
                      )}
                    />
                  </div>

                  <div className={cx('formAddContactsActions')}>
                    <button className={cx('addContactsAction')} type='submit'>
                      <img src={icons.adminIconTickContact} alt={t('common_img_text_alt')} />
                    </button>

                    <button
                      className={cx('addContactsAction')}
                      type='button'
                      onClick={handleResetForm}
                    >
                      <img src={icons.adminIconCloseContact} alt={t('common_img_text_alt')} />
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>

          {isEdit && (
            <div className={cx('actions')}>
              <BaseButton
                label={t('common_btn_cancel')}
                width={117}
                onClick={onClose}
                typeStyle={ButtonTypeEnum.CANCEL}
              />

              <BaseButton
                label={t('common_btn_save')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                onClick={handleEditContacts}
                isDisable={disableButtonSave}
              />
            </div>
          )}
        </div>

        {isShowModalUnderDevelopment && (
          <ModalUnderDevelopment onClose={handleShowModalUnderDevelopment} />
        )}

        {isLoading && <Loading />}
      </div>
    </div>
  );
};

export default AdminDetailVendorModal;
