// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import timeClockApi from '~/apis/timeClock';
import { ITableParams } from '~/utils/interface/common';
import {
  CREATE_TIME_CLOCK,
  DELETE_TIME_CLOCK,
  GET_LIST_TIME_CLOCK,
  GET_TIME_CLOCK_DETAILS,
} from '~/utils/constants/actionType';
import { ICreateTimeClock } from '~/utils/interface/timeClock';

export const getListTimeClock = createAsyncThunk(
  GET_LIST_TIME_CLOCK,
  async (payload: ITableParams, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.getListTimeClock(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTimeClockDetails = createAsyncThunk(
  GET_TIME_CLOCK_DETAILS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.getTimeClockDetails(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTimeClock = createAsyncThunk(
  DELETE_TIME_CLOCK,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.deleteTimeClock(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createTimeClock = createAsyncThunk(
  CREATE_TIME_CLOCK,
  async (body: ICreateTimeClock, { rejectWithValue }) => {
    try {
      const res = await timeClockApi.createTimeClock(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
