// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Fragment } from 'react';
import { fonts } from '~/assets';
// Components, Layouts, Pages
import { Space } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import { CurrencyEnum } from '~/utils/enum';
import { formatCurrency } from '~/utils/helper';
import { IBreakDownItem } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data: IBreakDownItem[];
  type: string;
};

const ProductBudget = (props: Props) => {
  //#region Destructuring Props
  const { type, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{type}</Text>
      {/* <Divider /> */}
      <Space height={6} />

      <View style={styles.row}>
        <Text style={[styles.productCol, styles.fontItalic]}>{`Item`}</Text>
        <Text style={[styles.qtyCol, styles.fontItalic]}>{`Qty`}</Text>
        <Text style={[styles.unitCostCol, styles.fontItalic]}>{`Unit Cost`}</Text>
        <Text style={[styles.subtotalCol, styles.fontItalic]}>{`Subtotal`}</Text>
      </View>
      <Space height={6} />

      {data && data?.length > 0 && (
        <>
          {data.map((item, index) => (
            <Fragment key={index}>
              <View style={styles.row}>
                <Text style={styles.productCol}>{item?.productName ?? EMPTY_STRING}</Text>
                <Text style={styles.qtyCol}>{item?.quantity ?? EMPTY_STRING}</Text>
                <Text style={styles.unitCostCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.unitCost ?? 0)}
                </Text>
                <Text style={styles.subtotalCol}>
                  {formatCurrency(CurrencyEnum.USD, item?.subTotal ?? 0)}
                </Text>
              </View>
              <Space height={6} />
            </Fragment>
          ))}
        </>
      )}
    </View>
  );
};

export default ProductBudget;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  productCol: {
    flexBasis: '50%',
    flexWrap: 'wrap',
  },
  qtyCol: {
    flexBasis: '10%',
    textAlign: 'right',
  },
  unitCostCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  subtotalCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },

  fontItalic: {
    fontStyle: 'italic',
  },
});
