// Libs
import { StyleSheet, Text, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
import { Space, SubcontractorRow } from '~/components/specific/proposalInvoicePdf/components';
// Others
import { ISubContractorProposal } from '~/utils/interface/proposal';
// Styles, images, icons

type Props = {
  data: ISubContractorProposal;
};

const SubcontractorBudget = (props: Props) => {
  //#region Destructuring Props
  const { data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{'Subcontractors'}</Text>
      <Space height={6} />

      {data?.unassignedWorks && data.unassignedWorks?.length > 0 && (
        <View style={styles.row}>
          <View style={styles.leftSpace} />
          <View style={styles.lineWrap}>
            <Text style={styles.title}>{'Unassigned Work'}</Text>
            <Space height={6} />

            <View style={styles.tableRow}>
              <Text style={[styles.tableItemCol, styles.fontItalic]}>{`Item`}</Text>
              <Text style={[styles.tableValueCol, styles.fontItalic]}>{`Value`}</Text>
            </View>
            <Space height={6} />

            {data.unassignedWorks?.map((item, index) => (
              <SubcontractorRow key={index} name={item?.name} value={item?.value} />
            ))}
          </View>
        </View>
      )}

      <Space height={10} />

      {data?.unassignedWorks && data.unassignedWorks?.length > 0 && (
        <View style={styles.row}>
          <View style={styles.leftSpace} />
          <View style={styles.lineWrap}>
            <Text style={styles.title}>{'Assigned Subcontractors'}</Text>
            <Space height={6} />

            <View style={styles.tableRow}>
              <Text style={[styles.tableItemCol, styles.fontItalic]}>{`Item`}</Text>
              <Text style={[styles.tableValueCol, styles.fontItalic]}>{`Value`}</Text>
            </View>
            <Space height={6} />

            {data.assignedSubcontractors?.map((item, index) => (
              <SubcontractorRow key={index} name={`${item?.vendorName}`} value={item?.value} />
            ))}
          </View>
        </View>
      )}
    </View>
  );
};

export default SubcontractorBudget;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
  },
  leftSpace: {
    width: 10,
  },
  lineWrap: {
    flex: 1,
    flexDirection: 'column',
  },

  tableRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: 10,
  },
  tableItemCol: {
    flex: 1,
    flexWrap: 'wrap',
  },
  tableValueCol: {
    flexBasis: '20%',
    textAlign: 'right',
  },
  fontItalic: {
    fontStyle: 'italic',
  },
});
