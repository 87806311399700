// Libs
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import classNames from 'classnames/bind';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { DropdownChart } from '~/components';
// Others
import { configColumChart } from '~/utils/constants/configChart';
import { commonOverviewOptionsStatistics } from '~/mockData';
// Styles, images, icons
import styles from './ColumnChart.module.scss';

type Props = {
  data: ApexOptions['series'];
  categories: string[];
  height?: string | number;
  colors: string[];
  isDropdown: boolean;
  title?: string;
};

const cx = classNames.bind(styles);

const ColumnChart = (props: Props) => {
  //#region Destructuring Props
  const { data, categories, height = 350, colors, title, isDropdown } = props;
  //#endregion Destructuring Props

  const seriesData = data;

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [configChart, setConfigChart] = useState<ApexOptions | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data) {
      return;
    }

    const config = configColumChart({
      categories: categories,
      colors: colors,
    });

    setConfigChart(config);
  }, [data]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='columnChartComponent' className={cx('columnChartComponent')}>
      <section className={cx('headerSection')}>
        <div className={cx('headerSectionText')}>
          {title && <p className={cx('headTitle')}>{t(`${title}`)}</p>}
        </div>
        <div className={cx('headerSectionText')}>
          {isDropdown && (
            <DropdownChart
              listOption={commonOverviewOptionsStatistics}
              defaultSelected={commonOverviewOptionsStatistics[0]}
            />
          )}
        </div>
      </section>
      <div className={cx('contentChart')}>
        {configChart && (
          <ReactApexChart options={configChart} series={seriesData} type='bar' height={height} />
        )}
      </div>
    </div>
  );
};

export default ColumnChart;
