import * as yup from 'yup';
import { TFunction } from 'i18next';

export const estimateSchema = (t: TFunction) => {
  // return yup
  //   .object({
  //     name: yup.string().optional(),
  //     isTemplate: yup.boolean().required(),
  //     jobId: yup.number().optional(),
  //     budget: yup
  //       .object({
  //         miscellanea: yup.number().optional(),
  //         equipment: yup.number().optional(),
  //         subcontractor: yup.number().optional(),
  //         labor: yup.number().optional(),
  //         material: yup.number().optional(),
  //       })
  //       .optional(),
  //     total: yup.number().optional(),
  //     phases: yup.array().of(phaseSchema(t)).required(),
  //   })
  //   .required();

  return yup.object().shape({
    name: yup.string().optional(),
    isTemplate: yup.boolean().optional(),
    jobId: yup.number().optional(),
    budget: yup
      .object()
      .shape({
        material: yup.number().optional(),
        labor: yup.number().optional(),
        subcontractor: yup.number().optional(),
        equipment: yup.number().optional(),
        miscellanea: yup.number().optional(),
      })
      .optional(),
    total: yup.number().optional(),
    phases: yup.array().of(phaseSchema(t)).required('Required'),
  });
};

const phaseSchema = (t: TFunction) => {
  // return yup.object({
  //   name: yup.string().required(t('template_form_phase_content_validate_field_name')),
  //   percentage: yup
  //     .number()
  //     .optional()
  //     .typeError(t('template_form_phase_content_validate_field_must_be_number'))
  //     .min(0, t('template_form_phase_content_validate_field_percentage_min'))
  //     .max(100, t('template_form_phase_content_validate_field_percentage_max')),
  //   materials: yup.array().of(budgetProduct(t)).min(1, t('min')).required(t('required')),
  //   labors: yup.array().of(budgetLabors(t)).optional(),
  //   subcontractor: yup
  //     .object({
  //       unassignedWorks: yup.array().of(unassignedWork(t)).optional(),
  //       assignedSubcontractors: yup.array().of(assignedSubContractor(t)).optional(),
  //     })
  //     .optional(),
  //   equipments: yup.array().of(budgetProduct(t)).optional(),
  //   miscellanea: yup.array().of(budgetProduct(t)).optional(),
  //   budget: yup
  //     .object({
  //       miscellanea: yup.number().optional(),
  //       equipment: yup.number().optional(),
  //       subcontractor: yup.number().optional(),
  //       labor: yup.number().optional(),
  //       material: yup.number().optional(),
  //     })
  //     .optional(),
  //   total: yup.number().optional(),
  // });

  return yup.object().shape({
    name: yup.string().required('Required'),
    percentage: yup
      .number()
      .typeError(t('template_form_phase_content_validate_field_must_be_number'))
      .min(0, 'Min 0')
      .max(100, 'Max 100')
      .optional()
      .nullable(),
    budget: yup
      .object()
      .shape({
        material: yup.number().optional(),
        labor: yup.number().optional(),
        subcontractor: yup.number().optional(),
        equipment: yup.number().optional(),
        miscellanea: yup.number().optional(),
      })
      .optional(),
    total: yup.number().optional(),
    // workOrder: yup.string().optional(),
    // proposalItemId: yup.string().optional(),
    materials: yup
      .array()
      .of(
        yup.object().shape({
          productName: yup.string().optional().nullable(),
          fixtureLocationName: yup.string().optional().nullable(),
          quantity: yup.number().min(1, 'Min 1').optional(),
          subTotal: yup.number().optional(),
          unitCost: yup.number().optional(),
        })
      )
      // .min(1, 'Min 1')
      .required(),
    // .required('Required')
    // .test('name', 'Required', (materials) => {
    //   if (materials && materials.length > 0) {
    //     const firstMaterial = materials[0];
    //     return (
    //       !!firstMaterial.productName &&
    //       !!firstMaterial.fixtureLocationName &&
    //       !!firstMaterial.quantity &&
    //       !!firstMaterial.unitCost &&
    //       !!firstMaterial.subTotal
    //     );
    //   }
    //   return true;
    // }),
    labors: yup.array().of(budgetLabors(t)).optional(),
    subcontractor: yup
      .object()
      .shape({
        unassignedWorks: yup.array().of(unassignedWork(t)).optional(),
        assignedSubcontractors: yup.array().of(assignedSubContractor(t)).optional(),
      })
      .optional(),
    equipments: yup.array().of(budgetProduct(t)).optional(),
    miscellanea: yup.array().of(budgetProduct(t)).optional(),
  });
};

const budgetProduct = (t: TFunction) => {
  // return yup.object({
  //   productName: yup.string().optional(),
  //   fixtureLocationName: yup.string().optional(),
  //   quantity: yup
  //     .number()
  //     .optional()
  //     .typeError(t('template_form_phase_content_validate_category_field_qty_must_be_number'))
  //     .min(0, t('template_form_phase_content_validate_category_field_qty_min')),
  //   unitCost: yup.number().optional(),
  //   subTotal: yup.number().optional(),
  // });

  return yup.object().shape({
    productId: yup.string().optional().nullable(),
    productName: yup.string().optional().nullable(),
    fixtureLocationName: yup.string().optional().nullable(),
    fixtureLocationId: yup.string().optional().nullable(),
    quantity: yup
      .number()
      .typeError(t('template_form_phase_content_validate_category_field_qty_must_be_number'))
      .min(1, 'Min 1')
      .optional(),
    subTotal: yup.number().optional(),
    unitCost: yup.number().optional(),
  });
};

const unassignedWork = (t: TFunction) => {
  // return yup.object({
  //   name: yup.string().optional(),
  //   value: yup
  //     .number()
  //     .optional()
  //     .typeError(
  //       t('template_form_phase_content_validate_subcontractors_field_value_must_be_number')
  //     )
  //     .min(0, t('template_form_phase_content_validate_subcontractors_field_value_min')),
  // });

  return yup.object().shape({
    name: yup.string().optional().nullable(),
    value: yup.number().optional().nullable(),
  });
};

const assignedSubContractor = (t: TFunction) => {
  // return yup.object({
  //   vendorId: yup.number().optional(),
  //   value: yup
  //     .number()
  //     .optional()
  //     .typeError(
  //       t('template_form_phase_content_validate_subcontractors_field_value_must_be_number')
  //     )
  //     .min(0, t('template_form_phase_content_validate_subcontractors_field_value_min')),
  // });

  return yup.object().shape({
    vendorId: yup.string().optional().nullable(),
    vendorName: yup.string().optional().nullable(),
    value: yup.number().optional(),
  });
};

const budgetLabors = (t: TFunction) => {
  // return yup
  //   .object({
  //     id: yup.number().optional(),
  //     hours: yup
  //       .number()
  //       .optional()
  //       .typeError(t('template_form_phase_content_validate_labors_field_hours_must_be_number'))
  //       .min(0, t('template_form_phase_content_validate_labors_field_hours_min')),
  //     hourlyCost: yup
  //       .number()
  //       .optional()
  //       .typeError(
  //         t('template_form_phase_content_validate_labors_field_hourly_cost_must_be_number')
  //       )
  //       .min(0, t('template_form_phase_content_validate_labors_field_hourly_cost_min')),
  //   })
  //   .required();

  return yup.object().shape({
    laborId: yup.mixed<string | number>().optional().nullable(),
    laborName: yup.string().optional().nullable(),
    hours: yup.number().optional(),
    hourlyCost: yup.number().optional(),
    subTotal: yup.number().optional(),
  });
};
