// Libs
import classNames from 'classnames/bind';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Description,
  Field,
  Label,
} from '@headlessui/react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { IBaseOption } from '~/utils/interface/common';
import useDebounce from '~/utils/hooks/useDebounce';
import { ASTERISK_SYMBOL, DEFAULT_DEBOUNCE_SEARCH, EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './SelectSearch.module.scss';
import { icons } from '~/assets';

type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  options?: IBaseOption[];
  width?: number | string;
  height?: number | string;
  required?: boolean;
  errorMessage?: string;
  hasMore?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  onChange?: (value: string, name: string) => void;
  onSearch?: (value: string) => void;
  loadMore?: () => void;
};

const cx = classNames.bind(styles);

const SelectSearch = (props: Props) => {
  //#region Destructuring Props
  const {
    name,
    label,
    placeholder,
    value,
    options = [],
    width,
    height,
    required,
    errorMessage,
    hasMore,
    isLoading,
    disabled,
    onChange,
    onSearch,
    loadMore,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const searchBoxRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<HTMLDivElement | null>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [optionSelected, setOptionSelected] = useState<string>(EMPTY_STRING);
  const [optionListWidth, setOptionListWidth] = useState<number>(0);
  const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>(EMPTY_STRING);
  const debounceSearch = useDebounce(searchValue, DEFAULT_DEBOUNCE_SEARCH);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!value) return;

    setOptionSelected(value);
  }, [value]);

  useEffect(() => {
    if (searchBoxRef.current) {
      setOptionListWidth(searchBoxRef.current.offsetWidth);
    }
  }, [searchBoxRef.current, width]);

  useEffect(() => {
    const element = observerRef.current;

    if (!element) return;
    const scrollContainer = element?.parentNode;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore && loadMore();
        }
      },
      {
        root: scrollContainer as Element,
        threshold: 1.0,
      }
    );

    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [isOpenOptions]);

  useEffect(() => {
    if (debounceSearch && onSearch) {
      onSearch(debounceSearch);
    }
  }, [debounceSearch, onSearch]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSelectChange = (value: string) => {
    setOptionSelected(value);

    if (value) {
      onChange && onChange(value, name || EMPTY_STRING);
    }
  };

  const handleSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value.trim());
  };

  const handleToggleOptions = () => {
    setIsOpenOptions(!isOpenOptions);
  };
  //#endregion Handle Function

  return (
    <Field id='selectSearchComponent' className={cx('container')} disabled={disabled}>
      {label && (
        <Label className={cx('label')}>
          {label}
          {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <Combobox as='div' value={optionSelected} onChange={handleSelectChange}>
        <div className={cx('searchBox')} ref={searchBoxRef} style={{ width }}>
          <ComboboxInput
            displayValue={(value: string) => {
              const selectedOption = options.find((option) => option.value === value);
              return selectedOption ? selectedOption.label : EMPTY_STRING;
            }}
            onChange={handleSearchQueryChange}
            className={cx('inputSearch')}
            placeholder={placeholder}
            style={{ height }}
            disabled={!onSearch}
          />

          <ComboboxButton
            className={cx('toggleOptions')}
            style={{ height }}
            onClick={handleToggleOptions}
          >
            {({ open }) => (
              <img
                src={icons.commonIconArrowBottom}
                alt={t('common_img_text_alt')}
                className={cx('selectIcon', { active: open })}
              />
            )}
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor={{ to: 'bottom start', gap: '4px' }}
          transition
          className={cx('optionList')}
          style={{ width: optionListWidth }}
        >
          {!isLoading &&
            (options && options.length > 0 ? (
              options.map((item, index) => (
                <ComboboxOption
                  key={index}
                  value={item.value}
                  className={({ selected }) => cx('option', { active: selected })}
                >
                  {item.label}
                </ComboboxOption>
              ))
            ) : (
              <div className={cx('noData')}>{t('common_empty_data')}</div>
            ))}

          {hasMore && (
            <div ref={observerRef} className={cx('lastItem')}>
              {isLoading && (
                <div className={cx('loadingBox')}>
                  <div className={cx('loading')}></div>
                </div>
              )}
            </div>
          )}
        </ComboboxOptions>
      </Combobox>

      {errorMessage && <Description className={cx('errorMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default SelectSearch;
