// Others
import axiosClient from './axiosClient';
import { CONTENT_TYPE_FORM_DATA, urlApiJob } from '~/utils/constants/common';
import { ICreateNewContractJob, IGetJobDetailsReq, IGetListJobReq } from '~/utils/interface/job';

const jobApi = {
  getListJob(params: IGetListJobReq) {
    const url = `${urlApiJob.job}`;
    return axiosClient.get(url, {
      params,
    });
  },

  getJobDetails(id: string, params: IGetJobDetailsReq) {
    const url = `${urlApiJob.job}/${id}`;
    return axiosClient.get(url, { params });
  },

  addNewJob(formData: FormData) {
    const url = `${urlApiJob.addNewJob}`;
    return axiosClient.post(url, formData, {
      headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA },
    });
  },

  getJobAssignees(id: number) {
    const url = `${urlApiJob.getJobAssignees(id)}`;
    return axiosClient.get(url);
  },

  createNewContractJob(body: ICreateNewContractJob) {
    const url = `${urlApiJob.createNewContractJob}`;
    return axiosClient.post(url, body);
  },
};

export default jobApi;
