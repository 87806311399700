// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, StatisticTopItem } from '~/components';
// Others
import { ButtonTypeEnum } from '~/utils/enum';
import { IStatisticTop } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './StatisticTop.module.scss';

type Props = {
  dataItem?: IStatisticTop[];
  title: string;
  onViewAll?: () => void;
};

const cx = classNames.bind(styles);

const StatisticTop = (props: Props) => {
  //#region Destructuring Props
  const { dataItem, title, onViewAll } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function
  return (
    <div id='statisticTopComponent' className={cx('statisticTopComponent')}>
      <div className={cx('headerStatisticTop')}>
        <div className={cx('titleStatisticTop')}>{t(title)}</div>
        <BaseButton
          isBorder={false}
          width={74}
          minWidth={74}
          height={23}
          label={t('admin_dashboard_button_view_all')}
          typeStyle={ButtonTypeEnum.SOLID_SECONDARY}
          onClick={onViewAll}
        />
      </div>
      <div className={cx('content')}>
        {dataItem && dataItem?.length > DEFAULT_NUMBER_ZERO ? (
          <>
            {dataItem.map((data: IStatisticTop, index: number) => (
              <div key={index} className={cx('items')}>
                <StatisticTopItem data={data} />
              </div>
            ))}
          </>
        ) : (
          <div className={cx('noData')}>{t('common_empty_data')}</div>
        )}
      </div>
    </div>
  );
};

export default StatisticTop;
