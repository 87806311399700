// Libs
import classNames from 'classnames/bind';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import {
  AdminAddScheduleModal,
  FullSchedule,
  ModalUnderDevelopment,
  MySchedule,
  Toolbar,
} from '~/components';
// Others
import { capitalizeFirstLetter } from '~/components/specific/scheduleModules/helper';
import { LoadingData } from '~/context';
import { dataScheduleMock } from '~/mockData';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';
import { RootState } from '~/redux/store';
import { scheduleActions } from '~/thunks/schedule/scheduleSlice';
import { getScheduleEvents } from '~/thunks/schedule/scheduleThunk';
import {
  DEFAULT_SCHEDULE_VIEWS,
  SCHEDULE_MODE_FULL,
  SCHEDULE_MODE_MYSELF,
} from '~/utils/constants/component';
import { checkTimelineMode } from '~/utils/helper';
import { IListDataResponse } from '~/utils/interface/common';
import {
  IParamsGetSchedule,
  IScheduleEvent,
  IScheduleTimelineData,
} from '~/utils/interface/schedule';
import { TCalendarViewMode, TScheduleMode } from '~/utils/type/schedule';
import { RolesEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './Schedule.module.scss';

type Props = {
  role: RolesEnum;
};

const cx = classNames.bind(styles);

const ManageSchedule = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loadingData = useContext(LoadingData);
  //#endregion Declare Hook

  //#region Selector
  const { calendarView, scheduleMode, currentTime, refreshSchedule } = useAppSelector(
    (state: RootState) => state.scheduleState
  );
  //#endregion Selector

  //#region Declare Memo
  const timeValue = useMemo(() => {
    switch (calendarView) {
      case Views.WEEK:
        const startOfWeek = moment(currentTime).startOf('week');
        const endOfWeek = moment(currentTime).endOf('week');
        const formattedWeek = `${startOfWeek.format('MMMM DD')} - ${endOfWeek.format('DD, YYYY')}`;
        return formattedWeek;
      case Views.DAY:
        return moment(currentTime).format('dddd, MMMM YYYY');
      default:
        return moment(currentTime).format('MMMM, YYYY');
    }
  }, [calendarView, currentTime]);

  const isTimelineMode = useMemo(
    () => checkTimelineMode(calendarView, scheduleMode),
    [calendarView, scheduleMode]
  );
  //#endregion Declare Memo

  //#region Declare State
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isShowModalUnderDev, setIsShowModalUnderDev] = useState<boolean>(false);
  const [scheduleEvents, setScheduleEvents] = useState<IScheduleEvent[]>([]);
  const [timelineEvents, setTimelineEvents] = useState<IScheduleTimelineData[]>([]);
  const [actionScheduleMode] = useState<string>(
    role === RolesEnum.ADMIN ? SCHEDULE_MODE_FULL : SCHEDULE_MODE_MYSELF
  );
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (role !== RolesEnum.ADMIN) return;

    handleChangeScheduleMode(SCHEDULE_MODE_FULL);
  }, []);

  useEffect(() => {
    const startDate = moment(currentTime).startOf(calendarView).format('YYYY-MM-DD');
    const endDate = moment(currentTime).endOf(calendarView).format('YYYY-MM-DD');
    const view = calendarView.toUpperCase();
    let type =
      role !== RolesEnum.ADMIN ? scheduleMode.toUpperCase() : actionScheduleMode.toUpperCase();
    const queryParam: IParamsGetSchedule = { startDate, endDate, view, type };

    handleFetchScheduleEvents(queryParam);
  }, [
    currentTime,
    calendarView,
    refreshSchedule,
    ...(role === RolesEnum.STAFF ? [scheduleMode] : []),
  ]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleFetchScheduleEvents = (queryParam: IParamsGetSchedule) => {
    loadingData?.show();
    setScheduleEvents([]);
    setTimelineEvents([]);

    dispatch(getScheduleEvents(queryParam))
      .unwrap()
      .then((res) => {
        loadingData?.hide();
        if (isTimelineMode) {
          const { responses } = res as IListDataResponse<IScheduleTimelineData[]>;
          setTimelineEvents(responses);
          return;
        }

        const responses = res as IScheduleEvent[];
        setScheduleEvents(responses);
      })
      .catch((err) => {
        loadingData?.hide();
        // TODO: SonVuGRF-ABD - Handle error
      });
  };

  const goPreviousTime = () => {
    dispatch(scheduleActions.goPreviousTime());
  };

  const goNextTime = () => {
    dispatch(scheduleActions.goNextTime());
  };

  const goToday = () => {
    dispatch(scheduleActions.goToday());
  };

  const handleChangeCalendarView = (newValue: TCalendarViewMode): void => {
    dispatch(scheduleActions.changeCalendarViewMode(newValue));
  };

  const handleChangeScheduleMode = (newValue: TScheduleMode) => {
    dispatch(scheduleActions.changeScheduleMode(newValue));
  };

  const handleToggleModalAdd = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleAddScheduleSuccess = () => {
    dispatch(scheduleActions.setRefreshScheduleList(!refreshSchedule));
    handleToggleModalAdd();
  };

  const handleCloseUnderDev = () => {
    setIsShowModalUnderDev(false);
  };

  const handleSelectedEventFullSchedule = (event: IScheduleEvent) => {
    // HieuGRF-ABD - Handle selected event full schedule
  };
  //#endregion Handle Function
  return (
    <div id='manageSchedule' className={cx('manageScheduleContainer')}>
      <Toolbar
        title={t('admin_manage_schedule_title')}
        primaryBtn={{
          action: handleToggleModalAdd,
          label: t('admin_manage_schedule_btn_add_title'),
        }}
      />

      <div className={cx('body')}>
        <div className={cx('toolbarGroup')}>
          <div className={cx('toolbarLeft')}>
            <div className={cx('btnControl')}>
              <button className={cx('dateControlBtn', 'btnPrev')} onClick={goPreviousTime}>
                <img className={cx('dateControlIcon')} src={icons.commonIconPrev} />
              </button>
              <button className={cx('dateControlBtn', 'btnNext')} onClick={goNextTime}>
                <img className={cx('dateControlIcon')} src={icons.commonIconNext} />
              </button>
            </div>

            <button className={cx('btnToday')} onClick={goToday}>
              {t('common_component_schedule_btn_today')}
            </button>
          </div>

          <div className={cx('timeValue')}>{timeValue}</div>

          <div className={cx('toolbarRight')}>
            {role !== RolesEnum.ADMIN && (
              <div className={cx('btnGroup')}>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_MYSELF && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_MYSELF)}
                >
                  {t('common_component_schedule_btn_myself_mode')}
                </button>
                <button
                  className={cx('btnCalendar', scheduleMode === SCHEDULE_MODE_FULL && 'active')}
                  onClick={() => handleChangeScheduleMode(SCHEDULE_MODE_FULL)}
                >
                  {t('common_component_schedule_btn_full_mode')}
                </button>
              </div>
            )}

            <div className={cx('btnGroup')}>
              {Object.keys(DEFAULT_SCHEDULE_VIEWS).map((view: string, index: number) => {
                return (
                  <button
                    key={index}
                    className={cx('btnCalendar', view === calendarView && 'active')}
                    onClick={() => handleChangeCalendarView(view as TCalendarViewMode)}
                  >
                    {capitalizeFirstLetter(view)}
                  </button>
                );
              })}
            </div>
          </div>
        </div>

        <div className={cx('scheduleWrap')}>
          {scheduleMode === SCHEDULE_MODE_FULL ? (
            <FullSchedule
              view={calendarView}
              date={currentTime}
              events={isTimelineMode ? timelineEvents : scheduleEvents}
              onSelectEvent={handleSelectedEventFullSchedule}
            />
          ) : (
            <MySchedule
              view={calendarView}
              date={currentTime}
              events={dataScheduleMock as IScheduleEvent[]}
              onSelectEvent={() => console.log('Click')}
            />
          )}
        </div>
      </div>

      {isOpenModal && (
        <AdminAddScheduleModal
          isOpen={isOpenModal}
          onClose={handleToggleModalAdd}
          onSuccess={handleAddScheduleSuccess}
        />
      )}
      {isShowModalUnderDev && <ModalUnderDevelopment onClose={handleCloseUnderDev} />}
    </div>
  );
};

export default ManageSchedule;
