// Libs
import { Image, StyleSheet, View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import { images } from '~/assets';

type Props = {};

const InvoiceLogo = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={styles.container}>
      <Image style={styles.logo} src={images.commonImageLogoPlumbing} />
    </View>
  );
};

export default InvoiceLogo;

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: { width: 100, height: 100, objectFit: 'cover' },
});
