// Libs
import classNames from 'classnames/bind';
import { memo, useMemo, useState } from 'react';
import { EventProps, View, Views } from 'react-big-calendar';
import {
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
} from 'react-calendar-timeline';
// Components, Layouts, Pages
import {
  BaseSchedule,
  BaseScheduleTimeline,
  EventContainer,
  EventTimeLine,
  GroupTimeline,
  ModalUnderDevelopment,
  ScheduleAssignee,
} from '~/components';
// Others
import { BaseCalendarProps } from '~/components/common/baseSchedule/BaseSchedule';
import {
  prepareMonthEventsData,
  prepareTimelineEventsData,
} from '~/components/specific/scheduleModules/helper';
import { EMPTY_STRING } from '~/utils/constants/common';
import { DEFAULT_SCHEDULE_EVENT_COLOR } from '~/utils/constants/component';
import {
  IScheduleEvent,
  IScheduleTimelineData,
  ITimelineEvent,
  ITimelineGroup,
  ITimelinePreparedData,
} from '~/utils/interface/schedule';
// Styles, images, icons
import styles from './FullSchedule.module.scss';
import './FullSchedule.scss';

type Props = Omit<BaseCalendarProps, 'view' | 'date' | 'onSelectEvent' | 'events'> & {
  view: View;
  date: string;
  events: IScheduleEvent[] | IScheduleTimelineData[];
  onSelectEvent?: (event: IScheduleEvent) => void;
};

const cx = classNames.bind(styles);

const CollapseMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = event.jobTitle || EMPTY_STRING;

  return (
    <div
      className={cx('eventContainer', 'eventContainerCollapse')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className={cx('eventName')}>{eventName}</div>
    </div>
  );
};

const ExpandMonthView = (props: { event: IScheduleEvent }) => {
  const { event } = props;
  const eventColor = event.color || DEFAULT_SCHEDULE_EVENT_COLOR;
  const eventName = event.jobTitle || EMPTY_STRING;

  return (
    <div
      className={cx('eventContainer', 'eventContainerExpand')}
      style={{ borderWidth: 1, borderColor: eventColor, backgroundColor: eventColor }}
    >
      <div className={cx('eventName')}>{eventName}</div>
      <div className={cx('eventTime')}>{`${event?.startTime} - ${event?.endTime}`}</div>

      <div className={cx('assigneeContainer')}>
        {event.assignees && (
          <ScheduleAssignee assignees={event.assignees} eventColor={eventColor} />
        )}
      </div>
    </div>
  );
};

const FullSchedule = (props: Props) => {
  //#region Destructuring Props
  const { ref, view, date, events, onSelectEvent, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const preparedMonthEvents = useMemo(() => {
    if (view !== Views.MONTH) return;

    return prepareMonthEventsData(props.events as IScheduleEvent[]);
  }, [view, events]);

  const preparedTimelineEvents: ITimelinePreparedData | undefined = useMemo(() => {
    if (view !== Views.WEEK && view !== Views.DAY) return;
    return prepareTimelineEventsData(props.events as IScheduleTimelineData[], view);
  }, [view, events]);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isShowModalUnderDev, setIsShowModalUnderDev] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleCloseUnderDev = () => {
    setIsShowModalUnderDev(false);
  };
  //#endregion Handle Function

  return (
    <div id='fullScheduleComponent' className={cx('container')}>
      {preparedMonthEvents && view === Views.MONTH && (
        <div className={cx({ 'h-[780px]': view === Views.MONTH })}>
          {
            <BaseSchedule
              {...restProps}
              events={preparedMonthEvents}
              selectable={true}
              toolbar={false}
              date={date}
              view={view}
              components={{
                event: ({ event }: EventProps) => (
                  <>
                    {view === Views.MONTH && (
                      <div className={cx('monthEventWrap')}>
                        {event?.resource?.map((eventData: IScheduleEvent, index: number) => (
                          <EventContainer
                            key={index}
                            data={eventData}
                            onClick={() => onSelectEvent && onSelectEvent(eventData)}
                          >
                            {event.resource.length > 1 ? (
                              <CollapseMonthView event={eventData} />
                            ) : (
                              <ExpandMonthView event={eventData} />
                            )}
                          </EventContainer>
                        ))}
                      </div>
                    )}
                  </>
                ),
              }}
            />
          }
        </div>
      )}

      {preparedTimelineEvents && (view === Views.WEEK || view === Views.DAY) && (
        <BaseScheduleTimeline
          date={date}
          view={view}
          items={preparedTimelineEvents.event}
          groups={preparedTimelineEvents.group}
          itemRenderer={(props: ReactCalendarItemRendererProps<ITimelineEvent>) => (
            <EventTimeLine {...props} eventData={props.item} viewType={view} />
          )}
          groupRenderer={(props: ReactCalendarGroupRendererProps<ITimelineGroup>) => (
            <GroupTimeline {...props} groupData={props?.group} />
          )}
        />
      )}

      {isShowModalUnderDev && <ModalUnderDevelopment onClose={handleCloseUnderDev} />}
    </div>
  );
};

export default memo(FullSchedule);
