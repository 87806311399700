// Libs
import classNames from 'classnames/bind';
import { CustomHeader, Interval } from 'react-calendar-timeline';
// Components, Layouts, Pages
// Others
import { DEFAULT_SCHEDULE_TIMELINE_VIEWS } from '~/utils/constants/component';
import { TCalendarViewMode } from '~/utils/type/schedule';
// Styles, images, icons
import styles from './HeaderTimeline.module.scss';

const cx = classNames.bind(styles);

type Props = {
  height?: number;
  view: TCalendarViewMode;
};

const HeaderTimeline = (props: Props) => {
  const { height = 50, view } = props;

  return (
    <CustomHeader height={height}>
      {(props) => {
        if (!props) return null;
        const {
          headerContext: { intervals },
          getRootProps,
          getIntervalProps,
        } = props;

        return (
          <div {...getRootProps()}>
            {intervals.map((interval: Interval, index: number) => (
              <div
                {...getIntervalProps({ interval, style: { height } })}
                className={cx('dateBlock')}
                key={index}
              >
                {view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.week && (
                  <>
                    <div className={cx('dateText')}>{interval.startTime.format('DD')}</div>
                    <div className={cx('dateText', 'dateOfWeek')}>
                      {interval.startTime.format('dddd')}
                    </div>
                  </>
                )}
                {view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.day && (
                  <div className={cx('dateText')}>{interval.startTime.format('h A')}</div>
                )}
              </div>
            ))}
          </div>
        );
      }}
    </CustomHeader>
  );
};

export default HeaderTimeline;
