// Libs
import classNames from 'classnames/bind';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TFunction } from 'i18next';
// Components, Layouts, Pages
import { BaseButton, BaseModal, FormTextarea } from '~/components';
// Others
import { IWorkOrder } from '~/utils/interface/estimate';
import { EMPTY_STRING } from '~/utils/constants/common';
import { ButtonTypeEnum, FormModalWorkOrderEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './FormModalWorkOrder.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (item: IWorkOrder) => void;
  phaseName: string;
  itemDataWorkOrder: IWorkOrder;
};

const cx = classNames.bind(styles);

const schema = (t: TFunction) => {
  return yup
    .object()
    .shape({
      description: yup.string().transform((value) => value.trim()),
    })
    .required();
};

const FormModalWorkOder = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, onSave, phaseName, itemDataWorkOrder } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  useEffect(() => {
    if (!isOpen) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const { t } = useTranslation();

  const initialValues: IWorkOrder = useMemo(() => {
    return {
      description: EMPTY_STRING,
    };
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IWorkOrder>({
    resolver: yupResolver(schema(t)),
    defaultValues: initialValues,
  });
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!itemDataWorkOrder) return;

    reset(itemDataWorkOrder);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemDataWorkOrder]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleSave = (item: IWorkOrder) => {
    onSave && onSave(item);
  };

  const handleDiscardWorkOrder = () => {
    onClose && onClose();
    reset(itemDataWorkOrder);
  };
  //#endregion Handle Function

  return (
    <BaseModal id='formWorkOrder' isOpen={isOpen} onClose={handleDiscardWorkOrder}>
      <div id='modalWorkOrder' className={cx('modalContent')}>
        <div className={cx('modalHeader')}>
          {phaseName ? t('form_work_order_title', { phaseName }) : t('work_order_title')}
        </div>

        <div className={cx('line')} />

        <div className={cx('form')}>
          <div className={cx('body')}>
            <Controller
              name={FormModalWorkOrderEnum.DESCRIPTION}
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormTextarea
                  height={200}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  messageError={errors.description?.message}
                />
              )}
            />
          </div>

          <div className={cx('actions')}>
            <BaseButton
              label={t('form_work_order_btn_discard_changes')}
              width={125}
              typeStyle={ButtonTypeEnum.CANCEL}
              type='button'
              onClick={handleDiscardWorkOrder}
            />

            <BaseButton
              label={t('form_work_order_btn_save_changes')}
              width={120}
              type='submit'
              typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
              onClick={handleSubmit(handleSave)}
            />
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default FormModalWorkOder;
