// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import quickBookApi from '~/apis/quickBook';
import {
  DISCONNECT_QUICK_BOOK,
  GET_AUTH_URL,
  GET_LIST_QUICK_BOOK_CUSTOMER,
  GET_LIST_QUICK_BOOK_ITEM,
  GET_TOKEN_QUICK_BOOK,
} from '~/utils/constants/actionType';
import { IQueryBase } from '~/utils/interface/common';
import { IQueryGetTokenQuickBook } from '~/utils/interface/quickBook';

export const getAuthUrl = createAsyncThunk(GET_AUTH_URL, async () => {
  try {
    const res = await quickBookApi.getAuthUrl();
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getToken = createAsyncThunk(
  GET_TOKEN_QUICK_BOOK,
  async (payload: IQueryGetTokenQuickBook) => {
    try {
      const res = await quickBookApi.getTokenQuickBook(payload);
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);

export const disconnect = createAsyncThunk(DISCONNECT_QUICK_BOOK, async () => {
  try {
    const res = await quickBookApi.disconnect();
    return res.data;
  } catch (error) {
    throw error;
  }
});

export const getListQuickBookCustomer = createAsyncThunk(
  GET_LIST_QUICK_BOOK_CUSTOMER,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookCustomer(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListQuickBookItem = createAsyncThunk(
  GET_LIST_QUICK_BOOK_ITEM,
  async (payload: IQueryBase, { rejectWithValue }) => {
    try {
      const res = await quickBookApi.getListQuickBookItems(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
