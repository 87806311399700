// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { createProduct, deleteProduct, getListProducts, updateProduct } from './productThunk';
import { RootState } from '~/redux/store';

export interface ProductState {
  isRefreshProductList: boolean;
}

const initialState: ProductState = {
  isRefreshProductList: false,
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setRefreshList(state, action) {
      state.isRefreshProductList = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list product
    builder
      .addCase(getListProducts.pending, (state) => {})
      .addCase(getListProducts.fulfilled, (state) => {})
      .addCase(getListProducts.rejected, (state) => {});

    // Create product
    builder
      .addCase(createProduct.pending, (state) => {})
      .addCase(createProduct.fulfilled, (state) => {})
      .addCase(createProduct.rejected, (state) => {});

    // Update product
    builder
      .addCase(updateProduct.pending, (state) => {})
      .addCase(updateProduct.fulfilled, (state) => {})
      .addCase(updateProduct.rejected, (state) => {});

    // Delete product
    builder
      .addCase(deleteProduct.pending, (state) => {})
      .addCase(deleteProduct.fulfilled, (state) => {})
      .addCase(deleteProduct.rejected, (state) => {});
  },
});

// Action
export const productActions = productSlice.actions;

// Selectors
export const selectIsRefreshProductList = (state: RootState) => state.product.isRefreshProductList;

// Reducer
const productReducer = productSlice.reducer;
export default productReducer;
