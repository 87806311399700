// Libs
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { EMPTY_STRING } from '~/utils/constants/common';
import {
  DEFAULT_FORM_LIST_INITIAL_VALUE,
  DEFAULT_FORM_LIST_NUMBER_REMAINING_FIELD,
} from '~/utils/constants/component';
// Styles, images, icons
import styles from './FormList.module.scss';

export interface FormListParamsValueChange<F> {
  index: number;
  name: keyof F;
  value: any;
}

interface ChildrenActions<F> {
  add: () => void;
  remove: (index: number) => void;
  onValueChange: (data: FormListParamsValueChange<F>) => void;
}

type Props<F> = {
  title?: string;
  name?: string;
  initialValue: F[];
  onFormChange?: (fields: F[]) => void;
  children: (fields: F[], actions: ChildrenActions<F>) => React.ReactNode;
};

const cx = classNames.bind(styles);

const FormList = <F extends object>(props: Props<F>) => {
  //#region Destructuring Props
  const { title, initialValue = DEFAULT_FORM_LIST_INITIAL_VALUE, children, onFormChange } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [fields, setFields] = useState<F[]>(initialValue);
  const [cachedFieldTemplate, setCachedFieldTemplate] = useState<F>();
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!initialValue) return;
    setFields(initialValue);
  }, [initialValue]);
  //#endregion Implement Hook

  //#region Handle Function
  const onValueChange = (data: FormListParamsValueChange<F>) => {
    const { index, name, value } = data;

    const updatedFields = [...fields];
    updatedFields[index] = { ...updatedFields[index], [name]: value };

    onFormChange && onFormChange(updatedFields);
    setFields(updatedFields);
  };

  const generateEmptyField = () => {
    if (!initialValue[0]) return;

    const emptyField = Object.keys(initialValue[0]).reduce((acc, key) => {
      acc[key as keyof F] = EMPTY_STRING as F[keyof F];
      return acc;
    }, {} as F);
    return emptyField;
  };

  const add = () => {
    const newField = generateEmptyField() ?? cachedFieldTemplate;
    if (!newField) return;

    const updatedFields = [...fields, newField];
    setFields(updatedFields);
    onFormChange && onFormChange(updatedFields);
  };

  const remove = (index: number) => {
    if (fields.length === DEFAULT_FORM_LIST_NUMBER_REMAINING_FIELD) {
      const fieldTemplate = generateEmptyField();
      setCachedFieldTemplate(fieldTemplate);
    }

    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
    onFormChange && onFormChange(updatedFields);
  };
  //#endregion Handle Function

  return (
    <div id='formListComponent' className={cx('container')}>
      {title && <div className={cx('title')}>{title}</div>}
      <div className={cx('line')} />

      {fields && (
        <div className={cx('contentFields')}>
          {children(fields, { add, remove, onValueChange })}
        </div>
      )}
    </div>
  );
};

export default FormList;
