import { CONTENT_TYPE_FORM_DATA, urlApiTechnician } from '~/utils/constants/common';
import { IParamGetListTechnician } from '~/utils/interface/technician';
import axiosClient from './axiosClient';

const technicianApi = {
  getListTechnician(params: IParamGetListTechnician) {
    const url = `${urlApiTechnician.technician}`;
    return axiosClient.get(url, {
      params: params,
    });
  },

  createTechnician(params: FormData) {
    const url = `${urlApiTechnician.createTechnician}`;
    return axiosClient.post(url, params, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },
};

export default technicianApi;
