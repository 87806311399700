// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import { IToast } from '~/utils/interface/toast';
import { ToastPositionTypeEnum } from '~/utils/enum';

export interface ToastState {
  toasts: IToast[];
}

const initialState: ToastState = {
  toasts: [] as IToast[],
};

const toastSlice = createSlice({
  name: 'toastState',
  initialState,
  reducers: {
    addToast(state, action) {
      // Multiple toast
      // let oldToasts = state.toasts;
      // action.payload.id = oldToasts[oldToasts.length - 1].id + 1;
      // oldToasts.push(action.payload);
      // if (oldToasts.length > 5) {
      //   oldToasts = oldToasts.slice(oldToasts.length - 5, oldToasts.length);
      // }
      // state.toasts = oldToasts;
      action.payload.id = 1;
      !action.payload.position && (action.payload.position = ToastPositionTypeEnum.CENTER);
      !action.payload.allowClose && (action.payload.allowClose = true);
      state.toasts = [action.payload];
    },
    removeToast(state) {
      state.toasts = [];
    },
  },
});

export const toastActions = toastSlice.actions;

// Get list toast
export const selectToasts = (state: RootState) => state.toastState.toasts;

const toastReducer = toastSlice.reducer;
export default toastReducer;
