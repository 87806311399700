// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useRef, useState, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
// Components, Layouts, Pages
import { CustomToolbar } from '~/components';
// Others
import { EMPTY_STRING, MAX_LENGTH_DESCRIPTIONS } from '~/utils/constants/common';
// Styles, images, icons
import 'react-quill/dist/quill.snow.css';
import styles from './QuillEditor.module.scss';
import './QuillEditor.scss';

const cx = classNames.bind(styles);

type Props = {
  onChange?: (name: string, text: string) => void;
  width?: number | string;
  height?: number | string;
  name?: string | null;
};

const QuillEditor = (props: Props) => {
  //#region Destructuring Props
  const { onChange, width = '100%', height = 356, name } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const initialValue = '';
  const quillRef = useRef<ReactQuill | null>(null);
  const [text, setText] = useState<string>(initialValue);
  const [textLength, setTextLength] = useState<number>(initialValue.length);
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  };
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      const updateTextLength = () => {
        const textContent = editor.getText().length - 1;
        setTextLength(textContent);
      };

      editor.on('text-change', updateTextLength);

      return () => {
        editor.off('text-change', updateTextLength);
      };
    }
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = useCallback(
    (html: string) => {
      const editor = quillRef.current?.getEditor();
      const textContent = editor?.getText() || EMPTY_STRING;
      const textLengthWithSpaces = textContent.length - 1;

      if (textLengthWithSpaces <= MAX_LENGTH_DESCRIPTIONS) {
        setText(html);
        setTextLength(textLengthWithSpaces);
        onChange && name && onChange(name, html);
      }
    },
    [name, onChange]
  );
  //#endregion Handle Function

  return (
    <div id='quillEditorComponent' className={cx('container')}>
      <div className={cx('quillEditorGroup')} style={{ width, height }}>
        <ReactQuill
          ref={quillRef}
          value={text}
          onChange={handleChange}
          modules={modules}
          theme='snow'
          className={cx('quillEditor')}
        />
        <CustomToolbar textLength={textLength} />
      </div>
    </div>
  );
};

export default QuillEditor;
