export const ZINC800 = '#333333';
export const VIOLET200 = '#DCD6F7';
export const ORANGE200 = '#DFD5A5';
export const SKY950 = '#0A2342';
export const GREEN600 = '#219653';
export const SKY500 = '#2D9CDB';
export const AMBER300 = '#F2C94C';
export const PURPLE500 = '#9B51E0';
export const NEUTRAL400 = '#9C9C9F';
export const CYAN600 = '#048BA8';
export const ZINC400 = '#AEAEB2';
export const WHITE = '#FFFFFF';
export const BLUE500 = '#2F80ED';
export const WHITE_OPACITY_50 = 'rgb(255 255 255/50%)';
export const TORCH_RED = '#FF0021';
export const PRIMARY_RED_600 = '#E02B20';
export const BLUE = '#73b0d7';
export const ORANGE = '#FFA500';
export const GREEN400 = '#559E38';
export const ORANGE800 = '#E45621';
