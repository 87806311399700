import { Link, StyleSheet, Text } from '@react-pdf/renderer';
import { ChildNode } from 'domhandler';
import { Fragment } from 'react';

type OrderedListType = 'ul' | 'ol';

export const renderNodes = (
  nodes: ChildNode[],
  orderedListType?: OrderedListType
): JSX.Element[] => {
  return nodes
    .map((node: ChildNode, index) => {
      if (node.type === 'text') {
        return <Text key={index}>{node.data}</Text>;
      }
      if (node.type === 'tag') {
        switch (node.name) {
          case 'h1':
            return (
              <Text key={index} style={styles.h1}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'h2':
            return (
              <Text key={index} style={styles.h2}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'h3':
            return (
              <Text key={index} style={styles.h3}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'h4':
            return (
              <Text key={index} style={styles.h4}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'h5':
            return (
              <Text key={index} style={styles.h5}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'h6':
            return (
              <Text key={index} style={styles.h6}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'p':
            return (
              <Text key={index} style={styles.normalText}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'strong':
            return (
              <Text key={index} style={styles.boldText}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'em':
            return (
              <Text key={index} style={styles.italicText}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'u':
            return (
              <Text key={index} style={styles.underlineText}>
                {renderNodes(node.children)}
              </Text>
            );

          case 'ul':
            return (
              <Text key={index} style={styles.unorderedList}>
                {renderNodes(node.children, 'ul')}
              </Text>
            );

          case 'ol':
            return (
              <Text key={index} style={styles.orderedList}>
                {renderNodes(node.children, 'ol')}
              </Text>
            );

          case 'li':
            return (
              <Fragment key={index}>
                {orderedListType === 'ol' && <Text>{`${index + 1}. `}</Text>}
                {orderedListType === 'ul' && <Text>{'• '}</Text>}
                <Text>{renderNodes(node.children)}</Text>
                <Text>{'\n'}</Text>
              </Fragment>
            );

          case 'a': {
            const url = getLinkFromAttribs(node.attribs);
            return url ? (
              <Link key={index} src={url}>
                {renderNodes(node.children)}
              </Link>
            ) : (
              renderNodes(node.children)
            );
          }

          case 'br':
            return <Link key={index}>{'\n'}</Link>;

          default:
            return renderNodes(node.children);
        }
      }
      return null;
    })
    .filter((element): element is JSX.Element => element !== null);
};

const getLinkFromAttribs = (attribs: { [key: string]: string }): string | null => {
  if (!attribs || !attribs.href) return null;
  return attribs.href.replace(/\\|"/g, '');
};

export const styles = StyleSheet.create({
  // Style for render element
  normalText: {
    fontWeight: 400,
  },
  boldText: {
    fontWeight: 700,
  },
  italicText: {
    fontStyle: 'italic',
  },
  underlineText: {
    textDecoration: 'underline',
  },
  // Style for list
  unorderedList: {
    marginLeft: 20,
  },
  orderedList: {
    marginLeft: 20,
  },

  // Style for Heading
  h1: {
    fontSize: 24,
    fontWeight: 700,
  },
  h2: {
    fontSize: 18,
    fontWeight: 600,
  },
  h3: {
    fontSize: 10,
    fontWeight: 500,
  },
  h4: {
    fontSize: 10,
    fontWeight: 500,
  },
  h5: {
    fontSize: 8,
    fontWeight: 400,
  },
  h6: {
    fontSize: 7,
    fontWeight: 400,
  },
});
