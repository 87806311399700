// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton, BaseModal } from '~/components';
// Others
import { ButtonTypeEnum, StatusEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './AdminDeactivatePopup.module.scss';

type Props = {
  activeDesc?: string;
  deactivateDesc?: string;
  isOpen?: boolean;
  currentStatus?: string;
  onClose?: () => void;
  onUpdateStatus?: () => void;
};

const cx = classNames.bind(styles);

const AdminDeactivatePopup = (props: Props) => {
  //#region Destructuring Props
  const { activeDesc, deactivateDesc, isOpen, currentStatus, onClose, onUpdateStatus } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClose = () => {
    onClose && onClose();
  };
  //#endregion Handle Function

  return (
    <BaseModal id='adminDeactivatePopupComponent' isOpen={isOpen} onClose={handleClose} width={340}>
      <div className={cx('iconCloseModal')} onClick={handleClose}>
        <img src={icons.commonIconClose} alt={t('common_img_text_alt')} />
      </div>

      <div className={cx('mainIconGroup')}>
        {currentStatus === StatusEnum.ACTIVE && (
          <div className={cx('bgMainIcon', 'deactivateBg')}>
            <img
              src={icons.commonIconCross}
              alt={t('common_img_text_alt')}
              className={cx('mainIcon')}
            />
          </div>
        )}

        {currentStatus === StatusEnum.INACTIVE && (
          <div className={cx('bgMainIcon', 'activateBg')}>
            <img
              src={icons.commonIconCheckMark}
              alt={t('common_img_text_alt')}
              className={cx('mainIcon')}
            />
          </div>
        )}
      </div>

      <div className={cx('title')}>
        {currentStatus === StatusEnum.ACTIVE
          ? t('admin_manage_employee_popup_deactivate_title')
          : t('admin_manage_employee_popup_activate_title')}
      </div>

      <div className={cx('description')}>
        {currentStatus === StatusEnum.ACTIVE
          ? deactivateDesc || t('common_popup_activate_desc_default')
          : activeDesc || t('common_popup_deactivate_desc_default')}
      </div>

      <div className={cx('modalFooter')}>
        <BaseButton
          label={t('common_btn_cancel')}
          typeStyle={ButtonTypeEnum.CANCEL}
          onClick={handleClose}
        />
        <BaseButton
          label={
            currentStatus === StatusEnum.ACTIVE
              ? t('common_btn_deactivate')
              : t('common_status_active')
          }
          typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
          onClick={onUpdateStatus}
        />
      </div>
    </BaseModal>
  );
};

export default AdminDeactivatePopup;
