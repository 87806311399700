// Others
import { urlApiLabor } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import { IListLabor } from '~/utils/interface/labor';

const laborApi = {
  getListLabor(params: ITableParams) {
    const url = `${urlApiLabor.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IListLabor[]>>>(url, { params });
  },
};

export default laborApi;
