// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
// Components, Layouts, Pages
// Others
import { SingleSelectTypeEnum } from '~/utils/enum';
import { ISingleSelectOption } from '~/utils/interface/common';
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
// Styles, images, icons
import styles from './SingleSelect.module.scss';
import { icons } from '~/assets';

type SingleSelectProps = {
  label?: string;
  width?: number | string;
  height?: number | string;
  labelMargin?: number | string;
  options?: ISingleSelectOption[];
  placeholder?: string;
  value?: string | number;
  name?: string;
  type?: SingleSelectTypeEnum;
  parentRef?: React.RefObject<HTMLDivElement>;
  errorMessage?: string;
  disabled?: boolean;
  isRequired?: boolean;
  onChange?: (value: string, name: string) => void;
};

const cx = classNames.bind(styles);

const SingleSelect = (props: SingleSelectProps) => {
  //#region Destructuring Props
  const {
    width,
    height,
    label,
    options,
    placeholder,
    value,
    name,
    type,
    labelMargin = 4,
    parentRef,
    errorMessage,
    disabled,
    isRequired,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const singleSelectRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [selectOption, setSelectOption] = useState<ISingleSelectOption>();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [openUpwards, setOpenUpwards] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      const find = options?.find((item) => item.value.toString() === value.toString());

      setSelectOption(find);
    }
  }, [value]);

  useEffect(() => {
    if (showOptions) {
      const selectRect = singleSelectRef.current?.getBoundingClientRect();
      const optionsHeight = optionsRef.current?.offsetHeight || 0;

      const modalRect = parentRef && parentRef.current?.getBoundingClientRect();

      if (selectRect && modalRect) {
        const spaceBelow = modalRect.bottom - selectRect.bottom;
        const spaceAbove = selectRect.top - modalRect.top;

        if (spaceBelow < optionsHeight && spaceAbove > optionsHeight) {
          setOpenUpwards(true);
        } else {
          setOpenUpwards(false);
        }
      }
    }
  }, [showOptions, parentRef]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleShowOptions = () => {
    if (disabled) return;

    setShowOptions(!showOptions);
  };

  const handleSelectOption = (item: ISingleSelectOption) => {
    onChange && onChange(item.value, name || '');
    setSelectOption(item);
    setShowOptions(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (singleSelectRef.current && !singleSelectRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };
  //#endregion Handle Function

  return (
    <div
      id='singleSelectComponent'
      className={cx('container', type, disabled && 'disabled')}
      ref={singleSelectRef}
    >
      {label && (
        <div className={cx('label')} style={{ marginBottom: labelMargin }}>
          {label}
          {isRequired && <span className={cx('viewStar')}>{ASTERISK_SYMBOL}</span>}
        </div>
      )}

      <div className={cx('selectMenu')} style={{ width }}>
        <div className={cx('selectBtn')} style={{ height }} onClick={handleShowOptions}>
          <span className={cx('value')}>
            {!selectOption && placeholder ? placeholder : selectOption?.label}
          </span>
          <img
            src={icons.commonIconSingleSelect}
            className={cx('icon', showOptions ? 'iconActive' : '')}
            alt={t('common_img_text_alt')}
          />
        </div>

        {showOptions && !disabled && (
          <ul ref={optionsRef} className={cx('options', { openUpwards })}>
            <div className={cx('listOption')}>
              {options?.map((item) => (
                <li
                  key={item.value}
                  className={cx('option', item.value === selectOption?.value && 'active')}
                  onClick={() => handleSelectOption(item)}
                >
                  {item.label}
                </li>
              ))}

              {(!options || options?.length === 0) && (
                <div className={cx('option', 'noData')}>{t('common_label_no_data_available')}</div>
              )}
            </div>
          </ul>
        )}
      </div>

      {errorMessage && !disabled && <p className={cx('errorMessage')}>{errorMessage}</p>}
    </div>
  );
};

export default SingleSelect;
