// Libs
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components, Layouts, Pages
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

// Others
import { LoadingDataProvider } from '~/context';
import { IUserProfile } from '~/utils/interface/profile';
import { AccountRoleCodesEnum, StorageEnum } from '~/utils/enum';
import { profileActions, selectUserProfile } from '~/thunks/profile/profileSlice';
import { getDetailProfile } from '~/thunks/profile/profileThunk';
import { useAppDispatch, useAppSelector } from '~/redux/hooks';

// Styles, images, icons
import styles from './Main.module.scss';

const cx = classNames.bind(styles);

type Props = {
  role: AccountRoleCodesEnum;
};

const Main = (props: Props) => {
  //#region Destructuring Props
  const { role } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const dispatch = useAppDispatch();
  //#endregion Declare Hook

  //#region Selector
  const userInfo: IUserProfile = useSelector(selectUserProfile);
  const isRefresh = useAppSelector((state) => state.profileState.isRefresh);
  //#endregion Selector

  //#region Declare State
  const [accountId] = useState<string | null>(localStorage.getItem(StorageEnum.ACCOUNT_ID));
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    handleGetProfile();
  }, [isRefresh, accountId]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleGetProfile = () => {
    dispatch(getDetailProfile())
      .unwrap()
      .then((res) => {
        dispatch(profileActions.setIsRefresh(false));
      })
      .catch((error) => {
        dispatch(profileActions.setIsRefresh(false));
      });
  };
  //#endregion Handle Function

  return (
    <div id='maidLayout' className={cx('mainContainer')}>
      <section className={cx('sidebarContainer')}>
        <Sidebar role={role} />
      </section>

      <section className={cx('primaryContent')}>
        <Header userInfo={userInfo} />

        <div className={cx('content')}>
          <LoadingDataProvider>
            <Outlet />
          </LoadingDataProvider>
        </div>
      </section>
    </div>
  );
};

export default Main;
