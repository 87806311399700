// Others
import { urlApiInvoice } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IFormCreateInvoice,
  IGetListInvoiceReq,
  IInvoiceDetail,
  IInvoices,
} from '~/utils/interface/invoices';
import axiosClient from './axiosClient';

const invoiceApi = {
  getListInvoice(params: IGetListInvoiceReq) {
    const url = `${urlApiInvoice.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IInvoices[]>>>(url, {
      params,
    });
  },

  createInvoice(body: IFormCreateInvoice) {
    const url = `${urlApiInvoice.createInvoice}`;
    return axiosClient.post<BaseResponse<IFormCreateInvoice>>(url, body);
  },

  getDetailInvoice(invoiceId: string) {
    const url = `${urlApiInvoice.getDetail(invoiceId)}`;
    return axiosClient.get<BaseResponse<IInvoiceDetail>>(url);
  },
};

export default invoiceApi;
