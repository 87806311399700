// Libs
import classNames from 'classnames/bind';
// Components, Layouts, Pages
import { SchedulePageTemplate } from '~/pages';
// Others
import { RolesEnum } from '~/utils/enum';
// Styles, images, icons
import styles from './ManageSchedule.module.scss';

const cx = classNames.bind(styles);

const ManageSchedule = () => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare Memo
  //#endregion Declare Memo

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return <SchedulePageTemplate role={RolesEnum.STAFF} />;
};

export default ManageSchedule;
