// Libs
import { View } from '@react-pdf/renderer';
// Components, Layouts, Pages
// Others
// Styles, images, icons

type Props = {
  width?: number;
  height?: number;
};

const Divider = (props: Props) => {
  //#region Destructuring Props
  const { height = 1, width = '100%' } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <View style={{ width, height, backgroundColor: '#000000', marginTop: 4, marginBottom: 4 }} />
  );
};

export default Divider;
