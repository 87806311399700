// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import employeeApi from '~/apis/employee';
import {
  CREATE_EMPLOYEE,
  UPDATE_STATUS_EMPLOYEE,
  GET_DETAIL_EMPLOYEE,
  GET_LIST_EMPLOYEE,
  UPDATE_EMPLOYEE,
} from '~/utils/constants/actionType';
import {
  IQueryListEmployee,
  IUpdateEmployeeBody,
  IUpdateStatusEmployeeBody,
} from '~/utils/interface/employee';

export const createEmployee = createAsyncThunk(
  CREATE_EMPLOYEE,
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const res = await employeeApi.createEmployee(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListEmployee = createAsyncThunk(
  GET_LIST_EMPLOYEE,
  async (payload: IQueryListEmployee, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getListEmployee(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailEmployee = createAsyncThunk(
  GET_DETAIL_EMPLOYEE,
  async (employeeId: number, { rejectWithValue }) => {
    try {
      const res = await employeeApi.getDetailEmployee(employeeId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateEmployee = createAsyncThunk(
  UPDATE_EMPLOYEE,
  async (dataUpdate: IUpdateEmployeeBody, { rejectWithValue }) => {
    try {
      const res = await employeeApi.updateEmployee(dataUpdate.id, dataUpdate.body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStatusEmployee = createAsyncThunk(
  UPDATE_STATUS_EMPLOYEE,
  async (payload: IUpdateStatusEmployeeBody, { rejectWithValue }) => {
    try {
      const res = await employeeApi.updateStatusEmployee(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
