// Libs
import classNames from 'classnames/bind';
import { TFunction } from 'i18next';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
// Components, Layouts, Pages
import { BaseImageCircle, BasePagination, BaseTable, Status } from '~/components';
// Others
import { mockDataChangeOrder } from '~/mockData';
import { ColumnType, IPagination, IQueryBase } from '~/utils/interface/common';
import { IChangeOrder } from '~/utils/interface/order';
import {
  DEFAULT_CURRENT_PAGE,
  DEFAULT_NUMBER_RECORD_TO_FETCH,
  EMPTY_STRING,
} from '~/utils/constants/common';
import { BaseTableEnum, CurrencyEnum } from '~/utils/enum';
import { formatCurrency, formattedTime, getAvatarWithName, getFullName } from '~/utils/helper';
// Styles, images, icons
import styles from './ChangeOrder.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const columns = (t: TFunction): ColumnType<IChangeOrder>[] => {
  return [
    {
      key: 'id',
      title: t('order_change_order_table_label_co'),
      dataIndex: 'id',
      render: (_, record) => <span>{record?.id || EMPTY_STRING}</span>,
    },
    {
      key: 'changeOrderBy',
      title: t('order_change_order_table_label_co_by'),
      dataIndex: 'changeOrderBy',
      render: (_, record) => (
        <div className={cx('avatarFirstLastName')}>
          {getAvatarWithName({ ...record.changeOrderBy }, record?.changeOrderBy.thumbnail) ? (
            <>
              <BaseImageCircle
                url={record?.changeOrderBy.thumbnail ?? record?.changeOrderBy.avatar}
                firstText={record?.changeOrderBy.firstName}
                secondText={record?.changeOrderBy.lastName}
                width={24}
                height={24}
                fontSize={12}
              />
              {getFullName({ ...record.changeOrderBy })}
            </>
          ) : (
            <div>{EMPTY_STRING}</div>
          )}
        </div>
      ),
    },
    {
      key: 'to',
      title: t('order_change_order_table_label_to'),
      dataIndex: 'to',
      render: (_, record) => <span>{record?.to || EMPTY_STRING}</span>,
    },
    {
      key: 'quantity',
      title: t('order_change_order_table_label_qty'),
      dataIndex: 'quantity',
      render: (_, record) => <span>{record?.quantity || EMPTY_STRING}</span>,
    },
    {
      key: 'description',
      title: t('order_change_order_table_label_description'),
      dataIndex: 'description',
      render: (_, record) => <span>{record?.description || EMPTY_STRING}</span>,
    },
    {
      key: 'price',
      title: t('order_change_order_table_label_price'),
      dataIndex: 'price',
      render: (_, record) => <span>{formatCurrency(CurrencyEnum.USD, record?.price)}</span>,
    },
    {
      key: 'subTotal',
      title: t('order_change_order_table_label_sub_total'),
      dataIndex: 'subTotal',
      render: (_, record) => <span>{formatCurrency(CurrencyEnum.USD, record?.subTotal)}</span>,
    },
    {
      key: 'dueDate',
      title: t('order_change_order_table_label_due_date'),
      dataIndex: 'dueDate',
      render: (_, record) => (
        <div className={cx('textBase')}>
          {record?.dueDate ? (
            <div className={cx('date')}>
              <img
                src={icons.commonIconCalender}
                alt={t('common_img_text_alt')}
                className={cx('icon')}
              />
              <span>{formattedTime(record.dueDate)}</span>
            </div>
          ) : (
            <span>{EMPTY_STRING}</span>
          )}
        </div>
      ),
    },
    {
      key: 'status',
      title: t('order_change_order_table_label_status'),
      dataIndex: 'status',
      render: (_, record) => <Status type='tag' status={record.status} />,
    },
  ];
};

const ChangeOrder = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => Object.fromEntries([...searchParams]), [searchParams]);
  const pageSelected = useMemo<number>(
    () => Number(params?.page ?? DEFAULT_CURRENT_PAGE),
    [params?.page]
  );
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [changeOrder, setChangeOrder] = useState<IChangeOrder[]>([]);
  // TODO: Mock data change order: const [changeOrder, setChangeOrder] = useState<IChangeOrder[]>(mockDataChangeOrder || []);
  const [pagination, setPagination] = useState<IPagination>();

  const [paramObject, setParamObject] = useState<IQueryBase>({
    page: pageSelected,
    limit: DEFAULT_NUMBER_RECORD_TO_FETCH,
  });
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handlePaginationChange = (page: number) => {
    const newParamObject: IQueryBase = { ...paramObject, page };
    setParamObject(newParamObject);
    setSearchParams({
      ...params,
      page: page.toString(),
      limit: `${DEFAULT_NUMBER_RECORD_TO_FETCH}`,
    });
  };
  //#endregion Handle Function

  return (
    <div id='changeOrderTab' className={cx('container')}>
      <div className={cx('table')}>
        <BaseTable
          typeStyle={BaseTableEnum.COLOR_TABLE}
          columns={columns(t)}
          dataSource={changeOrder || []}
        />
      </div>

      <div className={cx('pagination')}>
        <BasePagination
          defaultCurrentPage={pageSelected}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalItems}
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  );
};

export default ChangeOrder;
