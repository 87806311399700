// Libs
import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { Spinner, Notification } from '~/components';
// Others
import { dataNotificationsResp, defaultNumberHeaderNotification } from '~/mockData';
import { INotificationsResp } from '~/utils/interface/notifications';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './ReceivedNotification.module.scss';
import { icons } from '~/assets';

type Props = {};

const cx = classNames.bind(styles);

const ReceivedNotification = (props: Props) => {
  //#region Destructuring Props
  const {} = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  const receivedNotificationRef = useRef<HTMLDivElement>(null);
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState<boolean>(false);
  const [dataNotifications, setDataNotifications] =
    useState<INotificationsResp>(dataNotificationsResp);
  const [isShow, setIsShow] = useState<boolean>(false);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  //#endregion Implement Hook

  //#region Handle Function
  const handleBellClicked = () => {
    setIsShow(!isShow);
  };

  const handleNotificationClicked = (id: string) => {
    console.log(`[ReceivedNotification] handleNotificationClicked id: ${id}`);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      receivedNotificationRef.current &&
      !receivedNotificationRef.current.contains(event.target as Node)
    ) {
      setIsShow(false);
    }
  };
  //#endregion Handle Function

  return (
    <div
      id='receivedNotification'
      className={cx('receivedNotification')}
      ref={receivedNotificationRef}
    >
      <div className={cx('receivedNotificationBell')} onClick={handleBellClicked}>
        <img src={icons.adminIconHeaderNotification} />

        {dataNotifications.responses && dataNotifications.responses?.length > 0 && (
          <div className={cx('receivedNotificationNumberWrap')}>
            <span className={cx('receivedNotificationNumber')}>
              {dataNotifications.responses?.length}
            </span>
          </div>
        )}
      </div>

      {isShow && (
        <div className={cx('receivedNotificationContainer')}>
          {isLoadingFirstTime ? (
            <div className={cx('loadingCenter')}>
              <Spinner />
            </div>
          ) : (
            <div className={cx('receivedNotificationScroll')}>
              {dataNotifications?.responses &&
              dataNotifications?.responses?.length > DEFAULT_NUMBER_ZERO ? (
                <div className={cx('ReceivedNotificationItem')}>
                  <div className={cx('receivedNotificationList')}>
                    {dataNotifications?.responses?.map((item) => (
                      <Notification key={item.id} data={item} onClick={handleNotificationClicked} />
                    ))}
                  </div>
                </div>
              ) : (
                <div className={cx('noData')}>{t('common_no_notification')}</div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReceivedNotification;
