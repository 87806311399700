// Libs
import { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import classNames from 'classnames/bind';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { configStackedColumChart } from '~/utils/constants/configChart';
// Styles, images, icons
import styles from './StackedColumnChart.module.scss';

type Props = {
  data: ApexOptions['series'];
  categories: string[];
  height?: string | number;
  colors: string[];
  title?: string;
  label?: string;
  total?: string | number;
  descriptionTotal?: string;
};

const cx = classNames.bind(styles);

const StackedColumnChart = (props: Props) => {
  //#region Destructuring Props
  const { data, categories, height = 350, colors, title, label, total, descriptionTotal } = props;
  //#endregion Destructuring Props

  const seriesData = data;

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [configChart, setConfigChart] = useState<ApexOptions | null>(null);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!data) {
      return;
    }

    const config = configStackedColumChart({
      categories: categories,
      colors: colors,
      textNoData: t('common_empty_data'),
      labelContractJob: t('admin_dashboard_column_chart_contract_job'),
      labelServiceJob: t('admin_dashboard_column_chart_service_job'),
    });

    setConfigChart(config);
  }, [data]);
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='columnChartComponent' className={cx('columnChartComponent')}>
      <div className={cx('headerContainer')}>
        {title && <p className={cx('headTitle')}>{t(`${title}`)}</p>}

        {label && <p className={cx('headDescription')}>{t(`${label}`)}</p>}

        {descriptionTotal && (
          <p className={cx('headDescription')}>
            <span className={cx('headDescriptionData')}>{total}</span>
            {t(`${descriptionTotal}`)}
          </p>
        )}
      </div>
      <div className={cx('contentChart')}>
        {configChart && (
          <ReactApexChart options={configChart} series={seriesData} type='bar' height={height} />
        )}
      </div>
    </div>
  );
};

export default StackedColumnChart;
