// Libs
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Timeline, { ReactCalendarTimelineProps, TimelineHeaders } from 'react-calendar-timeline';
// Components, Layouts, Pages
import { HeaderTimeline } from '~/components';
// Others
import {
  DEFAULT_SCHEDULE_TIMELINE_VIEWS,
  DEFAULT_SCHEDULE_TIMELINE_VISIBLE_HOUR,
} from '~/utils/constants/component';
import { ITimelineEvent, ITimelineGroup } from '~/utils/interface/schedule';
import { TCalendarViewMode } from '~/utils/type/schedule';
// Styles, images, icons
import 'react-calendar-timeline/lib/Timeline.css';
import './BaseScheduleTimeline.scss';

export type BaseScheduleTimelineProps = ReactCalendarTimelineProps<
  ITimelineEvent,
  ITimelineGroup
> & {
  date: string | Date;
  view: TCalendarViewMode;
};

const BaseScheduleTimeline = (props: BaseScheduleTimelineProps) => {
  //#region Destructuring Props
  const { date, view, ...restProps } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Declare Memo
  const minTime = useMemo(() => moment(date).startOf('day').valueOf(), [date, view]);
  const maxTime = useMemo(() => moment(date).endOf('day').valueOf(), [date, view]);
  //#endregion Declare Memo

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [visibleTimeStart, setVisibleTimeStart] = useState<number>(
    moment().startOf('week').valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState<number>(moment().endOf('week').valueOf());
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (!date) return;
    if (view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.week) {
      const startTime = moment(date).startOf('week').valueOf();
      const endTime = moment(date).endOf('week').valueOf();
      setVisibleTimeStart(startTime);
      setVisibleTimeEnd(endTime);
      return;
    }
    if (view === DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) {
      const startTime = moment(date).startOf('day').valueOf();
      const endTime = moment(date)
        .startOf('date')
        .add(DEFAULT_SCHEDULE_TIMELINE_VISIBLE_HOUR, 'hour')
        .valueOf();
      setVisibleTimeStart(startTime);
      setVisibleTimeEnd(endTime);
      return;
    }
  }, [date, view]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleLimitHorizontalScroll = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ) => {
    if (!view || view !== DEFAULT_SCHEDULE_TIMELINE_VIEWS.day) return;

    const duration = visibleTimeEnd - visibleTimeStart;
    if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
      updateScrollCanvas(minTime, maxTime);
      setVisibleTimeStart(minTime);
      setVisibleTimeEnd(maxTime);
    } else if (visibleTimeStart < minTime) {
      updateScrollCanvas(minTime, minTime + duration);
      setVisibleTimeStart(minTime);
      setVisibleTimeEnd(minTime + duration);
    } else if (visibleTimeEnd > maxTime) {
      updateScrollCanvas(maxTime - duration, maxTime);
      setVisibleTimeStart(maxTime - duration);
      setVisibleTimeEnd(maxTime);
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
      setVisibleTimeStart(visibleTimeStart);
      setVisibleTimeEnd(visibleTimeEnd);
    }
  };
  //#endregion Handle Function

  return (
    <div id='baseScheduleTimeline'>
      <Timeline
        {...restProps}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        canMove={false}
        canChangeGroup={false}
        canResize={false}
        stackItems
        lineHeight={135}
        itemHeightRatio={0.7}
        sidebarWidth={173}
        onTimeChange={handleLimitHorizontalScroll}
      >
        <TimelineHeaders>
          <HeaderTimeline height={58} view={view} />
        </TimelineHeaders>
      </Timeline>
    </div>
  );
};

export default BaseScheduleTimeline;
