// Libs
import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
// Others
import { CREATE_INVOICE, GET_DETAIL_INVOICE, GET_LIST_INVOICE } from '~/utils/constants/actionType';
import { IFormCreateInvoice, IGetListInvoiceReq } from '~/utils/interface/invoices';
import invoiceApi from '~/apis/invoice';

export const getListInvoice = createAsyncThunk(
  GET_LIST_INVOICE,
  async (payload: IGetListInvoiceReq, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.getListInvoice(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  CREATE_INVOICE,
  async (payload: IFormCreateInvoice) => {
    try {
      const res = await invoiceApi.createInvoice(payload);
      return res.data;
    } catch (error) {
      return isRejectedWithValue(error);
    }
  }
);

export const getDetailInvoice = createAsyncThunk(
  GET_DETAIL_INVOICE,
  async (invoiceId: string, { rejectWithValue }) => {
    try {
      const res = await invoiceApi.getDetailInvoice(invoiceId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
