import { CONTENT_TYPE_FORM_DATA, urlApiEmployee } from '~/utils/constants/common';
import { BaseResponse, IListDataResponse } from '~/utils/interface/common';
import {
  IDetailEmployee,
  IEmployee,
  IQueryListEmployee,
  IUpdateStatusEmployeeBody,
} from '~/utils/interface/employee';
import axiosClient from './axiosClient';

const employeeApi = {
  createEmployee(params: FormData) {
    const url = `${urlApiEmployee.createEmployee}`;
    return axiosClient.post(url, params, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  getListEmployee(params: IQueryListEmployee) {
    const url = `${urlApiEmployee.employee}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IEmployee[]>>>(url, { params });
  },

  getDetailEmployee(employeeId: number) {
    const url = `${urlApiEmployee.detail(employeeId)}`;
    return axiosClient.get<BaseResponse<IDetailEmployee>>(url);
  },

  updateEmployee(id: string, formData: FormData) {
    const url = `${urlApiEmployee.update}/${id}`;
    return axiosClient.put(url, formData, { headers: { 'Content-Type': CONTENT_TYPE_FORM_DATA } });
  },

  updateStatusEmployee(payload: IUpdateStatusEmployeeBody) {
    const url = `${urlApiEmployee.updateStatus(payload.id)}`;
    return axiosClient.put<BaseResponse>(url);
  },
};

export default employeeApi;
