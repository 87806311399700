// Libs
import { Description, Field, Input, Label } from '@headlessui/react';
import classNames from 'classnames/bind';
import { ChangeEvent, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ASTERISK_SYMBOL, DEFAULT_NUMBER_ZERO, EMPTY_STRING } from '~/utils/constants/common';
import {
  DEFAULT_TAG_INPUT_HEIGHT,
  DEFAULT_TAG_INPUT_MAX_TAG,
  DEFAULT_TAG_INPUT_VALUES,
  DEFAULT_TAG_INPUT_WIDTH,
} from '~/utils/constants/component';
import { HTMLEventEnum } from '~/utils/enum';
// Styles, images, icons
import { icons } from '~/assets';
import styles from './FormTagInput.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  placeholder?: string;
  name?: string;
  tagValues?: string[];
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  maxTags?: number;
  onChange?: (values: string[]) => void;
};

const cx = classNames.bind(styles);

const FormTagInput = (props: Props) => {
  //#region Destructuring Props
  const {
    height = DEFAULT_TAG_INPUT_HEIGHT,
    width = DEFAULT_TAG_INPUT_WIDTH,
    label,
    placeholder,
    required,
    disabled,
    maxTags = DEFAULT_TAG_INPUT_MAX_TAG,
    errorMessage,
    tagValues = DEFAULT_TAG_INPUT_VALUES,
    name,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [inputValue, setInputValue] = useState<string>(EMPTY_STRING);
  const [inputTags, setInputTags] = useState<string[]>(tagValues);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    if (tagValues.length <= DEFAULT_NUMBER_ZERO) return;

    setInputTags(tagValues);
  }, [tagValues.length]);

  useEffect(() => {
    onChange && onChange(inputTags);
  }, [inputTags]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== HTMLEventEnum.ENTER) return;
    event.preventDefault();

    if (inputValue.trim() !== EMPTY_STRING && inputTags.length < maxTags) {
      addTag(inputValue);
      setInputValue('');
    }
  };

  const addTag = (newTag: string) => {
    if (newTag && !inputTags.includes(newTag) && inputTags.length < maxTags) {
      setInputTags([...inputTags, newTag]);
    }
  };

  const removeTag = (tagRemove: string) => {
    setInputTags(inputTags.filter((tag) => tag !== tagRemove));
  };

  const clearAll = () => {
    setInputValue(EMPTY_STRING);
    setInputTags([]);
  };

  const isHasTag = () => {
    return inputTags.length > DEFAULT_NUMBER_ZERO;
  };
  //#endregion Handle Function

  return (
    <Field
      id='formTagInputComponent'
      className={cx('container')}
      style={{ width }}
      disabled={disabled || inputTags.length === maxTags}
    >
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('inputContainer')} style={{ height: isHasTag() ? 'auto' : height }}>
        <div className={cx('valueGroup')}>
          {inputTags.length > DEFAULT_NUMBER_ZERO && (
            <Fragment>
              {inputTags.map((tag, index) => (
                <div key={index} className={cx('tagContainer')}>
                  <div className={cx('tagName')}>{tag}</div>

                  <div className={cx('btnRemove')} onClick={() => removeTag(tag)}>
                    <img
                      src={icons.commonIconClose}
                      alt={t('common_img_text_alt')}
                      className={cx('iconRemove')}
                    />
                  </div>
                </div>
              ))}
            </Fragment>
          )}

          <Input
            value={inputValue}
            placeholder={placeholder}
            name={name}
            autoComplete='off'
            className={cx('mainInput')}
            onKeyDown={handleKeyPress}
            onChange={handleInputChange}
          />
        </div>

        {isHasTag() && (
          <div className={cx('btnRemove')} onClick={clearAll}>
            <img
              src={icons.commonIconClose}
              alt={t('common_img_text_alt')}
              className={cx('iconRemove')}
            />
          </div>
        )}
      </div>

      {errorMessage && <Description className={cx('errMessage')}>{errorMessage}</Description>}
    </Field>
  );
};

export default FormTagInput;
