// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
import { BaseButton } from '~/components';
// Others
import { INote } from '~/utils/interface/note';
import { ButtonTypeEnum } from '~/utils/enum';
import { EMPTY_STRING } from '~/utils/constants/common';
// Styles, images, icons
import styles from './AdminNoteDetailModal.module.scss';

type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  data?: INote;
};

const cx = classNames.bind(styles);

const AdminNoteDetailModal = (props: Props) => {
  //#region Destructuring Props
  const { isOpen, onClose, data } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <div id='adminNoteDetailModal' className={cx('modalContainer')}>
      <>
        {isOpen && (
          <div className={cx('modalBody')}>
            <div className={cx('modalHead')}>
              <h3 className={cx('modalTitle')}>{data?.name || EMPTY_STRING}</h3>
            </div>

            <div className={cx('modalContent')}>
              <div className={cx('assignedJob')}>
                <div className={cx('sectionTitle')}>
                  {t('admin_manage_notes_modal_detail_assigned_job')}
                </div>
                <div className={cx('textContent')}>{data?.job?.title || EMPTY_STRING}</div>
              </div>

              <div className={cx('noteContent')}>
                <div className={cx('sectionTitle')}>
                  {t('admin_manage_notes_modal_detail_note')}
                </div>
                <div
                  className={cx('ql-editor', 'description')}
                  dangerouslySetInnerHTML={{ __html: data?.content || EMPTY_STRING }}
                ></div>
              </div>
            </div>

            <div className={cx('modalFooter')}>
              <BaseButton
                label={t('common_btn_close')}
                width={117}
                typeStyle={ButtonTypeEnum.SOLID_PRIMARY}
                onClick={onClose}
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default AdminNoteDetailModal;
