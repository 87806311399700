// Libs
import { Checkbox, Field, Label } from '@headlessui/react';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
// Components, Layouts, Pages
// Others
import { DEFAULT_CHECKBOX_VALUE } from '~/utils/constants/component';
// Styles, images, icons
import styles from './BaseCheckbox.module.scss';

type Props = {
  label?: string;
  name: string;
  value?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean, name: string) => void;
};

const cx = classNames.bind(styles);

const BaseCheckbox = (props: Props) => {
  //#region Destructuring Props
  const { value = DEFAULT_CHECKBOX_VALUE, name, label, onChange, disabled } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  const [enabled, setEnabled] = useState<boolean>(value);
  //#endregion Declare State

  //#region Implement Hook
  useEffect(() => {
    setEnabled(value);
  }, [value]);
  //#endregion Implement Hook

  //#region Handle Function
  const handleChange = (checked: boolean) => {
    onChange && onChange(checked, name);
    setEnabled(checked);
  };
  //#endregion Handle Function

  return (
    <Field
      id='baseCheckboxComponent'
      className={cx('container', disabled && 'disabledStyle')}
      disabled={disabled}
    >
      {label && (
        <Label htmlFor={name} className={cx('label')}>
          {label}
        </Label>
      )}

      <Checkbox
        id={name}
        name={name}
        checked={enabled}
        onChange={handleChange}
        className={cx('mainCheckbox', 'group')}
      >
        <svg className={cx('checkSvg')} viewBox='0 0 14 14' fill='none'>
          <path d='M3 8L6 11L11 3.5' strokeWidth={1} strokeLinecap='round' strokeLinejoin='round' />
        </svg>
      </Checkbox>
    </Field>
  );
};

export default BaseCheckbox;
