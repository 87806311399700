// Libs
import classNames from 'classnames/bind';
import { Field, Label } from '@headlessui/react';

// Components, Layouts, Pages
import { BaseRichEditor } from '~/components';

// Others
import { ASTERISK_SYMBOL } from '~/utils/constants/common';
import { DEFAULT_INPUT_WIDTH } from '~/utils/constants/component';
import { TextEditorEnum } from '~/utils/enum';

// Styles, images, icons
import styles from './Editor.module.scss';

type Props = {
  height?: number | string;
  width?: number | string;
  label?: string;
  required?: boolean;
  mode: string;
  value?: string;
  errorMessage?: string;
  type?: TextEditorEnum;
  onChange?: (text: string) => void;
};

const cx = classNames.bind(styles);

const Editor = (props: Props) => {
  //#region Destructuring Props
  const {
    label,
    required,
    mode,
    value,
    width = DEFAULT_INPUT_WIDTH,
    height,
    errorMessage,
    type,
    onChange,
  } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  //#endregion Handle Function

  return (
    <Field id='formEditorComponent' className={cx('container')} style={{ width }}>
      {label && (
        <Label className={cx('label')}>
          {label} {required && <span className={cx('required')}>{ASTERISK_SYMBOL}</span>}
        </Label>
      )}

      <div className={cx('inputContainer')}>
        <BaseRichEditor
          mode={mode}
          height={height}
          value={value}
          errorMessage={errorMessage}
          onChange={onChange}
          type={TextEditorEnum.CUSTOM}
        />
      </div>
    </Field>
  );
};

export default Editor;
