// Libs
import { createAsyncThunk } from '@reduxjs/toolkit';
// Others
import vendorsApi from '~/apis/vendors';
import {
  CHANGE_STATUS,
  CREATE_VENDOR,
  GET_LIST_VENDORS,
  GET_LIST_VENDORS_BY_SUBCONTRACTORS,
  GET_VENDOR_DETAILS,
  UPDATE_CONTACTS_VENDOR,
  UPDATE_VENDOR,
} from '~/utils/constants/actionType';
import { IQueryListProduct } from '~/utils/interface/product';
import {
  IFormVendorBody,
  IQueryListVendor,
  IUpdateContactsVendor,
  IUpdateVendor,
} from '~/utils/interface/vendors';

export const getListVendors = createAsyncThunk(
  GET_LIST_VENDORS,
  async (payload: IQueryListVendor, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.getListVendor(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createVendor = createAsyncThunk(
  CREATE_VENDOR,
  async (body: IFormVendorBody, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.createVendor(body);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeStatusVendor = createAsyncThunk(
  CHANGE_STATUS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.changeStatusVendor(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVendorDetails = createAsyncThunk(
  GET_VENDOR_DETAILS,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.getVendorDetails(id);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateVendor = createAsyncThunk(
  UPDATE_VENDOR,
  async (payload: IUpdateVendor, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.updateVendor(payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateContactsVendor = createAsyncThunk(
  UPDATE_CONTACTS_VENDOR,
  async ({ id, body }: { id: string; body: IUpdateContactsVendor }, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.updateContactsVendor({ id, body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getListVendorsSubcontractors = createAsyncThunk(
  GET_LIST_VENDORS_BY_SUBCONTRACTORS,
  async (payload: IQueryListProduct, { rejectWithValue }) => {
    try {
      const res = await vendorsApi.getListVendor(payload);
      return { data: res.data, type: payload.type };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
