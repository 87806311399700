import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import { urlApiFixtureLocation } from '~/utils/constants/common';
import {
  IFixtureLocation,
  IFormFixtureLocationPayload,
  IUpdateFixtureLocation,
  IQueryListFixtureLocations,
} from '~/utils/interface/fixtureLocation';
import axiosClient from './axiosClient';

const fixtureLocationApi = {
  getListFixtureLocations(params: IQueryListFixtureLocations) {
    const url = `${urlApiFixtureLocation.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IFixtureLocation[]>>>(url, {
      params: params,
    });
  },

  createFixtureLocation(body: IFormFixtureLocationPayload) {
    const url = `${urlApiFixtureLocation.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  updateFixtureLocation({ fixtureLocationId, body }: IUpdateFixtureLocation) {
    const url = `${urlApiFixtureLocation.updateFixtureLocation(fixtureLocationId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },

  deleteFixtureLocation(id: string) {
    const url = `${urlApiFixtureLocation.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },
};

export default fixtureLocationApi;
