import { urlApiNote } from '~/utils/constants/common';
import axiosClient from './axiosClient';
import { ITableParams } from '~/utils/interface/common';

const noteApi = {
  getListNote(params: ITableParams) {
    const url = `${urlApiNote.note}`;
    return axiosClient.get(url, {
      params,
    });
  },

  getNoteDetail(id: string) {
    const url = `${urlApiNote.getNoteDetail}/${id}`;
    return axiosClient.get(url);
  },

  createNewNote(payload: any) {
    const url = `${urlApiNote.createNewNote}`;
    return axiosClient.post(url, payload);
  },
};

export default noteApi;
