import { BaseResponse, IListDataResponse, ITableParams } from '~/utils/interface/common';
import axiosClient from './axiosClient';
import { urlApiService } from '~/utils/constants/common';
import { IProduct } from '~/utils/interface/product';
import { IFormServicePayload, IUpdateService } from '~/utils/interface/service';

const serviceApi = {
  getListServices(params: ITableParams) {
    const url = `${urlApiService.getList}`;
    return axiosClient.get<BaseResponse<IListDataResponse<IProduct[]>>>(url, {
      params: params,
    });
  },

  createService(body: IFormServicePayload) {
    const url = `${urlApiService.create}`;
    return axiosClient.post<BaseResponse>(url, body);
  },

  deleteService(id: string) {
    const url = `${urlApiService.delete(id)}`;
    return axiosClient.delete<BaseResponse>(url);
  },

  updateService({ serviceId, body }: IUpdateService) {
    const url = `${urlApiService.updateService(serviceId)}`;
    return axiosClient.put<BaseResponse>(url, body);
  },
};

export default serviceApi;
