// Libs
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
// Components, Layouts, Pages
// Others
import { ColumnType, IDataTable } from '~/utils/interface/common';
import { DEFAULT_NUMBER_ZERO } from '~/utils/constants/common';
// Styles, images, icons
import styles from './Table.module.scss';

type Props<T> = {
  columns: ColumnType<T>[];
  dataSource: T[];
  onClickRow?: (id: string) => void;
};

const cx = classNames.bind(styles);

const Table = <T extends IDataTable>(props: Props<T>) => {
  //#region Destructuring Props
  const { dataSource = [], columns, onClickRow } = props;
  //#endregion Destructuring Props

  //#region Declare Hook
  const { t } = useTranslation();
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function
  const handleClickRow = (id: string) => {
    onClickRow && onClickRow(id);
  };
  //#endregion Handle Function
  return (
    <div id='tableComponent' className={cx('baseTableComponent')}>
      <table className={cx('tableContainer')}>
        <thead className={cx('thead')}>
          <tr>
            {columns.map((column) => (
              <th className={cx('colTable')} key={column.key}>
                {column.title && column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={cx('tbody')}>
          {dataSource.length > DEFAULT_NUMBER_ZERO ? (
            dataSource.map((data, rowIndex: number) => (
              <tr
                key={rowIndex}
                onClick={() => handleClickRow(data['id'])}
                className={cx('rowTableBody')}
              >
                {columns.map((column) => (
                  <td
                    key={column.key}
                    style={{ maxWidth: column.width, width: column.width }}
                    className={cx('colTableBody')}
                  >
                    <div className={cx('cellContainer')}>
                      {column.render
                        ? column.render(data[column.dataIndex!], data, rowIndex)
                        : (data[column.dataIndex!] as React.ReactNode)}
                    </div>
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td className={cx('noDataAvailable')}>{t('common_empty_data')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
