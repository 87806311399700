// Libs
import { createSlice } from '@reduxjs/toolkit';
// Others
import { RootState } from '~/redux/store';
import {
  createDocumentJob,
  deleteDocumentJob,
  downloadDocumentJob,
  getDocumentJobDetails,
  getListDocument,
  getListDocumentJob,
  updateDocumentJob,
} from './documentThunk';
import { IDocumentResp } from '~/utils/interface/document';

export interface DocumentState {
  documentResp: IDocumentResp;
  isRefreshListDocument?: boolean;
}

const initialState: DocumentState = {
  documentResp: {} as IDocumentResp,
  isRefreshListDocument: false,
};

const documentSlice = createSlice({
  name: 'documentState',
  initialState,
  reducers: {
    setRefreshListDocument(state, action) {
      state.isRefreshListDocument = action.payload;
    },
  },
  extraReducers(builder) {
    // Get list document
    builder
      .addCase(getListDocument.pending, (state) => {})
      .addCase(getListDocument.fulfilled, (state, action) => {})
      .addCase(getListDocument.rejected, (state) => {});

    // Get list document job
    builder
      .addCase(getListDocumentJob.pending, (state) => {})
      .addCase(getListDocumentJob.fulfilled, (state, action) => {})
      .addCase(getListDocumentJob.rejected, (state) => {});

    // Add document job
    builder
      .addCase(createDocumentJob.pending, (state) => {})
      .addCase(createDocumentJob.fulfilled, (state, action) => {})
      .addCase(createDocumentJob.rejected, (state) => {});

    // Download document job
    builder
      .addCase(downloadDocumentJob.pending, (state) => {})
      .addCase(downloadDocumentJob.fulfilled, (state, action) => {})
      .addCase(downloadDocumentJob.rejected, (state) => {});

    // Get document job details
    builder
      .addCase(getDocumentJobDetails.pending, (state) => {})
      .addCase(getDocumentJobDetails.fulfilled, (state, action) => {})
      .addCase(getDocumentJobDetails.rejected, (state) => {});

    // Update document job
    builder
      .addCase(updateDocumentJob.pending, (state) => {})
      .addCase(updateDocumentJob.fulfilled, (state, action) => {})
      .addCase(updateDocumentJob.rejected, (state) => {});

    // Delete document job
    builder
      .addCase(deleteDocumentJob.pending, (state) => {})
      .addCase(deleteDocumentJob.fulfilled, (state, action) => {})
      .addCase(deleteDocumentJob.rejected, (state) => {});
  },
});

// Action
export const documentActions = documentSlice.actions;

// Selectors
export const selectIsRefreshListDocument = (state: RootState) =>
  state.document.isRefreshListDocument;

// Get list document
export const selectDocumentResp = (state: RootState) => state.document.documentResp;

const documentReducer = documentSlice.reducer;
export default documentReducer;
