// Libs
import classNames from 'classnames/bind';
import { Outlet } from 'react-router-dom';
// Components, Layouts, Pages
// Others
// Styles, images, icons
import styles from './Accountant.module.scss';

type Props = {};

const cx = classNames.bind(styles);

const Accountant = (props: Props) => {
  //#region Destructuring Props
  //#endregion Destructuring Props

  //#region Declare Hook
  //#endregion Declare Hook

  //#region Selector
  //#endregion Selector

  //#region Declare State
  //#endregion Declare State

  //#region Implement Hook
  //#endregion Implement Hook

  //#region Handle Function

  //#endregion Handle Function

  return (
    <div id='accountantPage' className={cx('accountantPage')}>
      <Outlet />
    </div>
  );
};

export default Accountant;
